import { Card } from 'components/templates/Card';
import { Table } from 'components/templates/Tables';
import React, { useState } from 'react';
import Checkbox from 'components/atoms/Checkbox';
import AddIcon from 'components/atoms/icons/AddIcon';
import TrashIcon from 'components/atoms/icons/TrashIcon';
import { DeleteEquityPassive } from 'components/molecules/PatrimonyMapModal/DeleteEquityPassive';
import { AddPassive } from 'components/molecules/PatrimonyMapModal/AddPassive';
import PencilIcon from 'components/atoms/icons/PencilIcon';
import { EditPassive } from 'components/molecules/PatrimonyMapModal/EditPassive';

export const EquityPassive = ({ equityPassive, tooltipText }) => {
  const columnOrder = [
    'passive',
    'institution',
    'pmt',
    'installments',
    'rate',
    'cachValue',
    'totalValue',
    'discount',
    'settled',
  ];

  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const [selectedItems, setSelectedItems] = useState(
    equityPassive.body.map(() => false)
  );

  const handleCheckboxChange = index => {
    const updatedSelectedItems = selectedItems.map((item, idx) =>
      idx === index ? !item : item
    );
    setSelectedItems(updatedSelectedItems);
  };

  const handleSelectAllChange = () => {
    const allSelected = selectedItems.every(item => item);
    const updatedSelectedItems = selectedItems.map(() => !allSelected);
    setSelectedItems(updatedSelectedItems);
  };

  return (
    <Card.Container className="spending-table">
      <Table.TitleInformation
        nameTable="Passivos Patrimoniais"
        className=" patrimony red font-regular "
        tooltipText={tooltipText}
      />
      <Table.Container className="responsive">
        <Table.TableBasic className="receipt-table">
          <Table.Thead>
            <Table.Tr className="receipt-table-title debts">
              <Table.Th className="budget-table-title checkbox first-table-border">
                <Checkbox
                  checked={selectedItems.every(item => item)}
                  onChange={handleSelectAllChange}
                />
              </Table.Th>
              {equityPassive.header.map((item, index) => (
                <Table.Th className="budget-table-title" key={index}>
                  {item}
                </Table.Th>
              ))}
              <Table.Th className="budget-table-title empty last-table-border" />
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {equityPassive.body.map((column, rowIndex) => (
              <Table.Tr className="receipt-table-body" key={rowIndex}>
                <Table.Td className="receipt-table-body checkbox">
                  <Checkbox
                    checked={selectedItems[rowIndex]}
                    onChange={() => handleCheckboxChange(rowIndex)}
                  />
                </Table.Td>
                {columnOrder.map((item, index) => {
                  let currentItem = column[item];
                  if (item === 'settled') {
                    return (
                      <Table.Td className="receipt-table-body " key={index}>
                        <Checkbox
                          checked={column.settled}
                          className="circular"
                        />
                      </Table.Td>
                    );
                  }

                  return (
                    <Table.Td
                      className={`receipt-table-body `}
                      first={item === 'passive'}
                      key={index}
                    >
                      {currentItem}
                    </Table.Td>
                  );
                })}
                <Table.Td>
                  <PencilIcon
                    className="category-title"
                    onClick={() => {
                      setShowEdit(true);
                    }}
                  />
                  {showEdit && (
                    <EditPassive
                      showModal={showEdit}
                      setShowModal={setShowEdit}
                      theme="edit"
                      classificationOptions={equityPassive.modalPassive}
                    />
                  )}
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table.TableBasic>
      </Table.Container>
      <Table.GroupIcons>
        <AddIcon
          className="receipt-table"
          onClick={() => {
            setShowAdd(true);
          }}
        />
        {showAdd && (
          <AddPassive
            showModal={showAdd}
            setShowModal={setShowAdd}
            theme="patrimony"
            classificationOptions={equityPassive.modalPassive}
          />
        )}
        <TrashIcon
          className="receipt-table"
          onClick={() => {
            setShowDelete(true);
          }}
        />
        {showDelete && (
          <DeleteEquityPassive
            showModal={showDelete}
            setShowModal={setShowDelete}
            theme="debts"
          />
        )}
      </Table.GroupIcons>
    </Card.Container>
  );
};
