export const getColorPercentage = (color, item, currentPage) => {
    const spendingPage = currentPage === 'spendings';
    const receiptsPage = currentPage === 'receipts';
    const investmentsPage = currentPage === 'investments';
    const { spentMoreThanEstimated = false } = item;

    const outSpendingPage = (color === 'red' || color === 'blue' || color === 'pink') && !spendingPage
    const outReceiptsPage = color === 'dark-blue' && !receiptsPage
    const outInvestmentsPage = color === 'dark-green' && !investmentsPage
    const outAllPages = outSpendingPage || outReceiptsPage || outInvestmentsPage

    if (outAllPages) {
        return 0
    }

    if (receiptsPage) {
        return color === 'dark-blue' ? item.paymentMadeInCashPercentage : 0;
    }

    if (investmentsPage) {
        return color === 'dark-green' ? item.paymentMadeInCashPercentage : 0;
    }

    if (spentMoreThanEstimated && spendingPage) {
        return color === 'red' ? 100 : 0;
    }

    if (color === 'red' && spendingPage) {
        return spentMoreThanEstimated ? 100 : 0;
    }

    if (color === 'pink' && spendingPage) {
        return spentMoreThanEstimated ? 0 : item.installmentsPercentage;
    }

    if (color === 'blue' && spendingPage) {
        return spentMoreThanEstimated ? 0 : item.paymentMadeInCashPercentage;
    }

    return 0
}