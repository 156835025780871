import React, { useState } from 'react';
import { IconWrapper } from './styles';
import TolltipIcon from '../icons/TolltipIcon';
import { Overlay } from '../Overlay';

const TooltipIconWithOverlay = ({ tooltipText: text, className, loading }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <IconWrapper className={className}>
      <TolltipIcon
        className={className}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        loading={loading}
      />
      <Overlay className={`${className} ${isVisible && 'visible'}`}>
        {text}
      </Overlay>
    </IconWrapper>
  );
};

export default TooltipIconWithOverlay;
