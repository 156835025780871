import * as React from 'react';
import { StyledSmallLogo } from './styles';

function SmallerLogo({ className }) {
  return (
    <StyledSmallLogo
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 754 850"
      className={className}
    >
      <style>{'.st0{fill:#fff}.st2{fill:#7386ff}'}</style>
      <g id="Yuno_Know_Vert_2">
        <path
          className="st0"
          d="M239.05 0L165.39 129.92 89.64 0 0.31 0 122.55 200.03 122.55 343.87 202.48 343.87 202.48 199.52 324.73 0z"
        />
        <path
          className="st0"
          d="M467.17 232.51c0 13.75-4.12 26.04-12.37 36.86-8.25 10.83-19.94 16.24-35.06 16.24-13.07 0-22.94-3.95-29.64-11.86-6.7-7.9-10.05-18.73-10.05-32.48v-139.2H305.8V252.1c0 32.31 8.16 56.71 24.49 73.21 16.32 16.5 38.23 24.75 65.73 24.75 14.09 0 27.58-3.01 40.47-9.02s23.28-13.66 31.19-22.94l2.06 25.78h71.15v-241.8h-73.72v130.43zM266.67 419.91c-13.75 0-27.15 2.92-40.21 8.76-13.07 5.85-23.55 13.58-31.45 23.2l-2.06-26.29H121.8v241.79h73.72V537.46c0-14.09 4.2-26.46 12.63-37.12 8.42-10.65 20.7-15.98 36.86-15.98 12.03 0 21.4 3.95 28.1 11.86 6.7 7.91 10.05 18.56 10.05 31.96v139.2h74.24V517.87c0-32.3-8.25-56.71-24.75-73.21-16.48-16.5-38.48-24.75-65.98-24.75zM644.68 481.26c-10.83-19.59-25.95-35.14-45.37-46.66-19.42-11.51-42.02-17.27-67.8-17.27-25.78 0-48.38 5.76-67.79 17.27-19.42 11.52-34.63 27.07-45.63 46.66-11 19.59-16.5 41.24-16.5 64.96s5.49 45.37 16.5 64.96c9.23 16.44 25.01 34.24 40.17 45.11 39.78-27.47 105.62-30.15 148.97 0 14.83-10.79 28.49-28.91 37.44-45.11 10.83-19.59 16.24-41.24 16.24-64.96s-5.4-45.36-16.23-64.96zm-62.12 96.41c-4.47 9.97-10.91 18.04-19.33 24.23-8.43 6.19-18.99 9.28-31.71 9.28-12.37 0-22.94-3.09-31.71-9.28-8.76-6.19-15.39-14.26-19.85-24.23-4.47-9.97-6.7-20.44-6.7-31.45 0-11 2.23-21.39 6.7-31.19 4.46-9.8 11.08-17.79 19.85-23.97 8.76-6.19 19.33-9.28 31.71-9.28 12.71 0 23.28 3.09 31.71 9.28 8.42 6.19 14.86 14.18 19.33 23.97 4.46 9.8 6.7 20.2 6.7 31.19 0 11.01-2.24 21.49-6.7 31.45z"
        />
        <g>
          <path
            className="st2"
            d="M532.91 679.62c41.65 0 72.18 36.68 72.18 85.19S575.75 850 532.91 850c-42.6 0-71.94-36.68-71.94-85.19s29.34-85.19 71.94-85.19zm0 155c31.95 0 54.43-30.05 54.43-69.81 0-36.92-21.3-69.57-54.43-69.57-32.89 0-54.19 32.66-54.19 69.57 0 39.76 21.29 69.81 54.19 69.81zM661.88 727.42l-6.86-13.73-1.42.24v133.7h-17.75V681.99h17.51l75.72 123.05 5.44 11.12 1.42-.24V681.99h17.75v165.65h-17.27l-74.54-120.22z"
          />
        </g>
      </g>
    </StyledSmallLogo>
  );
}

export default SmallerLogo;
