import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IoChevronDownSharp } from 'react-icons/io5';
import { Container, Title, Menu, Item } from './styles';
import { useOutsideClick } from '../../hooks/useOutsideClick';

const arProfiles = [
  {
    title: 'Cliente',
    show: true,
    path: process.env.REACT_APP_FRONTEND_URL,
  },
  {
    title: 'Administrador',
    show: true,
    path: process.env.REACT_APP_FRONTEND_ADM_URL,
  },
  {
    title: 'Planejador',
    show: true,
    path: process.env.REACT_APP_FRONTEND_PLANNER_URL,
  },
];

const ChangeProfile = ({ theme }) => {
  const [listButtons, setListButtons] = useState(arProfiles);
  const [selectedTitle, setSelectedTitle] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const adjustProfilesBasedOnCurrentUrl = () => {
      const currentUrl = window.location.href;
      const matchingProfile = listButtons.find(
        ({ path }) => path === currentUrl
      );

      if (matchingProfile) {
        moveProfileToTop(matchingProfile);
      } else {
        setSelectedTitle(listButtons[0]?.title);
      }
    };
    adjustProfilesBasedOnCurrentUrl();
  }, [listButtons]);

  const moveProfileToTop = matchingProfile => {
    setListButtons(prevList => [
      matchingProfile,
      ...prevList.filter(profile => profile !== matchingProfile),
    ]);
    setSelectedTitle(matchingProfile.title);
  };

  const handleToggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = () => {
    setIsOpen(false);
  };

  const ref = useOutsideClick(handleClickOutside);

  const renderList = () => {
    return listButtons
      .filter(({ title }) => title !== selectedTitle)
      .map(({ title, path }) => (
        <Item textColor={getColorThemeButton()} key={title}>
          <a onClick={() => handleItemClick({ title, path })} href={path}>
            {title}
          </a>
        </Item>
      ));
  };

  const handleItemClick = item => {
    setIsOpen(false);
    setSelectedTitle(item.title);
  };

  const themeColorMap = {
    blueTitle: 'listColorsBlue',
    grayTitle: 'listColorsGray',
    blueDarkenTitle: 'listColorsBlueDarken',
  };

  const getColorThemeButton = () => themeColorMap[theme];

  return (
    <Container width="100%" height="100%" spacing={listButtons.length}>
      <div ref={ref} style={{ width: '140px' }}>
        <p style={{ color: 'gray', marginBottom: -1 }}> Trocar de Perfil</p>
        <Title theme={theme}>
          <span
            className={isOpen ? 'opened' : 'closed'}
            role="button"
            tabIndex={0}
            onClick={handleToggleMenu}
          >
            {selectedTitle}
          </span>
          <IoChevronDownSharp onClick={handleToggleMenu} />
        </Title>

        {isOpen && (
          <Menu textColor={getColorThemeButton()}>{renderList()}</Menu>
        )}
      </div>
    </Container>
  );
};

ChangeProfile.propTypes = {
  theme: PropTypes.oneOf(['blueTitle', 'grayTitle', 'blueDarkenTitle']),
};

ChangeProfile.defaultProps = {
  theme: 'grayTitle',
};

export default ChangeProfile;
