import React, { useContext } from 'react';
import { Container, CardContainer } from './styles';
import TitleCard from '../../molecules/TitleCard';
import { DreamCard } from 'components/molecules/DreamCard';
import { EmptyDreamCard } from 'components/molecules/EmptyDreamCard';
import { GeneralContext } from 'utils/contexts/GeneralContext';

const DreamPanel = ({ title, tooltipText, url, goals, className }) => {
  const { loading } = useContext(GeneralContext);

  return (
    <Container className={`${loading && 'loading'}`}>
      <TitleCard
        text={tooltipText}
        url={url}
        title={title}
        loading={loading}
        className={`${className} title-card`}
      />
      <CardContainer className={`${loading ? 'loading' : 'default'}`}>
        {!loading &&
          goals.map((item, index) => (
            <DreamCard
              key={index}
              title={item.name}
              investedValue={item.saved_money}
              timeToAchieve={item.when}
              value={item.value.value}
              percentage={item.progress}
              dreamImage={item.url}
              className="dream-panel"
            />
          ))}
        {!loading && <EmptyDreamCard className="dream-panel" />}
      </CardContainer>
    </Container>
  );
};

export default DreamPanel;
