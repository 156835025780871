import React from 'react';
import { LoadingTable } from 'components/templates/Table/LoadingTable';
import { DebtMappingPage } from '../DebtMappingPage';
import { RecipeMapPage } from '../RecipeMapPage';
import { MapInvestmentsPage } from '../MapInvestmentsPage';
import { MapSpendingPage } from '../MapSpendingPage';

export const BudgetTable = ({
  currentPage,
  investments,
  debts,
  spendings,
  loading = true,
  receipts = [],
  reloadPageData,
}) => {

  if (loading) {
    return <LoadingTable />;
  }
  const pages = {
    receipts: <RecipeMapPage data={receipts} reloadPageData={reloadPageData} />,
    debts: <DebtMappingPage data={debts} reloadPageData={reloadPageData} />,
    investments: <MapInvestmentsPage investments={investments} />,
    spendings: <MapSpendingPage data={spendings} reloadPageData={reloadPageData} />,
  };
  return pages[currentPage] || <></>;
};
