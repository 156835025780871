import * as React from 'react';
import { StyledRiskManagementIcon } from './styles';

function RiskManagementIcon({ className }) {
  return (
    <StyledRiskManagementIcon
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.367 32.343a2.336 2.336 0 01-2.049-1.188 2.344 2.344 0 01.007-2.37L19.53 9.518a2.34 2.34 0 012.042-1.176h.006a2.338 2.338 0 012.043 1.188l11.061 19.267a2.35 2.35 0 01-.002 2.364 2.339 2.339 0 01-2.047 1.181H10.367zM21.572 9.387c-.476 0-.902.245-1.142.656L9.227 29.311a1.31 1.31 0 00-.004 1.325c.238.415.666.663 1.145.663h22.265c.477 0 .905-.247 1.143-.66.239-.415.24-.908.002-1.323L22.716 10.05a1.307 1.307 0 00-1.142-.663h-.003z"
        fill="#fff"
      />
      <path
        d="M21.5 25.426c-.931 0-1.69-.76-1.69-1.694v-9.453c0-.933.759-1.694 1.69-1.694.932 0 1.69.76 1.69 1.694v9.454c0 .933-.758 1.694-1.69 1.694v-.001zm0-11.743a.595.595 0 00-.594.595v9.454a.595.595 0 001.189 0v-9.453a.595.595 0 00-.595-.595v-.001zM21.5 30.085a1.985 1.985 0 010-3.97 1.985 1.985 0 010 3.97zm0-2.926a.94.94 0 10.002 1.881.94.94 0 00-.002-1.881z"
        fill="#fff"
      />
    </StyledRiskManagementIcon>
  );
}

export default RiskManagementIcon;
