import React from 'react';
import { ProgressBarContainer, Progress, Container } from './styles';

const ProgressBar = ({ progress, className }) => {
  return (
    <Container className={className}>
      <ProgressBarContainer className={className}>
        <Progress className={className} progress={progress} />
      </ProgressBarContainer>
    </Container>
  );
};

export default ProgressBar;
