import React from 'react';
import { ArrowContainer } from './styles';

const ArrowDropdown = ({ width, height, className }) => {
  return (
    <ArrowContainer
      width={width}
      height={height}
      viewBox="0 0 11 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1 1l4.5 4L10 1"
        stroke="#8C8B8C"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </ArrowContainer>
  );
};

export default ArrowDropdown;
