import React, { useContext, useState, useEffect } from 'react';
import { Card } from 'components/templates/Card';
import { GeneralContext } from 'utils/contexts/GeneralContext';

export const SocialSecurity = ({
  title = 'Previdência Social',
  icon = 'socialSecurity',
  currentPage,
  setCurrentPage,
  totalValue,
}) => {
  const { loading } = useContext(GeneralContext);

  const [selected, setSelected] = useState(currentPage === 'active');
  const [hovered, setHovered] = useState(currentPage === 'active');

  const select = selected || hovered ? 'selected-patrimony' : 'passive';
  const selectedClass =
    selected || hovered ? 'selected-patrimony' : 'patrimony';
  const loadingClass = loading ? 'loading' : '';

  const handleClick = () => {
    setSelected(!selected);
    setCurrentPage('socialSecurity');
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  useEffect(() => {
    setSelected(currentPage === 'socialSecurity');
    setHovered(currentPage === 'socialSecurity');
  }, [currentPage]);

  return (
    <Card.Container
      selected={selected}
      className={`budget ${loadingClass} ${selectedClass}`}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Card.BodyContainer className={`budget ${loadingClass}`}>
        <Card.Title
          title={title}
          type={icon}
          className={`spending-card ${select} blue`}
        />
      </Card.BodyContainer>
      <Card.Content className={`budget ${loadingClass}`}>
        <Card.ContentMoney
          className={`${select} blue`}
          label="Status atual"
          value={totalValue}
        />
      </Card.Content>
    </Card.Container>
  );
};
