import styled from "styled-components";

export const Container = styled.div`
    width: 168px;
    margin-left: 2.63%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1440px){
        width: 144px;
        margin-left: 2.5%;
    }
`