import styled from "styled-components";

export const SVG = styled.svg`
   &.extract-title {
      width: 19px;
      height: 18px;

      path {
        fill: ${({ theme }) => theme.blueMidnight};
      }

      @media (max-width: 1440px) {
         width: 16px;
         height: 14px;
      }
   }

    &.extract-title.item {
        path {
            fill: ${({ theme }) => theme.white};
        }
    }
`