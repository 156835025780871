import * as React from "react"
import { SVG } from "./styles"

export const WalletCategoryIcon = (props) => {
    return (
        <SVG
            width={20}
            height={18}
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.247.743A2.554 2.554 0 013.049 0h12.049c.43 0 .842.17 1.146.473.305.303.476.714.476 1.142v2.077h.231c.43 0 .843.17 1.147.473.304.303.475.714.475 1.143v3.156a1.622 1.622 0 01.927 1.46v1.845c0 .429-.17.84-.475 1.143a1.625 1.625 0 01-1.147.473h-2.78a2.554 2.554 0 01-1.803-.744 2.533 2.533 0 010-3.59 2.554 2.554 0 011.803-.743h2.085v-3a.23.23 0 00-.232-.231H3.05a2.554 2.554 0 01-1.802-.744 2.533 2.533 0 010-3.59zm14.082 2.95V1.614a.23.23 0 00-.231-.23H3.048a1.16 1.16 0 00-.818.338 1.151 1.151 0 00.819 1.97h12.28zm-.231 6a1.16 1.16 0 00-1.159 1.153A1.16 1.16 0 0015.098 12h2.78a.232.232 0 00.232-.23V9.922a.23.23 0 00-.232-.23h-2.78z"
                fill="#fff"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.195 1.846c.384 0 .695.31.695.692v12.923a1.16 1.16 0 001.159 1.154H16.95a.232.232 0 00.232-.23v-3.693c0-.382.311-.692.695-.692.384 0 .695.31.695.692v3.693c0 .428-.17.839-.475 1.142a1.625 1.625 0 01-1.147.473H3.05a2.554 2.554 0 01-1.802-.744A2.533 2.533 0 01.5 15.461V2.538c0-.382.311-.692.695-.692z"
                fill="#fff"
            />
        </SVG>
    )
}
