import { Button } from 'components/atoms/Button';
import { EditFinancialPatrimony } from 'components/molecules/RetirementMapModal/EditFinancialPatrimony';
import { EditRecom } from 'components/molecules/RetirementMapModal/EditRecom';
import { Card } from 'components/templates/Card';
import { Table } from 'components/templates/Tables';
import React, { useState } from 'react';
import { CenteredDiv } from './styles';

export const FinancialPatrimony = ({ financialPatrimony }) => {
  const [currentTableIndex, setCurrentTableIndex] = useState(0);
  const maxTables = financialPatrimony.tables.length;

  const updateWhetherAmountWasSpentOnTheCard = spendingId => {};

  const columnOrder1 = ['data'];
  const columnOrder2 = ['plan'];
  // const columnOrder3 = ['recom'];

  const handleNextTable = () => {
    if (currentTableIndex < maxTables - 1) {
      setCurrentTableIndex(currentTableIndex + 1);
    }
  };

  const handlePrevTable = () => {
    if (currentTableIndex > 0) {
      setCurrentTableIndex(currentTableIndex - 1);
    }
  };

  const tableData = financialPatrimony.tables[currentTableIndex];

  const [showEdit, setShowEdit] = useState(false);
  const [showEditRecom, setShowEditRecom] = useState(false);

  const handleEditClick = () => {
    setShowEdit(true);
  };
  const handleEditRecom = () => {
    setShowEditRecom(true);
  };

  return (
    <Card.Container className="spending-table">
      <Table.TitleSet
        nameTable="Previdência Privada"
        className="patrimony font-regular blue"
        onClickLeft={handlePrevTable}
        onClickRight={handleNextTable}
        currentTableIndex={currentTableIndex}
        maxTables={maxTables}
      />
      <Card.Container className="table-recom ">
        <Table.Container className="responsive">
          <Table.TableBasic className="min-table">
            <Table.Thead>
              <Table.Tr className="receipt-table-title receipts">
                <Table.Th
                  className={`budget-table-title
                  first-table-border checkbox`}
                >
                  {tableData.header[0]}
                </Table.Th>
                <Table.Th className="budget-table-title empty last-table-border" />
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {tableData.body.map((column, rowIndex) => (
                <Table.Tr className="receipt-table-body" key={rowIndex}>
                  {columnOrder1.map((item, index) => {
                    const currentItem = column[item];
                    return (
                      <Table.Td
                        className={`receipt-table-body ${item === 'data' &&
                          'first-table-border checkbox'}`}
                        first={item === 'data'}
                        key={index}
                      >
                        {currentItem}
                      </Table.Td>
                    );
                  })}
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table.TableBasic>
        </Table.Container>
        <Table.Container className="responsive">
          <Table.TableBasic className="plan-table">
            <Table.Thead>
              <Table.Tr className="receipt-table-title receipts">
                <Table.Th className={`budget-table-title first-table-border `}>
                  <div onClick={handleEditClick}>
                    <Table.ContentIcon
                      item={tableData.header[1]}
                      type="pencil-clip"
                    />
                  </div>
                </Table.Th>

                <Table.Th className="budget-table-title empty last-table-border" />
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {tableData.body.map((column, rowIndex) => (
                <Table.Tr className="receipt-table-body" key={rowIndex}>
                  {columnOrder2.map((item, index) => {
                    const currentItem = column[item];

                    if (currentItem === true || currentItem === false) {
                      return (
                        <Table.Td className="receipt-table-body" key={index}>
                          <Table.Switch
                            checked={currentItem}
                            onClick={() =>
                              updateWhetherAmountWasSpentOnTheCard(
                                column.spendingId
                              )
                            }
                            getValue={() => {}}
                          />
                        </Table.Td>
                      );
                    }
                    return (
                      <Table.Td
                        className={`receipt-table-body first-table-border `}
                      >
                        {currentItem}
                      </Table.Td>
                    );
                  })}
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table.TableBasic>
        </Table.Container>
        <Table.Container className="responsive">
          <Table.TableBasic className="recom-table">
            <Table.Thead>
              <Table.Tr className="receipt-table-title receipts">
                <Table.Th className={`budget-table-title first-table-border `}>
                  <div onClick={handleEditRecom}>
                    <Table.ContentIcon item="Recomendação" type="pencil" />
                  </div>
                </Table.Th>

                <Table.Th className="budget-table-title empty last-table-border" />
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              <Table.Tr className="receipt-table-title">
                <Table.Td className="options">
                  <CenteredDiv>
                    <Button className="retirement-table">
                      {tableData.recom}
                    </Button>
                  </CenteredDiv>
                </Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table.TableBasic>
        </Table.Container>
      </Card.Container>
      {showEdit && (
        <EditFinancialPatrimony
          showModal={showEdit}
          setShowModal={setShowEdit}
          theme="edit"
        />
      )}
      {showEditRecom && (
        <EditRecom
          showModal={showEditRecom}
          setShowModal={setShowEditRecom}
          theme="edit"
        />
      )}
    </Card.Container>
  );
};
