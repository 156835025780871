import styled from 'styled-components';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

export const StyledSummaryCardRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ContainerSummaryCardRow = styled(StyledSummaryCardRow)`
  background-color: ${({ theme }) => theme.grayLight};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 4px;

  gap: 14px;
  padding: 12px;

  &.first-part {
    width: 70%;
  }

  &.second-part {
    width: calc(30% - 15px);
  }

  &.loading {
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 5rem;
    text-align: center;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.gradientSkeleton[0]} 25%,
      ${({ theme }) => theme.gradientSkeleton[1]} 50%,
      ${({ theme }) => theme.gradientSkeleton[0]} 75%
    );
    background-size: 200% 100%;
    animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;
  }

  &.loading.first-part {
    width: 70%;

    @media (max-width: 1700px) {
      width: 68%;
    }

    @media (max-width: 1500px) {
      width: 65%;
    }

    @media (max-width: 1280px) {
      width: 60%;
    }
  }

  &.loading.second-part {
    width: 28%;

    @media (max-width: 1650px) {
      width: 30%;
    }

    @media (max-width: 1500px) {
      width: 32%;
    }

    @media (max-width: 1280px) {
      width: 36%;
    }
  }
`;

export const ContainerShoppingCartIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;

  &.summary-card {
    background-color: ${({ theme, type }) => {
      const defaultColors = {
        entry: theme.blueSky,
        installment: theme.pinkInstallmentPurchases,
      };
      return defaultColors[type] || 'red';
    }};
    border-radius: 4px;
    padding-top: 5px;
    padding-left: 3px;
    padding-right: 4px;
    padding-bottom: 5px;
  }
`;
