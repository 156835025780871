import React from 'react';
import { StyledIcon } from './styles';

function ActiveIcon({ className }) {
  return (
    <StyledIcon
      viewBox="0 0 34 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1 27h3.2m0 0h17.6M4.2 27v-9.152c0-.853 0-1.28.1-1.679a3.3 3.3 0 01.436-.99c.224-.342.538-.63 1.163-1.199l3.682-3.36c1.208-1.102 1.811-1.653 2.496-1.862a3.153 3.153 0 011.846 0c.685.21 1.29.76 2.5 1.864l3.68 3.358c.623.57.935.857 1.161 1.198a3.3 3.3 0 01.435.991c.101.398.101.826.101 1.679V27m0 0h8m0 0H33m-3.2 0V6.195c0-1.817 0-2.727-.349-3.42a3.227 3.227 0 00-1.4-1.42C27.368 1 26.472 1 24.68 1H14.12c-1.792 0-2.688 0-3.373.354a3.224 3.224 0 00-1.398 1.42C9 3.47 9 4.38 9 6.2v4.55"
        stroke="#140361"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledIcon>
  );
}

export default ActiveIcon;
