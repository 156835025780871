import React from 'react';
import { StyledIcon } from './styles';

function SetRightIcon({ className, onClick }) {
  return (
    <StyledIcon
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M10 19.25l6.125-6.125L10 7"
        stroke="#0C053F"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 19.25l6.125-6.125L10 7"
        stroke="#000"
        strokeOpacity={0.2}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 19.25l6.125-6.125L10 7"
        stroke="#000"
        strokeOpacity={0.2}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 19.25l6.125-6.125L10 7"
        stroke="#000"
        strokeOpacity={0.2}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 19.25l6.125-6.125L10 7"
        stroke="#000"
        strokeOpacity={0.2}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 19.25l6.125-6.125L10 7"
        stroke="#000"
        strokeOpacity={0.2}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledIcon>
  );
}

export default SetRightIcon;
