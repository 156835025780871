import styled from 'styled-components';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

export const Container = styled.div`
  padding: ${props => props.theme.getResponsiveWidth(32)};
  border-radius: 16px;
  border-radius: ${props => props.theme.getResponsiveWidth(16)};
  background-color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${props => props.theme.getResponsiveWidth(376)};
  height: ${props => props.theme.getResponsiveHeight(256)};
  overflow: hidden;
  box-shadow: 4px 4px 15px 0px ${({ theme }) => theme.boxShadowColor};

  &:hover {
    transition: transform 0.3s ease;
    transform: scale(1.05);
    transform-origin: left;
  }

  &.selected {
    background-color: ${({ theme }) => theme.greenDark};
  }
  &.loading {
    overflow-x: hidden;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.gradientSkeleton[0]} 25%,
      ${({ theme }) => theme.gradientSkeleton[1]} 50%,
      ${({ theme }) => theme.gradientSkeleton[0]} 75%
    );
    background-size: 200% 100%;
    animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;
  }
`;

export const Card = styled.div`
  width: ${props => props.theme.getResponsiveWidth(163)};
  height: ${props => props.theme.getResponsiveHeight(192)};
  gap: ${props => props.theme.getResponsiveHeight(35)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.loading {
    display: none;
  }
`;

export const TitleContainer = styled.div`
  width: ${props => props.theme.getResponsiveWidth(163)};
  height: ${props => props.theme.getResponsiveHeight(72)};
`;
