import styled from "styled-components";

export const Container = styled.div`
    margin-top: ${props => props.theme.getResponsiveWidth(34)};
    margin-bottom: ${props => props.theme.getResponsiveWidth(182.5)};

    @media (max-width: 1023px) {
        margin-top: 18.1px;
    }
`;

export const ProfileContainer = styled.div`
    margin-top: ${props => props.theme.getResponsiveWidth(40.5)};
    display: flex;
    margin-left: ${props => props.theme.getResponsiveWidth(229)};
    background-color: ${props => props.theme.white};
    width: ${props => props.theme.getResponsiveWidth(1142)};
    border-radius: ${props => props.theme.getResponsiveWidth(16)};
    justify-self: center;
    align-self: center;

    @media (max-width: 1023px) {
        margin-top: 21.6px;
        width: 609.1px;
        border-radius: 8.5px;
    }

    @media (max-width: 768px){
        margin-left: 0;
    }
`;

export const ProfileDescriptionContainer = styled.div`
    width: ${props => props.theme.getResponsiveWidth(512)};
    padding: ${props => props.theme.getResponsiveWidth(8)};
    margin-left: ${props => props.theme.getResponsiveWidth(32)};

    @media (max-width: 1023px) {
        width: 273.1px;
        padding: 4.3px;
        margin-left: 17.1px;
    }
`;

export const Wrapper = styled.div`
    width: ${props => props.theme.getResponsiveWidth(1600)};
    overflow: auto;

    &.button {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;