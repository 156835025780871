import React from 'react';
import { Svg } from './styles';

export const DecreaseArrow = ({ className = '', onClick = () => {} }) => {
  return (
    <Svg
      viewBox="0 0 11 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path d="M10.5 0H.5l5 5 5-5z" fill="#D9D9D9" />
    </Svg>
  );
};
