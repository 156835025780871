import Checkbox from 'components/atoms/Checkbox';
import { Table } from 'components/atoms/Table';
import { Td } from 'components/atoms/Td';
import { Th } from 'components/atoms/Th';
import { Tr } from 'components/atoms/Tr';
import React, { useState, useEffect } from 'react';
import { InvestmentsTableTItle } from 'components/molecules/InvestmentsTableTItle';
import { CardContainer, Container } from './styles';

export const InvestmentsTable = ({
  body,
  nameTable,
  totalCategoryValue,
  totalCategoryPercentage,
  typeCard,
  showTooltip,
  tooltipText,
}) => {
  const [tableData, setTableData] = useState([]);
  const columnOrder = [
    'name',
    'value',
    'patrimonial_active_value',
    'financial_active_value',
    'when',
    'allocation_strategy',
    'completed',
  ];

  const header = [
    'Nome',
    'Valor Total',
    'Patrimônio Imobilizado Atual',
    'Patrimônio Financeiro Atual',
    'Prazo (meses)',
    'Estratégia de Alocação',
    'Concluído',
  ]

  useEffect(() => {
    setTableData(body);
  }, [body]);

  return (
    <CardContainer className="no-footer">
      <InvestmentsTableTItle
        nameTable={nameTable}
        total={totalCategoryValue}
        percentage={totalCategoryPercentage}
        className={typeCard}
        showTooltip={showTooltip}
        tooltipText={tooltipText}
      />
      <Container>
        <Table className="receipt-table">
          <thead>
            <Tr className="receipt-table-title investments">
              {header.map((item, index) => (
                <Th
                  className={`budget-table-title ${index === 0 &&
                    'first-table-border '}`}
                  first={index === 0}
                  key={index}
                >
                  {item}
                </Th>
              ))}
              <Th className="budget-table-title empty last-table-border" />
            </Tr>
          </thead>
          <tbody>
            {tableData.map((column, rowIndex) => (
              <Tr className="receipt-table-body" key={rowIndex}>
                {columnOrder.map((item, index) => {
                  if (item === 'completed') {
                    return (
                      <Td className="receipt-table-body " key={index}>
                        <Checkbox checked={column[item]} className="circular" />
                      </Td>
                    );
                  }

                  return (
                    <Td
                      className="receipt-table-body"
                      first={item === 'name'}
                      key={item}
                    >
                      {column[item] || '-'}
                    </Td>
                  );
                })}
              </Tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </CardContainer>
  );
};
