import styled from 'styled-components';
import { VerticalScrollbarStyles } from 'styles/components/VerticalScrollbarStyles';

export const Container = styled.div`
  flex: 1 1 16%;
  background-color: ${({ theme }) => theme.white};
  min-width: 330px;
  padding: 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  min-height: 277px;
  border-radius: 16px;
  height: 100%;
  box-shadow: 4px 4px 15px 0px ${({ theme }) => theme.boxShadowColor};

`;

export const GroupEmptyCard = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  ${VerticalScrollbarStyles};
`;

export const SpendingCard = styled.div`
  margin-top: 2%;
  padding-right: 2%;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  height: 100%;
  gap: 15px;

  &.empty {
    margin-top: 0;
    justify-content: start;
    width: 95%;
    height: 100%;
    border: 0.1vw solid ${({ theme }) => theme.grayPale};
  }

  &.loading {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.52vw;
    margin-bottom: 2.41vh;
    overflow: auto;
    padding-right: 2%;
    margin-top: 1.81vh;
    height: 31.39vh;
  }

  ${VerticalScrollbarStyles}
`;
