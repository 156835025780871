import React, { useState } from 'react';
import { Span } from 'components/atoms/Span';
import { Button } from 'components/atoms/Button';
import SpendingsIcon from 'components/atoms/icons/SpendingsIcon';
import ReceiptIcon from 'components/atoms/icons/ReceiptIcon';
import InvestmentsIcon from 'components/atoms/icons/InvestmentsIcon';
import { Container, ContainerOverlay, DivContainer, Wrapper } from './styles';
import DebtsIcon from 'components/atoms/icons/DebtsIcon';

const icons = {
  receipt: ReceiptIcon,
  spendings: SpendingsIcon,
  debts: DebtsIcon,
  investment: InvestmentsIcon,
};

const buttonClasses = {
  receipt: 'report-receipt',
  spendings: 'report-spending',
  debts: 'report-debts',
  investment: 'report-investment',
};

const spanTextMapping = {
  receipt: { text: 'RECEBIMENTO', className: 'blue' },
  spendings: { text: 'GASTO', className: 'blue-sky' },
  debts: { text: 'PARCELADO NO CARTÃO', className: 'red' },
  investment: { text: 'INVESTIMENTOS', className: 'green' },
};

export default function NavegationButton({ currentPage, setCurrentPage }) {
  const [hoveredButton, setHoveredButton] = useState(null);

  const handleSelectButton = button => {
    setCurrentPage(button);
  };

  const handleMouseEnter = button => {
    setHoveredButton(button);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  const isButtonSelected = button =>
    currentPage === button || hoveredButton === button;
  const { text, className } = spanTextMapping[currentPage] || {};

  return (
    <Container>
      <DivContainer>
        {Object.entries(icons).map(([buttonType, IconComponent]) => (
          <ContainerOverlay
            key={buttonType}
            onMouseEnter={() => handleMouseEnter(buttonType)}
            onMouseLeave={handleMouseLeave}
          >
            <Button
              className={`${buttonClasses[buttonType]} ${
                isButtonSelected(buttonType) ? 'selected' : ''
              }`}
              onClick={() => handleSelectButton(buttonType)}
            >
              <IconComponent
                className={`report ${
                  isButtonSelected(buttonType) ? 'selected' : ''
                }`}
              />
            </Button>
          </ContainerOverlay>
        ))}
      </DivContainer>
      <Wrapper>
        <Span className="modal-realease">
          Insira os dados do seu
          <Span className={`modal-realease ${className}`}>{text}</Span>
        </Span>
      </Wrapper>
    </Container>
  );
}
