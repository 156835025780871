import React from "react";
import { Container } from "./styles";
import { EnlargeArrow } from "components/atoms/icons/EnlargeArrow";
import { DecreaseArrow } from "components/atoms/icons/DecreaseArrow";

export const UpAndDownSelector = ({
    className = '',
}) => {
    return (
        <Container className={className}>
            <EnlargeArrow className={className} />
            <DecreaseArrow className={className} />
        </Container>
    )
}