import styled from "styled-components";

export const GraphContainer = styled.div`
    &.financial-assets {
        width: ${props => props.theme.getResponsiveWidth(784)};
        height: ${props => props.theme.getResponsiveWidth(512)};
        border-radius: ${props => props.theme.getResponsiveWidth(16)};
        padding: ${props => props.theme.getResponsiveWidth(48)};
        background-color: ${props => props.theme.white};

        @media (max-width: 1023px) {
            width: 418.1px;
            height: 273.1px;
            border-radius: 8.5px;
            padding: 25.6px;
        }
    }
`;

export const GraphWrapper = styled.div`
    &.financial-assets {
        display: flex;
        width: 100%;
    }
`;

export const LabelContainer = styled.div`
    &.financial-assets {
       margin-left: ${props => props.theme.getResponsiveWidth(112)};
       margin-top: ${props => props.theme.getResponsiveWidth(228)};

       @media (max-width: 1023px) {
        margin-left: 59.7px;
        margin-top: 121.6px;
       }
    }
`;

export const DescriptionContainer = styled.div`
    display: flex;
    width: ${props => props.theme.getResponsiveWidth(162)};
    height: ${props => props.theme.getResponsiveWidth(17)};
    border-radius: ${props => props.theme.getResponsiveWidth(32)};
    background-color: ${props => props.backgroundColor};
    margin-top: ${props => props.theme.getResponsiveWidth(4)};

    @media (max-width: 1023px) {
        display: flex;
        width: 86.4px;
        height: 9.1px;
        border-radius: 17.1px;
        margin-top: 2.1px;
    }

    &.first {
        margin-top: 0;
    }
`;