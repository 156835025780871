import * as React from 'react';
import { StyledAddIcon } from './styles';

function AddIcon({ ...props }) {
  return (
    <StyledAddIcon
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.404 9.714h-8.117V1.596A.788.788 0 0010.5.81a.788.788 0 00-.786.786v8.118H1.596a.788.788 0 00-.786.786c0 .433.353.786.786.786h8.118v8.118c0 .433.353.787.786.787a.788.788 0 00.787-.787v-8.118h8.117a.788.788 0 00.787-.786.788.788 0 00-.787-.786z"
        fill="#6C6C6C"
      />
    </StyledAddIcon>
  );
}

export default AddIcon;
