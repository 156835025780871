import * as React from 'react';
import { StyledTaxPlanningIcon } from './styles';

function TaxPlanningIcon({ className }) {
  return (
    <StyledTaxPlanningIcon
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M28.226 34.037H13.47c-1.535 0-2.784-1.159-2.784-2.583V10.879c0-1.425 1.25-2.584 2.784-2.584h14.756c1.535 0 2.783 1.16 2.783 2.584v20.575c0 1.424-1.248 2.583-2.783 2.583zM13.47 9.421c-.866 0-1.571.654-1.571 1.458v20.575c0 .803.705 1.458 1.57 1.458h14.757c.866 0 1.57-.655 1.57-1.458V10.879c0-.804-.704-1.458-1.57-1.458H13.47z"
        fill="#fff"
      />
      <path
        d="M27.111 17.64H14.55c-.856 0-1.553-.646-1.553-1.442v-4.395c0-.794.696-1.442 1.553-1.442H27.11c.856 0 1.553.646 1.553 1.442v4.395c0 .794-.696 1.442-1.553 1.442zM14.55 11.487c-.189 0-.34.141-.34.316v4.395c0 .175.151.316.34.316H27.11c.188 0 .34-.141.34-.316v-4.395c0-.175-.152-.316-.34-.316H14.55zM15.782 32.183h-1.946c-.463 0-.839-.35-.839-.778v-1.807c0-.429.377-.778.839-.778h1.946c.462 0 .839.35.839.778v1.807c0 .429-.377.778-.84.778zm-1.574-1.125h1.2v-1.114h-1.2v1.114zM21.803 32.19h-1.945c-.463 0-.84-.35-.84-.778v-1.807c0-.429.377-.778.84-.778h1.945c.463 0 .84.35.84.778v1.807c0 .429-.377.778-.84.778zm-1.573-1.125h1.2V29.95h-1.2v1.114zM15.782 27.058h-1.946c-.463 0-.839-.35-.839-.779v-1.806c0-.429.377-.778.839-.778h1.946c.462 0 .839.349.839.778v1.806c0 .43-.377.78-.84.78zm-1.574-1.125h1.2v-1.114h-1.2v1.114zM21.803 27.065h-1.945c-.463 0-.84-.35-.84-.779V24.48c0-.429.377-.779.84-.779h1.945c.463 0 .84.35.84.78v1.805c0 .43-.377.78-.84.78zM20.23 25.94h1.2v-1.114h-1.2v1.114zM15.782 22.183h-1.946c-.463 0-.839-.35-.839-.779v-1.806c0-.429.377-.778.839-.778h1.946c.462 0 .839.35.839.778v1.806c0 .43-.377.78-.84.78zm-1.574-1.125h1.2v-1.114h-1.2v1.114zM21.803 22.19h-1.945c-.463 0-.84-.35-.84-.779v-1.806c0-.429.377-.778.84-.778h1.945c.463 0 .84.35.84.778v1.806c0 .43-.377.78-.84.78zm-1.573-1.125h1.2V19.95h-1.2v1.114zM27.825 22.19H25.88c-.462 0-.839-.35-.839-.779v-1.806c0-.429.377-.778.84-.778h1.945c.463 0 .839.35.839.778v1.806c0 .43-.377.78-.839.78zm-1.574-1.125h1.2V19.95h-1.2v1.114zM27.635 32.18H26.07c-.568 0-1.029-.429-1.029-.954v-6.577c0-.527.463-.954 1.029-.954h1.566c.568 0 1.029.429 1.029.954v6.577c0 .527-.462.955-1.029.955zm-1.384-1.125h1.2V24.82h-1.2v6.236z"
        fill="#fff"
      />
    </StyledTaxPlanningIcon>
  );
}

export default TaxPlanningIcon;
