import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${({ theme }) => theme.getResponsiveHeight(46)};
  align-items: center;
`;

export const ContainerWrapper = styled.div``;

export const TitleWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.getResponsiveWidth(9)};
`;

export const GroupItens = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.getResponsiveWidth(129)};
  justify-content: space-between;
`;
