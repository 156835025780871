import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { IconContainer, InputContainer } from "./styles";
import { FiSearch } from "react-icons/fi";
import { Input } from "components/atoms/Input";

export const SearchBox = ({
    text = '',
    setText = () => null
}) => {
    const [isInputVisible, setIsInputVisible] = useState(false);
    const inputRef = useRef(null);

    const handleClick = () => {
        setIsInputVisible(true);
    };

    const handleBlur = useCallback((e) => {
        if (inputRef.current && !inputRef.current.contains(e.target)) {
            setIsInputVisible(false);
            setText('');
        }
    }, [inputRef, setText]);

    useEffect(() => {
        document.addEventListener('mousedown', handleBlur);
        return () => {
            document.removeEventListener('mousedown', handleBlur);
        };
    }, [handleBlur]);

    const renderedInput = useMemo(() => (
        <InputContainer className="main">
            <Input
                type="text"
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="Categoria, nome, valor..."
                className="extract-menu-header"
            />
            <FiSearch className="detailed-extract-search-icon-right" />
        </InputContainer>
    ), [text, setText]);

    return (
        <div ref={inputRef}>
            {isInputVisible ? renderedInput : (
                <IconContainer className="detailed-extract-search-icon" onClick={handleClick}>
                    <FiSearch className="detailed-extract-search-icon" />
                </IconContainer>
            )}
        </div>
    );
};
