import * as React from "react";
import { StyledIcon, ImageIconContainer } from "./styles";

export const ImageIcon = ({
    className = '',
}) => {
    return (
        <ImageIconContainer className={className}>
            <StyledIcon
                width={22}
                height={22}
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <path
                    d="M19.556 2.444v17.112H2.444V2.444h17.112zm0-2.444H2.444A2.452 2.452 0 000 2.444v17.112C0 20.9 1.1 22 2.444 22h17.112C20.9 22 22 20.9 22 19.556V2.444C22 1.1 20.9 0 19.556 0zm-5.94 10.829l-3.667 4.73-2.616-3.166-3.666 4.718h14.666l-4.717-6.282z"
                    fill="#E2E2E2"
                />
            </StyledIcon>
        </ImageIconContainer>
    )
}
