import styled from 'styled-components';

export const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const DropdownTrigger = styled.div`
  height: 30px;
  background-color: transparent;
  padding: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.grayShadow};

  @media (max-width: 1440px) {
    height: 24px;
    padding: 6px;
  }

  @media (max-width: 1023px) {
    height: 16px;
    padding: 4px;
  }
  
  &.simple-modal {
    margin-top: 11px;

    @media (max-width: 1440px) {
      margin-top: 9px;
    }

    @media (max-width: 1024px) {
      margin-top: 7px;
    }

    &.min-size {
      margin-top: 0;
    }
  }

  &.hide-border {
    border: none;
  }

  &.generic-dream-modal {
    height: ${props => props.theme.getResponsiveWidth(30)};
    margin-top: ${props => props.theme.getResponsiveWidth(11)};
    padding: ${props => props.theme.getResponsiveWidth(8)};
    border-bottom: ${props => props.theme.getResponsiveWidth(1)} solid
      ${props => props.theme.grayShadow};

    @media (max-width: 1023px) {
      height: 16px;
      margin-top: 5.87px;
      padding: 4.27px;
      border-bottom: 0.53px solid ${props => props.theme.grayShadow};
    }
  }
`;

export const DropdownTriggerLabel = styled.span`
  font-family: ${props => props.theme.fonts.AperturaRegular};
  font-size: 0.7rem;
  line-height: 0.7rem;
  color: ${({ theme }) => theme.graySelectPlaceholder};
  font-weight: 400;

  @media (max-width: 1440px) {
    font-size: 0.6rem;
    line-height: 0.6rem;
  }

  @media (max-width: 1024px) {
    font-size: 0.5rem;
    line-height: 0.5rem;
  }

  &.generic-dream-modal {
    font-size: ${props => props.theme.getResponsiveWidth(14)};
    line-height: ${props => props.theme.getResponsiveWidth(14)};

    @media (max-width: 1023px) {
      font-size: 7.47px;
      line-height: 7.47px;
    }
  }
`;

export const DropdownTriggerIcon = styled.span`
  transition: transform 0.2s ease-in-out;
  transform: ${props => (props.isOpen ? 'rotate(180deg)' : 'none')};
`;

export const DropdownOptionsList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.grayPale};
  border-radius: 4px;
  list-style: none;
  padding: 0;
  margin: 0;
  display: none;
  max-height: 262px;
  overflow: auto;
  display: ${props => (props.isOpen ? 'block' : 'none')};
  z-index: 1000;

  @media (max-width: 1440px) {
    max-height: 220px;
  }

  @media (max-width: 1024px) {
    max-height: 139.7px;
  }

  &.generic-dream-modal {
    border-radius: ${({ theme }) => theme.getResponsiveWidth(4)};
    max-height: ${props => props.theme.getResponsiveWidth(262)};

    @media (max-width: 1023px) {
      border-radius: 2.1px;
      max-height: 139.7px;
    }
  }
`;

export const DropdownOptionItem = styled.li`
  padding: 10px 16px;
  cursor: pointer;

  @media (max-width: 1440px) {
    padding: 8px 12px;
  }

  @media (max-width: 1024px) {
    padding: 5.33px 8.53px;
  }

  &:hover {
    background-color: ${props => props.theme.selectHover};

    span {
      color: ${props => props.theme.white};
    }
  }

  &.margin {
    padding-left: ${({ theme }) => theme.getResponsiveWidth(10)};
  }

  &.generic-dream-modal {
    padding: ${props => props.theme.getResponsiveWidth(10)},
      ${props => props.theme.getResponsiveWidth(16)};
    width: 100%;

    @media (max-width: 1023px) {
      padding: 5.33px 8.53px;
    }
  }
`;

export const DropdownOptionItemLabel = styled.span`
  font-family: ${props => props.theme.fonts.AperturaRegular};
  font-size: 0.7rem;
  line-height:0.7rem;
  font-weight: 400;
  color: ${props => props.theme.graySilver};

  @media (max-width: 1440px) {
    font-size: 0.6rem;
    line-height: 0.6rem;
  }

  @media (max-width: 1024px) {
    font-size: 0.5rem;
    line-height: 0.5rem;
  }
`;