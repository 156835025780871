import styled from "styled-components";

export const Container = styled.div`
    &.main {
        width: 100%;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 1440px) {
            height: 38px;
        }
    }

    &.main > .detailed-extract-search-icon {
        font-size: 16px;
    }
`;