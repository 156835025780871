export const getGalleryImages = () => {
  return [
    {
      url:
        'https://img.freepik.com/psd-gratuitas/ilustracao-realista-de-anel-de-casamento-isolada_23-2151551258.jpg',
      alt: 'Imagem de um anel de casamento',
    },
    {
      url:
        'https://img.freepik.com/fotos-gratis/bela-palmeira-de-coco-tropical-no-ceu-azul_74190-7469.jpg',
      alt: 'Imagem de uma palmeira tropical',
    },
    {
      url:
        'https://img.freepik.com/fotos-gratis/mini-cupe-preto-na-estrada_114579-5056.jpg',
      alt: 'Imagem de um carro na estrada',
    },
    {
      url:
        'https://img.freepik.com/fotos-gratis/casa-isolada-no-campo_1303-23773.jpg?t=st=1730947494~exp=1730951094~hmac=de4631d381c6f2592e92921bff89c599e3f850a1d8b95bffbda8ed3c501e8c98&w=1800',
      alt: 'Imagem de uma casa isolada',
    },
  ];
};
