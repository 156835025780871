import React from "react";
import { Container, Icon } from "./styles";

export const PercentageIcon = ({
    className = '',
    onClick = () => { },
}) => {
    return (
        <Container className={className} onClick={onClick}>
            <Icon
                viewBox="0 0 25 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <path
                    d="M23.436 4.368L3.869 23.934a1.633 1.633 0 01-2.31-2.31L21.126 2.058a1.634 1.634 0 012.31 2.31zM1.59 9.776a5.435 5.435 0 113.843 1.594A5.398 5.398 0 011.59 9.776zm1.669-3.841a2.174 2.174 0 104.347.002 2.174 2.174 0 00-4.347-.002zM25 20.065a5.435 5.435 0 11-1.593-3.843A5.394 5.394 0 0125 20.065zm-3.261 0a2.156 2.156 0 00-.637-1.538 2.175 2.175 0 10.637 1.538z"
                    fill="#140361"
                />
            </Icon>
        </Container>
    )
}