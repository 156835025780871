import * as React from 'react';
import { StyledCloudIcon } from './styles';

function CloudIcon({ className }) {
  return (
    <StyledCloudIcon
      viewBox="0 0 65 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M52.166 45.997H15.941c-4.03 0-7.82-1.605-10.672-4.522-2.8-2.86-4.314-6.618-4.268-10.58.084-7.237 5.268-13.351 12.326-14.537a.294.294 0 00.242-.216C15.653 7.227 23.448 1 32.525 1 43.262 1 52 9.827 52 20.676c0 .444-.016.895-.043 1.333a.32.32 0 00.08.235c.044.047.124.1.239.103h.021c3.144 0 6.092 1.242 8.306 3.495A11.814 11.814 0 0164 34.262C63.953 40.735 58.645 46 52.166 46v-.003z"
        stroke="#E2E2E2"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
    </StyledCloudIcon>
  );
}

export default CloudIcon;
