import React from 'react';
import { CardContent } from './styles';
import { H5 } from 'components/atoms/Headings/H5';

export const CardContentH5 = ({ className, title }) => {
  return (
    <CardContent className={className}>
      <H5 className={className}>{title}</H5>
    </CardContent>
  );
};
