import styled from 'styled-components';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

export const TolltipIconContainer = styled.svg`
  &.home {
    cursor: pointer;
    align-self: center;
    width: 14px;
    height: 14px;
    margin-top: 2px;
    margin-left: 14px;

    path:first-of-type {
      fill: ${({ theme }) => theme.bege};
    }

    path:nth-of-type(2) {
      fill: ${({ theme }) => theme.pale};
    }
  }

  &.investments {
    width: 14px;
    height: 14px;

    path:first-of-type {
      fill: ${({ theme }) => theme.bege};
    }

    path:nth-of-type(2) {
      fill: ${({ theme }) => theme.pale};
    }
  }

  @media (max-width: 1440px) {
    &.home,
    &.investments {
      width: 12px;
      height: 12px;
    }
  }

  @media (max-width: 768px) {
    &.home,
    &.investments {
      width: 10px;
      height: 10px;
    }
  }
`;

export const LoadingIcon = styled.div`
  &.home {
    width: 14px;
    height: 14px;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.gradientSkeleton[0]} 25%,
      ${({ theme }) => theme.gradientSkeleton[1]} 50%,
      ${({ theme }) => theme.gradientSkeleton[0]} 75%
    );
    background-size: 200% 100%;
    animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;
    border-radius: 50%;
    margin-left: 8px;
  }

  @media (max-width: 1023px) {
    &.home {
      width: 12px;
      height: 12px;
      margin-left: 6px;
    }
  }

  @media (max-width: 768px) {
    &.home {
      width: 10px;
      height: 10px;
      margin-left: 4px;
    }
  }
`;
