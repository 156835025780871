import React from 'react';
import { StyledIcon } from './styles';

function FlexibleMonthlyIcon({ className }) {
  return (
    <StyledIcon
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.09 14.94l-1.45 1.442 12.032 11.95-2.405 2.39-12.036-11.949L3.195 30.722l-2.407-2.39 15.895-15.784c-1.001-2.462.034-6.008 2.759-8.714 3.323-3.3 7.903-4.103 10.228-1.793 2.328 2.31 1.52 6.859-1.804 10.158-2.726 2.707-6.295 3.735-8.775 2.741zM1.994.845l11.434 11.354-4.815 4.78-6.619-6.573A6.737 6.737 0 010 5.625C0 3.833.717 2.114 1.993.846m23.468 8.965c2.14-2.125 2.58-4.608 1.804-5.378-.774-.771-3.274-.332-5.413 1.791-2.142 2.126-2.582 4.61-1.806 5.38.776.769 3.276.33 5.415-1.793z"
        fill="#697BFF"
      />
    </StyledIcon>
  );
}

export default FlexibleMonthlyIcon;
