import styled from 'styled-components';

export const ReceiptIconContainer = styled.svg`
  width: ${props => props.theme.getResponsiveHeight(49)};
  height: ${props => props.theme.getResponsiveHeight(32)};

  &.selected path {
    fill: ${({ theme }) => theme.white};
  }

  &.report {
    width: ${props => props.theme.getResponsiveWidth(24)};
    height: ${props => props.theme.getResponsiveHeight(16)};
    min-width: 24px;
    min-height: 16px;
  }

  &.extract.receipts {
    width: 24px;
    height: 16px;

    path {
      fill: ${({ theme }) => theme.blueNavy};
    }

    &.selected path {
      fill: ${({ theme }) => theme.white};
    }

    @media (max-width: 1440px) {
      width: 20px;
      height: 14px;
    }
  }
`;
