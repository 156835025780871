import React from "react";
import { Container, Icon } from "./styles";

export const DroupIcon = ({
    className = '',
    onClick = () => { },
}) => {
    return (
        <Container className={className} onClick={onClick}>
            <Icon
                viewBox="0 0 18 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <path
                    d="M2.571 18.05c0 1.79.678 3.507 1.883 4.773C5.66 24.089 7.295 24.8 9 24.8s3.34-.711 4.546-1.977c1.205-1.266 1.883-2.983 1.883-4.773 0-2.33-2.135-6.792-6.429-13.032-4.294 6.24-6.429 10.702-6.429 13.032zM9 .5c6 8.222 9 14.071 9 17.55 0 2.506-.948 4.91-2.636 6.682C13.676 26.504 11.387 27.5 9 27.5s-4.676-.996-6.364-2.768C.948 22.96 0 20.556 0 18.05 0 14.571 3 8.721 9 .5z"
                    fill="#140361"
                />
            </Icon>
        </Container>
    )
}