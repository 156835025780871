import styled from "styled-components";

export const SVG = styled.svg`
    &.extract-title {
        width: 23px;
        height: 18px;

        path {
            fill: ${({ theme }) => theme.blueMidnight};
        }

        @media (max-width: 1440px) {
            width: 20px;
            height: 15px;
        }
    }

    &.extract-title.item {
        path {
            fill: ${({ theme }) => theme.white};
        }
    }
`