export const getExtractData = (type = 'spendings') => {
    return [
        {
            id: 1,
            name: 'Moradia',
            type,
            classification: 'housing',
            value: 'R$ 10.000',
            maxValue: 'R$ 100.000',
            paymentMethod: 'Recorrente',
            installments: '12/12',
            date: '2024-09-25',
            spentMoreThanEstimated: false,
            installmentsPercentage: 40,
            paymentMadeInCashPercentage: 20,
            data: [
                {
                    id: 1,
                    name: 'Aluguel',
                    value: 'R$ 10.000',
                    maxValue: 'R$ 100.000',
                    paymentMethod: 'Recorrente',
                    installments: '12/12',
                    date: '2024-09-25',
                    spentMoreThanEstimated: false,
                    installmentsPercentage: 50,
                    paymentMadeInCashPercentage: 50,
                    data: [
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1000,00',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                    ]
                },
                {
                    id: 2,
                    name: 'Empregados Diaristas (Diarista/Piscineiro/Jardineiro/Passadeira)',
                    value: 'R$ 10.000',
                    maxValue: 'R$ 100.000',
                    paymentMethod: 'Recorrente',
                    installments: '12/12',
                    date: '2024-09-25',
                    spentMoreThanEstimated: false,
                    installmentsPercentage: 0,
                    paymentMadeInCashPercentage: 20,
                    data: [
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                    ]
                },
                {
                    id: 3,
                    name: 'Condomínio',
                    value: 'R$ 10.000',
                    maxValue: 'R$ 100.000',
                    paymentMethod: 'Recorrente',
                    installments: '12/12',
                    date: '2024-09-25',
                    spentMoreThanEstimated: true,
                    installmentsPercentage: 0,
                    paymentMadeInCashPercentage: 0,
                    data: [
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                    ]
                },
                {
                    id: 4,
                    name: 'Spotify',
                    value: 'R$ 10.000',
                    maxValue: 'R$ 100.000',
                    paymentMethod: 'Recorrente',
                    installments: '12/12',
                    date: '2024-09-25',
                    spentMoreThanEstimated: false,
                    installmentsPercentage: 90,
                    paymentMadeInCashPercentage: 10,
                    data: [
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                    ]
                }
            ]
        },
        {
            id: 2,
            name: 'Transporte',
            classification: 'transport',
            value: 'R$ 10.000',
            maxValue: 'R$ 100.000',
            paymentMethod: 'Recorrente',
            installments: '12/12',
            date: '2024-09-25',
            spentMoreThanEstimated: false,
            installmentsPercentage: 20,
            paymentMadeInCashPercentage: 10,
            type,
            data: [
                {
                    id: 1,
                    name: 'Aluguel',
                    value: 'R$ 10.000',
                    maxValue: 'R$ 100.000',
                    paymentMethod: 'Recorrente',
                    installments: '12/12',
                    date: '2024-09-25',
                    spentMoreThanEstimated: false,
                    installmentsPercentage: 50,
                    paymentMadeInCashPercentage: 50,
                    data: [
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                    ]
                },
                {
                    id: 2,
                    name: 'Empregados Diaristas (Diarista/Piscineiro/Jardineiro/Passadeira)',
                    value: 'R$ 10.000',
                    maxValue: 'R$ 100.000',
                    paymentMethod: 'Recorrente',
                    installments: '12/12',
                    date: '2024-09-25',
                    spentMoreThanEstimated: false,
                    installmentsPercentage: 0,
                    paymentMadeInCashPercentage: 20,
                    data: [
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                    ]
                },
                {
                    id: 3,
                    name: 'Condomínio',
                    value: 'R$ 10.000',
                    maxValue: 'R$ 100.000',
                    paymentMethod: 'Recorrente',
                    installments: '12/12',
                    date: '2024-09-25',
                    spentMoreThanEstimated: true,
                    installmentsPercentage: 0,
                    paymentMadeInCashPercentage: 0,
                    data: [
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                    ]
                },
                {
                    id: 4,
                    name: 'Spotify',
                    value: 'R$ 10.000',
                    maxValue: 'R$ 100.000',
                    paymentMethod: 'Recorrente',
                    installments: '12/12',
                    date: '2024-09-25',
                    spentMoreThanEstimated: false,
                    installmentsPercentage: 90,
                    paymentMadeInCashPercentage: 10,
                    data: [
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                    ]
                }
            ]
        },
        {
            id: 3,
            name: 'Alimentação Fora de Casa',
            classification: 'eatingOut',
            value: 'R$ 10.000',
            maxValue: 'R$ 100.000',
            paymentMethod: 'Recorrente',
            installments: '12/12',
            date: '2024-09-25',
            spentMoreThanEstimated: false,
            installmentsPercentage: 80,
            paymentMadeInCashPercentage: 20,
            type,
            data: [
                {
                    id: 1,
                    name: 'Aluguel',
                    value: 'R$ 10.000',
                    maxValue: 'R$ 100.000',
                    paymentMethod: 'Recorrente',
                    installments: '12/12',
                    date: '2024-09-25',
                    spentMoreThanEstimated: false,
                    installmentsPercentage: 50,
                    paymentMadeInCashPercentage: 50,
                    data: [
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                    ]
                },
                {
                    id: 2,
                    name: 'Empregados Diaristas (Diarista/Piscineiro/Jardineiro/Passadeira)',
                    value: 'R$ 10.000',
                    maxValue: 'R$ 100.000',
                    paymentMethod: 'Recorrente',
                    installments: '12/12',
                    date: '2024-09-25',
                    spentMoreThanEstimated: false,
                    installmentsPercentage: 0,
                    paymentMadeInCashPercentage: 20,
                    data: [
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                    ]
                },
                {
                    id: 3,
                    name: 'Condomínio',
                    value: 'R$ 10.000',
                    maxValue: 'R$ 100.000',
                    paymentMethod: 'Recorrente',
                    installments: '12/12',
                    date: '2024-09-25',
                    spentMoreThanEstimated: true,
                    installmentsPercentage: 0,
                    paymentMadeInCashPercentage: 0,
                    data: [
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                    ]
                },
                {
                    id: 4,
                    name: 'Spotify',
                    value: 'R$ 10.000',
                    maxValue: 'R$ 100.000',
                    paymentMethod: 'Recorrente',
                    installments: '12/12',
                    date: '2024-09-25',
                    spentMoreThanEstimated: false,
                    installmentsPercentage: 90,
                    paymentMadeInCashPercentage: 10,
                    data: [
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                    ]
                }
            ]
        },
        {
            id: 4,
            name: 'Vida e Saúde',
            classification: 'lifeAndHealth',
            value: 'R$ 10.000',
            maxValue: 'R$ 100.000',
            paymentMethod: 'Recorrente',
            installments: '12/12',
            date: '2024-09-25',
            spentMoreThanEstimated: true,
            installmentsPercentage: 0,
            paymentMadeInCashPercentage: 0,
            type,
            data: [
                {
                    id: 1,
                    name: 'Aluguel',
                    value: 'R$ 10.000',
                    maxValue: 'R$ 100.000',
                    paymentMethod: 'Recorrente',
                    installments: '12/12',
                    date: '2024-09-25',
                    spentMoreThanEstimated: false,
                    installmentsPercentage: 50,
                    paymentMadeInCashPercentage: 50,
                    data: [
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                    ]
                },
                {
                    id: 2,
                    name: 'Empregados Diaristas (Diarista/Piscineiro/Jardineiro/Passadeira)',
                    value: 'R$ 10.000',
                    maxValue: 'R$ 100.000',
                    paymentMethod: 'Recorrente',
                    installments: '12/12',
                    date: '2024-09-25',
                    spentMoreThanEstimated: false,
                    installmentsPercentage: 0,
                    paymentMadeInCashPercentage: 20,
                    data: [
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                    ]
                },
                {
                    id: 3,
                    name: 'Condomínio',
                    value: 'R$ 10.000',
                    maxValue: 'R$ 100.000',
                    paymentMethod: 'Recorrente',
                    installments: '12/12',
                    date: '2024-09-25',
                    spentMoreThanEstimated: true,
                    installmentsPercentage: 0,
                    paymentMadeInCashPercentage: 0,
                    data: [
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                    ]
                },
                {
                    id: 4,
                    name: 'Spotify',
                    value: 'R$ 10.000',
                    maxValue: 'R$ 100.000',
                    paymentMethod: 'Recorrente',
                    installments: '12/12',
                    date: '2024-09-25',
                    spentMoreThanEstimated: false,
                    installmentsPercentage: 90,
                    paymentMadeInCashPercentage: 10,
                    data: [
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                    ]
                }
            ]
        },
        {
            id: 5,
            name: 'Lazer',
            classification: 'leisure',
            value: 'R$ 10.000',
            maxValue: 'R$ 100.000',
            paymentMethod: 'Recorrente',
            installments: '12/12',
            date: '2024-09-25',
            spentMoreThanEstimated: false,
            installmentsPercentage: 15,
            paymentMadeInCashPercentage: 13,
            type,
            data: [
                {
                    id: 1,
                    name: 'Aluguel',
                    value: 'R$ 10.000',
                    maxValue: 'R$ 100.000',
                    paymentMethod: 'Recorrente',
                    installments: '12/12',
                    date: '2024-09-25',
                    spentMoreThanEstimated: false,
                    installmentsPercentage: 50,
                    paymentMadeInCashPercentage: 50,
                    data: [
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                    ]
                },
                {
                    id: 2,
                    name: 'Empregados Diaristas (Diarista/Piscineiro/Jardineiro/Passadeira)',
                    value: 'R$ 10.000',
                    maxValue: 'R$ 100.000',
                    paymentMethod: 'Recorrente',
                    installments: '12/12',
                    date: '2024-09-25',
                    spentMoreThanEstimated: false,
                    installmentsPercentage: 0,
                    paymentMadeInCashPercentage: 20,
                    data: [
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                    ]
                },
                {
                    id: 3,
                    name: 'Condomínio',
                    value: 'R$ 10.000',
                    maxValue: 'R$ 100.000',
                    paymentMethod: 'Recorrente',
                    installments: '12/12',
                    date: '2024-09-25',
                    spentMoreThanEstimated: true,
                    installmentsPercentage: 0,
                    paymentMadeInCashPercentage: 0,
                    data: [
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                    ]
                },
                {
                    id: 4,
                    name: 'Spotify',
                    value: 'R$ 10.000',
                    maxValue: 'R$ 100.000',
                    paymentMethod: 'Recorrente',
                    installments: '12/12',
                    date: '2024-09-25',
                    spentMoreThanEstimated: false,
                    installmentsPercentage: 90,
                    paymentMadeInCashPercentage: 10,
                    data: [
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                    ]
                }
            ]
        },
        {
            id: 6,
            name: 'Pessoa Jurídica',
            classification: 'legalEntity',
            value: 'R$ 10.000',
            maxValue: 'R$ 100.000',
            paymentMethod: 'Recorrente',
            installments: '12/12',
            date: '2024-09-25',
            spentMoreThanEstimated: false,
            installmentsPercentage: 42,
            paymentMadeInCashPercentage: 33,
            type,
            data: [
                {
                    id: 1,
                    name: 'Aluguel',
                    value: 'R$ 10.000',
                    maxValue: 'R$ 100.000',
                    paymentMethod: 'Recorrente',
                    installments: '12/12',
                    date: '2024-09-25',
                    spentMoreThanEstimated: false,
                    installmentsPercentage: 50,
                    paymentMadeInCashPercentage: 50,
                    data: [
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                    ]
                },
                {
                    id: 2,
                    name: 'Empregados Diaristas (Diarista/Piscineiro/Jardineiro/Passadeira)',
                    value: 'R$ 10.000',
                    maxValue: 'R$ 100.000',
                    paymentMethod: 'Recorrente',
                    installments: '12/12',
                    date: '2024-09-25',
                    spentMoreThanEstimated: false,
                    installmentsPercentage: 0,
                    paymentMadeInCashPercentage: 20,
                    data: [
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                    ]
                },
                {
                    id: 3,
                    name: 'Condomínio',
                    value: 'R$ 10.000',
                    maxValue: 'R$ 100.000',
                    paymentMethod: 'Recorrente',
                    installments: '12/12',
                    date: '2024-09-25',
                    spentMoreThanEstimated: true,
                    installmentsPercentage: 0,
                    paymentMadeInCashPercentage: 0,
                    data: [
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                    ]
                },
                {
                    id: 4,
                    name: 'Spotify',
                    value: 'R$ 10.000',
                    maxValue: 'R$ 100.000',
                    paymentMethod: 'Recorrente',
                    installments: '12/12',
                    date: '2024-09-25',
                    spentMoreThanEstimated: false,
                    installmentsPercentage: 90,
                    paymentMadeInCashPercentage: 10,
                    data: [
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                    ]
                }
            ]
        },
        {
            id: 7,
            name: 'Pessoal  - Cliente',
            classification: 'personal',
            value: 'R$ 10.000',
            maxValue: 'R$ 100.000',
            paymentMethod: 'Recorrente',
            installments: '12/12',
            date: '2024-09-25',
            spentMoreThanEstimated: true,
            installmentsPercentage: 0,
            paymentMadeInCashPercentage: 0,
            type,
            data: [
                {
                    id: 1,
                    name: 'Aluguel',
                    value: 'R$ 10.000',
                    maxValue: 'R$ 100.000',
                    paymentMethod: 'Recorrente',
                    installments: '12/12',
                    date: '2024-09-25',
                    spentMoreThanEstimated: false,
                    installmentsPercentage: 50,
                    paymentMadeInCashPercentage: 50,
                    data: [
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                    ]
                },
                {
                    id: 2,
                    name: 'Empregados Diaristas (Diarista/Piscineiro/Jardineiro/Passadeira)',
                    value: 'R$ 10.000',
                    maxValue: 'R$ 100.000',
                    paymentMethod: 'Recorrente',
                    installments: '12/12',
                    date: '2024-09-25',
                    spentMoreThanEstimated: false,
                    installmentsPercentage: 0,
                    paymentMadeInCashPercentage: 20,
                    data: [
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                    ]
                },
                {
                    id: 3,
                    name: 'Condomínio',
                    value: 'R$ 10.000',
                    maxValue: 'R$ 100.000',
                    paymentMethod: 'Recorrente',
                    installments: '12/12',
                    date: '2024-09-25',
                    spentMoreThanEstimated: true,
                    installmentsPercentage: 0,
                    paymentMadeInCashPercentage: 0,
                    data: [
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                    ]
                },
                {
                    id: 4,
                    name: 'Spotify',
                    value: 'R$ 10.000',
                    maxValue: 'R$ 100.000',
                    paymentMethod: 'Recorrente',
                    installments: '12/12',
                    date: '2024-09-25',
                    spentMoreThanEstimated: false,
                    installmentsPercentage: 90,
                    paymentMadeInCashPercentage: 10,
                    data: [
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                        {
                            id: 1,
                            name: 'Aluguel',
                            value: 'R$ 10.000',
                            paymentMethod: 'Recorrente',
                            installments: '12/12',
                            date: '01/01/2024',
                            description: 'Pagamento de aluguel',
                            recurrent: true,
                            installmentValue: 'R$ 1.000',
                            startDate: '2024-09-25',
                            endDate: '2024-09-25',
                            classification: 'Lazer'
                        },
                    ]
                }
            ]
        }
    ]
}

export const getDetailedExtractItems = (type = 'spendings') => {
    return {
        total: "R$ 60.0000",
        data: [
            {
                id: 1,
                categoryName: 'qqq',
                categoryItemName: 'Decoração',
                releaseDate: 'dd/mm/aa',
                value: 'R$ 1.000,00',
                paymentMethod: 'À vista',
                installments: '1/1',
                classification: 'housing',
                type: 'investments',
                inInstallments: false,
                startDate: '2024-09-25',
                endDate: '2024-09-25',
                recurrent: true,
                description: 'Pagamento de aluguel'
            },
            {
                id: 2,
                categoryName: 'Moradia',
                categoryItemName: 'Decoração',
                releaseDate: 'dd/mm/aa',
                value: 'R$ 1.000,00',
                paymentMethod: 'À vista',
                installments: '1/1',
                classification: 'lifeAndHealth',
                type: 'spendings',
                inInstallments: false,
                startDate: '2024-09-25',
                endDate: '2024-09-25',
                recurrent: true,
                description: 'Pagamento de aluguel'
            },
            {
                id: 3,
                categoryName: 'Moradia',
                categoryItemName: 'Decoração',
                releaseDate: 'dd/mm/aa',
                value: 'R$ 1.000,00',
                paymentMethod: 'Recorr.',
                installments: null,
                classification: 'personal',
                type,
                inInstallments: false,
                startDate: '2024-09-25',
                endDate: '2024-09-25',
                recurrent: true,
                description: 'Pagamento de aluguel'
            },
            {
                id: 4,
                categoryName: 'Moradia',
                categoryItemName: 'Decoração',
                releaseDate: 'dd/mm/aa',
                value: 'R$ 1.000,00',
                paymentMethod: 'À vista',
                installments: '1/1',
                classification: 'leisure',
                type,
                inInstallments: false,
                startDate: '2024-09-25',
                endDate: '2024-09-25',
                recurrent: true,
                description: 'Pagamento de aluguel'
            },
            {
                id: 4,
                categoryName: 'Moradia',
                categoryItemName: 'Decoração',
                releaseDate: 'dd/mm/aa',
                value: 'R$ 1.000,00',
                paymentMethod: 'Parcelas',
                installments: '1/12',
                classification: 'leisure',
                type,
                inInstallments: true,
                startDate: '2024-09-25',
                endDate: '2024-09-25',
                recurrent: true,
                description: 'Pagamento de aluguel'
            },
            {
                id: 4,
                categoryName: 'Moradia',
                categoryItemName: 'Decoração',
                releaseDate: 'dd/mm/aa',
                value: 'R$ 1.000,00',
                paymentMethod: 'À vista',
                installments: '1/1',
                classification: 'leisure',
                type: 'receipts',
                inInstallments: false,
                startDate: '2024-09-25',
                endDate: '2024-09-25',
                recurrent: true,
                description: 'Pagamento de aluguel'
            },
            {
                id: 4,
                categoryName: 'Moradia',
                categoryItemName: 'Decoração',
                releaseDate: 'dd/mm/aa',
                value: 'R$ 1.000,00',
                paymentMethod: 'À vista',
                installments: '1/1',
                classification: 'leisure',
                type,
                inInstallments: false,
                startDate: '2024-09-25',
                endDate: '2024-09-25',
                recurrent: true,
                description: 'Pagamento de aluguel'
            },
            {
                id: 4,
                categoryName: 'Moradia',
                categoryItemName: 'Decoração',
                releaseDate: 'dd/mm/aa',
                value: 'R$ 1.000,00',
                paymentMethod: 'Parcelas',
                installments: '1/13',
                classification: 'leisure',
                type,
                inInstallments: true,
                startDate: '2024-09-25',
                endDate: '2024-09-25',
                recurrent: true,
                description: 'Pagamento de aluguel'
            },
            {
                id: 4,
                categoryName: 'Moradia',
                categoryItemName: 'Decoração',
                releaseDate: 'dd/mm/aa',
                value: 'R$ 1.000,00',
                paymentMethod: 'À vista',
                installments: '1/1',
                classification: 'leisure',
                type,
                inInstallments: false,
                startDate: '2024-09-25',
                endDate: '2024-09-25',
                recurrent: true,
                description: 'Pagamento de aluguel'
            },
            {
                id: 4,
                categoryName: 'Moradia',
                categoryItemName: 'Decoração',
                releaseDate: 'dd/mm/aa',
                value: 'R$ 1.000,00',
                paymentMethod: 'À vista',
                installments: '1/1',
                classification: 'leisure',
                type,
                inInstallments: false,
                startDate: '2024-09-25',
                endDate: '2024-09-25',
                recurrent: true,
                description: 'Pagamento de aluguel'
            },
            {
                id: 4,
                categoryName: 'Moradia',
                categoryItemName: 'Decoração',
                releaseDate: 'dd/mm/aa',
                value: 'R$ 1.000,00',
                paymentMethod: 'À vista',
                installments: '1/1',
                classification: 'leisure',
                type,
                inInstallments: false,
                startDate: '2024-09-25',
                endDate: '2024-09-25',
                recurrent: true,
                description: 'Pagamento de aluguel'
            },
            {
                id: 4,
                categoryName: 'Moradia',
                categoryItemName: 'Decoração',
                releaseDate: 'dd/mm/aa',
                value: 'R$ 1.000,00',
                paymentMethod: 'À vista',
                installments: '1/1',
                classification: 'leisure',
                type,
                inInstallments: false,
                startDate: '2024-09-25',
                endDate: '2024-09-25',
                recurrent: true,
                description: 'Pagamento de aluguel'
            },
            {
                id: 4,
                categoryName: 'Moradia',
                categoryItemName: 'Decoração',
                releaseDate: 'dd/mm/aa',
                value: 'R$ 1.000,00',
                paymentMethod: 'À vista',
                installments: '1/1',
                classification: 'leisure',
                type,
                inInstallments: false,
                startDate: '2024-09-25',
                endDate: '2024-09-25',
                recurrent: true,
                description: 'Pagamento de aluguel'
            },
            {
                id: 4,
                categoryName: 'Moradia',
                categoryItemName: 'Decoração',
                releaseDate: 'dd/mm/aa',
                value: 'R$ 1.000,00',
                paymentMethod: 'À vista',
                installments: '1/1',
                classification: 'leisure',
                type,
                inInstallments: false,
                startDate: '2024-09-25',
                endDate: '2024-09-25',
                recurrent: true,
                description: 'Pagamento de aluguel'
            }
        ]
    }
}