import React, { useState, useRef, useEffect } from 'react';
import {
  DropdownOptionsList,
  DropdownOptionItem,
  DropdownContainer,
  DropdownTrigger,
  DropdownTriggerIcon,
  DropdownOptionItemLabel,
} from './styles';
import { H5 } from '../Headings/H5';
import ArrowDropdown from '../icons/ArrowDropdown';

export const DropdownList = ({
  options,
  placeholder = 'Planejar',
  className,
  onClick,
}) => {
  const [showOptions, setShowOptions] = useState(false);

  const handleToggleDropdown = () => {
    setShowOptions(prevState => !prevState);
  };

  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => document.removeEventListener('click', handleClickOutside);
  }, [showOptions]);

  return (
    <DropdownContainer className={`${className}`}>
      <DropdownTrigger onClick={handleToggleDropdown} ref={modalRef}>
        <H5 className={`${className}`} onClick={onClick}>
          Planejar
        </H5>
        <DropdownTriggerIcon className={`${className}`}>
          <ArrowDropdown />
        </DropdownTriggerIcon>
      </DropdownTrigger>
      <DropdownOptionsList isOpen={showOptions}>
        {options.map((option, index) => (
          <DropdownOptionItem key={index} onClick={option.onClick}>
            <DropdownOptionItemLabel>{option.label}</DropdownOptionItemLabel>
          </DropdownOptionItem>
        ))}
      </DropdownOptionsList>
    </DropdownContainer>
  );
};
