import React from 'react';
import { CardContainer } from './styles';
import { RetirementYear } from './RetirementYear';
import { RetirementValue } from './RetirementValue';
import { Composition } from './Composition';
import { GenericLoading } from 'components/atoms/GenericLoading';
import { GeneralContext } from 'utils/contexts/GeneralContext';
import { getNavegationCardRetirement } from 'development/retirement';

export default function RetirementPlanningCard({
  currentPage,
  setCurrentPage,
}) {
  const { loading } = React.useContext(GeneralContext);

  const page = {
    loading: (
      <CardContainer>
        {Array.from({ length: 4 }, (_, index) => (
          <GenericLoading className="goal-panel-navigation-card " key={index} />
        ))}
      </CardContainer>
    ),
    content: (
      <CardContainer>
        <Composition
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalValue={getNavegationCardRetirement().financialPatrimony}
        />
        <RetirementYear
          totalValue={getNavegationCardRetirement().percentValue}
        />
        <RetirementValue totalValue={getNavegationCardRetirement().rentValue} />
      </CardContainer>
    ),
  };

  return page[loading ? 'loading' : 'content'];
}
