import * as React from 'react';
import { StyledRetirementPlanningIcon } from './styles';

function RetirementPlanningIcon({ className }) {
  return (
    <StyledRetirementPlanningIcon
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M21.001 14.408c2.11 0 3.825-1.585 3.825-3.533S23.11 7.343 21 7.343c-2.108 0-3.824 1.584-3.824 3.532s1.716 3.533 3.824 3.533zm0-6.034c1.493 0 2.708 1.122 2.708 2.501 0 1.38-1.215 2.502-2.708 2.502-1.492 0-2.707-1.123-2.707-2.502 0-1.379 1.215-2.5 2.707-2.5zM26.435 15.673a3.74 3.74 0 00-2.047-.6h-.035c-1.944.018-2.481.097-2.537.106l-.05.01c-.096.025-2.379.63-4.418 3.31-.368.484-.837.87-1.357 1.12a13.6 13.6 0 01-1.29.505c-1.255.442-2.553.899-2.694 2.148-.052.521.183 1.043.629 1.398.428.34 1.006.512 1.73.517v10.56c0 .285.249.515.559.515.31 0 .559-.23.559-.515V24.154c0-.024-.005-.05-.008-.073.117-.02.234-.043.355-.07 1.749-.28 3.99-1.705 5.066-2.774.006.218.008.495.006.844v12.386c0 .483.425.876.948.876h4.524a.986.986 0 00.7-.285.834.834 0 00.243-.667c-.093-.943-.156-3.304 1.242-4.86a5.279 5.279 0 001.29-2.782c.405-2.778.21-8.668-3.415-11.071v-.005zm2.308 10.935a4.284 4.284 0 01-1.044 2.261c-1.58 1.76-1.6 4.274-1.509 5.439h-4.17V22.082c.011-1.634-.035-2.22-.59-2.397-.501-.158-.85.265-1.018.468l-.02.028c-.648.9-3.093 2.555-4.766 2.815l-.04.007c-1.407.317-1.995.064-2.219-.115a.609.609 0 01-.246-.517c.064-.57.791-.865 1.979-1.284.468-.165.95-.335 1.406-.553a4.873 4.873 0 001.756-1.442c1.678-2.203 3.527-2.826 3.776-2.902.132-.016.713-.073 2.326-.086a2.564 2.564 0 011.42.405c2.962 1.963 3.392 7.113 2.959 10.096v.003z"
        fill="#fff"
      />
    </StyledRetirementPlanningIcon>
  );
}

export default RetirementPlanningIcon;
