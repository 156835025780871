import UpIcon from 'components/atoms/icons/UpIcon';
import React from 'react';
import { StyledContainer, StyledH6, StyledUpIcon } from './styles';

export default function UpButton() {
  return (
    <StyledContainer>
      <StyledH6 className="up-button"> Voltar ao início</StyledH6>
      <StyledUpIcon>
        <UpIcon />
      </StyledUpIcon>
    </StyledContainer>
  );
}
