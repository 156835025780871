import React from 'react';
import { ImageIcon } from 'components/atoms/icons/ImageIcon';
import { InputImageContainer } from './styles';

export const ModalInputImage = ({
  className = '',
  backgroundImage = '',
  getImageValue = () => {},
  onClick = () => {},
}) => {
  const [currentImage, setCurrentImage] = React.useState(backgroundImage);

  React.useEffect(() => {
    setCurrentImage(backgroundImage);
  }, [backgroundImage]);

  return (
    <InputImageContainer
      className={className}
      backgroundImage={currentImage}
      onClick={onClick}
    >
      <ImageIcon className={className} />
    </InputImageContainer>
  );
};
