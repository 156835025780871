import CommittedMonthlyIcon from 'components/atoms/icons/CommittedMonthlyIcon';
import { CardTitle } from './styles';
import React from 'react';
import CommittedEventualIcon from 'components/atoms/icons/CommittedEventualIcon';
import FlexibleMonthlyIcon from 'components/atoms/icons/FlexibleMonthlyIcon';
import FlexibleEventualIcon from 'components/atoms/icons/FlexibleEventualIcon';
import { H4 } from 'components/atoms/Headings/H4';
import ActiveIcon from 'components/atoms/icons/ActiveIcon';
import PassiveIcon from 'components/atoms/icons/PassiveIcon';
import LiquidPatrimonyIcon from 'components/atoms/icons/LiquidPatrimonyIcon';
import MoneyBagIcon from 'components/atoms/icons/MoneyBagIcon';
import RentIcon from 'components/atoms/icons/RentIcon';
import SocialSecurityIcon from 'components/atoms/icons/SocialSecurityIcon';
import FinancialPatrimonyIcon from 'components/atoms/icons/FinancialPatrimony';
import { PercentageIcon } from 'components/atoms/icons/PercentageIcon';
import YearIcon from 'components/atoms/icons/YearIcon';

export const Cardtitle = ({ className, type, title }) => {
  const icons = {
    committedMonthly: <CommittedMonthlyIcon className={`${className}`} />,
    committedEventual: <CommittedEventualIcon className={`${className}`} />,
    flexibleMonthly: <FlexibleMonthlyIcon className={`${className}`} />,
    flexibleEventual: <FlexibleEventualIcon className={`${className}`} />,
    active: <ActiveIcon className={`${className}`} />,
    passive: <PassiveIcon className={`${className}`} />,
    liquidPatrimony: <LiquidPatrimonyIcon />,
    coverageIndex: <MoneyBagIcon />,
    rent: <RentIcon className={`${className}`} />,
    socialSecurity: <SocialSecurityIcon className={`${className}`} />,
    financialPatrimony: <FinancialPatrimonyIcon className={`${className}`} />,
    percentage: <PercentageIcon className={`${className}`} />,
    year: <YearIcon className={`${className}`} />,
  };

  return (
    <CardTitle className={className}>
      {icons[type]}
      <H4 className={className}>{title}</H4>
    </CardTitle>
  );
};
