import React from 'react';
import {
  DropdownWrapper,
  GroupItens,
  TextContainer,
  TitleWrapper,
} from './styles';
import { Span } from 'components/atoms/Span';
import { Dropdown } from 'components/atoms/Dropdown';

export const TableTitleDropdown = ({
  nameTable,
  className,
  selectedYear,
  selectedMonth,
  setSelectedYear,
  setSelectedMonth,
  yearOptions,
  monthOptions,
}) => {
  return (
    <TextContainer className={className}>
      <GroupItens>
        <TitleWrapper className="set">
          <Span className="table-title">{nameTable}</Span>
          <DropdownWrapper>
            <Dropdown
              className="generic-dream-modal after half second-section margin"
              placeholder="Selecione um ano..."
              options={yearOptions}
              getDropdownValue={setSelectedYear}
              value={selectedYear}
            />
            <Dropdown
              className="generic-dream-modal after half second-section margin"
              placeholder="Selecione um mês..."
              options={monthOptions}
              getDropdownValue={setSelectedMonth}
              value={selectedMonth}
            />
          </DropdownWrapper>
        </TitleWrapper>
      </GroupItens>
    </TextContainer>
  );
};
