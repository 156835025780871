import React, { useContext } from 'react';
import { Container } from './styles';
import SearchIcon from 'components/atoms/icons/SearchIcon';
import { DropdownSearch } from 'components/molecules/DropdownSearch';
import { GeneralContext } from 'utils/contexts/GeneralContext';

export const SearchBar = ({ placeholder, className, options }) => {
  const { loading } = useContext(GeneralContext);
  if (loading) {
    return <></>;
  }
  return (
    <Container className={className}>
      <SearchIcon className={className} />
      <DropdownSearch
        className={className}
        placeholder={placeholder}
        options={options}
      />
    </Container>
  );
};
