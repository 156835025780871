import React from 'react';
import { StyledIcon } from './styles';

function ValueIcon({ className }) {
  return (
    <StyledIcon
      viewBox="0 0 27 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M26 22.838v3.455a2 2 0 01-2 2H3a2 2 0 01-2-2v-14a2 2 0 012-2h21a2 2 0 012 2v4m0 6.545h-9a2 2 0 01-2-2v-2.545a2 2 0 012-2h9m0 6.545v-6.545m-16.5-6L17.793 2a1 1 0 011.414 0l5.586 5.586a1 1 0 010 1.414L23.5 10.293m-19.5 0L12.293 2a1 1 0 011.414 0L16 4.293m3 15.5a.5.5 0 11-1 0 .5.5 0 011 0z"
        stroke="#140361"
        strokeWidth={2}
      />
    </StyledIcon>
  );
}

export default ValueIcon;
