import styled from 'styled-components';

export const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${props => props.theme.getResponsiveHeight(36)};
  position: relative;
  width: ${props => props.theme.getResponsiveWidth(1600)};
`;

export const CarouselContent = styled.div`
  display: flex;
  overflow-x: hidden;
  scroll-behavior: smooth;
`;

export const CardContainer = styled.div`
  display: inline-flex;
  margin-right: ${props => props.theme.getResponsiveWidth(8)};
`;
