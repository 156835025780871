/* eslint-disable import/no-cycle */
import axios from 'axios';
import { store } from 'store';
import { signOut } from 'store/modules/auth/actions';

import history from 'services/history';

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    console.error(error.response.data);
    const access_token = localStorage.getItem('persist:realeasy/client');
    if (error.response.status === 401 && access_token) {
      history.push('/sign_out');
      store.dispatch(signOut());
    }
    return Promise.reject(error);
  }
);

export default api;
