import * as React from 'react';
import { StyledExpandMenuIcon } from './styles';

function ExpandMenuIcon({ className }) {
  return (
    <StyledExpandMenuIcon
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13 15.342h17m-17 6h9m-9 6h13"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </StyledExpandMenuIcon>
  );
}

export default ExpandMenuIcon;
