import React from 'react';
import { HorizontalBarChart } from 'components/atoms/HorizontalBarChart';
import { H4 } from 'components/atoms/Headings/H4';
import { GeneralContext } from 'utils/contexts/GeneralContext';
import { getImmediateLiquidityData } from 'development';
import { GenericLoading } from 'components/atoms/GenericLoading';
import light from 'styles/themes/light';
import { Container, GraphContainer } from './styles';

export const ImmediateLiquidity = () => {
  const { loading } = React.useContext(GeneralContext);
  const graphData = getImmediateLiquidityData();
  const colors = [light.blueSky, light.blueRoyal];
  const labels = graphData.columns;

  const getDataInOrder = item => {
    const { data } = item;
    const dataInOrder = [];

    labels.forEach(label =>
      data.forEach(data => {
        if (data.deadline === label) {
          dataInOrder.push(data.value);
        }
      })
    );

    return dataInOrder;
  };

  const getDatasets = () => {
    return graphData.liquidity.map((item, index) => ({
      label: item.label,
      data: getDataInOrder(item),
      backgroundColor: colors[index],
      borderColor: colors[index],
      borderWidth: 1,
      barThickness: 20,
    }));
  };

  const data = {
    labels,
    datasets: getDatasets(),
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: 'right',
      align: 'start',
      labels: {
        boxWidth: 12,
        boxHeight: 12,
        padding: 10,
      },
    },
    cornerRadius: 2,
    tooltips: {
      callbacks: {
        label(tooltipItem, data) {
          const datasetLabel =
            data.datasets[tooltipItem.datasetIndex].label || '';
          return `${datasetLabel}: ${tooltipItem.xLabel}%`;
        },
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            display: true,
          },
        },
      ],
      yAxes: [
        {
          barPercentage: 0.7,
          categoryPercentage: 0.8,
          gridLines: {
            lineWidth: 0,
          },
          ticks: {
            padding: 10,
            minRotation: 0,
            autoSkip: true,
            maxTicksLimit: 6,
          },
        },
      ],
    },
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  if (loading) {
    return (
      <GraphContainer>
        <GenericLoading className="immediate-liquidity" />
      </GraphContainer>
    );
  }

  return (
    <Container>
      <H4 className="immediate-liquidity-title">
        Tabela de Liquidez Patrimonial
      </H4>
      <GraphContainer>
        <HorizontalBarChart
          className="immediate-liquidity"
          data={data}
          options={options}
          width={1522}
          height={677}
        />
      </GraphContainer>
    </Container>
  );
};
