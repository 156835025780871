import styled from 'styled-components';

export const Table = styled.table`
  &.receipt-table {
    width: ${props => props.theme.getResponsiveWidth(1556)};
    margin-top: ${props => props.theme.getResponsiveHeight(18)};
    height: min-content;
    min-width: 974px;
    overflow: auto;

    td,
    th {
      padding: ${props => props.theme.getResponsiveHeight(10)};
    }
  }
`;
