import styled from 'styled-components';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 calc(33.33% - 30px);
  min-width: 250px;
  max-width: 100%;
  padding: 15px;
  height: inherit;
  background-color: ${({ theme }) => theme.white};
  border-radius: 1rem;
  box-shadow: 4px 4px 15px 0px ${({ theme }) => theme.boxShadowColor};

  @media (max-width: 1024px) {
    flex: 1 1 calc(50% - 30px);
  }

  @media (max-width: 768px) {
    flex: 1 1 100%;
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.gradientSkeleton[0]} 25%,
    ${({ theme }) => theme.gradientSkeleton[1]} 50%,
    ${({ theme }) => theme.gradientSkeleton[0]} 75%
  );
  background-size: 200% 100%;
  animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;
  border-radius: 5px;
  margin-top: 20px;
`;
