const extractOptions = routes => {
  const options = [];

  Object.keys(routes).forEach(routeKey => {
    const route = routes[routeKey];
    if (route.subPages && route.subPages.length > 0) {
      route.subPages.forEach(subPage => {
        if (route.path === '') {
          options.push({
            label: `${route.title} - ${subPage.title}`,
            value: `${subPage.path}`,
          });
        } else {
          options.push({
            label: `${route.title} - ${subPage.title}`,
            value: `${route.path}/${subPage.path}`,
          });
        }
      });
    }
  });

  return options;
};

export { extractOptions };
