import styled from 'styled-components';

export const FlexContainer = styled.div`
  &.home {
    display: flex;
    height: min-content;
    background-color: ${({ theme }) => theme.white};
    justify-content: end;
    align-items: flex-end;
  }
`;
