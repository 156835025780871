import * as React from "react";
import { SealedIConContainer } from "./styles";

export const SealedICon = ({
    className,
}) => {
    return (
        <SealedIConContainer
            width={23}
            height={28}
            viewBox="0 0 23 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M2.292 26.917h18.083m.349-10.683a3.228 3.228 0 00-2.287-.942H4.23A3.23 3.23 0 001 18.52v1.937a1.291 1.291 0 001.292 1.292h18.083a1.291 1.291 0 001.292-1.292v-1.937c0-.853-.336-1.68-.943-2.287z"
                stroke="#140361"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.916 15.292V9.479c0-1.937 1.292-1.937 1.292-4.52a3.875 3.875 0 00-3.875-3.876c-2.144 0-3.875 1.292-3.875 3.875 0 2.584 1.292 2.584 1.292 4.521v5.813"
                stroke="#140361"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SealedIConContainer>
    )
}
