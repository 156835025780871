import React, { useState, useRef, useEffect } from "react";
import { ArrowContainer, Container, DropdownContent, Month, ChevronIcon } from "./styles";
import { FaChevronDown } from "react-icons/fa";
import light from "styles/themes/light";
import { getCurrentMonthObject } from 'utils/date';
import { Span } from "components/atoms/Span";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

export const DateDropdown = ({
    className = '',
    prefix = '',
    selectedDate = {
        value: 'yyyy-mm-dd',
        label: 'dd/mm/yyyy'
    },
    setSelectedDate = () => { },
}) => {
    const dropdownRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [currentYear, setCurrentYear] = useState(new Date(selectedDate.value).getFullYear());

    const monthsName = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];

    const getMonthName = (index) => {
        const monthObject = getCurrentMonthObject(new Date(currentYear, index, 15));
        setSelectedDate(monthObject);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const addNewYear = () => {
        setCurrentYear(currentYear + 1);
    };

    const removeNewYear = () => {
        setCurrentYear(currentYear - 1);
    };

    useEffect(() => {
        setCurrentYear(new Date(selectedDate.value).getFullYear());
    }, [selectedDate]);

    return (
        <Container className={`${className} dropdown`} onClick={toggleDropdown}>
            <Span className={`${className} dropdown`}>{prefix || ''} {selectedDate.label}</Span>

            <ChevronIcon className={`${className}`} isRotated={isOpen}>
                <FaChevronDown color={light.blueMidnight} className="date-dropdown-arrow" />
            </ChevronIcon>

            {isOpen && (
                <>
                    <DropdownContent ref={dropdownRef} className={`${className} title`}>
                        <ArrowContainer
                            className={`${className}`}
                            onClick={(e) => {
                                e.stopPropagation();
                                removeNewYear();
                            }}>
                            <IoIosArrowBack className="date-dropdown-arrow" />
                        </ArrowContainer>
                        <Span className={`${className} date-dropdown-year`}>
                            {currentYear}
                        </Span>
                        <ArrowContainer
                            className={`${className}`}
                            onClick={(e) => {
                                e.stopPropagation();
                                addNewYear();
                            }}>
                            <IoIosArrowForward className="date-dropdown-arrow" />
                        </ArrowContainer>
                    </DropdownContent>
                    <DropdownContent ref={dropdownRef} className={`${className} body`}>
                        {monthsName.map((month, index) => {
                            const currentDate = new Date(currentYear, index, 15);
                            const selected = new Date(selectedDate.value);
                            const isSameDate = currentDate.getFullYear() === selected.getFullYear() && currentDate.getMonth() === selected.getMonth() && currentDate.getDate();
                            const classname = isSameDate ? 'selected' : '';
                            return (
                                <Month onClick={() => getMonthName(index)} key={index} className={`${className} ${classname}`}>
                                    <Span className={`${className} date-dropdown-month-name`}>
                                        {month}
                                    </Span>
                                </Month>
                            )
                        })}
                    </DropdownContent>
                </>
            )}
        </Container>
    );
};
