import styled from "styled-components";

export const Container = styled.div`
    &.asset-management {
        width: ${props => props.theme.getResponsiveWidth(27)};
        height: ${props => props.theme.getResponsiveWidth(28)};

        @media (max-width: 1023px){
            width: 14.4px;
            height: 14.9px;   
        }
    }
`;

export const Svg = styled.svg`
    &.asset-management {
        width: ${props => props.theme.getResponsiveWidth(27)};
        height: ${props => props.theme.getResponsiveWidth(28)};

        path {
            fill: ${({ theme }) => theme.blueRoyal};
        }

        &.hovered {
            path {
                fill: ${({ theme }) => theme.white};
            }
        }

        @media (max-width: 1023px){
            width: 14.4px;
            height: 14.9px;   
        }
    }
`;