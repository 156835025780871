import React from 'react';
import { Span } from 'components/atoms/Span';
import { Button } from 'components/atoms/Button';
import { GroupItens, TextContainer, TitleWrapper } from './styles';

export const TableTitleButton = ({
  nameTable,
  className,
  onClickLeft,
  onClickRight,
  currentTableIndex,
  maxTables,
}) => {
  return (
    <TextContainer className={className}>
      <GroupItens>
        <TitleWrapper className="set">
          <Span className="table-title">{nameTable}</Span>
          <Button className="grafic-table">Salvar</Button>
        </TitleWrapper>
      </GroupItens>
    </TextContainer>
  );
};
