const home = {
  title: 'Dashboard',
  path: '',
  subPages: [
    {
      path: 'home',
      title: 'Home',
      hidden: true,
    },
  ],
};
const goals = {
  title: 'Plano de Vida',
  path: 'goals',
  subPages: [
    {
      title: 'Painel dos sonhos',
      path: 'goalPanel',
    },
  ],
};
const budget = {
  title: 'Gestão Orçamentária',
  path: 'finances',
  subPages: [
    {
      title: 'Mapear',
      path: 'budget',
    },
  ],
};
const management = {
  title: 'Gestão Orçamentária',
  path: 'management',
  subPages: [
    {
      title: 'Planejar - Gastos',
      path: 'spending',
    },
    {
      title: 'Planejar - Extrato',
      path: 'extract',
    },
    {
      title: 'Planejar - Semestral',
      path: 'report',
    },
    {
      title: 'Planejar - Pote',
      path: 'detail',
    },
  ],
};
const actManagement = {
  title: 'Gestão Orçamentária',
  path: 'management',
  subPages: [
    {
      title: 'Atuar - Relatório',
      path: 'report',
    },
  ],
};

const retirement = {
  title: 'Plano de Aposentadoria',
  path: 'retirement',
  subPages: [
    {
      title: 'Mapear',
      path: 'map',
    },
    {
      title: 'Planejar',
      path: 'planning',
    },
    {
      title: 'Atuar',
      path: 'act',
    },
  ],
};
const assetManagement = {
  title: 'Gestão Patrimonial',
  path: 'finances/patrimony',
  subPages: [
    {
      title: 'Mapear',
      path: 'map',
    },
    {
      title: 'Planejar',
      path: 'planning',
    },
    {
      title: 'Atuar',
      path: 'act',
    },
  ],
};

const routesInfo = {
  home,
  goals,
  budget,
  management,
  actManagement,
  retirement,
  assetManagement,
};

export default routesInfo;
