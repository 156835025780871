import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    background-color: ${props => props.theme.white};
    margin-top: ${props => props.theme.getResponsiveWidth(34)};
    border-radius: ${props => props.theme.getResponsiveWidth(16)};
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.getResponsiveWidth(32)};
    margin-bottom: ${props => props.theme.getResponsiveWidth(64)};

    @media (max-width: 1023px) {
        margin-top: 18.1px;
        border-radius: 8.5px;
        gap: 17.1px;
        margin-bottom: 34.1px;
    }
`;

export const ScrollContainer = styled.div`
    height: max-content;
    gap: ${props => props.theme.getResponsiveWidth(32)};

    @media (max-width: 1023px) {
        gap: 17.1px;
        overflow: auto;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    width: ${props => props.theme.getResponsiveWidth(1600)};
    height: min-content;
    overflow: auto;
    min-width: 375px;

    &.button {
        min-width: 0;
    }
`;

export const TitleContainer = styled.div`
    margin-top: ${props => props.theme.getResponsiveWidth(45)};
    height: min-content;

    @media (max-width: 1023px) {
        margin-top: 24px;
    }
`;

export const IntroductionContainer = styled.div`
    border: ${props => props.theme.getResponsiveWidth(1)} solid ${props => props.theme.lilca};
    border-radius: ${props => props.theme.getResponsiveWidth(16)};
    width: ${props => props.theme.getResponsiveWidth(612)};
    height: ${props => props.theme.getResponsiveWidth(304)};
    padding: ${props => props.theme.getResponsiveWidth(54)} ${props => props.theme.getResponsiveWidth(50)};
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 1023px){
        border: 0.5px solid ${props => props.theme.lilca};
        border-radius: 8.5px;
        width: 326.4px;
        height: 162.1px;
        padding: 28.8px 26.7px;
    }
`;
