import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: ${props => props.theme.getResponsiveWidth(32)};
    gap: ${props => props.theme.getResponsiveWidth(24)};

    @media (max-width: 1023px) {
        margin-top: 17.1px;
        gap: 12.8px;
    }
`;

export const QuestionOptionsContainer = styled.div`
    border-bottom: ${props => props.theme.getResponsiveHeight(1)} solid ${props => props.theme.grayShadow};
    display: flex;
    align-items: center;
    justify-content: left;
    cursor: pointer;

    &:hover, &.selected {
        background-color: ${props => props.theme.suitabilityOptionHover};

        span {
            color: ${props => props.theme.grayShadow};
        }
    }

    @media (max-width: 1023px) {
        border-bottom: 0.7px solid ${props => props.theme.grayShadow};
    }
`;