import React from 'react';
import { StyledContainer, StyledBar, LoadingContainer } from './styles';

export const BarGrafic = ({ loading, data, options, className }) => {
  return (
    <StyledContainer className={className}>
      {loading ? (
        <>
          <LoadingContainer />
        </>
      ) : (
        <>
          <StyledBar data={data} options={options} />
        </>
      )}
    </StyledContainer>
  );
};
