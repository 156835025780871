import styled from 'styled-components';

export const ModalFooterWithOneButtonContainer = styled.div`
  &.generic-dream-modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 38px;

    @media (max-width: 1023px) {
      margin-top: 20.27px;
    }
  }
`;
