export const getPotDetails = () => {
  return {
    '2023': [
      {
        month: '01',
        potEstimated: {
          receipt: 1800,
          flexibleEventual: 150,
          flexibleMonthly: 250,
          committedMonthly: 100,
          committedEventual: 200,
          investments: 300,
          installment: 0,
          debts: 150,
        },
        potRealized: {
          receipt: 1700,
          flexibleEventual: 130,
          flexibleMonthly: 230,
          committedMonthly: 90,
          committedEventual: 180,
          investments: 280,
          installment: 0,
          debts: 140,
        },
      },
      {
        month: '02',
        potEstimated: {
          receipt: 1900,
          flexibleEventual: 170,
          flexibleMonthly: 270,
          committedMonthly: 120,
          committedEventual: 220,
          investments: 320,
          installment: 0,
          debts: 170,
        },
        potRealized: {
          receipt: 1800,
          flexibleEventual: 150,
          flexibleMonthly: 250,
          committedMonthly: 100,
          committedEventual: 200,
          investments: 310,
          installment: 0,
          debts: 160,
        },
      },
      {
        month: '03',
        potEstimated: {
          receipt: 2000,
          flexibleEventual: 180,
          flexibleMonthly: 280,
          committedMonthly: 130,
          committedEventual: 230,
          investments: 350,
          installment: 0,
          debts: 180,
        },
        potRealized: {
          receipt: 1900,
          flexibleEventual: 160,
          flexibleMonthly: 270,
          committedMonthly: 120,
          committedEventual: 220,
          investments: 340,
          installment: 0,
          debts: 170,
        },
      },
      {
        month: '12',
        potEstimated: {
          receipt: 2500,
          flexibleEventual: 250,
          flexibleMonthly: 350,
          committedMonthly: 180,
          committedEventual: 280,
          investments: 450,
          installment: 0,
          debts: 250,
        },
        potRealized: {
          receipt: 2400,
          flexibleEventual: 230,
          flexibleMonthly: 330,
          committedMonthly: 170,
          committedEventual: 270,
          investments: 440,
          installment: 0,
          debts: 240,
        },
      },
    ],
    '2024': [
      {
        month: '01',
        potEstimated: {
          receipt: 2000,
          flexibleEventual: 200,
          flexibleMonthly: 300,
          committedMonthly: 150,
          committedEventual: 250,
          investments: 400,
          installment: 0,
          debts: 200,
        },
        potRealized: {
          receipt: 1900,
          flexibleEventual: 180,
          flexibleMonthly: 290,
          committedMonthly: 140,
          committedEventual: 240,
          investments: 390,
          installment: 0,
          debts: 190,
        },
      },
      {
        month: '02',
        potEstimated: {
          receipt: 2100,
          flexibleEventual: 220,
          flexibleMonthly: 320,
          committedMonthly: 160,
          committedEventual: 260,
          investments: 420,
          installment: 0,
          debts: 220,
        },
        potRealized: {
          receipt: 2000,
          flexibleEventual: 200,
          flexibleMonthly: 310,
          committedMonthly: 150,
          committedEventual: 250,
          investments: 410,
          installment: 0,
          debts: 210,
        },
      },
      {
        month: '03',
        potEstimated: {
          receipt: 2200,
          flexibleEventual: 240,
          flexibleMonthly: 340,
          committedMonthly: 170,
          committedEventual: 270,
          investments: 440,
          installment: 0,
          debts: 240,
        },
        potRealized: {
          receipt: 2100,
          flexibleEventual: 220,
          flexibleMonthly: 330,
          committedMonthly: 160,
          committedEventual: 260,
          investments: 430,
          installment: 0,
          debts: 230,
        },
      },
      {
        month: '08',
        potEstimated: {
          receipt: 2200,
          flexibleEventual: 240,
          flexibleMonthly: 340,
          committedMonthly: 170,
          committedEventual: 270,
          investments: 440,
          installment: 0,
          debts: 240,
        },
        potRealized: {
          receipt: 2100,
          flexibleEventual: 220,
          flexibleMonthly: 330,
          committedMonthly: 160,
          committedEventual: 260,
          investments: 430,
          installment: 0,
          debts: 230,
        },
      },
      {
        month: '12',
        potEstimated: {
          receipt: 3000,
          flexibleEventual: 300,
          flexibleMonthly: 400,
          committedMonthly: 200,
          committedEventual: 300,
          investments: 500,
          installment: 0,
          debts: 300,
        },
        potRealized: {
          receipt: 2900,
          flexibleEventual: 280,
          flexibleMonthly: 390,
          committedMonthly: 190,
          committedEventual: 290,
          investments: 490,
          installment: 0,
          debts: 290,
        },
      },
    ],
  };
};
