import styled from 'styled-components';

export const ArrowIconContainer = styled.svg`
  &.dream-panel {
    fill: none;
    width: calc((24 / 1920) * 100vw);
    height: calc((24 / 1080) * 100vh);

    path:first-of-type {
      fill: ${({ theme }) => theme.white};
    }

    path:nth-of-type(2) {
      fill: ${({ theme }) => theme.white};
    }
  }
`;
