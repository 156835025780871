import styled from "styled-components";

export const Container = styled.div`

`;

export const Wrapper = styled.div`
    &.empty-suitability-questionnaire {
        display: flex;
        align-items: center;
        align-self: center;
    }
`;

export const ContentBody = styled.div`
    &.empty-suitability-questionnaire {
        width: ${props => props.theme.getResponsiveWidth(35)};
        height: ${props => props.theme.getResponsiveWidth(35)};
        padding: ${props => props.theme.getResponsiveWidth(8)} ${props => props.theme.getResponsiveWidth(8)} ${props => props.theme.getResponsiveWidth(4)} ${props => props.theme.getResponsiveWidth(8)};
        border-radius: ${props => props.theme.getResponsiveWidth(50)};
        background-color: ${props => props.theme.grayQuestionPager};
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            background-color: ${props => props.theme.blueMidnight};

            span.empty-suitability-questionnaire {
                color: ${props => props.theme.white};
            }
        }

        &.selected {
            background-color: ${props => props.theme.blueMidnight};
        }

        @media (max-width: 1023px) {
            width: 18.7px;
            height: 18.7px;
            padding: 4.3px 4.3px 2.1px 4.3px;
            border-radius: 26.7px;
        }
    }
    `;

export const Content = styled.span`
    &.empty-suitability-questionnaire {
        display: block;
        width: min-content;
        height: min-content;
        color: ${props => props.theme.graySilver};
        line-height: ${props => props.theme.getResponsiveWidth(18)};
        font-size: ${props => props.theme.getResponsiveWidth(18)};
        font-weight: 700;
        text-align: center;

        &.selected {
            color: ${props => props.theme.white};
        }

        @media (max-width: 1023px) {
            line-height: 9.6px;
            font-size: 9.6px;
        }
    }
`;

export const Separator = styled.div`
    &.empty-suitability-questionnaire {
        width: ${props => props.theme.getResponsiveWidth(5)};
        height: ${props => props.theme.getResponsiveWidth(5)};
        border-radius: ${props => props.theme.getResponsiveWidth(50)};
        background-color: ${props => props.theme.questionPagerHover};
        margin-left: ${props => props.theme.getResponsiveWidth(4)};
        margin-right: ${props => props.theme.getResponsiveWidth(6)};

        @media (max-width: 1023px) {
            width: 2.7px;
            height: 2.7px;
            border-radius: 26.7px;
            margin-left: 2.1px;
            margin-right: 3.2px;
        }
    }
`;