import { H4 } from 'components/atoms/Headings/H4';
import SetLeftIcon from 'components/atoms/icons/SetLeftIcon';
import SetRightIcon from 'components/atoms/icons/SetRightIcon';
import { Card } from 'components/templates/Card';
import React, { useState, useRef } from 'react';
import { CardContainer, CarouselContainer, CarouselContent } from './styles';

export const CarouselReport = ({ currentPage, months }) => {
  const carouselRef = useRef(null);
  const [startIndex, setStartIndex] = useState(0);
  const visibleItems = 6;
  const itemWidth = 260;

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex(prev => prev - 1);
      carouselRef.current.scrollBy({
        left: -itemWidth,
        behavior: 'smooth',
      });
    }
  };

  const handleNext = () => {
    if (startIndex + visibleItems < months.length) {
      setStartIndex(prev => prev + 1);
      carouselRef.current.scrollBy({
        left: itemWidth,
        behavior: 'smooth',
      });
    }
  };

  return (
    <CarouselContainer>
      <SetLeftIcon
        onClick={handlePrev}
        className={startIndex > 0 ? 'blue' : ''}
      >
        &#8249;
      </SetLeftIcon>
      <CarouselContent ref={carouselRef} className="carousel-content">
        {months.slice(startIndex, startIndex + visibleItems).map((month, i) => (
          <CardContainer key={i}>
            <Card.Container className={`carousel-report ${currentPage}`}>
              <Card.Content className="carousel">
                <H4 className="title-carousel">{month.name}</H4>
                <H4 className="value-carousel">{month.value}</H4>
              </Card.Content>
            </Card.Container>
          </CardContainer>
        ))}
      </CarouselContent>

      <SetRightIcon onClick={handleNext}>&#8250;</SetRightIcon>
    </CarouselContainer>
  );
};
