import { H6 } from 'components/atoms/Headings/H6';
import { Card, StyledContainer } from './styles';
import React from 'react';
import { H5 } from 'components/atoms/Headings/H5';
import { H4 } from 'components/atoms/Headings/H4';
import PencilIcon from 'components/atoms/icons/PencilIcon';

export const Legend = ({
  title,
  subtitle,
  value,
  className,
  type = 'none',
  onClick,
}) => {
  const icons = {
    edit: <PencilIcon className={`${className}`} onClick={onClick} />,
    none: '',
  };
  return (
    <StyledContainer>
      <Card>
        <H5 className={`${className}`}>{title}</H5>
        {icons[type]}
      </Card>
      <H6 className={`${className}`}>{subtitle}</H6>
      <H4 className={`${className}`}>{value}</H4>
    </StyledContainer>
  );
};
