import { differenceInCalendarMonths } from 'date-fns';
import { formatDateToISO } from './date';

export const calculateIFP = ({
  end,
  profitability,
  value,
  patrimonialActiveValue,
  financialActiveValue,
}) => {
  const formatEnd = formatDateToISO(end);

  if (
    typeof formatEnd !== 'string' ||
    !formatEnd.match(/^\d{4}-\d{2}-\d{2}$/)
  ) {
    console.error('Data de término inválida:', end);
    return '0';
  }

  const isFirstDayOfMonth = formatEnd.split('-')[2] === '01';
  const subtractValue = isFirstDayOfMonth ? 0 : 1;
  const differenceInMonths =
    differenceInCalendarMonths(new Date(end), new Date()) - subtractValue + 1;
  const n = differenceInMonths <= 0 ? 1 : differenceInMonths;
  const i = profitability / 100;
  const fv = value - patrimonialActiveValue;
  const pv = financialActiveValue;
  const ifp = (fv - pv * (1 + i) ** n) * (((1 + i) ** n - 1) / i) ** -1;

  return (ifp || 0).toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
};
