import styled from "styled-components";

export const Container = styled.div`
    &.asset-management-doughnut-grafic-description {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: ${props => props.theme.getResponsiveWidth(10)};
        height: ${props => props.theme.getResponsiveWidth(12)};
        margin-top: ${props => props.theme.getResponsiveWidth(16)};

        @media (max-width: 1023px) {
            width: 5.3px;
            height: 6.4px;
            margin-top: 8.5px;
        }
    }
`;