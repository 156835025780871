import React, { useContext } from 'react';
import { Container } from './styles';
import { EstimatedPot } from '../../molecules/EstimatedPot';
import { ActualPot } from '../../molecules/ActualPot';
import { GeneralContext } from 'utils/contexts/GeneralContext';

const PotCard = ({ className, budgetEstimated, budgetActual, url }) => {
  const { loading } = useContext(GeneralContext);

  return (
    <Container className={className}>

      <EstimatedPot
        className={className}
        title="Pote Estimado"
        loading={loading}
        budget={budgetEstimated}
      />
      <ActualPot
        className={className}
        url={url}
        title="Pote Realizado"
        loading={loading}
        budget={budgetActual}
        potSum={budgetActual.scale}
      />
    </Container>
  );
};

export default PotCard;
