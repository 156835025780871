import React from 'react';
import { StyledContainer, FlexContainer, DivContainer } from './styles';
import { SubtitleBar } from '../../atoms/SubtitleBar';
import { BarGrafic } from '../../atoms/BarGrafic';
import TitleCard from 'components/molecules/TitleCard';
import light from 'styles/themes/light';

export const ActualPot = ({
  url,
  title,
  loading,
  className,
  budget,
  width,
  height,
}) => {
  const data = {
    labels: [''],
    datasets: [
      {
        label: 'Receitas',
        data: [budget.receipts.raw],
        backgroundColor: 'transparent',
        borderColor: light.blueRoyal,
        borderSkipped: 'none',
        borderWidth: 1.5,
        yAxisID: 'right',
        barPercentage: 1.0,
        categoryPercentage: 1.0,
      },
      {
        label: 'Dívidas',
        data: [budget.debts.raw],
        backgroundColor: light.redLight,
        borderWidth: 0,
        barPercentage: 1.0,
        categoryPercentage: 1.0,
      },
      {
        label: 'Investimentos',
        data: [budget.investment.raw],
        backgroundColor: light.greenDark,
        borderWidth: 0,
        barPercentage: 1.0,
        categoryPercentage: 1.0,
      },
      {
        label: 'Gastos',
        data: [budget.spendings.raw],
        backgroundColor: light.blueSky,
        borderWidth: 0,
        barPercentage: 1.0,
        categoryPercentage: 1.0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cornerRadius: 5,
    legend: {
      display: false,
    },
    tooltips: {
      showAllTooltips: false,
      enabled: false,
      mode: 'index',
      bodySpacing: 12,
      bodyFontSize: 15,
    },
    plugins: {
      datalabels: {
        color: 'rgba(0,0,0,0)',
      },
    },
    scales: {
      xAxes: [
        {
          display: false,
          stacked: true,
        },
      ],

      yAxes: [
        {
          display: false,
          stacked: true,
          ticks: {
            beginAtZero: true,
            max: budget.scale,
          },
        },
        {
          id: 'right',
          display: false,
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            max: budget.scale,
          },
        },
      ],
    },
  };

  return (
    <StyledContainer width={width} height={height} className="default">
      <TitleCard
        url={url}
        title={title}
        loading={loading}
        className={`${className} title-card`}
      />
      <DivContainer className={className}>
        <BarGrafic
          loading={loading}
          budget={budget}
          potSum={budget.scale}
          width={width}
          data={data}
          options={options}
        />
        <FlexContainer className={className}>
          <SubtitleBar
            subtitleValue={budget.receipts.formatted}
            subtitleText="Recebimentos"
            className="color-receipt"
            loading={loading}
            width={width}
          />
          <SubtitleBar
            subtitleValue={budget.spendings.formatted}
            subtitleText="Gastos"
            className="color-spending"
            loading={loading}
            width={width}
          />
          <SubtitleBar
            subtitleValue={budget.investment.formatted}
            subtitleText="Investimentos"
            className="color-investments"
            loading={loading}
            width={width}
          />
          <SubtitleBar
            subtitleValue={budget.debts.formatted}
            subtitleText="Dívidas"
            className="color-debts"
            loading={loading}
            width={width}
          />
        </FlexContainer>
      </DivContainer>
    </StyledContainer>
  );
};
