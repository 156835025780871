import * as React from 'react';
import { InvestmentIconContainer } from './styles';

function InvestmentsIcon({ className }) {
  return (
    <InvestmentIconContainer
      className={className}
      viewBox="0 0 41 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.043 27c.266 0 .535-.102.738-.306l15.673-15.737 6.222 6.246c.462.464 1.21.464 1.671 0L38.914 3.582V9.7c0 .547.399 1.034.941 1.086.623.06 1.145-.429 1.145-1.042V1.052c0-.068-.008-.138-.02-.206-.006-.03-.02-.058-.027-.087-.01-.036-.018-.073-.034-.11-.013-.034-.034-.063-.05-.097-.015-.028-.028-.057-.046-.083-.034-.053-.076-.1-.118-.147l-.01-.013a1.085 1.085 0 00-.159-.13c-.026-.02-.052-.03-.078-.045-.034-.019-.066-.04-.102-.053-.031-.013-.065-.02-.1-.031a1.029 1.029 0 00-.096-.03 1.32 1.32 0 00-.174-.017c-.01 0-.019-.003-.029-.003h-8.613c-.545 0-1.03.4-1.082.945-.06.626.428 1.15 1.038 1.15h6.138L24.51 15.074 18.291 8.83a1.182 1.182 0 00-1.674 0L.305 25.209a1.052 1.052 0 000 1.482c.203.204.47.306.738.306V27z"
        fill="#0E8769"
      />
    </InvestmentIconContainer>
  );
}

export default InvestmentsIcon;
