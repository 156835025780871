import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  &.invisible {
    display: none;
  }

  &.header {
    border-bottom: 1.5px solid ${({ theme }) => theme.grayMedium};
    padding-left: 2%;
    gap: 2%;
    min-width: 300px;
  }
`;
