import styled from 'styled-components';

export const CardContainer = styled.div`
  display: block;
  margin-top: ${({ theme }) => theme.getResponsiveHeight(24)};
  background-color: ${({ theme }) => theme.white};
  padding-top: ${({ theme }) => theme.getResponsiveHeight(24)};
  padding-left: ${({ theme }) => theme.getResponsiveWidth(22)};
  padding-right: ${({ theme }) => theme.getResponsiveWidth(22)};
  margin: ${({ theme }) => theme.getResponsiveHeight(32)} auto
    ${({ theme }) => theme.getResponsiveHeight(24)};
  border-radius: ${({ theme }) => theme.getResponsiveWidth(16)};

  &.no-footer {
    padding-bottom: ${({ theme }) => theme.getResponsiveHeight(24)};
  }
`;
export const Container = styled.div`
  width: 100%;
  padding-bottom: 0;

  @media (max-width: 1202px) {
    overflow-x: scroll;
  }

  &::-webkit-scrollbar {
    height: ${({ theme }) => theme.getResponsiveHeight(3)};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.graySilver};
    border-radius: 50px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.grayPale};
    border-radius: 50px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

export const GroupIcons = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  height: min-content;
  gap: ${({ theme }) => theme.getResponsiveWidth(13)};
  margin-top: ${({ theme }) => theme.getResponsiveHeight(23)};
  padding-bottom: ${({ theme }) => theme.getResponsiveHeight(24)};
`;
