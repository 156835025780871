import styled, { css } from "styled-components";

export const Container = styled.div`
    &.dropdown {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 48.03%;
        height: 42px;
        border-radius: 8px;
        padding: 12px 16px;
        border: 1px solid ${props => props.theme.blueMidnight};
        cursor: pointer;

        @media (max-width: 1440px) {
            padding: 9px 12px;
            height: 38px;
        }
    }
`;

export const DropdownContent = styled.div`
    &.extract {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: ${props => props.theme.grayPale};
        z-index: 1000;
        max-height: 200px;
        overflow-y: hidden;
        width: 100%;
        padding: 0 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 1440px) {
            padding: 0 12px;
        }
    }
    
    &.extract.title {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        height: 40px;

        @media (max-width: 1440px) {
            height: 36px;
        }
    }

    &.extract.body {
        flex-wrap: wrap;
        padding: 18px 24px;
        gap: 8px 30px;
        margin-top: 40px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        @media (max-width: 1440px) {
            padding: 12px 18px;
            margin-top: 36px;
            gap: 6px 24px;
        }
    }
`;

export const Month = styled.div`
    &.extract {
        width: 20.89%;
        height: 36px;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            cursor: pointer;
            background-color: ${props => props.theme.questionPagerHover};

            span {
                color: ${props => props.theme.white} !important;
            }
        }

        @media (max-width: 1440px) {
            height: 32px;
        }
    }

    &.extract.selected {
        background-color: ${props => props.theme.questionPagerHover};
        span {
            color: ${props => props.theme.white} !important;
        }
    }
`;

export const ArrowContainer = styled.div`
    &.extract {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border-radius: 4px;

        &:hover {
            cursor: pointer;
            background-color: ${props => props.theme.sideBarHover};
            color: ${props => props.theme.white};
        }

        @media (max-width: 1440px) {
            width: 18px;
            height: 18px;
        }
    }

    &.extract > .date-dropdown-arrow {
        width: 100%;
        height: 80%;
    }
`;

export const ChevronIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease-in-out;

    ${({ isRotated }) =>
        isRotated &&
        css`
            transform: rotate(-180deg);
        `}

    &.extract > .date-dropdown-arrow {
        width: 16px;
        height: 13px;

        @media (max-width: 1440px) {
            width: 14px;
            height: 11px;
        }
    }
`;