import React, { useState } from 'react';
import { Modal } from 'components/templates/Modal';

export const EditSocialSecurity = ({
  showModal = false,
  setShowModal = () => {},
  selectedItem = null,
  selectedCategoryKey,
  onConfirm = () => {},
  onCancel = () => {},
  theme = 'receipts',
}) => {
  const [name, setName] = useState(selectedItem?.name);
  const [classification] = useState(selectedItem?.category);
  const [installmentValue, setInstallmentValue] = useState(
    selectedItem?.installmentValue
  );

  const confirm = () => {
    setShowModal(false);
    onConfirm();
  };

  const cancel = () => {
    const currentCategory = JSON.parse(
      localStorage.getItem(selectedCategoryKey)
    );
    currentCategory.name = name;
    currentCategory.classification = classification;
    localStorage.setItem(selectedCategoryKey, JSON.stringify(currentCategory));
    setShowModal(false);
    onCancel();
  };

  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="simple-modal edit"
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title="Editar Previdência Social"
          onClose={() => setShowModal(false)}
          type="edit"
          className="simple-modal"
          theme={theme}
        />
      </Modal.TitleContainer>
      <Modal.BodyContainer>
        <Modal.InputText
          className="generic-dream-modal"
          label="Nome "
          getInputValue={setName}
          defaultValue={name}
        />

        <Modal.DatePicker
          className="generic-dream-modal-datepicker after full-width"
          label="Data da Análise"
        />
        <Modal.DatePicker
          className="generic-dream-modal-datepicker after full-width"
          label="Data Prevista de Aposentadoria"
        />
        <Modal.InputText
          className="generic-dream-modal after"
          label="Valor à Investir "
          getInputValue={setName}
          defaultValue={name}
        />

        <Modal.InputMoney
          className="generic-dream-modal after "
          label="Aporte Mensal "
          getInputValue={setInstallmentValue}
          defaultValue={installmentValue}
        />
        <Modal.InputText
          className="generic-dream-modal after"
          label="R.M.I Atual"
          getInputValue={setName}
          defaultValue={name}
        />
        <Modal.InputText
          className="generic-dream-modal after"
          label="Renda na Aposentadoria"
          getInputValue={setName}
          defaultValue={name}
        />
      </Modal.BodyContainer>
      <Modal.SimpleFooter
        onConfirm={confirm}
        onCancel={cancel}
        className={`simple-modal ${theme}`}
        confirmButtonText="Concluído"
        cancelButtonText="Cancelar"
      />
    </Modal.Container>
  );
};
