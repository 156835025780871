import React, { useState } from 'react';
import {
  ArrowsContainer,
  CalendarContainer,
  CalendarTitle,
  Title,
  Day,
  DaysGrid,
  ButtonContainer,
  Overlay,
} from './styles';
import { getMonthsName } from 'utils/date';
import { DownArrowIcon } from '../../atoms/icons/SimpleArrows/DownArrow';
import { UpArrowIcon } from '../../atoms/icons/SimpleArrows/UpArrow';
import { Button } from '../../atoms/Button';

export const Calendar = ({
  className = 'default',
  buttonRef = null,
  showCalendar = true,
  setShowCalendar = () => {},
  getValue = () => {},
  defaultValue = new Date(),
  top = false,
}) => {
  const dayInitials = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
  const monthNames = getMonthsName();
  const [currentDate, setCurrentDate] = useState(defaultValue || new Date());
  const [selectedDate, setSelectedDate] = useState(defaultValue || new Date());
  const buttonPosition = { top: 0, left: 0 };

  const generateCalendar = date => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const firstDayOfMonth = new Date(year, month, 1);
    const lastDayOfMonth = new Date(year, month + 1, 0);
    const daysInMonth = lastDayOfMonth.getDate();
    const firstDayOfWeek = firstDayOfMonth.getDay();

    const calendar = [];

    for (let i = firstDayOfWeek; i > 0; i--) {
      const day = new Date(year, month, -i + 1);
      calendar.push({ date: day, outside: true });
    }

    for (let i = 1; i <= daysInMonth; i++) {
      const day = new Date(year, month, i);
      calendar.push({ date: day, outside: false });
    }

    const lastDayOfWeek = lastDayOfMonth.getDay();
    for (let i = 1; i < 7 - lastDayOfWeek; i++) {
      const day = new Date(year, month + 1, i);
      calendar.push({ date: day, outside: true });
    }

    return calendar;
  };

  const selectToday = () => {
    setSelectedDate(new Date());
    setCurrentDate(new Date());
    getValue(new Date());
    setShowCalendar(false);
  };

  const cleanDate = () => {
    setSelectedDate(null);
    getValue(null);
    getValue('');
    setShowCalendar(false);
  };

  const selectDate = date => {
    setSelectedDate(date);
    setShowCalendar(false);
    getValue(date);
  };

  const prevMonth = () => {
    setCurrentDate(
      prevDate => new Date(prevDate.getFullYear(), prevDate.getMonth() - 1)
    );
  };

  const nextMonth = () => {
    setCurrentDate(
      prevDate => new Date(prevDate.getFullYear(), prevDate.getMonth() + 1)
    );
  };

  const calendar = generateCalendar(currentDate);

  React.useEffect(() => {
    const handleClickOutside = event => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target) &&
        showCalendar
      ) {
        setShowCalendar(false);
      }
    };
    document.addEventListener('click', handleClickOutside);

    return () => document.removeEventListener('click', handleClickOutside);
  }, [showCalendar, setShowCalendar, buttonRef]);

  return (
    <>
      {showCalendar && (
        <Overlay
          className={`${className} ${top && 'top'}`}
          onClick={() => setShowCalendar(false)}
        >
          <CalendarContainer
            className={className}
            onClick={e => e.stopPropagation()}
            style={{
              top: buttonPosition.top,
              left: buttonPosition.left,
              position: 'absolute',
            }}
          >
            <CalendarTitle className={className}>
              <Title className={className}>
                {monthNames[currentDate.getMonth()]},{' '}
                {currentDate.getFullYear()}
              </Title>
              <ArrowsContainer className={className}>
                <DownArrowIcon onClick={prevMonth} />
                <UpArrowIcon onClick={nextMonth} />
              </ArrowsContainer>
            </CalendarTitle>
            <DaysGrid className={className} type="title">
              {dayInitials.map((day, index) => (
                <Day className={className} key={index} type="title">
                  {day}
                </Day>
              ))}
            </DaysGrid>
            <DaysGrid>
              {calendar.map((day, index) => (
                <React.Fragment key={index}>
                  <Day
                    onClick={() => selectDate(day.date)}
                    selected={
                      selectedDate &&
                      selectedDate.toDateString() === day.date.toDateString() &&
                      'currentMonth'
                    }
                    outside={day.outside}
                    className={className}
                    type="text"
                  >
                    {day.date.getDate()}
                  </Day>
                </React.Fragment>
              ))}
            </DaysGrid>
            <ButtonContainer className={className}>
              <Button className="calendar-button" onClick={selectToday}>
                Hoje
              </Button>
              <Button className="calendar-button" onClick={cleanDate}>
                Limpar
              </Button>
            </ButtonContainer>
          </CalendarContainer>
        </Overlay>
      )}
    </>
  );
};
