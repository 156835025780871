import React, { useState } from "react";
import {
    Container,
    Wrapper,
    IconContainer,
    ChevronIcon
} from './styles';
import { EatingOutCategoryIcon } from "components/atoms/icons/EatingOutCategoryIcon";
import { HousingCategoryIcon } from "components/atoms/icons/HousingCategoryIcon";
import { LifeAndHealthCategoryIcon } from "components/atoms/icons/LifeAndHealthCategoryIcon";
import { LeisureCategoryIcon } from "components/atoms/icons/LeisureCategoryIcon";
import { LegalEntityCategoryIcon } from "components/atoms/icons/LegalEntityCategoryIcon";
import { PersonalCategoryIcon } from "components/atoms/icons/PersonalCategoryIcon";
import { TransportCategoryIcon } from "components/atoms/icons/TransportCategoryIcon";
import { Span } from "components/atoms/Span";
import CategoryProgressBar from "components/molecules/CategoryProgressBar";
import { FaChevronDown } from "react-icons/fa";
import { InvestmentBarChartCategoryIcon } from "components/atoms/icons/InvestmentBarChartCategoryIcon";
import { WalletCategoryIcon } from "components/atoms/icons/WalletCategoryIcon";
import { getColorPercentage } from "utils/extract";
import { GenericLoading } from "components/atoms/GenericLoading";
import { SimpleExtractCategoryItemsTable } from "components/molecules/SimpleExtractCategoryItemsTable";

export const CategoryIntroduction = ({
    classification = '',
    additionClassname = '',
    name = '',
    currentValue = 'R$ 0',
    maxValue = 'R$ 0',
    redPercentage = 0,
    bluePercentage = 0,
    pinkPercentage = 0,
    darkBluePercentage = 0,
    darkGreenPercentage = 0,
    data = [],
    currentPage = 'spendings',
    type = 'spendings',
    loading = false,
    setShowEditItemModal = () => { },
    setItemData = () => { },
}) => {
    const [loadingItems, setLoadingItems] = useState(loading || false);
    const [loadingItem, setLoadingItem] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [expandedItems, setExpandedItems] = useState(null);

    React.useEffect(() => {
        setLoadingItems(false);
        setLoadingItem(null);
    }, []);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const handleToggleItems = (index) => {
        setExpandedItems(prevIndex => (prevIndex === index ? null : index));
    };

    const categoryIcons = {
        eatingOut: <EatingOutCategoryIcon className="extract-title" />,
        housing: <HousingCategoryIcon className="extract-title" />,
        lifeAndHealth: <LifeAndHealthCategoryIcon className="extract-title" />,
        leisure: <LeisureCategoryIcon className="extract-title" />,
        legalEntity: <LegalEntityCategoryIcon className="extract-title" />,
        personal: <PersonalCategoryIcon className="extract-title" />,
        transport: <TransportCategoryIcon className="extract-title" />,
        investment: <InvestmentBarChartCategoryIcon className="extract-title" />,
        wallet: <WalletCategoryIcon className="extract-title" />
    };

    const currentIcon = type === 'spendings' ? categoryIcons[classification] : type === 'receipts' ? categoryIcons['wallet'] : categoryIcons['investment'];

    const items = data.map((item, index) => (
        <>
            <Container className={`items ${expandedItems === index ? 'expanded' : ''}`} key={index}>
                <Container className="category-items">
                    <Wrapper className="extract-title-item">
                        <Span className="extract-item">{item.name}</Span>
                    </Wrapper>
                    <Wrapper className="extract-title-item">
                        <Span className="extract-title-item">
                            {item.value}
                            <Span className={'extract-title-item secondary'}>
                                {' de ' + item.maxValue}
                            </Span>
                        </Span>
                    </Wrapper>
                </Container>
                <Container className="body-items" key={item}>
                    <CategoryProgressBar
                        redPercentage={getColorPercentage('red', item, currentPage)}
                        bluePercentage={getColorPercentage('blue', item, currentPage)}
                        pinkPercentage={getColorPercentage('pink', item, currentPage)}
                        darkBluePercentage={getColorPercentage('dark-blue', item, currentPage)}
                        darkGreenPercentage={getColorPercentage('dark-green', item, currentPage)}
                        className="extract-category-item"
                    />
                    <IconContainer className="extract-category-item" onClick={() => handleToggleItems(index)}>
                        <ChevronIcon isRotated={expandedItems === index}>
                            <FaChevronDown size={9} />
                        </ChevronIcon>
                    </IconContainer>
                </Container>

            </Container>
            {expandedItems === index ?
                <SimpleExtractCategoryItemsTable
                    item={item}
                    loading={loadingItem}
                    setShowEditItemModal={setShowEditItemModal}
                    setItemData={setItemData}
                />
                : <></>}
        </>
    ));

    const loadingItemsList = Array.from({ length: 5 }, (_, index) => (
        <Container className={`items ${expandedItems === index ? 'expanded' : ''}`} key={index}>
            <Container className="body-items">
                <GenericLoading className="extract-item-progressbar" />
                <IconContainer className="extract-category-item" onClick={() => handleToggleItems(index)}>
                    <GenericLoading className="extract-category-title-icon" />
                </IconContainer>
            </Container>
        </Container>
    ));

    if (loading) {
        return (
            <>
                <Container className="header" onClick={handleToggle}>
                    <Wrapper className="extract-title">
                        <GenericLoading className="extract-category-title" />
                    </Wrapper>
                    <Wrapper className="extract-title">
                        <GenericLoading className="extract-category-title-text" />
                    </Wrapper>
                </Container>
                <Container className="body">
                    <GenericLoading className="extract-category-title-progressbar" />
                    <IconContainer className="extract-category">
                        <GenericLoading className="extract-category-title-icon" />
                    </IconContainer>
                </Container>
            </>
        )
    }

    return (
        <>
            <Container className="header" onClick={handleToggle}>
                <Wrapper className="extract-title">
                    <IconContainer className="extract-title">
                        {currentIcon || <></>}
                    </IconContainer>
                    <Span className="extract-title">{name}</Span>
                </Wrapper>
                <Wrapper className="extract-title">
                    <Span className="extract-title">
                        {currentValue}
                        <Span className={'extract-title secondary'}>
                            {' de ' + maxValue}
                        </Span>
                    </Span>
                </Wrapper>
            </Container>
            <Container className="body">
                <CategoryProgressBar
                    redPercentage={redPercentage}
                    bluePercentage={bluePercentage}
                    pinkPercentage={pinkPercentage}
                    darkBluePercentage={darkBluePercentage}
                    darkGreenPercentage={darkGreenPercentage}
                    redTooltip="Progresso de falhas"
                    blueTooltip="Progresso de sucesso"
                    className="extract-category"
                />
                <IconContainer className="extract-category" onClick={handleToggle}>
                    <ChevronIcon isRotated={isExpanded}>
                        <FaChevronDown size={9} />
                    </ChevronIcon>
                </IconContainer>
            </Container>
            {isExpanded && (loadingItems ? loadingItemsList : items)}
        </>
    );
};
