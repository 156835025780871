import React from 'react';
import { DreamCard } from 'components/templates/DreamCard';
import { GenericDreamModal } from 'components/molecules/GenericDreamModal';

export const EmptyGoal = ({ onCreateGoal = () => {}, reloadPage }) => {
  const [showModal, setShowModal] = React.useState(false);

  const editModal = () => {
    setShowModal(prev => !prev);
  };

  return (
    <>
      {showModal && (
        <GenericDreamModal
          showModal={showModal}
          setShowModal={setShowModal}
          action="add"
          onCreateGoal={onCreateGoal}
          onConfirm={reloadPage}
        />
      )}
      <DreamCard.RootContainer
        className="goal-panel empty-goal-panel clickable"
        onClick={editModal}
      >
        <DreamCard.Icon icon="add-dream" className="empty-goal-panel" />
        <DreamCard.Text
          text="Preencha esse espaço com novos sonhos"
          className="empty-goal-panel"
        />
      </DreamCard.RootContainer>
    </>
  );
};
