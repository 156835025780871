import styled from 'styled-components';

export const ShoppingCartIconContainer = styled.svg`
  &.summary-card {
    width: 19.18px;
    height: 15.63px;
    border-radius: 4px;

    path {
      fill: ${({ theme }) => theme.pale};
    }

    @media (max-width: 1920px) {
      width: 18px;
      height: 14.6px;
      border-radius: 3.8px;
    }

    @media (max-width: 1440px) {
      width: 16px;
      height: 13px;
      border-radius: 3.5px;
    }

    @media (max-width: 1280px) {
      width: 14px;
      height: 11.5px;
      border-radius: 3px;
    }

    @media (max-width: 1024px) {
      width: 12px;
      height: 10px;
      border-radius: 2.8px;
    }

    @media (max-width: 768px) {
      width: 10px;
      height: 8.5px;
      border-radius: 2.5px;
    }
  }
`;
