import React from 'react';
import { Span } from 'components/atoms/Span';

export const HighlightedBodyContent = ({
  className,
  text,
  highlightedText,
}) => {
  return (
    <>
      <Span className={className}>{text}</Span>
      <Span className={` ${className} highlighted`}>{highlightedText}</Span>
    </>
  );
};
