import { css } from 'styled-components';

export const HorizontalScrollbarStyles = css`
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.white};
    border-radius: 0px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.white};
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;
