import * as React from 'react';
import { StyledIcon } from './styles';

function DownIcon(props) {
  return (
    <StyledIcon
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={15} cy={15} r={14} stroke="#0C053F" strokeOpacity={0.45} />
      <path
        d="M8.875 13.25L15 19.375l6.125-6.125"
        stroke="#0C053F"
        strokeOpacity={0.45}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledIcon>
  );
}

export default DownIcon;
