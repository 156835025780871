import * as React from 'react';
import { SpendingIconContainer } from './styles';

function SpendingsIcon({ className }) {
  return (
    <SpendingIconContainer
      className={className}
      viewBox="0 0 38 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.55 4.989c-.422-.553-1.103-.85-1.802-.85H6.868L5.864.826A1.023 1.023 0 004.91.103L.842 0a.818.818 0 00-.84.878c.032.424.408.744.836.755l2.894.073a.955.955 0 01.889.673l.847 2.792 4.216 14.146a2.046 2.046 0 01-.568 2.085 2.744 2.744 0 00-.888 2.16c.065 1.497 1.36 2.654 2.868 2.654h3.403a3.09 3.09 0 00-1.586 2.85c.079 1.569 1.377 2.858 2.955 2.93a3.103 3.103 0 003.257-3.087 3.091 3.091 0 00-1.59-2.695h7.46a3.09 3.09 0 00-1.586 2.85c.078 1.57 1.376 2.858 2.954 2.93 1.78.084 3.257-1.333 3.257-3.087a3.091 3.091 0 00-1.59-2.695h3.578c.48 0 .864-.409.817-.896-.041-.424-.425-.737-.853-.737H11.064c-.593 0-1.122-.428-1.187-1.014-.04-.353.08-.693.334-.944.217-.218.505-.337.812-.337h21.043c.748 0 1.406-.49 1.62-1.203l4.223-14.17c.198-.669.07-1.372-.355-1.926l-.004.004zm-21.533 25.38a1.464 1.464 0 01-1.466-1.458c0-.804.659-1.458 1.466-1.458.808 0 1.466.654 1.466 1.458 0 .803-.658 1.458-1.466 1.458zm10.494 0a1.464 1.464 0 01-1.466-1.458c0-.804.658-1.458 1.466-1.458.807 0 1.466.654 1.466 1.458 0 .803-.659 1.458-1.466 1.458zm9.82-23.918L32.21 20.277a.531.531 0 01-.51.378H12.258a.623.623 0 01-.598-.445L7.594 6.568a.622.622 0 01.598-.798h27.629c.231 0 .366.134.426.212.06.08.15.245.084.467v.002z"
        fill="#697BFF"
      />
    </SpendingIconContainer>
  );
}

export default SpendingsIcon;
