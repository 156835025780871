import React from "react";
import { Container, QuestionOptionsContainer } from "./styles";
import { Span } from "components/atoms/Span";

export const SuitabilityQuiz = ({
    currentQuestion = {},
    answersGiven = [],
    setAnswersGiven = () => { },
}) => {

    const isSelected = ({ questionId, answerId }) => {
        return answersGiven.some(answer => answer.questionId === questionId && answer.answerId === answerId);
    }

    const handleAnswer = ({ questionId, answerId }) => {
        const newAnswers = answersGiven.filter(answer => answer.questionId !== questionId);
        newAnswers.push({ questionId, answerId });
        setAnswersGiven(newAnswers);
    }

    return (
        <Container>
            <Span className="empty-suitability-questionnaire-title-question">
                {currentQuestion.questionText}
            </Span>
            {currentQuestion.answerOptions?.map((question, index) => {
                const questionId = currentQuestion.questionId;
                const answerId = question.answerId;

                return (
                    <QuestionOptionsContainer
                        key={index}
                        onClick={() => handleAnswer({ questionId, answerId })}
                        className={isSelected({ questionId, answerId }) ? "selected" : ""}
                    >
                        <Span className={"empty-suitability-questionnaire-option"}>
                            {question.answerText}
                        </Span>
                    </QuestionOptionsContainer>
                )
            })}
        </Container>
    )
}
