import React, { useContext } from 'react';
import { GeneralContext } from 'utils/contexts/GeneralContext';
import { LoadingTable } from 'components/templates/Table/LoadingTable';
import { RealEstateList } from './RealEstateList';
import { SocialSecurity } from './SocialSecurity';
import { FinancialPatrimony } from './FinancialPatrimony';
import { Rentirement } from './Rentirement';
import {
  getFinancialPatrimony,
  getRealEstateList,
  getRetirement,
  getSocialSecurity,
} from 'development/retirement';

export const RetirementMapTable = ({ currentPage }) => {
  const { loading } = useContext(GeneralContext);

  const pages = {
    socialSecurity: <SocialSecurity socialSecurity={getSocialSecurity()} />,
    rent: <RealEstateList realEstateList={getRealEstateList()} />,
    financialPatrimony: (
      <>
        <FinancialPatrimony financialPatrimony={getFinancialPatrimony()} />
        <Rentirement rentirement={getRetirement()} />
      </>
    ),
  };

  if (loading) {
    return <LoadingTable />;
  }
  return pages[currentPage] || <></>;
};
