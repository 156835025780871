import styled from "styled-components";

export const BoxContainer = styled.div`
    &.extract {
        display: flex;
        background-color: transparent;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 8px;
        padding: 4px;
        cursor: pointer;

        @media (max-width: 1440px) {
            width: 36px;
            height: 36px;
            padding: 3px;
        }
    }

    &.extract.receipts {
        border: 1px solid ${props => props.theme.blueNavy};
    }

    &.extract.receipts.selected {
        background-color: ${props => props.theme.blueNavy};
        cursor: auto;
    }

    &.extract.receipts.hovered {
        background-color: ${props => props.theme.receiptsIconHover};
    }

    &.extract.spendings {
        border: 1px solid ${props => props.theme.blueSky};

        @media (max-width: 1023px) {
            border: 0.53px solid ${props => props.theme.blueNavy};
        }
    }

    &.extract.spendings.selected {
        background-color: ${props => props.theme.blueSky};
        cursor: auto;
    }

    &.extract.spendings.hovered {
        background-color: ${props => props.theme.blueSkyWithSlowOpacity};
    }

    &.extract.investments {
        border: 1px solid ${props => props.theme.greenDark};
    }

    &.extract.investments.selected {
        background-color: ${props => props.theme.greenDark};
        cursor: auto;
    }
    
    &.extract.investments.hovered {
        background-color: ${props => props.theme.investmentsIconHover};
    }
`;