import styled from 'styled-components';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';
import { HorizontalScrollbarStyles } from 'styles/components/HorizontalScrollbarStyles';

export const Container = styled.div`
 flex: 1 1 50%;
  padding: 16px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  width: inherit;
  min-width: 800px;
  align-items: inherit;
  height: inherit;
  box-shadow: 4px 4px 15px 0px ${({ theme }) => theme.boxShadowColor};

`;

export const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;
  gap: 1.2%;
  background: transparent;
  padding-right: 0.1%;

  & > * {
    flex: 0 0 auto;
  }

  &.default {
    ${HorizontalScrollbarStyles};
  }

  &.loading {
    overflow-x: hidden;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.gradientSkeleton[0]} 25%,
      ${({ theme }) => theme.gradientSkeleton[1]} 50%,
      ${({ theme }) => theme.gradientSkeleton[0]} 75%
    );
    background-size: 200% 100%;
    animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;
    border-radius: 5px;
    margin-top: 10px;
  }

  @media (max-width: 2570px) and (max-height: 1080px) {
    gap: 1.5%;
    padding-right: 0.2%;
  }

  @media (max-width: 1920px) and (max-height: 900px) {
    gap: 1.2%;
    padding-right: 0.1%;
  }

  @media (max-width: 1440px) and (max-height: 900px) {
    gap: 1%;
    padding-right: 0.05%;
  }

  @media (max-width: 1280px) and (max-height: 720px) {
    gap: 0.9%;
    padding-right: 0.05%;
  }

  @media (max-width: 1024px) and (max-height: 600px) {
    gap: 0.8%;
  }

  @media (max-width: 768px) and (orientation: portrait) {
    gap: 1%;
    padding-right: 0.05%;
  }
`;
