import styled from "styled-components";

export const UpArrowContainer = styled.div`
    &.default {
        width: 12px;
        height: 11px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: ${props => props.theme.blueRoyal};

        @media (max-width: 1440px){
            width: 9px;
            height: 8px;
            border-radius: 2px;
        }

        @media (max-width: 1024px) {
            width: 6.4px;
            height: 5.87px;
            border-radius: 1.6px;
        }
    }
`;

export const StyledUpArrow = styled.svg`
    &.default {
        width: 6px;
        height: 4px;

        path {
            stroke: ${props => props.theme.white};
        }

        @media (max-width: 1440px){
            width: 4px;
            height: 3px;
        }

        @media (max-width: 1024px){
            width: 3.2px;
            height: 2.13px;
        }
    }
`;