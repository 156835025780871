import React from "react";
import { RootContainer } from "./styles";

export const CardContainer = ({
    children,
    className = '',
    onClick = () => { },
    ...rest
}) => {
    return (
        <RootContainer className={className} onClick={onClick} {...rest}>
            {children}
        </RootContainer>
    )
}