import styled from "styled-components";

export const Container = styled.div`
    &.gallery-modal {
        width: min-content;
        height: min-content;
    }
`;

export const StyledSelectImageCard = styled.svg`
    &.gallery-modal {
        width: ${props => props.theme.getResponsiveWidth(15.33)};
        height: ${props => props.theme.getResponsiveWidth(15)};

        @media (max-width: 1023px) {
            width: 8.176px;
            height: 8px;
        }
    }
`;