import React from 'react';
import { H4 } from 'components/atoms/Headings/H4';
import { GeneralContext } from 'utils/contexts/GeneralContext';

export const HeaderTitle = ({ text, className, loadingClassName }) => {
  const { loading } = React.useContext(GeneralContext);

  return (
    <H4 className={`${className} ${loading ? loadingClassName : ''}`}>
      {text}
    </H4>
  );
};
