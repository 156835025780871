import React from 'react';
import GoalPanel from './GoalPanel';

export default function Goals() {
    const [, , choosenSubPath] = window.location.pathname.split('/');

    if (choosenSubPath === 'goalPanel') {
        return <GoalPanel />;
    }
    return <></>;
}
