import styled from "styled-components";

export const Container = styled.div`
    &.detailed-extract-item{
        display: flex;
        align-items: center;
        height: 52px;
        border-bottom: 1px solid ${props => props.theme.graySilver};
        padding: 20px 0;

        @media (max-width: 1440px) {
            height: 44px;
        }

        @media (max-width: 1024px){
            width: 100%;
            justify-content: space-between;
            padding: 16px 0;
        }
    }
`

export const IconContainer = styled.div`
    &.detailed-extract-item.category {
        width: 32px;
        height: 32px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 1440px) {
            width: 28px;
            height: 28px;
        }
    }

    &.detailed-extract-item.category.spending {
        background-color: ${props => props.theme.detailedExtractItemSpending};
    }

    &.detailed-extract-item.category.spending.installment {
        background-color: ${props => props.theme.pinkInstallmentPurchases};
    }

    &.detailed-extract-item.category.wallet {
        background-color: ${props => props.theme.blueHeaderWithSlowOpacity};
    }

    &.detailed-extract-item.category.investment {
        background-color: ${props => props.theme.detailedInvestmentItemSpending};
    }
`;

export const TextContainer = styled.div`
    &.detailed-extract-item {
        height: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;

        @media (max-width: 1440px) {
            height: 28px;
        }
    }

    &.detailed-extract-item.first {
        margin-left: 0.42vw;
        width: 7.29vw;
    }

    &.detailed-extract-item.second {
        margin-left: 2.29vw;
        width: 5.83vw;
    }

    &.detailed-extract-item.third {
        margin-left: 0.57vw;
        width: 5.83vw;
    }

    &.detailed-extract-item.fourth {
        margin-left: 0.57vw;
        width: 2.86vw;
    }

    &.detailed-extract-item > .reload-icon {
        font-size: 14px;

        @media (max-width: 1440px) {
            font-size: 11px;
        }
    }

`;
