import React from 'react';
import { StyledIcon } from './styles';

function RentIcon({ className }) {
  return (
    <StyledIcon
    viewBox="0 0 27 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1 21.51v3.742c0 .749.499 1.248 1.248 1.248h4.99v-3.743h3.743v-3.743h2.495l1.747-1.746a8.11 8.11 0 10-4.99-4.99L1 21.508z"
        stroke="#140361"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.09 9.033a.624.624 0 100-1.247.624.624 0 000 1.247z"
        stroke="#140361"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledIcon>
  );
}

export default RentIcon;
