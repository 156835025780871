export const extractNumbers = (inputString) => {
    const numbersOnly = inputString.replace(/[^\d.]/g, ''); // Remove caracteres não numéricos, mas mantém ponto
    return parseFloat(numbersOnly); // Converte para número de ponto flutuante
}

export const camelToSnake = (str) => {
    return str.replace(/([A-Z])/g, "_$1").toLowerCase();
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};