import styled from 'styled-components';

export const StyledIcon = styled.svg`
  width: ${props => props.theme.getResponsiveWidth(26)};
  height: ${props => props.theme.getResponsiveHeight(26)};
  min-width: 14.4px;
  min-height: 14.4px;

  &.gray path {
    stroke: ${props => props.theme.graySilver};
  }

  &.none {
    display: none;
  }
`;
