import React from 'react';
import { ModalFooter } from './styles';
import { Button } from 'components/atoms/Button';
import { GenericLoading } from 'components/atoms/GenericLoading';

export const SimpleModalFooter = ({
  className,
  onCancel,
  onConfirm,
  cancelButtonText = 'Cancelar',
  confirmButtonText = 'Concluído',
  loading = false,
  loadingClassname = 'loading',
}) => {

  if (loading) {
    return (
      <ModalFooter>
        <GenericLoading className={loadingClassname + ' first'} />
        <GenericLoading className={loadingClassname + ' second'} />
      </ModalFooter>
    );
  }

  return (
    <ModalFooter>
      <Button className={`${className} cancel`} onClick={onCancel}>
        {cancelButtonText}
      </Button>
      <Button className={`${className} confirm`} onClick={onConfirm}>
        {confirmButtonText}
      </Button>
    </ModalFooter>
  );
};
