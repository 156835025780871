import { ReceiptTable } from 'components/templates/Table/ReceiptTable';
import React, { useState } from 'react';
import { ButtonCategory } from 'components/molecules/ButtonCategory';
import { EditRevenueItemModal } from '../../molecules/BudgetModal/EditRevenueItemModal';
import { GenericEditCategoryTableModal } from 'components/molecules/BudgetModal/GenericEditCategoryTableModal';
import { SimpleDeleteModal } from 'components/molecules/BudgetModal/SimpleDeleteModal';
import { SimpleAddModal } from 'components/molecules/BudgetModal/SimpleAddModal';
import { ButtonContainer } from './styles';
import useBudgetManagement from 'hooks/useBudgetManagement';
import toast from 'react-hot-toast';
import { getItemsClassificationOptions, getReceiptsClassificationOptions } from 'utils/data';

export const RecipeMapPage = ({ data, reloadPageData }) => {
  const [showEditItemModal, setShowEditItemModal] = useState(false);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSimpleAddModal, setShowSimpleAddModal] = useState(false);
  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  const classificationOptions = getReceiptsClassificationOptions()
  const itemsClassificationOptions = getItemsClassificationOptions()

  const {
    createItem,
    updateItem,
    deleteItem,
    createCategory,
    deleteCategory,
    updateCategory,
    deleteManyItems
  } = useBudgetManagement();

  const addItem = async (data) => {
    const createdItem = await createItem(data);

    if (createdItem) {
      setShowAddItemModal(false);
      toast.success('Item criado com sucesso!');
      await reloadPageData();

      setSelectedItem(null);
    }
  }

  const editCategory = async (data) => {
    const uptadedCategory = await updateCategory({
      name: data.name,
      id: data.id,
      classification: data.classification
    });

    if (uptadedCategory) {
      setShowEditCategoryModal(false);
      await reloadPageData();
      setSelectedCategory(null);
    }
  }

  const editItem = async (data) => {
    const updatedItem = await updateItem({ ...data });

    if (updatedItem) {
      setShowEditItemModal(false);
      setSelectedItem(null);
      await reloadPageData();
    }
  }

  const deleteOneItem = async (id) => {
    const deletedItem = await deleteItem(id);

    if (deletedItem) {
      setShowEditItemModal(false);
      setSelectedItem(null);
      await reloadPageData();
    }
  }

  const handleDeleteItems = async () => {
    if (selectedItems.length === 0) {
      toast.error('Selecione pelo menos um item para deletar!');
      setShowDeleteModal(true);
      return
    }

    const deletedItems = await deleteManyItems(selectedItems);

    if (deletedItems) {
      setShowDeleteModal(false);
      setSelectedItems([]);
      await reloadPageData();
    }
  }

  const addNewCategory = async (data) => {
    const createdCategory = await createCategory({
      name: data.name,
      classification: 'Recebimento',
      type: 'receipts',
    });

    if (createdCategory) {
      setShowSimpleAddModal(false);
      await reloadPageData();
      setSelectedCategory(null);
    }
  }

  const handleDeleteCategory = async () => {
    const deletedCategory = await deleteCategory(selectedCategory.categoryId);

    if (deletedCategory) {
      setShowEditCategoryModal(false);
      await reloadPageData();
      setSelectedCategory(null);
    }
  }

  return (
    <>
      {showEditItemModal && (
        <EditRevenueItemModal
          showModal={showEditItemModal}
          setShowModal={setShowEditItemModal}
          selectedItem={selectedItem}
          onCancel={editItem}
          onConfirm={deleteOneItem}
          theme='receipt'
          classificationOptions={itemsClassificationOptions}
        />
      )}
      {showAddItemModal && (
        <EditRevenueItemModal
          showModal={showAddItemModal}
          setShowModal={setShowAddItemModal}
          title='Adicionar item'
          icon='add'
          onCancel={addItem}
          theme='receipt'
          classificationOptions={itemsClassificationOptions}
          selectedCategory={selectedCategory}
        />
      )}
      {showEditCategoryModal && (
        <GenericEditCategoryTableModal
          showModal={showEditCategoryModal}
          setShowModal={setShowEditCategoryModal}
          selectedItem={selectedCategory}
          onCancel={editCategory}
          onConfirm={handleDeleteCategory}
          theme='receipt'
          classificationOptions={classificationOptions}
        />
      )}
      {showDeleteModal && (
        <SimpleDeleteModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          text={selectedItems.length > 1 ? 'Excluir itens?' : 'Excluir item?'}
          highlightedText='Esta ação é permanente e não poderá ser desfeita'
          onConfirm={handleDeleteItems}
          cancelButtonText='Cancelar'
          confirmButtonText={selectedItems.length > 1 ? 'Excluir itens?' : 'Excluir item?'}
          theme='receipt'
        />
      )}
      {showSimpleAddModal && (
        <SimpleAddModal
          showModal={showSimpleAddModal}
          setShowModal={setShowSimpleAddModal}
          classificationOptions={classificationOptions}
          cancelButtonText='Concluído'
          confirmButtonText='Adicionar categoria'
          dropdownLabel='Tipo'
          onConfirm={addNewCategory}
          theme='receipt'
          icon='add'
        />
      )}
      {data.map(
        (item, index) => (
          <ReceiptTable
            categoryId={item.id}
            totalPercentage={item.percentage}
            totalSpent={item.total}
            classification={item.classification}
            categoryName={item.name}
            items={item.items}
            key={index}
            className="color-receipt"
            setShowEditItemModal={setShowEditItemModal}
            setShowEditCategoryModal={setShowEditCategoryModal}
            showDeleteModal={showDeleteModal}
            setShowDeleteModal={setShowDeleteModal}
            setShowSimpleAddModal={setShowAddItemModal}
            setSelectedCategory={setSelectedCategory}
            setSelectedItem={setSelectedItem}
            getSelectedItems={setSelectedItems}
          />
        )
      )}
      <ButtonContainer>
        <ButtonCategory setShowModal={setShowSimpleAddModal} />
      </ButtonContainer>
    </>
  );
};
