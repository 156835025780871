import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.theme.getResponsiveWidth(671)};
  height: ${props => props.theme.getResponsiveHeight(719)};
  min-width: 500px;
`;

export const FlexContainer = styled.div`
  &.home {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
  }
`;

export const DivContainer = styled.div`
  &.home {
    display: flex;
    height: 90%;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 2%;
  }
`;
export const Line = styled.div`
  border-top: 1px solid ${({ theme }) => theme.grayShadow};
  width: ${props => props.theme.getResponsiveWidth(500)};
  margin-top: ${props => props.theme.getResponsiveHeight(30)};
  margin-bottom: ${props => props.theme.getResponsiveHeight(30)};
  min-width: 400px;
`;
