import styled from 'styled-components';

export const ArrowContainer = styled.svg`
  width: 15px;
  height: 8px;

  @media (max-width: 1920px) {
    width: 13px;
    height: 7px;
  }

  @media (max-width: 1023px) {
    width: 11px;
    height: 6px;
  }

  &.loading {
    display: none;
  }
`;
