import React from 'react';
import { Container } from './styles';
import CloudIcon from 'components/atoms/icons/Cloud';

export const EmptyDreamCard = ({ className, width, height }) => {
  return (
    <Container className={className} width={width} height={height}>
      <CloudIcon className="empty-dream" width={width} height={height} />
    </Container>
  );
};
