import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  width: min-content;
  height: min-content;
  align-items: flex-start;
  margin-top: 1%;
`;
export const DivContainer = styled.div`
  width: 100%;
  margin-right: ${props => props.theme.getResponsiveHeight(20)};
`;
