import Maguire from '../styles/img/maguire.jpg';
import FinancialIndependence from '../styles/img/financialIndependence.jpeg';

export function getDreams(arraySize) {
  const dreamsArray = [
    {
      name: 'Independência Financeira',
      timeToAchieve: '12 meses',
      totalValue: 'R$ 1.000.000,00',
      investedValue: 'R$ 500,00',
      percentageCompleted: '5%',
      dreamImage: FinancialIndependence,
    },
  ];

  if (arraySize === 1) {
    return dreamsArray;
  }

  for (let i = 0; i < arraySize - 1; i++) {
    const timeInMonths = (i + 1) * 12; // Calcula o tempo para alcançar em meses
    const totalValue = (i + 1) * 1000; // Valor total como número
    const investedValue = (i + 1) * 500; // Valor investido como número
    const randomPercentage = Math.random() * 100; // Gera uma porcentagem aleatória

    const formattedTotalValue = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(totalValue);

    const formattedInvestedValue = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(investedValue);

    dreamsArray.push({
      name: `Outro sonho`,
      timeToAchieve: `${timeInMonths} meses`, // Tempo para alcançar em meses
      totalValue: formattedTotalValue,
      investedValue: formattedInvestedValue,
      percentageCompleted: `${randomPercentage.toFixed(2)}%`, // Porcentagem realizada do sonho gerada aleatoriamente e formatada com duas casas decimais
      dreamImage: Maguire,
    });
  }

  return dreamsArray;
}

export function createSpendingObjects(numObjects) {
  const spendingArray = [];

  for (let i = 0; i < numObjects; i++) {
    const spending = {
      name: `Despesa ${i + 1}`,
      category: `Categoria ${i + 1}`,
      value: `R$ ${(i + 1) * 1000},00`,
      spendingDate: new Date(),
    };
    spendingArray.push(spending);
  }

  return spendingArray;
}

export const getSpendings = () => {
  return {
    header: [
      'Nome',
      'Classificação',
      'Vencimento',
      'Valor',
      'Gasto no Cartão',
      'Valor Mensal',
    ],
    body: [
      {
        categoryId: 1,
        name: 'Moradia',
        total: 'R$ 0',
        percentage: '0%',
        classification: 'Moradia',
        list: [
          {
            spendingId: 1,
            name: 'Aluguel',
            classification: 'Mensal comprometido',
            maturity: '5',
            value: 'R$ 0,00',
            spentOnCard: true,
            monthlyValue: 'R$ 0,00',
          },
          {
            spendingId: 2,
            name: 'Condomínio',
            classification: 'Mensal comprometido',
            maturity: '5',
            value: 'R$ 0,00',
            spentOnCard: true,
            monthlyValue: 'R$ 0,00',
          },
          {
            spendingId: 3,
            name: 'Animais de estimação',
            classification: 'Mensal flexível',
            maturity: '5',
            value: 'R$ 0,00',
            spentOnCard: false,
            monthlyValue: 'R$ 0,00',
          },
          {
            spendingId: 4,
            name: 'Telefone/Internet',
            classification: 'Mensal comprometido',
            maturity: '5',
            value: 'R$ 0,00',
            spentOnCard: false,
            monthlyValue: 'R$ 0,00',
          },
          {
            spendingId: 5,
            name: 'Serviços Diários (Faxineiro/Piscineiro/Jardine...)',
            classification: 'Mensal flexível',
            maturity: '5',
            value: 'R$ 0,00',
            spentOnCard: false,
            monthlyValue: 'R$ 0,00',
          },
          {
            spendingId: 6,
            name: 'Móveis/Manutenção/Decoração',
            classification: 'Mensal comprometido',
            maturity: '5',
            value: 'R$ 0,00',
            spentOnCard: false,
            monthlyValue: 'R$ 0,00',
          },
        ],
      },
      {
        categoryId: 2,
        name: 'Transporte',
        total: 'R$ 0',
        percentage: '0%',
        classification: 'Uber/Coletivo/99Pop',
        list: [
          {
            spendingId: 7,
            name: 'Estacionamento/Pedágio/Sem Parar',
            classification: 'Mensal comprometido',
            maturity: '5',
            value: 'R$ 0,00',
            spentOnCard: false,
            monthlyValue: 'R$ 0,00',
          },
          {
            spendingId: 8,
            name: 'Lavagem',
            classification: 'Mensal comprometido',
            maturity: '5',
            value: 'R$ 0,00',
            spentOnCard: false,
            monthlyValue: 'R$ 0,00',
          },
          {
            spendingId: 9,
            name: 'Revisão/Manutenção do automóvel',
            classification: 'Mensal flexível',
            maturity: '5',
            value: 'R$ 0,00',
            spentOnCard: false,
            monthlyValue: 'R$ 0,00',
          },
          {
            spendingId: 10,
            name: 'Uber/Coletivo/99Pop',
            classification: 'Mensal comprometido',
            maturity: '5',
            value: 'R$ 0,00',
            spentOnCard: false,
            monthlyValue: 'R$ 0,00',
          },
          {
            spendingId: 11,
            name: 'IPVA/Despesas Legais',
            classification: 'Mensal flexível',
            maturity: '5',
            value: 'R$ 0,00',
            spentOnCard: false,
            monthlyValue: 'R$ 0,00',
          },
          {
            spendingId: 12,
            name: 'Revisão Preventiva/Troca de óleo',
            classification: 'Mensal comprometido',
            maturity: '5',
            value: 'R$ 0,00',
            spentOnCard: false,
            monthlyValue: 'R$ 0,00',
          },
        ],
      },
    ],
    categoryClassification: [
      { value: 'Moradia', label: 'Moradia' },
      { value: 'Transporte', label: 'Transporte' },
      { value: 'Vida e Saúde', label: 'Vida e Saúde' },
      { value: 'Lazer', label: 'Lazer' },
      { value: 'Alimentação Fora de Casa', label: 'Alimentação Fora de Casa' },
      { value: 'Pessoa Jurídica', label: 'Pessoa Jurídica' },
      {
        value: 'Pessoa Física - Cliente Yuno',
        label: 'Pessoa Física - Cliente Yuno',
      },
    ],
    itemClassification: [
      { value: 'Mensal Comprometido', label: 'Mensal Comprometido' },
      { value: 'Mensal Flexível', label: 'Mensal Flexível' },
      { value: 'Eventual Comprometido', label: 'Eventual Comprometido' },
      { value: 'Eventual Flexível', label: 'Eventual Flexível' },
    ],
  };
};

export const getReceipts = () => {
  return {
    header: [
      'Nome',
      'Classificação',
      'Mês/Dia do mês',
      'Valor',
      'Valor Mensal',
    ],
    body: [
      {
        categoryId: 1,
        name: 'Recebimentos',
        total: 'R$ 0',
        percentage: '0%',
        classification: 'Salário',
        list: [
          {
            recipeId: 1,
            body: {
              name: 'Receita 1',
              category: 'Mensal Comprometido',
              spendingDate: '01/01/2024',
              value: 'R$ 1.000,00',
              monthlyValue: 'R$ 1.000,00',
              recurrent: false,
            },
          },
          {
            recipeId: 2,
            body: {
              name: 'Receita 2',
              category: 'Mensal Flexível',
              spendingDate: '01/01/2024',
              value: 'R$ 1.000,00',
              monthlyValue: 'R$ 1.000,00',
              recurrent: false,
            },
          },
          {
            recipeId: 3,
            body: {
              name: 'Receita 3',
              category: 'Eventual Comprometido',
              spendingDate: '01/01/2024',
              value: 'R$ 1.000,00',
              monthlyValue: 'R$ 1.000,00',
              recurrent: false,
            },
          },
          {
            recipeId: 4,
            body: {
              name: 'Receita 4',
              category: 'Eventual Flexível',
              spendingDate: '01/01/2024',
              value: 'R$ 1.000,00',
              monthlyValue: 'R$ 1.000,00',
              recurrent: false,
            },
          },
        ],
      },
      {
        categoryId: 2,
        name: 'Tigrinho',
        total: 'R$ 0',
        percentage: '0%',
        classification: 'Jogos de azar',
        list: [
          {
            recipeId: 5,
            body: {
              name: 'Receita 1',
              category: 'Categoria 1',
              spendingDate: '01/01/2024',
              value: 'R$ 1.000,00',
              monthlyValue: 'R$ 1.000,00',
              recurrent: false,
            },
          },
          {
            recipeId: 6,
            body: {
              name: 'Receita 2',
              category: 'Categoria 2',
              spendingDate: '01/01/2024',
              value: 'R$ 1.000,00',
              monthlyValue: 'R$ 1.000,00',
              recurrent: false,
            },
          },
          {
            recipeId: 7,
            body: {
              name: 'Receita 3',
              category: 'Categoria 3',
              spendingDate: '01/01/2024',
              value: 'R$ 1.000,00',
              monthlyValue: 'R$ 1.000,00',
              recurrent: false,
            },
          },
        ],
      },
    ],
    categoryClassification: [
      { value: 'Moradia', label: 'Moradia' },
      { value: 'Transporte', label: 'Transporte' },
      { value: 'Vida e Saúde', label: 'Vida e Saúde' },
      { value: 'Lazer', label: 'Lazer' },
      { value: 'Alimentação Fora de Casa', label: 'Alimentação Fora de Casa' },
      { value: 'Pessoa Jurídica', label: 'Pessoa Jurídica' },
      {
        value: 'Pessoa Física - Cliente Yuno',
        label: 'Pessoa Física - Cliente Yuno',
      },
    ],
    itemClassification: [
      { value: 'Mensal Comprometido', label: 'Mensal Comprometido' },
      { value: 'Mensal Flexível', label: 'Mensal Flexível' },
      { value: 'Eventual Comprometido', label: 'Eventual Comprometido' },
      { value: 'Eventual Flexível', label: 'Eventual Flexível' },
    ],
  };
};

export const getInvestments = () => {
  return {
    financialIndependence: {
      header: [
        'Nome',
        'Valor Total',
        'Patrimônio Imobilizado Atual',
        'Patrimônio Financeiro Atual',
        'Prazo (meses)',
        'Estratégia de Alocação',
        'Concluído',
      ],
      list: [
        {
          id: 1,
          name: 'Base de Plena Liquidez',
          totalValue: 'R$ 00,00',
          recurrentFixedAssets: 'R$ 00,00',
          currentFinancialAssets: 'R$ 00,00',
          term: 12,
          investmentStrategy: '-',
          completed: false,
        },
        {
          id: 2,
          name: 'PLF - Portfólio da Liberdade Financeira',
          totalValue: 'R$ 00,00',
          currentFixedAssets: 'R$ 00,00',
          currentFinancialAssets: 'R$ 00,00',
          term: 12,
          investmentStrategy: '-',
          completed: false,
        },
      ],
      definition:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel erat lectus. Praesent vel ligula placerat. Nulla congue felis quis.',
    },
    dreams: {
      header: [
        'Nome',
        'Valor Total',
        'Patrimônio Imobilizado Atual',
        'Patrimônio Financeiro Atual',
        'Prazo (meses)',
        'Estratégia de Alocação',
        'Concluído',
      ],
      list: [
        {
          id: 1,
          name: 'Independência Financeira',
          totalValue: 'R$ 00,00',
          currentFixedAssets: 'R$ 00,00',
          currentFinancialAssets: 'R$ 00,00',
          term: 12,
          investmentStrategy: '-',
          completed: false,
        },
        {
          id: 2,
          name: 'Viagem - EUA',
          totalValue: 'R$ 00,00',
          currentFixedAssets: 'R$ 00,00',
          currentFinancialAssets: 'R$ 00,00',
          term: 12,
          investmentStrategy: '-',
          completed: false,
        },
        {
          id: 3,
          name: 'Casa própria',
          totalValue: 'R$ 00,00',
          currentFixedAssets: 'R$ 00,00',
          currentFinancialAssets: 'R$ 00,00',
          term: 12,
          investmentStrategy: '-',
          completed: false,
        },
        {
          id: 4,
          name: 'Fusca com nitro',
          totalValue: 'R$ 00,00',
          currentFixedAssets: 'R$ 00,00',
          currentFinancialAssets: 'R$ 00,00',
          term: 12,
          investmentStrategy: '-',
          completed: false,
        },
        {
          id: 5,
          name: 'Comprar um caminhão',
          totalValue: 'R$ 00,00',
          currentFixedAssets: 'R$ 00,00',
          currentFinancialAssets: 'R$ 00,00',
          term: 12,
          investmentStrategy: '-',
          completed: false,
        },
      ],
      definition:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel erat lectus. Praesent vel ligula placerat. Nulla congue felis quis.',
    },
  };
};

export const getDebts = () => {
  return {
    cashPurchases: {
      categoryId: 1,
      name: 'Compras Parceladas',
      totalCategoryPercentage: '0%',
      totalCategoryValue: 'R$ 00,00',
      header: [
        'Nome',
        'Categoria',
        'Item',
        'Total de parcelas',
        'Valor da parcela',
        'Valor restante',
      ],
      categoriesClassification: [
        { value: 'Mensal Comprometido', label: 'Mensal Comprometido' },
        { value: 'Mensal Flexível', label: 'Mensal Flexível' },
        { value: 'Eventual Comprometido', label: 'Eventual Comprometido' },
        { value: 'Eventual Flexível', label: 'Eventual Flexível' },
      ],
      itensClassification: [
        { value: 'Mensal Comprometido', label: 'Mensal Comprometido' },
        { value: 'Mensal Flexível', label: 'Mensal Flexível' },
        { value: 'Eventual Comprometido', label: 'Eventual Comprometido' },
        { value: 'Eventual Flexível', label: 'Eventual Flexível' },
      ],
      list: [
        {
          id: 1,
          body: {
            name: 'Dívida 1',
            category: 'Cruzes',
            item: '-',
            totalInstallments: '20042',
            installmentValue: 'R$ 10.000,00',
            remainingValue: 'R$ 900.000,00',
          },
        },
        {
          id: 2,
          body: {
            name: 'Divida 2',
            category: 'Deu ruim',
            item: '-',
            totalInstallments: '256',
            installmentValue: 'R$ 15.000,00',
            remainingValue: 'R$150.000,00',
          },
        },
        {
          id: 3,
          body: {
            name: 'Divida 3',
            category: 'Vou pro Serasa',
            item: '-',
            totalInstallments: '100000',
            installmentValue: 'R$ 12.000,00',
            remainingValue: 'R$ 100.000.000,00',
          },
        },
      ],
    },
    equityLiabilities: {
      name: 'Passivos Patrimoniais',
      categoryId: 2,
      totalCategoryPercentage: '0%',
      totalCategoryValue: 'R$ 00,00',
      header: [
        'Passivo',
        'Valor da Parcela',
        'Número de Parcelas',
        'Valor total',
        'Quitado',
      ],
      list: [
        {
          id: 1,
          body: {
            name: 'Passivo Patrimonial 1',
            installmentValue: 'R$99',
            numberOfInstallments: 12,
            amount: 'R$1000000',
            settled: false,
          },
        },
        {
          id: 2,
          body: {
            name: 'Passivo Patrimonial 2',
            installmentValue: 'R$99',
            numberOfInstallments: 12,
            amount: 'R$1000000',
            settled: false,
          },
        },
        {
          id: 3,
          body: {
            name: 'Passivo Patrimonial 3',
            installmentValue: 'R$99',
            numberOfInstallments: 12,
            amount: 'R$1000000',
            settled: false,
          },
        },
      ],
    },
    financialLiability: {
      name: 'Passivos Financeiros',
      categoryId: 3,
      totalCategoryPercentage: '0%',
      totalCategoryValue: 'R$ 00,00',
      header: [
        'Passivo',
        'Valor da Parcela',
        'Número de Parcelas',
        'Valor total',
        'Quitado',
      ],
      list: [
        {
          id: 1,
          body: {
            name: 'Passivo Financeiro 1',
            installmentValue: 'R$99',
            numberOfInstallments: 12,
            amount: 'R$1000000',
            settled: false,
          },
        },
        {
          id: 2,
          body: {
            name: 'Passivo Financeiro 2',
            installmentValue: 'R$99',
            numberOfInstallments: 12,
            amount: 'R$1000000',
            settled: false,
          },
        },
        {
          id: 3,
          body: {
            name: 'Passivo Financeiro 3',
            installmentValue: 'R$99',
            numberOfInstallments: 12,
            amount: 'R$1000000',
            settled: false,
          },
        },
      ],
    },
  };
};

export const getCommittedMonthly = () => {
  return {
    header: [
      'Item',
      'Categoria',
      'Vencimento',
      'Estimado',
      'Realizado',
      'Recorrente',
      'Status',
    ],
    body: [
      {
        spendingId: 1,
        item: 'Plano de Saúde',
        category: 'Vida e Saúde',
        maturity: '00',
        estimated: 'R$ 0',
        realized: 'R$ 0',
        recurrent: true,
        status: 'ATRASADO',
      },
      {
        spendingId: 2,
        item: 'Plano de Saúde',
        category: 'Vida e Saúde',
        maturity: '00',
        estimated: 'R$ 0',
        realized: 'R$ 0',
        recurrent: true,
        status: 'ATRASADO',
      },
      {
        spendingId: 3,
        item: 'Plano de Saúde',
        category: 'Vida e Saúde',
        maturity: '00',
        estimated: 'R$ 0',
        realized: 'R$ 0',
        recurrent: false,
        status: 'ATRASADO',
      },
      {
        spendingId: 4,
        item: 'Plano de Saúde',
        category: 'Vida e Saúde',
        maturity: '00',
        estimated: 'R$ 0',
        realized: 'R$ 0',
        recurrent: false,
        status: 'PAGO',
      },
      {
        spendingId: 5,
        item: 'Plano de Saúde',
        category: 'Vida e Saúde',
        maturity: '00',
        estimated: 'R$ 0',
        realized: 'R$ 0',
        recurrent: false,
        status: 'ATRASADO',
      },
      {
        spendingId: 6,
        item: 'Plano de Saúde',
        category: 'Vida e Saúde',
        maturity: '00',
        estimated: 'R$ 0',
        realized: 'R$ 0',
        recurrent: false,
        status: 'A PAGAR',
      },
      {
        spendingId: 7,
        item: 'Plano de Saúde',
        category: 'Vida e Saúde',
        maturity: '00',
        estimated: 'R$ 0',
        realized: 'R$ 0',
        recurrent: false,
        status: 'A PAGAR',
      },
      {
        spendingId: 6,
        item: 'Plano de Saúde',
        category: 'Vida e Saúde',
        maturity: '00',
        estimated: 'R$ 0',
        realized: 'R$ 0',
        recurrent: false,
        status: 'A PAGAR',
      },
      {
        spendingId: 6,
        item: 'Plano de Saúde',
        category: 'Vida e Saúde',
        maturity: '00',
        estimated: 'R$ 0',
        realized: 'R$ 0',
        recurrent: false,
        status: 'A PAGAR',
      },
      {
        spendingId: 6,
        item: 'Plano de Saúde',
        category: 'Vida e Saúde',
        maturity: '00',
        estimated: 'R$ 0',
        realized: 'R$ 0',
        recurrent: false,
        status: 'A PAGAR',
      },
      {
        spendingId: 6,
        item: 'Plano de Saúde',
        category: 'Vida e Saúde',
        maturity: '00',
        estimated: 'R$ 0',
        realized: 'R$ 0',
        recurrent: false,
        status: 'A PAGAR',
      },
    ],
  };
};
export const getFlexibleMonthly = () => {
  return {
    progress: 10,
    header: [
      'Item',
      'Categoria',
      'Vencimento',
      'Estimado',
      'Realizado',
      '  ',
      'Saldo para gastar',
    ],
    body: [
      {
        spendingId: 1,
        item: 'Plano de Saúde',
        category: 'Vida e Saúde',
        maturity: '00',
        estimated: 'R$ 0',
        realized: 'R$ 0',
        balance: 'R$ 0',
      },
      {
        spendingId: 2,
        item: 'Plano de Saúde',
        category: 'Vida e Saúde',
        maturity: '00',
        estimated: 'R$ 0',
        realized: 'R$ 0',
        balance: 'R$ 0',
      },
      {
        spendingId: 3,
        item: 'Plano de Saúde',
        category: 'Vida e Saúde',
        maturity: '00',
        estimated: 'R$ 0',
        realized: 'R$ 0',
        balance: 'R$ 0',
      },
      {
        spendingId: 4,
        item: 'Plano de Saúde',
        category: 'Vida e Saúde',
        maturity: '00',
        estimated: 'R$ 0',
        realized: 'R$ 0',
        balance: 'R$ 0',
      },
      {
        spendingId: 5,
        item: 'Plano de Saúde',
        category: 'Vida e Saúde',
        maturity: '00',
        estimated: 'R$ 0',
        realized: 'R$ 0',
        balance: 'R$ 0',
      },
      {
        spendingId: 6,
        item: 'Plano de Saúde',
        category: 'Vida e Saúde',
        maturity: '00',
        estimated: 'R$ 0',
        realized: 'R$ 0',
        balance: 'R$ 0',
      },
      {
        spendingId: 7,
        item: 'Plano de Saúde',
        category: 'Vida e Saúde',
        maturity: '00',
        estimated: 'R$ 0',
        realized: 'R$ 0',
        balance: 'R$ 0',
      },
      {
        spendingId: 6,
        item: 'Plano de Saúde',
        category: 'Vida e Saúde',
        maturity: '00',
        estimated: 'R$ 0',
        realized: 'R$ 0',
        balance: 'R$ 0',
      },
      {
        spendingId: 6,
        item: 'Plano de Saúde',
        category: 'Vida e Saúde',
        maturity: '00',
        estimated: 'R$ 0',
        realized: 'R$ 0',
        balance: 'R$ 0',
      },
      {
        spendingId: 6,
        item: 'Plano de Saúde',
        category: 'Vida e Saúde',
        maturity: '00',
        estimated: 'R$ 0',
        realized: 'R$ 0',
        balance: 'R$ 0',
      },
      {
        spendingId: 6,
        item: 'Plano de Saúde',
        category: 'Vida e Saúde',
        maturity: '00',
        estimated: 'R$ 0',
        realized: 'R$ 0',
        balance: 'R$ 0',
      },
    ],
  };
};
export const getCommittedEventual = () => {
  return {
    receipt: 3000,
    pec: 500,
    committed: 0,
    header: [
      'Item',
      'Categoria',
      'Vencimento',
      'Estimado',
      'Realizado',
      'Status',
    ],
    body: [
      {
        spendingId: 1,
        item: 'Plano de Saúde',
        category: 'Vida e Saúde',
        maturity: '00',
        estimated: 'R$ 0',
        realized: 'R$ 0',
        status: 'ATRASADO',
      },
      {
        spendingId: 2,
        item: 'Plano de Saúde',
        category: 'Vida e Saúde',
        maturity: '00',
        estimated: 'R$ 0',
        realized: 'R$ 0',
        status: 'A PAGAR',
      },
      {
        spendingId: 3,
        item: 'Plano de Saúde',
        category: 'Vida e Saúde',
        maturity: '00',
        estimated: 'R$ 0',
        realized: 'R$ 0',
        status: 'ATRASADO',
      },
      {
        spendingId: 4,
        item: 'Plano de Saúde',
        category: 'Vida e Saúde',
        maturity: '00',
        estimated: 'R$ 0',
        realized: 'R$ 0',
        status: 'PAGO',
      },
      {
        spendingId: 5,
        item: 'Plano de Saúde',
        category: 'Vida e Saúde',
        maturity: '00',
        estimated: 'R$ 0',
        realized: 'R$ 0',
        status: 'ATRASADO',
      },
    ],
  };
};
export const getMaterialAssets = () => {
  return {
    total: 3000,
    percentage: 500,
    header: ['Nome', 'Tipo', 'Selável', 'Valor'],
    body: [
      {
        patrimonyId: 1,
        name: '-',
        type: '-',
        seleable: true,
        value: 'R$ 0',
      },
      {
        patrimonyId: 2,
        name: '-',
        type: '-',
        seleable: false,
        value: 'R$ 0',
      },
      {
        patrimonyId: 3,
        name: '-',
        type: '-',
        seleable: false,
        value: 'R$ 0',
      },
    ],
  };
};

export const getFinancialAssets = () => {
  return {
    total: 3000,
    percentage: 500,
    header: [
      'Nome',
      'Categoria',
      'Selável',
      'Previdência Privada',
      'Liquidez',
      'Valor',
    ],
    body: [
      {
        patrimonyId: 1,
        name: '-',
        category: '-',
        seleable: true,
        pension: false,
        liquidity: '-',
        value: 'R$ 0',
      },
      {
        patrimonyId: 2,
        name: '-',
        category: '-',
        seleable: false,
        pension: false,
        liquidity: '-',
        value: 'R$ 0',
      },
      {
        patrimonyId: 3,
        name: '-',
        category: '-',
        seleable: true,
        pension: true,
        liquidity: '-',
        value: 'R$ 0',
      },
    ],
  };
};

export const getEquityPassive = () => {
  return {
    modalName: [
      { label: 'Empréstimo pessoal', value: 'Empréstimo pessoal' },
      { label: 'Empréstimo consignado', value: 'Empréstimo consignado' },
    ],
    modalPassive: [
      { label: 'Financiamento Automóvel', value: 'Financiamento Automóvel' },
      { label: 'Financiamento Imóvel SAC', value: 'Financiamento Imóvel SAC' },
      {
        label: 'Financiamento Imóvel Price',
        value: 'Financiamento Imóvel Price',
      },
      { label: 'Consórcio Automóvel', value: 'Consórcio Automóvel' },
      { label: 'Consórcio Imóvel', value: 'Consórcio Imóvel' },
      { label: 'Consórcio entre amigos', value: 'Consórcio entre amigos' },
    ],
    total: 3000,
    percentage: 500,
    header: [
      'Passivo',
      'Instituição',
      'PMT',
      ' Nº de Parcelas',
      'Taxa',
      'Valor à Vista',
      'Valor Total',
      'Descontos',
      'Quitados',
    ],
    body: [
      {
        patrimonyId: 1,
        passive: '-',
        institution: '-',
        pmt: '-',
        installments: '-',
        rate: '0,0%',
        cachValue: 'R$ 0',
        totalValue: 'R$ 0',
        discount: 'R$ 0',
        settled: true,
      },
      {
        patrimonyId: 2,
        passive: '-',
        institution: '-',
        pmt: '-',
        installments: '-',
        rate: '0,0%',
        cachValue: 'R$ 0',
        totalValue: 'R$ 0',
        discount: 'R$ 0',
        settled: false,
      },
      {
        patrimonyId: 3,
        passive: '-',
        institution: '-',
        pmt: '-',
        installments: '-',
        rate: '0,0%',
        cachValue: 'R$ 0',
        totalValue: 'R$ 0',
        discount: 'R$ 0',
        settled: false,
      },
    ],
  };
};
export const getAllocationData = () => {
  return {
    labels: [
      'R. Fixa Pós',
      'R. Fixa Pré',
      'R. Fixa IPCA',
      'Multimercado',
      'FIIS',
      'Ações BR',
      'R.V. Exterior',
      'Alternativo',
    ],
    currentAllocation: [
      {
        label: 'R. Fixa Pós',
        percentage: '0.0%',
        proportion: 0,
      },
      {
        label: 'R. Fixa Pré',
        percentage: '0.0%',
        proportion: 0,
      },
      {
        label: 'R. Fixa IPCA',
        percentage: '0.0%',
        proportion: 0,
      },
      {
        label: 'Multimercado',
        percentage: '0.0%',
        proportion: 0,
      },
      {
        label: 'FIIS',
        percentage: '0.0%',
        proportion: 0,
      },
      {
        label: 'Ações BR',
        percentage: '0.0%',
        proportion: 0,
      },
      {
        label: 'R.V. Exterior',
        percentage: '0.0%',
        proportion: 0,
      },
      {
        label: 'Alternativo',
        percentage: '0.0%',
        proportion: 0,
      },
    ],
    recommendedAllocation: [
      {
        label: 'R. Fixa Pós',
        percentage: '40.0%',
        proportion: 40,
      },
      {
        label: 'R. Fixa Pré',
        percentage: '12.0%',
        proportion: 12,
      },
      {
        label: 'R. Fixa IPCA',
        percentage: '8.0%',
        proportion: 8,
      },
      {
        label: 'Multimercado',
        percentage: '8.0%',
        proportion: 8,
      },
      {
        label: 'FIIS',
        percentage: '8.0%',
        proportion: 8,
      },
      {
        label: 'Ações BR',
        percentage: '8.0%',
        proportion: 8,
      },
      {
        label: 'R.V. Exterior',
        percentage: '8.0%',
        proportion: 8,
      },
      {
        label: 'Alternativo',
        percentage: '8.0%',
        proportion: 8,
      },
    ],
  };
};

export const getImmediateLiquidityData = () => {
  return {
    columns: [
      'D0',
      'Até 30 dias',
      'De 1 a 6 meses',
      'De 6 a 12 meses',
      'De 12 a 24 meses',
      'Acima de 24 meses',
    ],
    liquidity: [
      {
        label: 'Liquidez Atual',
        data: [
          {
            deadline: 'D0',
            value: 12,
          },
          {
            deadline: 'Até 30 dias',
            value: 3.2,
          },
          {
            deadline: 'De 1 a 6 meses',
            value: 19,
          },
          {
            deadline: 'De 6 a 12 meses',
            value: 12,
          },
          {
            deadline: 'De 12 a 24 meses',
            value: 23,
          },
          {
            deadline: 'Acima de 24 meses',
            value: 4.5,
          },
        ],
      },
      {
        label: 'Necessidade de liquidez',
        data: [
          {
            deadline: 'D0',
            value: 3,
          },
          {
            deadline: 'Até 30 dias',
            value: 7,
          },
          {
            deadline: 'De 1 a 6 meses',
            value: 11,
          },
          {
            deadline: 'De 6 a 12 meses',
            value: 12,
          },
          {
            deadline: 'De 12 a 24 meses',
            value: 8,
          },
          {
            deadline: 'Acima de 24 meses',
            value: 19,
          },
        ],
      },
    ],
  };
};

export const getInvestmentPanelData = () => {
  return {
    columns: [
      'Estratégias',
      'Valores Atuais',
      'Participação Atual',
      'Alocação Estratégica',
      'Full Alocation',
      'Valores Finais',
      'Próximas Movimentações',
    ],
    data: [
      [
        { label: 'Estratégias', value: 'R. Fixa Pós' },
        { label: 'Valores Atuais', value: 'R$ 0' },
        { label: 'Participação Atual', value: '0,0%' },
        { label: 'Alocação Estratégica', value: '0,0%' },
        { label: 'Full Alocation', value: '0,0%' },
        { label: 'Valores Finais', value: 'R$ 0' },
        { label: 'Próximas Movimentações', value: 'R$ 0' },
      ],
      [
        { label: 'Estratégias', value: 'R. Fixa Pré' },
        { label: 'Valores Atuais', value: 'R$ 0' },
        { label: 'Participação Atual', value: '0,0%' },
        { label: 'Alocação Estratégica', value: '0,0%' },
        { label: 'Full Alocation', value: '0,0%' },
        { label: 'Valores Finais', value: 'R$ 0' },
        { label: 'Próximas Movimentações', value: 'R$ 0' },
      ],
      [
        { label: 'Estratégias', value: 'R. Fixa IPCA' },
        { label: 'Valores Atuais', value: 'R$ 0' },
        { label: 'Participação Atual', value: '0,0%' },
        { label: 'Alocação Estratégica', value: '0,0%' },
        { label: 'Full Alocation', value: '0,0%' },
        { label: 'Valores Finais', value: 'R$ 0' },
        { label: 'Próximas Movimentações', value: 'R$ 0' },
      ],
      [
        { label: 'Estratégias', value: 'Multimercado' },
        { label: 'Valores Atuais', value: 'R$ 0' },
        { label: 'Participação Atual', value: '0,0%' },
        { label: 'Alocação Estratégica', value: '0,0%' },
        { label: 'Full Alocation', value: '0,0%' },
        { label: 'Valores Finais', value: 'R$ 0' },
        { label: 'Próximas Movimentações', value: 'R$ 0' },
      ],
      [
        { label: 'Estratégias', value: 'FIIS' },
        { label: 'Valores Atuais', value: 'R$ 0' },
        { label: 'Participação Atual', value: '0,0%' },
        { label: 'Alocação Estratégica', value: '0,0%' },
        { label: 'Full Alocation', value: '0,0%' },
        { label: 'Valores Finais', value: 'R$ 0' },
        { label: 'Próximas Movimentações', value: 'R$ 0' },
      ],
      [
        { label: 'Estratégias', value: 'Ações BR' },
        { label: 'Valores Atuais', value: 'R$ 0' },
        { label: 'Participação Atual', value: '0,0%' },
        { label: 'Alocação Estratégica', value: '0,0%' },
        { label: 'Full Alocation', value: '0,0%' },
        { label: 'Valores Finais', value: 'R$ 0' },
        { label: 'Próximas Movimentações', value: 'R$ 0' },
      ],
      [
        { label: 'Estratégias', value: 'R.V. Exterior' },
        { label: 'Valores Atuais', value: 'R$ 0' },
        { label: 'Participação Atual', value: '0,0%' },
        { label: 'Alocação Estratégica', value: '0,0%' },
        { label: 'Full Alocation', value: '0,0%' },
        { label: 'Valores Finais', value: 'R$ 0' },
        { label: 'Próximas Movimentações', value: 'R$ 0' },
      ],
      [
        { label: 'Estratégias', value: 'Alternativo' },
        { label: 'Valores Atuais', value: 'R$ 0' },
        { label: 'Participação Atual', value: '0,0%' },
        { label: 'Alocação Estratégica', value: '0,0%' },
        { label: 'Full Alocation', value: '0,0%' },
        { label: 'Valores Finais', value: 'R$ 0' },
        { label: 'Próximas Movimentações', value: 'R$ 0' },
      ],
    ],
  };
};
