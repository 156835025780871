import CommittedMonthlyIcon from 'components/atoms/icons/CommittedMonthlyIcon';
import { CardTitle, TitleWrapper } from './styles';
import React from 'react';
import CommittedEventualIcon from 'components/atoms/icons/CommittedEventualIcon';
import FlexibleMonthlyIcon from 'components/atoms/icons/FlexibleMonthlyIcon';
import FlexibleEventualIcon from 'components/atoms/icons/FlexibleEventualIcon';
import { H4 } from 'components/atoms/Headings/H4';
import ActiveIcon from 'components/atoms/icons/ActiveIcon';
import PassiveIcon from 'components/atoms/icons/PassiveIcon';
import LiquidPatrimonyIcon from 'components/atoms/icons/LiquidPatrimonyIcon';
import MoneyBagIcon from 'components/atoms/icons/MoneyBagIcon';
import TooltipIconWithOverlay from 'components/atoms/IconInformation';
import ValueIcon from 'components/atoms/icons/ValueIcon';
import BarIcon from 'components/atoms/icons/BarIcon';

export const CardTitleInformation = ({
  className,
  type,
  title,
  tooltipText,
}) => {
  const icons = {
    committedMonthly: <CommittedMonthlyIcon className={`${className}`} />,
    committedEventual: <CommittedEventualIcon className={`${className}`} />,
    flexibleMonthly: <FlexibleMonthlyIcon className={`${className}`} />,
    flexibleEventual: <FlexibleEventualIcon className={`${className}`} />,
    active: <ActiveIcon className={`${className}`} />,
    passive: <PassiveIcon className={`${className}`} />,
    liquidPatrimony: <LiquidPatrimonyIcon />,
    coverageIndex: <MoneyBagIcon />,
    value: <ValueIcon />,
    bar: <BarIcon className={`${className}`} />,
  };

  return (
    <CardTitle className={className}>
      {icons[type]}
      <TitleWrapper>
        <H4 className={className}>{title}</H4>
        <TooltipIconWithOverlay
          className="home spending"
          tooltipText={tooltipText}
        />
      </TitleWrapper>
    </CardTitle>
  );
};
