import styled from 'styled-components';

export const Span = styled.span`
  &.patrimony-value {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: ${({ theme }) => theme.blueRoyal};
    text-align: center;
    flex-grow: 1;
    font-family: ${({ theme }) => theme.fonts.PoppinsBold};
    font-size: 3.5rem;

    @media (min-width: 1921px) and (max-width: 2160px) {
      font-size: 4rem;
    }
    @media (min-width: 1441px) and (max-width: 1920px) {
      font-size: 3.5rem;
    }
    @media (min-width: 1025px) and (max-width: 1440px) {
      font-size: 2.5rem;
    }
    @media (min-width: 769px) and (max-width: 1024px) {
      font-size: 2.5rem;
    }
    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
    @media (orientation: portrait) and (max-width: 768px) {
      font-size: 2.5rem;
    }
  }

  &.dream-investment {
    color: ${({ theme }) => theme.white};
    font-size: 12px;
    font-weight: 300;
  }

  &.sidebar-icon-description {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 4%;
    margin: 1 0 0 0;

    &:hover {
      background-color: ${({ theme }) => theme.sideBarHover};
      border-radius: 5px;
      cursor: pointer;
    }
  }

  &.sidebar {
    width: min-content;
    transition: linear 0.5s ease;
    cursor: pointer;
    border-radius: 8px;
    color: ${({ theme }) => theme.white};
    font-weight: 500;
    white-space: nowrap;
    padding: 0;
    display: flex;
    align-items: center;
    padding: 2%;
    border-radius: 8px;
    font-size: 14px;

    &:hover {
      background-color: ${({ theme }) => theme.sideBarHover};
    }
  }

  &.category-title {
    display: inline-block;
    height: 100%;
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: 700;
    font-family: ${({ theme }) => theme.fonts.PoppinsMedium};

    @media (max-width: 1023px) {
      font-size: 1rem;
    }
    @media (max-width: 600px) {
      font-size: 0.8rem;
    }
  }

  &.table-title {
    display: inline-block;
    height: 100%;
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: 700;
    font-family: ${({ theme }) => theme.fonts.PoppinsMedium};

    @media (max-width: 1023px) {
      font-size: 1rem;
    }
    @media (max-width: 600px) {
      font-size: 0.8rem;
    }

    &.color-blue {
      color: ${({ theme }) => theme.blueMidnight};
    }
  }

  &.category-title-two {
    font-family: ${props => props.theme.fonts.PoppinsRegular};
    font-size: 0.8rem;
    color: ${({ theme }) => theme.bluePale};

    @media (max-width: 1023px) {
      font-size: 0.6px;
    }
    @media (max-width: 600px) {
      font-size: 0.5rem;
    }

    &.debts {
      color: ${({ theme }) => theme.redBlood};
    }

    &.investments {
      color: ${({ theme }) => theme.greenDark};
      opacity: 0.55;
    }
  }

  &.simple-modal {
    font-size: 0.8rem;
    line-height: 0.8rem;
    color: ${({ theme }) => theme.grayShadow};

    &.body {
      font-size: 0.9rem;
      color: ${({ theme }) => theme.grayShadow};

      &.highlighted {
        font-weight: bold;
        padding-left: 1%;
      }

      @media (max-width: 1023px) {
        font-size: 9.6px;
        line-height: 9.6px;
      }
    }

    @media (max-width: 1023px) {
      font-size: 10.93px;
      line-height: 10.93px;
    }
  }

  &.passive {
    color: ${({ theme }) => theme.redMedium};
    font-size: 0.8rem;
    line-height: 0.8rem;
    font-weight: 700;
    font-family: ${props => props.theme.fonts.PoppinsMedium};

    &.blue {
      color: ${({ theme }) => theme.blueRoyal};
    }

    @media (max-width: 1023px) {
      font-size: 8.53px;
      line-height: 8.53px;
    }
    &.font-regular {
      font-family: ${props => props.theme.fonts.PoppinsRegular};
    }
  }

  &.selected-patrimony {
    color: ${({ theme }) => theme.white};
    font-size: 0.8rem;
    line-height: 0.8rem;
    font-weight: 700;
    font-family: ${props => props.theme.fonts.PoppinsMedium};

    @media (max-width: 1023px) {
      font-size: 8.53px;
      line-height: 8.53px;
    }
  }

  &.goal-panel {
    color: ${({ theme }) => theme.blueRoyal};
    font-size: 0.8rem;
    line-height: 0.8rem;
    font-weight: 700;
    font-family: ${props => props.theme.fonts.PoppinsMedium};
    &.hovered {
      color: ${({ theme }) => theme.white};
    }

    @media (max-width: 1023px) {
      font-size: 8.53px;
      line-height: 8.53px;
    }
  }

  &.goals-panel {
    &.financial-independence {
      color: ${({ theme }) => theme.white};

      &.title {
        font-size: ${({ theme }) => theme.getResponsiveWidth(24)};
        line-height: ${({ theme }) => theme.getResponsiveWidth(24)};
        font-weight: 450;
        width: ${({ theme }) => theme.getResponsiveWidth(229)};

        @media (max-width: 1023px) {
          width: 122.1px;
          font-size: 12.8px;
          line-height: 12.8px;
        }
      }

      &.time-to-achieve {
        font-size: ${({ theme }) => theme.getResponsiveWidth(16)};
        line-height: ${({ theme }) => theme.getResponsiveWidth(16)};
        font-weight: 400;
        margin-top: ${({ theme }) => theme.getResponsiveWidth(13)};
        font-family: ${({ theme }) => theme.fonts.PoppinsRegular};

        @media (max-width: 1023px) {
          font-size: 8.5px;
          line-height: 8.5px;
          margin-top: 6.9px;
        }
      }

      &.section-title {
        font-size: ${({ theme }) => theme.getResponsiveWidth(16)};
        line-height: ${({ theme }) => theme.getResponsiveWidth(16)};
        font-weight: 400;
        font-family: ${({ theme }) => theme.fonts.PoppinsRegular};

        @media (max-width: 1023px) {
          font-size: 8.533px;
          line-height: 8.533px;
        }
      }

      &.bpl {
        margin-top: ${({ theme }) => theme.getResponsiveWidth(43)};

        @media (max-width: 1023px) {
          margin-top: 22.94px;
        }
      }

      &.total-financial-independence {
        margin-top: ${({ theme }) => theme.getResponsiveWidth(18)};

        @media (max-width: 1023px) {
          margin-top: 9.6px;
        }
      }

      &.progress-information {
        font-size: ${({ theme }) => theme.getResponsiveWidth(12)};
        line-height: ${({ theme }) => theme.getResponsiveWidth(12)};
        font-weight: 400;
        font-family: ${({ theme }) => theme.fonts.PoppinsRegular};

        &.money {
          margin-top: ${({ theme }) => theme.getResponsiveWidth(12)};

          @media (max-width: 1023px) {
            margin-top: 6.4px;
          }
        }

        &.percentage {
          margin-top: ${({ theme }) => theme.getResponsiveWidth(10)};

          @media (max-width: 1023px) {
            margin-top: 5.33px;
          }
        }

        @media (max-width: 1023px) {
          font-size: 6.4px;
          line-height: 6.4px;
        }
      }
    }

    &.generic-dream {
      color: ${({ theme }) => theme.white};

      &.title {
        font-size: ${({ theme }) => theme.getResponsiveWidth(24)};
        line-height: ${({ theme }) => theme.getResponsiveWidth(24)};
        font-weight: 450;
        width: ${({ theme }) => theme.getResponsiveWidth(229)};
        margin-bottom: ${({ theme }) => theme.getResponsiveWidth(32)};

        @media (max-width: 1023px) {
          width: 122.14px;
          font-size: 12.8px;
          line-height: 12.8px;
          margin-bottom: 12.067px;
        }
      }

      &.realized {
        font-size: ${({ theme }) => theme.getResponsiveWidth(24)};
        line-height: ${({ theme }) => theme.getResponsiveWidth(24)};
        font-weight: 450;
        width: ${({ theme }) => theme.getResponsiveWidth(209)};
        margin-bottom: ${({ theme }) => theme.getResponsiveWidth(8)};
        align-self: center;
        text-align: center;

        @media (max-width: 1023px) {
          font-size: 12.8px;
          line-height: 12.8px;
          width: 111.47px;
          margin-bottom: 4.27px;
        }
      }

      &.mim {
        font-size: ${props => props.theme.getResponsiveWidth(16)};
        line-height: ${props => props.theme.getResponsiveWidth(16)};
        font-weight: 700;
        margin-bottom: ${props => props.theme.getResponsiveWidth(24)};
        font-family: ${props => props.theme.fonts.PoppinsRegular};

        @media (max-width: 1023px) {
          font-size: 8.533px;
          line-height: 8.533px;
          margin-bottom: 12.8px;
        }
      }

      &.progress-information {
        font-size: ${({ theme }) => theme.getResponsiveWidth(12)};
        line-height: ${({ theme }) => theme.getResponsiveWidth(12)};
        font-weight: 400;
        font-family: ${({ theme }) => theme.fonts.PoppinsRegular};

        &.percentage {
          margin-top: ${({ theme }) => theme.getResponsiveWidth(10)};

          @media (max-width: 1023px) {
            margin-top: 5.33px;
          }
        }

        @media (max-width: 1023px) {
          font-size: 6.4px;
          line-height: 6.4px;
        }
      }
    }
  }

  &.generic-dream-modal,
  &.generic-dream-modal-datepicker {
    color: ${({ theme }) => theme.graySilver};
    font-weight: 700;
    font-size: ${props => props.theme.getResponsiveWidth(14)};
    line-height: ${props => props.theme.getResponsiveWidth(14)};

    @media (max-width: 1023px) {
      font-size: 7.47px;
      line-height: 7.47px;
    }
  }

  &.empty-goal-panel {
    color: ${({ theme }) => theme.graySilver};
    font-size: ${props => props.theme.getResponsiveWidth(20)};
    line-height: ${props => props.theme.getResponsiveWidth(20)};
    text-align: center;
    padding-left: ${props => props.theme.getResponsiveWidth(69)};
    padding-right: ${props => props.theme.getResponsiveWidth(71)};
    margin-top: ${props => props.theme.getResponsiveWidth(28)};

    @media (max-width: 1023px) {
      font-size: 10.67px;
      line-height: 10.67px;
      padding-left: 36.8px;
      padding-right: 37.87px;
      margin-top: 14.83px;
    }
  }

  &.gallery-modal {
    font-size: ${props => props.theme.getResponsiveWidth(8)};
    font-weight: 450;
    line-height: ${props => props.theme.getResponsiveWidth(8)};
    color: ${({ theme }) => theme.graySilver};

    @media (max-width: 1023px) {
      font-size: 4.27px;
      line-height: 4.27px;
    }
  }

  &.asset-management-doughnut-grafic-description {
    font-size: ${props => props.theme.getResponsiveWidth(14)};
    line-height: ${props => props.theme.getResponsiveWidth(14)};
    font-weight: 400;
    font-family: ${props => props.theme.fonts.PoppinsRegular};
    text-align: center;
    color: ${({ theme }) => theme.blueRoyal};
    margin-top: ${props => props.theme.getResponsiveWidth(16)};

    &.first {
      width: ${props => props.theme.getResponsiveWidth(87)};
    }

    &.second {
      width: ${props => props.theme.getResponsiveWidth(39)};
    }

    &.hovered {
      color: ${({ theme }) => theme.white};
    }

    @media (max-width: 1023px) {
      font-size: 7.5px;
      line-height: 7.5px;
      margin-top: 8.5px;

      &.first {
        width: 46.4px;
      }

      &.second {
        width: 20.8px;
      }
    }
  }

  &.empty-suitability-questionnaire {
    font-family: ${props => props.theme.fonts.PoppinsMedium};
    font-size: ${props => props.theme.getResponsiveWidth(24)};
    line-height: ${props => props.theme.getResponsiveWidth(24)};
    margin-left: ${props => props.theme.getResponsiveWidth(43)};
    color: ${({ theme }) => theme.blueMidnight};
    font-weight: 700;

    @media (max-width: 1023px) {
      font-size: 13px;
      line-height: 13px;
      margin-left: 23px;
    }
  }

  &.empty-suitability-questionnaire-description {
    font-size: ${props => props.theme.getResponsiveWidth(16)};
    font-weight: 400;
    line-height: ${props => props.theme.getResponsiveWidth(16)};
    text-align: center;
    color: ${({ theme }) => theme.grayShadow};
    display: block;

    @media (max-width: 1023px) {
      font-size: 8.5px;
      line-height: 8.5px;
    }
  }

  &.empty-suitability-questionnaire-warning {
    display: block;
    color: ${({ theme }) => theme.blueMidnight};
    font-size: ${props => props.theme.getResponsiveWidth(16)};
    font-weight: 700;
    line-height: ${props => props.theme.getResponsiveWidth(16)};
    text-align: center;

    @media (max-width: 1023px) {
      font-size: 8.5px;
      line-height: 8.5px;
    }
  }

  &.empty-suitability-questionnaire-title-question {
    color: ${({ theme }) => theme.blueMidnight};
    font-size: ${props => props.theme.getResponsiveWidth(24)};
    font-weight: 450;
    line-height: ${props => props.theme.getResponsiveWidth(24)};
    display: block;
    font-family: ${props => props.theme.fonts.PoppinsMedium};
    max-width: ${props => props.theme.getResponsiveWidth(744)};
    align-self: center;

    @media (max-width: 1023px) {
      font-size: 12.8px;
      font-weight: 450;
      line-height: 12.8px;
    }
  }

  &.empty-suitability-questionnaire-option {
    color: ${({ theme }) => theme.graySilver};
    font-size: ${props => props.theme.getResponsiveWidth(18)};
    line-height: ${props => props.theme.getResponsiveWidth(18)};
    font-weight: 400;
    font-family: ${props => props.theme.fonts.PoppinsRegular};
    display: block;
    margin: ${props => props.theme.getResponsiveWidth(8)};
    width: ${props => props.theme.getResponsiveWidth(744)};
    text-align: left;

    @media (max-width: 1023px) {
      width: 396.8px;
      font-size: 9.6px;
      line-height: 9.6px;
      margin: 4.3px;
    }
  }

  &.financial-assets {
    font-size: ${props => props.theme.getResponsiveWidth(24)};
    line-height: ${props => props.theme.getResponsiveWidth(24)};
    font-weight: 700;
    color: ${({ theme }) => theme.blueMidnight};
    font-family: ${({ theme }) => theme.fonts.PoppinsMedium};

    @media (max-width: 1023px) {
      font-size: 12.8px;
      line-height: 12.8px;
    }
  }

  &.allocation-card {
    display: inline-block;
    color: ${({ theme }) => theme.white};
    font-size: ${props => props.theme.getResponsiveWidth(12)};
    line-height: ${props => props.theme.getResponsiveWidth(12)};
    font-weight: 400;
    margin-top: ${props => props.theme.getResponsiveWidth(4)};

    &.percentage {
      margin-left: ${props => props.theme.getResponsiveWidth(12)};
      width: ${props => props.theme.getResponsiveWidth(43)};
    }

    &.name {
      margin-left: ${props => props.theme.getResponsiveWidth(16)};
    }

    @media (max-width: 1023px) {
      font-size: 6.4px;
      line-height: 6.4px;
      margin-top: 2.1px;

      &.percentage {
        margin-left: 6.4px;
        width: 22.9px;
      }

      &.name {
        margin-left: 8.5px;
      }
    }
  }
  &.pot-detail {
    font-size: ${props => props.theme.getResponsiveWidth(24)};
    font-family: ${({ theme }) => theme.fonts.PoppinsRegular};

    @media (max-width: 1023px) {
      font-size: 18px;
    }
  }
`;
