import React from "react";
import { Group } from "./styles";
import { GeneralContext } from "utils/contexts/GeneralContext";

export const GroupItens = ({ children, className, loadingClassName }) => {
    const { loading } = React.useContext(GeneralContext);

    return (
        <Group className={`${className} ${loading ? loadingClassName : ''}`}>
            {children}
        </Group>
    )
}