import React, { useState, useContext } from "react";
import {
    PageContainer,
    Wrapper,
    Container,
    ExtractWrapper,
    CategoryContainer
} from './styles'
import { ExtractMenu } from "components/organisms/ExtractMenu";
import { CategoryIntroduction } from "components/organisms/CategoryIntroduction";
import { getExtractData, getDetailedExtractItems } from "development/extract";
import { DetailedExtractMenu } from "components/organisms/DetailedExtractMenu";
import { DetailedExtract } from "components/organisms/DetailedExtract";
import { getColorPercentage } from "utils/extract";
import { GeneralContext } from 'utils/contexts/GeneralContext';
import { SimpleEditExtractItemModal } from "components/molecules/SimpleEditExtractItemModal";
import { SimpleDeleteModal } from "components/molecules/BudgetModal/SimpleDeleteModal";
import { calculateMonthDifference, getCurrentMonthObject } from 'utils/date';

export default function PeriodStatementPage() {

    const [currentPage, setCurrentPage] = useState('spendings');
    const extractItems = getExtractData(currentPage);
    const detailedExtractItems = getDetailedExtractItems(currentPage);
    const { loading, setLoading } = useContext(GeneralContext);
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
    const [itemData, setItemData] = useState({});
    const [loadingSimpleModal, setLoadingSimpleModal] = useState(false);
    const [loadingDeleteItemModal, setLoadingDeleteItemModal] = useState(false);
    const [startDate, setStartDate] = React.useState(getCurrentMonthObject(new Date()));
    const [endDate, setEndDate] = React.useState(getCurrentMonthObject(new Date()));

    const deleteItem = () => {
        if (!itemData.id) {
            alert('Selecione um item para excluir');
            return
        }
        // faça o resto da lógica para deletar o item aqui
    }

    React.useEffect(() => {
        const monthDifference = calculateMonthDifference(endDate.value, startDate.value);
        if (monthDifference > 12 || monthDifference < -12) {
            setEndDate(startDate);
        }
    }, [endDate, startDate]);

    React.useEffect(() => {
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, [setLoading]);

    return (
        <PageContainer>
            {showEditItemModal && (
                <SimpleEditExtractItemModal
                    showModal={true}
                    setShowModal={setShowEditItemModal}
                    title="Editar item"
                    data={itemData}
                    loading={loadingSimpleModal}
                    setLoading={setLoadingSimpleModal}
                    onConfirm={() => {
                        setShowEditItemModal(false);
                        setShowDeleteItemModal(true);
                    }}
                    onCancel={() => {
                        setShowEditItemModal(false);
                    }}
                />
            )}
            {showDeleteItemModal && (
                <SimpleDeleteModal
                    showModal={true}
                    setShowModal={setShowDeleteItemModal}
                    title="Excluir item"
                    text="Excluir item?"
                    highlightedText={"Esta ação é permanente e não poderá ser desfeita"}
                    confirmButtonText="Excluir item"
                    cancelButtonText="Cancelar"
                    loading={loadingDeleteItemModal}
                    setLoading={setLoadingDeleteItemModal}
                    onConfirm={() => {
                        deleteItem();
                        setItemData({});
                        setShowDeleteItemModal(false);
                    }}
                    onCancel={() => {
                        setShowDeleteItemModal(false);
                        setItemData({});
                    }}
                />
            )}
            <Container>
                <Wrapper className="extract">
                    <ExtractMenu
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        loading={loading}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                    />
                    <ExtractWrapper>
                        {extractItems.map((item, index) => (
                            <CategoryContainer className={index > 0 ? 'after' : ''} key={index}>
                                <CategoryIntroduction
                                    key={index}
                                    classification={item.classification}
                                    name={item.name}
                                    currentValue={item.value}
                                    maxValue={item.maxValue}
                                    redPercentage={getColorPercentage('red', item, currentPage)}
                                    bluePercentage={getColorPercentage('blue', item, currentPage)}
                                    pinkPercentage={getColorPercentage('pink', item, currentPage)}
                                    darkBluePercentage={getColorPercentage('dark-blue', item, currentPage)}
                                    darkGreenPercentage={getColorPercentage('dark-green', item, currentPage)}
                                    data={item.data}
                                    currentPage={currentPage}
                                    type={item.type}
                                    loading={loading}
                                    setShowEditItemModal={setShowEditItemModal}
                                    setItemData={setItemData}
                                />
                            </CategoryContainer>
                        ))}
                    </ExtractWrapper>
                </Wrapper>
                <Wrapper className="detailed-extract">
                    <DetailedExtractMenu loading={loading} />
                    <DetailedExtract
                        items={detailedExtractItems}
                        currentPage={currentPage}
                        loading={loading}
                        setShowEditItemModal={setShowEditItemModal}
                        setItemData={setItemData}
                    />
                </Wrapper>
            </Container>
        </PageContainer>
    );
}