import styled from 'styled-components';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

export const TextContainer = styled.div`
  width: ${({ theme }) => theme.getResponsiveWidth(1556)};
  display: flex;
  justify-content: space-between;
`;

export const TitleCardWrapper = styled.div`
  width: ${({ theme }) => theme.getResponsiveWidth(261)};
  display: flex;
  justify-content: space-between;

  &.first-column {
    height: ${({ theme }) => theme.getResponsiveHeight(24)};
  }

  &.second-column {
    height: ${({ theme }) => theme.getResponsiveHeight(16)};
  }
`;

export const GroupItens = styled.div`
  &.loading-small {
    width: ${props => props.theme.getResponsiveWidth(171)};
    height: ${props => props.theme.getResponsiveHeight(46)};
    margin-bottom: 10px;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.gradientSkeleton[0]} 25%,
      ${({ theme }) => theme.gradientSkeleton[1]} 50%,
      ${({ theme }) => theme.gradientSkeleton[0]} 75%
    );
    background-size: 200% 100%;
    animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;
    border-radius: 4px;
  }
  &.loading-large {
    width: ${props => props.theme.getResponsiveWidth(281)};
    height: ${props => props.theme.getResponsiveHeight(46)};
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.gradientSkeleton[0]} 25%,
      ${({ theme }) => theme.gradientSkeleton[1]} 50%,
      ${({ theme }) => theme.gradientSkeleton[0]} 75%
    );
    background-size: 200% 100%;
    animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;
    border-radius: 4px;
  }
`;
