import styled from 'styled-components';

export const H6 = styled.h6`
  &.table-progress {
    display: flex;
    justify-content: flex-start;
    font-family: ${({ theme }) => theme.fonts.PoppinsRegular}, sans-serif;
    font-size: ${({ theme }) => theme.getResponsiveHeight(12)};
    color: ${({ theme }) => theme.grayMedium};
  }
  &.apertura-medium {
    font-family: ${({ theme }) => theme.fonts.PoppinsMedium}, sans-serif;
    font-size: 24px;
  }

  &.up-button {
    font-size: 8px;
    color: ${({ theme }) => theme.blueMidnightOpacity};
    font-family: ${({ theme }) => theme.fonts.PoppinsRegular}, sans-serif;
  }

  &.avatar {
    font-size: 20px;
    font-weight: 450;
    line-height: 20px;
    text-align: center;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.selected {
    color: ${({ theme }) => theme.white};
    font-size: ${({ theme }) => theme.getResponsiveHeight(14)};
  }
  &.spending {
    color: ${({ theme }) => theme.blueSky};
    font-size: ${({ theme }) => theme.getResponsiveHeight(14)};
  }
  &.passive {
    color: ${({ theme }) => theme.blueMidnight};
    font-size: ${({ theme }) => theme.getResponsiveHeight(14)};
  }
  &.blue {
    color: ${({ theme }) => theme.blueMidnight};
    font-size: ${({ theme }) => theme.getResponsiveHeight(10)};
    @media (max-width: 1023px) {
      font-size: 8px;
      line-height: 12.8px;
    }
  }

  &.selected-patrimony {
    color: ${({ theme }) => theme.white};
    font-size: ${({ theme }) => theme.getResponsiveHeight(14)};
    @media (max-width: 1023px) {
      font-size: 8px;
      line-height: 12.8px;
    }
  }

  &.legend-table {
    color: ${({ theme }) => theme.blueSky};
    font-size: ${({ theme }) => theme.getResponsiveHeight(14)};
    font-family: ${({ theme }) => theme.fonts.PoppinsRegular}, sans-serif;

    @media (max-width: 1023px) {
      font-size: 7.33px;
      line-height: 7.33px;
    }
  }
`;
