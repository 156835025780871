import React from 'react';
import { Page } from 'components/templates/Page';
import DirecionalButton from 'components/organisms/Direcional';
import { PotDetail } from 'components/organisms/PotDetail';

export default function Detail() {
  return (
    <Page.RootContainer>
      <PotDetail />
      <DirecionalButton />
    </Page.RootContainer>
  );
}
