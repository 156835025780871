import React from 'react';
import { Container, TopRowCard } from './styles';
import { H3 } from 'components/atoms/Headings/H3';
import TooltipIconWithOverlay from '../../atoms/IconInformation';

export const TitleCardNoGo = ({
  url,
  title,
  tooltipText,
  showToolTip,
  loading,
  className,
}) => {
  return (
    <TopRowCard>
      <Container>
        <H3 className={`${className}`}>{title}</H3>
        {showToolTip && (
          <TooltipIconWithOverlay
            tooltipText={tooltipText}
            className={`${className}`}
            loading={loading}
          />
        )}
      </Container>
    </TopRowCard>
  );
};
