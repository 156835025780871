import React from 'react';
import { FinancialIndependenceGoal } from './FinancialIndependenceGoal';
import { GenericDream } from './GenericDream';
import { EmptyGoal } from './EmptyGoal';

export const Goal = ({
  type = '',
  dreamProps,
  onToggleDreamStatus,
  onCreateGoal = () => {},
  onUpdateGoal = () => {},
  onDeleteGoal = () => {},
  reloadPage,
}) => {
  const pages = {
    'financial-independence': (
      <FinancialIndependenceGoal
        dreamProps={dreamProps}
        onToggleDreamStatus={onToggleDreamStatus}
      />
    ),
    'empty-goal': (
      <EmptyGoal onCreateGoal={onCreateGoal} reloadPage={reloadPage} />
    ),
    generic: (
      <GenericDream
        dreamProps={dreamProps}
        onToggleDreamStatus={onToggleDreamStatus}
        onCreateGoal={onCreateGoal}
        onUpdateGoal={onUpdateGoal}
        onDeleteGoal={onDeleteGoal}
        reloadPage={reloadPage}
      />
    ),
  };

  return pages[type] || pages['generic'];
};

export default Goal;
