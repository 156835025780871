import React from "react";
import { StyledSelectImageCard, Container } from "./styles";

export const SelectImageIcon = ({
    className,
    onClick = () => { },
}) => {
    return (
        <Container className={className} onClick={onClick}>
            <StyledSelectImageCard
                width={17}
                height={15}
                viewBox="0 0 17 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <path
                    d="M13.433 6.548c-.224 0-.443-.02-.658-.057v7.414H1.931V3.389h7.745a3.63 3.63 0 01-.033-1.095H2.24c-.775 0-1.404.628-1.404 1.404v9.898C.836 14.37 1.465 15 2.24 15h10.227c.775 0 1.404-.629 1.404-1.404V6.522c-.143.017-.29.026-.438.026z"
                    fill="#969797"
                />
                <path
                    d="M5.108 9.523l-2.191 2.848h8.763L8.832 8.647l-2.19 2.848-1.534-1.972zM11.216 3.244h1.702v1.703a.516.516 0 001.03 0V3.244h1.702a.516.516 0 000-1.03h-1.703v-1.7a.516.516 0 00-1.03 0v1.703h-1.701a.516.516 0 000 1.03v-.003z"
                    fill="#969797"
                />
            </StyledSelectImageCard>
        </Container>
    )
}