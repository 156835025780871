import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { externalAcess } from 'store/modules/auth/actions';
import history from 'services/history';
import api from 'services/api';
import toast from 'react-hot-toast';

export default function ExternalAcess() {
  const [, setErr] = useState(false);
  const dispatch = useDispatch();

  const getRedirect = profile => {
    if (profile.type === 'assistant-planner') return '/finances/budget';
    return '/home';
  };

  useEffect(() => {
    const url = new URLSearchParams(window.location.search);
    const token = url.get('token');
    const user_id = url.get('user_id');
    const external_user_id = url.get('external_user_id');

    async function fetchData() {
      toast
        .promise(
          (async () => {
            const { data } = await api.post('/external_acess', {
              token,
              user_id,
              external_user_id,
            });
            dispatch(
              externalAcess({
                user: data.user,
                external_user: data.external_user,
              })
            );

            api.defaults.headers.Authorization = `Bearer ${token}`;
            const path = getRedirect(data.external_user.profile);
            history.push(path);
          })(),
          {
            loading: 'Verificando...',
            success: <b>Acesso concedido!</b>,
            error: <b>Acesso negado.</b>,
          },
          {
            duration: 5000,
          }
        )
        .catch(err => {
          setErr(true);
          console.error(err);
        });
    }

    fetchData();
  }, [dispatch]);

  return <></>;
}
