import styled, { keyframes } from 'styled-components';

const slideFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
    &.table {
        animation: ${slideFromTop} 0.6s ease-out;
    }

    &.table.loading {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 24px;
        margin-bottom: 16px;
        width: 97%;

        @media (max-width: 1440px) {
            margin-top: 18px;
            margin-bottom: 12px;
            gap: 14px;
        }
    }
`;
