import React from 'react';
import { Page } from 'components/templates/Page';
import RetirementMapCard from 'components/organisms/RetirementMapCard';
import { RetirementMapTable } from 'components/organisms/RetirementMapTable';
import DirecionalButton from 'components/organisms/Direcional';

export default function MapRetirement() {
  const [currentPage, setCurrentPage] = React.useState('socialSecurity');

  return (
    <Page.RootContainer>
      <RetirementMapCard
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      <RetirementMapTable currentPage={currentPage} />
      <DirecionalButton />
    </Page.RootContainer>
  );
}
