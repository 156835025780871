import React from 'react';
import { Label } from 'components/atoms/Label';
import { Input } from 'components/atoms/Input';
import { Span } from 'components/atoms/Span';

export const ModalInputNumber = ({
  className,
  getInputValue,
  placeholder,
  label,
  defaultValue = '',
  dayOfMonth = false,
  date = false,
}) => {
  const [inputValue, setInputValue] = React.useState(defaultValue);

  React.useEffect(() => {
    getInputValue(inputValue);
  }, [getInputValue, inputValue]);

  const handleInputChange = e => {
    let value = e.target.value.replace(/\D/g, '');

    if (dayOfMonth) {
      value = Math.min(Math.max(parseInt(value), 1), 31).toString();
      value = isNaN(value) ? '' : value;
    }

    setInputValue(value);
  };

  return (
    <Label className={className}>
      <Span className={className}>{label}</Span>
      <Input
        className={className}
        value={inputValue}
        onChange={handleInputChange}
        placeholder={placeholder}
      />
    </Label>
  );
};
