import React, { useState } from 'react';
import { Modal } from 'components/templates/Modal';

export const EditFinancialPatrimony = ({
  showModal = false,
  setShowModal = () => {},
  selectedItem = null,
  selectedCategoryKey,
  onConfirm = () => {},
  onCancel = () => {},
  theme = 'receipts',
}) => {
  const [name, setName] = useState(selectedItem?.name);
  const [cnpj, setCnpj] = useState(selectedItem?.cnpj);
  const [currentAmount, setCurrentAmount] = useState(
    selectedItem?.currentAmount
  );
  const [monthlyQuota, setMonthlyQuota] = useState(selectedItem?.monthlyQuota);
  const [cop, setCop] = useState(selectedItem?.cop);
  const [type, setType] = useState(selectedItem?.type);
  const [ir, setIr] = useState(selectedItem?.ir);

  const [profitability, setProfitability] = useState(
    selectedItem?.profitability
  );

  const confirm = () => {
    setShowModal(false);
    onConfirm();
  };
  const cancel = () => {
    const currentCategory = JSON.parse(
      localStorage.getItem(selectedCategoryKey)
    );
    currentCategory.name = name;
    localStorage.setItem(selectedCategoryKey, JSON.stringify(currentCategory));
    setShowModal(false);
    onCancel();
  };

  // const options = ['Vender', 'Alugar', 'Manter'];

  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="simple-modal edit"
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title="Editar Previdência Privada"
          onClose={() => setShowModal(false)}
          type="edit"
          className="simple-modal"
          theme={theme}
        />
      </Modal.TitleContainer>
      <Modal.BodyContainer>
        <Modal.InputText
          className="generic-dream-modal"
          label="Nome do plano"
          getInputValue={setName}
          defaultValue={name}
        />
        <Modal.InputText
          className="generic-dream-modal after"
          label="CNPJ"
          getInputValue={setCnpj}
          defaultValue={cnpj}
        />
        <Modal.GroupInputs show className="generic-dream-modal">
          <Modal.InputMoney
            className="generic-dream-modal first half "
            label="Montante Atual "
            getInputValue={setCurrentAmount}
            defaultValue={currentAmount}
          />
          <Modal.InputMoney
            className="generic-dream-modal first half last  "
            label="Contingente Mensal"
            getInputValue={setMonthlyQuota}
            defaultValue={monthlyQuota}
          />
        </Modal.GroupInputs>
        <Modal.GroupInputs show className="generic-dream-modal">
          <Modal.InputMoney
            className="generic-dream-modal first half "
            label="COP "
            getInputValue={setCop}
            defaultValue={cop}
          />
          <Modal.InputMoney
            className="generic-dream-modal first half last  "
            label="Tipo de Previdência"
            getInputValue={setType}
            defaultValue={type}
          />
        </Modal.GroupInputs>
        <Modal.GroupInputs show className="generic-dream-modal">
          <Modal.InputMoney
            className="generic-dream-modal first half "
            label="Benefício IR "
            getInputValue={setIr}
            defaultValue={ir}
          />
          <Modal.InputMoney
            label="Rentabilidade Mens."
            className="generic-dream-modal after half last second-section"
            getInputValue={setProfitability}
            defaultValue={profitability}
          />
        </Modal.GroupInputs>
      </Modal.BodyContainer>
      <Modal.SimpleFooter
        onConfirm={confirm}
        onCancel={cancel}
        className={`simple-modal ${theme}`}
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
      />
    </Modal.Container>
  );
};
