import styled from "styled-components";

export const Container = styled.div`
    &.immediate-liquidity {
        width: ${props => props.theme.getResponsiveWidth(1522)};
        height: ${props => props.theme.getResponsiveWidth(677)};

        @media (max-width: 1023px) {
            width: 811.7px;
            height: 361.1px;
        }
    }
`;