import * as React from 'react';
import { StyledTrashIcon, Container } from './styles';

function TrashIcon({
  className = '',
  onClick = () => { },
}) {
  return (
    <Container className={className} onClick={onClick}>
      <StyledTrashIcon
        viewBox="0 0 17 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M15.59 3.105H12.23V1.594A.781.781 0 0011.45.81h-5.9a.781.781 0 00-.777.784v1.51H1.41a.844.844 0 00-.839.846v1.733c0 .467.377.845.839.845h.5v12.728a1.81 1.81 0 001.799 1.815h9.58c.993 0 1.8-.814 1.8-1.815V6.53h.501c.462 0 .838-.38.838-.846V3.952a.844.844 0 00-.838-.846v-.001zM14.018 6.53v12.728a.732.732 0 01-.727.734H3.709a.732.732 0 01-.728-.735V6.53h11.037zM5.843 3.105V1.889h5.314v1.216H5.843zm9.515 1.08v1.264H1.642V4.185h13.716z"
          fill="#6C6C6C"
        />
        <path
          d="M5.18 8.317h-.04a.604.604 0 00-.603.607v8.558c0 .335.27.607.602.607h.041a.604.604 0 00.602-.607V8.924a.604.604 0 00-.602-.607zM8.52 8.317h-.04a.604.604 0 00-.602.607v8.558c0 .335.269.607.601.607h.041a.604.604 0 00.602-.607V8.924a.604.604 0 00-.602-.607zM11.86 8.317h-.04a.604.604 0 00-.602.607v8.558c0 .335.27.607.601.607h.042a.604.604 0 00.601-.607V8.924a.604.604 0 00-.601-.607z"
          fill="#6C6C6C"
        />
      </StyledTrashIcon>
    </Container>
  );
}

export default TrashIcon;
