import React, { useEffect, useRef, useCallback } from 'react';

import {
  StyledContainer,
  StyledSvg,
  StyledCircle,
  StyledPercentage,
} from './styles';

export const DoughnutGrafic = ({
  percentage,
  radius = 45,
  strokeWidth = 10,
  duration = 500,
  max = 100,
  loading,
  className = '',
}) => {
  const circleCircumference = 2 * Math.PI * radius;
  const circleRef = useRef();
  const animatedValue = useRef(0);
  const animation = useCallback(
    toValue => {
      const start = animatedValue.current;
      const change = toValue - start;
      const startTime = performance.now();

      const animate = () => {
        const now = performance.now();
        const elapsed = now - startTime;
        animatedValue.current = start + (elapsed / duration) * change;

        if (elapsed < duration) {
          requestAnimationFrame(animate);
        } else {
          animatedValue.current = toValue;
        }

        if (circleRef.current) {
          const maxPerc = (100 * animatedValue.current) / max;
          const strokeDashoffset =
            circleCircumference - (circleCircumference * maxPerc) / 100;
          circleRef.current.setAttribute('stroke-dashoffset', strokeDashoffset);
        }
      };

      animate();
    },
    [duration, max, circleCircumference]
  );

  useEffect(() => {
    animation(percentage);

    return () => {
      cancelAnimationFrame(animation);
    };
  }, [animation, percentage]);

  return (
    <StyledContainer className={className}>
      <StyledSvg
        viewBox={`-5 -5 ${2 * (radius + strokeWidth)} ${2 *
          (radius + strokeWidth)}`}
        className={className}
      >
        <StyledCircle
          className="loading"
          cx={radius + strokeWidth / 2}
          cy={radius + strokeWidth / 2}
          r={radius}
          fill="transparent"
          stroke="#f0f0f0"
          strokeWidth={strokeWidth}
          transform={`rotate(-90 ${radius + strokeWidth / 2} ${radius +
            strokeWidth / 2})`}
        />
        {!loading && (
          <>
            <StyledCircle
              className={className}
              ref={circleRef}
              cx={radius + strokeWidth / 2}
              cy={radius + strokeWidth / 2}
              r={radius}
              fill="transparent"
              strokeWidth={strokeWidth}
              strokeDasharray={circleCircumference}
              strokeLinecap="round"
              strokeDashoffset={circleCircumference}
              transform={`rotate(-90 ${radius + strokeWidth / 2} ${radius +
                strokeWidth / 2})`}
            />
            <StyledPercentage
              x={radius + strokeWidth / 2}
              y={radius + 5 + strokeWidth / 2}
              className={className}
            >
              {percentage}%
            </StyledPercentage>
          </>
        )}
      </StyledSvg>
    </StyledContainer>
  );
};
