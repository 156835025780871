import React from "react";
import { AmountOfMoneyContainer } from "./styles";
import { Span } from "components/atoms/Span";
import { H3 } from "components/atoms/Headings/H3";

export const NavigationCardAmountOfMoney = ({
    className = '',
    label = '',
    value = '',
}) => {
    return (
        <AmountOfMoneyContainer className={className}>
            <Span className={className}>{label}</Span>
            <H3 className={className}>{value}</H3>
        </AmountOfMoneyContainer>
    )
}