import React from 'react';
import { TextContainer, GroupItens } from './styles';

export const TitleLoading = () => {
  return (
    <TextContainer className="first-row">
      <GroupItens className="loading-small" />
      <GroupItens className="loading-large" />
    </TextContainer>
  );
};
