import React from "react";
import { ModalSectionContainer } from "./styles";

export const ModalSection = ({
    children,
    className = '',
}) => {
    return (
        <ModalSectionContainer className={className}>
            {children}
        </ModalSectionContainer>
    )
}