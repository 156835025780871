import * as React from 'react';
import { StyledNewReleaseIcon } from './styles';

function NewReleaseIcon({ className }) {
  return (
    <StyledNewReleaseIcon
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M28.618 32.195H13.664a3.52 3.52 0 01-3.516-3.517V13.726a3.52 3.52 0 013.516-3.517h14.954a3.52 3.52 0 013.516 3.517v14.954a3.52 3.52 0 01-3.516 3.516zM13.664 11.263a2.464 2.464 0 00-2.462 2.462v14.954a2.464 2.464 0 002.462 2.461h14.954a2.464 2.464 0 002.462-2.462V13.726a2.464 2.464 0 00-2.462-2.462H13.664z"
        fill="#fff"
      />
      <path
        d="M27.299 20.657h-5.615v-5.615c0-.299-.245-.544-.544-.544-.3 0-.544.245-.544.544v5.615h-5.614c-.3 0-.544.244-.544.544 0 .3.244.544.543.544h5.615v5.614c0 .3.245.544.544.544.3 0 .544-.244.544-.544v-5.614h5.615c.299 0 .544-.245.544-.544 0-.3-.245-.544-.544-.544z"
        fill="#fff"
      />
    </StyledNewReleaseIcon>
  );
}

export default NewReleaseIcon;
