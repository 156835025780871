import React from 'react';
import MapRetirement from './Map';
import PlanningRetirement from './Planning';

export default function Retirement() {
  const pathname = window.location.pathname;

  if (pathname.includes('/retirement/map')) {
    return <MapRetirement />;
  }
  if (pathname.includes('/retirement/planning')) {
    return <PlanningRetirement />;
  }
  return <React.Fragment></React.Fragment>;
}
