import styled from 'styled-components';

export const Container = styled.div`
  flex: 1 1 50%;
  padding: 16px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  min-width: 800px;
  align-items: inherit;
  height: inherit;
  box-shadow: 4px 4px 15px 0px ${({ theme }) => theme.boxShadowColor};

`;
