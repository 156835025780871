import React, { useEffect, useState } from 'react';
import { SwitchContainer, Slider, Input } from './styles';

const Switch = ({ checked, getValue, onChange, onClick, className }) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleChange = () => {
    setIsChecked(!isChecked);
    getValue(!isChecked);
    onChange && onChange(!isChecked);
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <SwitchContainer onClick={onClick} className={className}>
      <Input type="checkbox" checked={isChecked} onChange={handleChange} />
      <Slider />
    </SwitchContainer>
  );
};

export default Switch;
