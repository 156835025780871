import styled from 'styled-components';

export const DropdownContainer = styled.div`
  position: relative;
  width: 11.46vw;
`;

export const DropdownTrigger = styled.div`
  gap: 0.52vw;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.grayShadow};
  border: none;
`;

export const DropdownTriggerLabel = styled.span`
  font-family: ${props => props.theme.fonts.AperturaRegular};
  font-size: 0.7rem;
  line-height:  0.7rem;
  color: ${({ theme }) => theme.graySelectPlaceholder};
  font-weight: 400;
`;

export const DropdownTriggerIcon = styled.span`
  transition: transform 0.2s ease-in-out;
  transform: ${props => (props.isOpen ? 'rotate(180deg)' : 'none')};

  &.map-header-loading {
    display: none;
  }
`;

export const DropdownOptionsList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.blueRoyal};
  border-radius: 4px;
  list-style: none;
  padding: 0;
  margin: 0;
  display: none;
  overflow: auto;
  display: ${props => (props.isOpen ? 'block' : 'none')};
  z-index: 1000;

  @media (max-width: 1024px) {
    width: max-content;
    padding: 0 5px;
  }
`;

export const DropdownOptionItem = styled.li`
  padding: 0.26vw 0.26vw 0.26vw 0.78vw;

  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.hoover};

    span {
      color: ${props => props.theme.white};
    }
  }
`;

export const DropdownOptionItemLabel = styled.span`
  font-family: ${props => props.theme.fonts.AperturaRegular};
  font-size: 0.7rem;
  line-height: 0.7rem;
  font-weight: 400;
  color: ${props => props.theme.graySilver};

  @media (max-width: 1023px) {
    font-size: 0.6rem;
    line-height: 0.6rem;
  }
`;