import React from "react";
import { UpAndDownSelector } from "components/molecules/UpAndDownSelector";


export const NavigationUpAndDownSelector = ({
    className = '',
}) => {
    return (
        <UpAndDownSelector
            className={className}
        />
    )
}