import styled from 'styled-components';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

export const StyledLegend = styled.div`
  display: flex;
  align-items: center;

  &.detail {
    display: flex;
    margin-bottom: 0;
  }
`;

export const LegendColor = styled.div`
  &.home {
    width: 8px;
    height: 8px;
    border-radius: 2px;
    margin-right: 5px;
    transform: rotate(-180deg);
  }
  &.detail {
    width: 0.52vw;
    height: ${props => props.theme.getResponsiveHeight(10)};
    border-radius: 1.01vh;
    margin-right: 1.15vw;
    transform: rotate(-180deg);
    min-width: 10px;
    min-height: 10px;
  }

  &.first-column {
    background-color: ${({ theme }) => theme.blueRoyal};
  }

  &.second-column {
    background-color: ${({ theme }) => theme.blueDeep};
  }

  &.third-column {
    background-color: ${({ theme }) => theme.blueSky};
  }
  &.lilac {
    background-color: ${({ theme }) => theme.blueLilac};
  }
  &.blue-light {
    background-color: ${({ theme }) => theme.blueLight};
  }
  &.green-medium {
    background-color: ${({ theme }) => theme.greenMedium};
  }
  &.green-dark {
    background-color: ${({ theme }) => theme.greenDark};
  }
  &.red-light {
    background-color: ${({ theme }) => theme.redLight};
  }
  &.red-medium {
    background-color: ${({ theme }) => theme.redMedium};
  }
`;

export const LegendText = styled.p`
  &.home {
    font-size: 12px;
    margin: 0;
    font-family: ${({ theme }) => theme.fonts.PoppinsRegular};
    color: #6e7097b2;
  }
  &.detail {
    color: ${({ theme }) => theme.grayMedium};
    font-size: ${props => props.theme.getResponsiveHeight(18)};
    margin: 0;
    font-family: ${({ theme }) => theme.fonts.PoppinsRegular};

    &.line {
      text-decoration: line-through;
      color: ${({ theme }) => theme.grayMedium};
    }
  }
`;

export const Loading = styled.div`
  width: 70%;
  height: 8px;
  margin-bottom: 3%;
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.gradientSkeleton[0]} 25%,
    ${({ theme }) => theme.gradientSkeleton[1]} 50%,
    ${({ theme }) => theme.gradientSkeleton[0]} 75%
  );
  background-size: 200% 100%;
  animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;
  border-radius: 5px;
`;
