import styled from 'styled-components';

export const StyledContainer = styled.div`
  &.home {
    /* background-color: black; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-self: flex-end;
  }
`;
