import styled from 'styled-components';

export const Tr = styled.tr`
  &.receipt-table-title {
    height: ${props => props.theme.getResponsiveHeight(58)};

    &.receipts {
      background-color: ${({ theme }) => theme.blueHeaderWithSlowOpacity};
    }

    &.debts {
      background-color: ${({ theme }) => theme.redBloodWithSlowOpacity};
    }

    &.investments {
      background-color: ${({ theme }) => theme.greenDark};
      opacity: 0.55;
    }

    &.spendings {
      background-color: ${({ theme }) => theme.blueSky};
      opacity: 0.55;
    }
  }

  &.receipt-table-body {
    height: ${props => props.theme.getResponsiveHeight(44)};
    border-bottom: ${props => props.theme.getResponsiveHeight(1)} solid
      ${({ theme }) => theme.graySilver};
  }
  &.loading {
    border-bottom: none;
    height: ${props => props.theme.getResponsiveHeight(44)};
  }
`;
