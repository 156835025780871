
import BeginnerInvestor from './fake-images/investidor-inciante.jpeg';

export const getSuitabilityQuestions = () => {
    return [
        {
            questionId: 1,
            questionText: 'Qual seu principal objetivo ao investir?',
            answerOptions: [
                {
                    answerText: 'Estou montando a minha reserva financeira',
                    answerId: 1
                },
                {
                    answerText: 'Quero poupar e rentabilizar para a vida de um bem ou serviço',
                    answerId: 2
                },
                {
                    answerText: 'Construir patrimônio e garantir uma aposentadoria tranquila',
                    answerId: 3
                }
            ]
        },
        {
            questionId: 2,
            questionText: 'Qual o valor do seu patrimônio financeiro?',
            answerOptions: [
                {
                    answerText: 'Até R$ 100 mil',
                    answerId: 4
                },
                {
                    answerText: 'Entre R$ 100 mil e R$ 600 mil',
                    answerId: 5
                },
                {
                    answerText: 'Entre R$ 600 mil e R$ 1 milhão',
                    answerId: 6
                },
                {
                    answerText: 'Acima de R$ 1 milhão',
                    answerId: 7
                }
            ]
        },
        {
            questionId: 3,
            questionText: 'A tabela a seguir demonstra os melhores e piores casos ao se investir R$ 100.000 em um ano. Carteiras que tem um maior potencial de ganho, também tem um maior potencial de perda. Em qual carteira você estaria mais confortável em investir?',
            answerOptions: [
                {
                    answerText: 'Pior R$ 94.000. Melhor R$ 105.000',
                    answerId: 8
                },
                {
                    answerText: 'Pior R$ 90.000. Melhor R$ 110.000',
                    answerId: 9
                },
                {
                    answerText: 'Pior R$ 85.000. Melhor R$ 115.000',
                    answerId: 10
                },
                {
                    answerText: 'Pior R$ 80.000. Melhor R$ 120.000',
                    answerId: 11
                }
            ]
        },
        {
            questionId: 4,
            questionText: 'Considerando a sua renda mensal e seu custo de vida, você diria que:',
            answerOptions: [
                {
                    answerText: 'Nem sempre cubro meus custos mensais, e por isso preciso dos meus investimentos para arcar com as despesas',
                    answerId: 12
                },
                {
                    answerText: 'Minhas contas fecham, mas não sobra dinheiro pra investir',
                    answerId: 13
                },
                {
                    answerText: 'Consigo investir até 30% da minha renda e reaplico o resultado dos meus investimentos',
                    answerId: 14
                },
                {
                    answerText: 'Consigo arcar com meus custos mensais e ainda invisto mais do que 30% da minha renda',
                    answerId: 15
                }
            ]
        },
        {
            questionId: 5,
            questionText: 'Qual das alternativas abaixo representa melhor o seu conhecimento e experiência no mercado financeiro?',
            answerOptions: [
                {
                    answerText: 'Não tenho conhecimento sobre investimentos. O máximo que já apliquei foi na poupança',
                    answerId: 16
                },
                {
                    answerText: 'Tenho conhecimento e experiência em renda fixa (Tesouro Direto, CDB, LCI, LCA, Fundos de Investimentos)',
                    answerId: 17
                },
                {
                    answerText: 'Tenho algum conhecimento e experiência em renda fixa e renda variável (Renda fixa, Ações, Fundos Imobiliários, Fundos Multimercado)',
                    answerId: 18
                },
                {
                    answerText: 'Possuo ampla experiência no mercado de renda variável e derivativos (ações e opções)',
                    answerId: 19
                }
            ]
        },
        {
            questionId: 6,
            questionText: 'Considerando o seu principal objetivo de investimento (desconsiderando sua reserva de emergência), em quanto tempo você acredita que precisará resgatar o valor aplicado?',
            answerOptions: [
                {
                    answerText: 'Posso precisar de todo o dinheiro em menos de 1 ano',
                    answerId: 20
                },
                {
                    answerText: 'Acredito que precisarei em menos de 2 anos',
                    answerId: 21
                },
                {
                    answerText: 'Devo manter meus investimentos por até 5 anos',
                    answerId: 22
                },
                {
                    answerText: 'O meu principal objetivo é bem claro e está em mais de 5 anos',
                    answerId: 23
                }
            ]
        },
        {
            questionId: 7,
            questionText: 'O que você prioriza na hora de investir?',
            answerOptions: [
                {
                    answerText: 'Segurança e tranquilidade',
                    answerId: 24
                },
                {
                    answerText: 'Rentabilidade e diversificação',
                    answerId: 25
                }
            ]
        },
        {
            questionId: 8,
            questionText: 'Qual alternativa melhor descreve o seu comportamento em relação ao risco de oscilação dos seus investimentos?',
            answerOptions: [
                {
                    answerText: 'Não quero que meu patrimônio oscile, mesmo que a minha rentabilidade seja menor',
                    answerId: 26
                },
                {
                    answerText: 'Posso aceitar oscilações ocasionais em busca de retornos maiores no médio prazo, desde que sejam pequenas parcelas do total',
                    answerId: 27
                },
                {
                    answerText: 'Posso aceitar maiores oscilações em busca de retornos muito elevados no longo prazo, mesmo que eventualmente implique em desvalorizações significativas do total investido',
                    answerId: 28
                }
            ]
        },
        {
            questionId: 9,
            questionText: 'Escolha, dentre as opções abaixo, a que melhor reflita sua reação ao verificar uma desvalorização de 15% em seu portfólio:',
            answerOptions: [
                {
                    answerText: 'Resgatar a totalidade dos recursos em renda variável e investí-los em renda fixa',
                    answerId: 29
                },
                {
                    answerText: 'Manter os recursos com a atual estratégia de investimento e não fazer investimentos adicionais até a estabilização do mercado',
                    answerId: 30
                },
                {
                    answerText: 'Posso aceitar maiores oscilações em busca de retornos muito elevados no longo prazo, mesmo que eventualmente implique em desvalorizações significativas do total investido',
                    answerId: 31
                }
            ]
        },
        {
            questionId: 10,
            questionText: 'Com base no seu patrimônio atual, como você se sente:',
            answerOptions: [
                {
                    answerText: 'Me sinto extremamente insatisfeito',
                    answerId: 32
                },
                {
                    answerText: 'Me sinto um pouco insatisfeito',
                    answerId: 33
                },
                {
                    answerText: 'Me sinto satisfeito. Sei que estou no caminho para minha tranquilidade financeira',
                    answerId: 34
                },
                {
                    answerText: 'Me sinto extremamente satisfeito com meu patrimônio',
                    answerId: 35
                }
            ]
        },
        {
            questionId: 11,
            questionText: 'Você já está vivendo de renda?',
            answerOptions: [
                {
                    answerText: 'Não',
                    answerId: 36
                },
                {
                    answerText: 'Sim',
                    answerId: 37
                }
            ]
        }
    ]
}

export const getSuitabilityProfile = () => {
    return {
        profileName: 'Indefinido',
        profileDescription: `<b>Um investidor iniciante está começando a sua jornada nos investimentos, por isso, geralmente prioriza a preservação do seu capital e a minimização dos riscos. </b>Algumas das características são: baixíssima tolerância ao risco, ênfase na preservação de capital, diversificação conservadora e aversão a produtos financeiros complexos.É imprescindível que este investidor busque conhecimento sobre como poupar, proteger seu patrimônio e aprender sobre as opções de investimentos disponíveis.Quem se enquadra nesse perfil, geralmente não se importa em ter uma rentabilidade mais baixa, desde que suas aplicações priorizem a segurança do capital investido.`,
        profileImage: BeginnerInvestor,
    }
}