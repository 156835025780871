import React from 'react';
import { AmountOfMoneyContainer } from './styles';
import { Span } from 'components/atoms/Span';
import { H3 } from 'components/atoms/Headings/H3';
import { H6 } from 'components/atoms/Headings/H6';

export const CardContentValueDescription = ({
  className = '',
  label = '',
  value = '',
  descriptionValue,
}) => {
  return (
    <AmountOfMoneyContainer className={className}>
      <Span className={className}>{label}</Span>
      <H3 className={className}>{value}</H3>
      <H6 className={className}>{descriptionValue}</H6>
    </AmountOfMoneyContainer>
  );
};
