import styled from 'styled-components';

export const IMG = styled.img`
  width: min-content;
  height: min-content;
  src: url(${props => props.src});
  background-color: transparent;
  transition: ${props => props.transition};

  &.gallery-modal {
    display: inline-block;
    width: ${props => props.theme.getResponsiveWidth(111)};
    height: ${props => props.theme.getResponsiveWidth(82)};
    border-radius: ${props => props.theme.getResponsiveWidth(8)};
    cursor: pointer;

    @media (max-width: 1023px) {
      width: 59.2px;
      height: 43.73px;
      border-radius: 4.27px;
    }
  }

  &.filled-suitability-questionnaire {
    display: block;
    width: ${props => props.theme.getResponsiveWidth(510)};
    height: ${props => props.theme.getResponsiveWidth(304)};
    border-radius: ${props => props.theme.getResponsiveWidth(16)};

    @media (max-width: 1023px) {
      width: 272px;
      height: 162.1px;
      border-radius: 8.5px;
    }
  }
`;
