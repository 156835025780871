import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  overflow: hidden;

  &.loading {
    display: none;
  }
`;
export const Button = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${({ theme }) => theme.getResponsiveHeight(18)};
  border-radius: ${({ theme }) => theme.getResponsiveWidth(10)};
  border: 1px solid ${({ theme }) => theme.blueMidnight};
  color: ${({ theme }) => theme.blueMidnight};
  height: ${({ theme }) => theme.getResponsiveHeight(38)};
  padding: 5px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.AperturaMedium};

  @media (max-width: 1023px) {
    font-size: 14px;
  }
  @media (max-width: 850px) {
    font-size: 10px;
  }

  &.month {
    width: ${({ theme }) => theme.getResponsiveWidth(250)};
    min-width: 150px;
  }
  &.year {
    width: ${({ theme }) => theme.getResponsiveWidth(116)};
    min-width: 80px;
  }
`;
