import * as React from 'react';
import { SVG } from './styles';

function BellIcon({ className }) {
  return (
    <SVG
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M22.99 18.155c-.11-1.51-1.384-2.653-2.869-2.653h-.005a.3.3 0 01-.296-.301v-4.52c0-.154-.005-.307-.012-.461-.186-3.541-2.52-6.513-5.694-7.586C14.098 1.178 12.931 0 11.5 0c-1.433 0-2.6 1.178-2.614 2.632C5.71 3.706 3.379 6.678 3.19 10.22c-.006.153-.011.307-.011.461v4.514c0 .17-.136.307-.302.307a2.88 2.88 0 00-1.95.762 2.836 2.836 0 00-.92 1.892c-.121 1.682 1.188 3.09 2.812 3.09h5.184C8.407 22.828 9.821 24 11.5 24c1.679 0 3.094-1.173 3.498-2.755h5.183c1.626 0 2.933-1.408 2.813-3.09l-.003-.001zM11.498.922c.849 0 1.554.631 1.69 1.457a8.166 8.166 0 00-1.69-.174c-.579 0-1.142.059-1.688.174.136-.824.841-1.457 1.688-1.457zm0 22.113c-1.15 0-2.134-.748-2.506-1.79h5.013c-.373 1.042-1.355 1.79-2.508 1.79h.001zm8.681-2.714H2.868c-.979 0-1.84-.728-1.951-1.721-.132-1.174.772-2.176 1.9-2.176h.051c.671 0 1.214-.553 1.214-1.235v-4.12c-.005-.152 0-.232 0-.388 0-.155.006-.308.015-.461.183-3.084 2.19-5.675 4.935-6.663.283-.102.575-.187.875-.256a7.326 7.326 0 014.057.256c2.745.988 4.752 3.578 4.935 6.663.01.151.015.307.015.461 0 .154.006.176.004.335l-.004.126v4.047c0 .682.543 1.235 1.214 1.235.49 0 .95.183 1.301.488.351.306.596.738.65 1.232.132 1.176-.772 2.175-1.901 2.175v.002z" />
    </SVG>
  );
}

export default BellIcon;
