import * as React from 'react';
import { ReceiptIconContainer } from './styles';

function ReceiptIcon({ className }) {
  return (
    <ReceiptIconContainer
      className={className}
      viewBox="0 0 49 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.742 0H3.258C1.46 0 0 1.439 0 3.205v25.59C0 30.564 1.463 32 3.258 32h42.484C47.54 32 49 30.561 49 28.795V3.205C49 1.436 47.537 0 45.742 0zM1.968 3.205c0-.701.58-1.27 1.29-1.27H6.26c.035.21.053.422.053.631 0 2.146-1.774 3.89-3.954 3.89-.133 0-.263-.005-.391-.018V3.205zm1.29 26.862a1.28 1.28 0 01-1.29-1.27v-2.751a3.83 3.83 0 01.39-.018c2.181 0 3.955 1.744 3.955 3.89 0 .047 0 .099-.003.151H3.258v-.002zm43.774-1.27a1.28 1.28 0 01-1.29 1.27h-3.005a3.873 3.873 0 01-.05-.63c0-2.146 1.774-3.89 3.954-3.89.133 0 .26.004.391.017v3.234zm0-5.064a4.82 4.82 0 00-.39-.013c-3.205 0-5.811 2.564-5.811 5.716 0 .212.01.421.034.63H8.167v-.151c0-3.152-2.606-5.719-5.81-5.719a4.78 4.78 0 00-.392.013V8.267c.125.01.258.013.391.013 3.205 0 5.81-2.564 5.81-5.716 0-.21-.01-.421-.034-.63h32.701v.151c0 3.152 2.606 5.719 5.81 5.719.134 0 .264-.003.392-.013v15.942h-.003zm0-17.774c-.13.01-.258.019-.39.019-2.184 0-3.955-1.745-3.955-3.89v-.152h3.053c.71 0 1.29.57 1.29 1.269v2.754h.002z"
        fill="#180A83"
      />
      <path
        d="M24.5 6.119c-5.54 0-10.044 4.432-10.044 9.881 0 5.45 4.505 9.884 10.044 9.884 5.54 0 10.044-4.435 10.044-9.884 0-5.45-4.505-9.881-10.044-9.881zm0 17.907c-4.5 0-8.159-3.6-8.159-8.026s3.66-8.026 8.159-8.026c4.5 0 8.159 3.6 8.159 8.026s-3.66 8.026-8.159 8.026z"
        fill="#180A83"
      />
      <path
        d="M26.524 15.296c-.37-.17-.758-.277-1.152-.387l-.135-.04c-.07-.02-.139-.038-.21-.06-.176-.049-.357-.093-.538-.138l-.032-.008a6.78 6.78 0 01-.662-.183c-.29-.102-.59-.4-.553-.562l.005-.066c0-.233.125-.41.418-.591.266-.162.582-.204.91-.123.468.11.912.455 1.19.918.421.699 1.115.921 1.772.565.622-.337.808-1.012.476-1.719-.423-.897-1.098-1.52-2.013-1.852-.26-.091-.26-.104-.263-.264-.014-.67-.484-1.12-1.197-1.148-.665-.024-1.136.342-1.29 1.012a1.674 1.674 0 00-.042.311c-.035.016-.07.03-.107.045l-.034.016a4.658 4.658 0 00-.641.31c-1.061.634-1.604 1.589-1.572 2.758.03 1.227.673 2.143 1.859 2.648.322.138.654.227.981.316l.112.032c.114.031.226.063.34.097.112.034.226.065.34.094l.1.026c.337.086.656.17.917.316.16.092.324.375.313.49-.016.16-.1.455-.476.622-.954.43-1.808.055-2.343-1.028-.308-.623-.848-.876-1.486-.698-.654.18-.992.688-.904 1.357.026.225.1.466.229.74.457.96 1.233 1.604 2.372 1.965.013.136.029.28.077.427.17.544.6.855 1.218.876h.037c.683 0 1.109-.56 1.14-.947.022-.29.112-.322.45-.442l.12-.044c.662-.252 1.18-.6 1.537-1.036.359-.44.561-.974.606-1.588.09-1.327-.59-2.425-1.87-3.014v-.003z"
        fill="#180A83"
      />
    </ReceiptIconContainer>
  );
}

export default ReceiptIcon;
