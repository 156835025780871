import React, { useState } from 'react';
import { Modal } from 'components/templates/Modal';

export const DeleteMaterialAssets = ({
  showModal = false,
  setShowModal = () => {},
  selectedItem = null,
  selectedCategoryKey,
  onConfirm = () => {},
  onCancel = () => {},
  theme,
}) => {
  const [name] = useState(selectedItem?.name);
  const [classification] = useState(selectedItem?.category);

  const confirm = () => {
    setShowModal(false);
    onConfirm();
  };

  const cancel = () => {
    const currentCategory = JSON.parse(
      localStorage.getItem(selectedCategoryKey)
    );
    currentCategory.name = name;
    currentCategory.classification = classification;
    localStorage.setItem(selectedCategoryKey, JSON.stringify(currentCategory));
    setShowModal(false);
    onCancel();
  };

  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="simple-modal edit"
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title="Excluir ativo"
          onClose={() => setShowModal(false)}
          type="delete"
          className="simple-modal"
          theme={theme}
        />
      </Modal.TitleContainer>
      <Modal.BodyContainer>
        <Modal.HighlightedBodyContent
          text="Excluir ativo? "
          highlightedText="Esta ação é permanente e não poderá ser desfeita"
          className="simple-modal body"
        />
      </Modal.BodyContainer>
      <Modal.SimpleFooter
        onConfirm={confirm}
        onCancel={cancel}
        className={`simple-modal ${theme}`}
        confirmButtonText="Excluir Ativo"
        cancelButtonText="Cancelar"
      />
    </Modal.Container>
  );
};
