import styled from "styled-components";

export const StyledDirectArrowDown = styled.svg`
    &.simple-modal,
    &.generic-dream-modal {
        width: ${props => props.theme.getResponsiveHeight(12)};
        height: ${props => props.theme.getResponsiveHeight(6)};
        padding: 0;
        margin: 0;
        stroke: ${({ theme }) => theme.grayShadow};

        @media (max-width: 1023px) {
            width: 8.2px;
            height: 4.1px;
        }
    }

    &.extract-modal {
        width: 12px;
        height: 6px;
        padding: 0;
        margin: 0;
        stroke: ${({ theme }) => theme.grayShadow};
        @media (max-width: 1440px) {
            width: 8.2px;
            height: 4.1px;
        }
        @media (max-width: 1024px) {
            width: 8.2px;
            height: 4.1px;
        }
    }
`;