import React from "react";
import { Label } from "components/atoms/Label";
import { Span } from "components/atoms/Span";
import Switch from "components/atoms/Switch";

export const ModalSwitch = ({
    className,
    label,
    getSwitchValue = () => { },
    defaultValue = false
}) => {
    return (
        <Label className={className}>
            <Span className={className}>{label}</Span>
            <Switch
                className={className}
                checked={defaultValue}
                getValue={getSwitchValue}
            />
        </Label>
    )
}