import { H4 } from 'components/atoms/Headings/H4';
import React from 'react';
import { ColorBox, LegendContainer, LegendItem, Line } from './styles';

const LegendBarLine = () => {
  return (
    <LegendContainer>
      <div>
        <LegendItem className="start">
          <ColorBox className="blue" />
          <H4 className="legend-bar">Patrimônio Financeiro Projetado</H4>
        </LegendItem>
        <LegendItem className="start">
          <ColorBox className="blue-opacity" />
          <H4 className="legend-bar">Patrimônio Financeiro Alcançado</H4>
        </LegendItem>
      </div>
      <div>
        <LegendItem className="start">
          <ColorBox className="blue-sky" />
          <H4 className="legend-bar">Patrimônio Imobilizado Projetado</H4>
        </LegendItem>
        <LegendItem className="start">
          <ColorBox className="lilac-opacity" />
          <H4 className="legend-bar">Patrimônio Imobilizado Alcançado</H4>
        </LegendItem>
      </div>

      <div>
        <LegendItem>
          <Line className="gray" dashed />
          <H4 className="legend-bar">Curva Estimada</H4>
        </LegendItem>
        <LegendItem>
          <Line className="green" />
          <H4 className="legend-bar">Curva Projetada</H4>
        </LegendItem>
      </div>
    </LegendContainer>
  );
};

export default LegendBarLine;
