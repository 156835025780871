import React from 'react';
import { SubtitleGraphic } from '../../atoms/SubtitleGraphic';
import { StyledContainer } from './styles';

const SubtitleGroup = ({ loading, className, mainAssets }) => {
  return (
    <StyledContainer className={className}>
      <SubtitleGraphic
        subtitleText={mainAssets[0].fund}
        loading={loading}
        className={`${className} first-column`}
      />
      <SubtitleGraphic
        subtitleText={mainAssets[1].fund}
        loading={loading}
        className={`${className} second-column`}
      />
      <SubtitleGraphic
        subtitleText={mainAssets[2].fund}
        loading={loading}
        className={`${className} third-column`}
      />
    </StyledContainer>
  );
};

export default SubtitleGroup;
