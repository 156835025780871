export const getHousing = () => {
  return {
    months: [
      { name: 'JAN 2024', value: 'R$ 10.000' },
      { name: 'FEV 2024', value: 'R$ 10.000' },
      { name: 'MAR 2024', value: 'R$ 10.000' },
      { name: 'ABR 2024', value: 'R$ 10.000' },
      { name: 'MAI 2024', value: 'R$ 10.000' },
      { name: 'JUN 2024', value: 'R$ 10.000' },
      { name: 'JUL 2024', value: 'R$ 10.000' },
      { name: 'AGO 2024', value: 'R$ 10.000' },
      { name: 'SET 2024', value: 'R$ 10.000' },
      { name: 'OUT 2024', value: 'R$ 10.000' },
      { name: 'NOV 2024', value: 'R$ 10.000' },
    ],

    itemClassification: [
      { value: 'Mensal Comprometido', label: 'Mensal Comprometido' },
      { value: 'Mensal Flexível', label: 'Mensal Flexível' },
      { value: 'Eventual Comprometido', label: 'Eventual Comprometido' },
      { value: 'Eventual Flexível', label: 'Eventual Flexível' },
    ],
    header: [
      'Item',
      'Estimado',
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Média',
      'Soma',
    ],
    body: [
      {
        id: 1,
        name: 'Condomínio',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
      {
        id: 2,
        name: 'Animais de estimação',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
      {
        id: 3,
        name: 'Aluguel',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
      {
        id: 4,
        name: 'Água',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
      {
        id: 5,
        name: 'Supermercado / Feira / Açougue',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
      {
        id: 6,
        name: 'Telefone / Internet',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
      {
        id: 7,
        name: 'Empregados Diaristas',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
      {
        id: 8,
        name: 'Empregados Mensalistas',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
      {
        id: 9,
        name: 'Luz',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
      {
        id: 10,
        name: 'Gás',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
      {
        id: 11,
        name: 'IPTU / Obrigações Legais',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
      {
        id: 12,
        name: 'Lavanderia',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
      {
        id: 13,
        name: 'Móveis / Decoração / Manutenção',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
      {
        id: 14,
        name: 'Total',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
    ],
  };
};
export const getTransport = () => {
  return {
    itemClassification: [
      { value: 'Mensal Comprometido', label: 'Mensal Comprometido' },
      { value: 'Mensal Flexível', label: 'Mensal Flexível' },
      { value: 'Eventual Comprometido', label: 'Eventual Comprometido' },
      { value: 'Eventual Flexível', label: 'Eventual Flexível' },
    ],
    header: [
      'Item',
      'Estimado',
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Média',
      'Soma',
    ],
    body: [
      {
        id: 1,
        name: 'Plano de Saúde',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
      {
        id: 2,
        name: 'Plano Odontológico',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
      {
        id: 3,
        name: 'Seguro de Vida',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
      {
        id: 4,
        name: 'Farmácia / Medicamentos',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
      {
        id: 5,
        name: 'Planejamento Financeiro',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
      {
        id: 6,
        name: 'Suplementos',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
      {
        id: 7,
        name: 'Ofertas / Doações / Dízimos',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
      {
        id: 8,
        name: 'Tratamentos/Terapias',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
      {
        id: 9,
        name: 'Total',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
    ],
  };
};
export const getReceiptSemiannual = () => {
  return {
    itemClassification: [
      { value: 'Mensal Comprometido', label: 'Mensal Comprometido' },
      { value: 'Mensal Flexível', label: 'Mensal Flexível' },
      { value: 'Eventual Comprometido', label: 'Eventual Comprometido' },
      { value: 'Eventual Flexível', label: 'Eventual Flexível' },
    ],
    header: [
      'Item',
      'Estimado',
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Média',
      'Soma',
    ],
    body: [
      {
        id: 1,
        name: 'Salário',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
      {
        id: 2,
        name: 'Salário',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
      {
        id: 3,
        name: 'Total',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
    ],
  };
};
export const getInvestmentSemiannual = () => {
  return {
    itemClassification: [
      { value: 'Mensal Comprometido', label: 'Mensal Comprometido' },
      { value: 'Mensal Flexível', label: 'Mensal Flexível' },
      { value: 'Eventual Comprometido', label: 'Eventual Comprometido' },
      { value: 'Eventual Flexível', label: 'Eventual Flexível' },
    ],
    header: [
      'Item',
      'Estimado',
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Média',
      'Soma',
    ],
    body: [
      {
        id: 1,
        name: 'Poupança',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
      {
        id: 2,
        name: 'Caixinha da Nubank',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
      {
        id: 3,
        name: 'Total',
        valueEstimated: 'R$ 0',
        month1: 'R$ 0',
        month2: 'R$ 0',
        month3: 'R$ 0',
        month4: 'R$ 0',
        month5: 'R$ 0',
        month6: 'R$ 0',
        average: 'R$ 0',
        sum: 'R$ 0',
      },
    ],
  };
};
