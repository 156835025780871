import { GroupItens } from './GroupItens';
import { HeaderAvatar } from './HeaderAvatar';
import { HeaderNotifications } from './HeaderNotifications';
import { SearchBar } from 'components/molecules/SearchBar';
import { HeaderTitle } from './HeaderTitle';
import { RootContainer } from './RootContainer';
import { HeaderBudgetMapList } from './HeaderBudgetMapList';
import { HeaderBudgetMapListWithoutDropdown } from './HeaderBudgetMapListWithoutDropdown';
import { HeaderPatrimonyMapList } from './HeaderPatrimony';
import { DropdownSearch } from 'components/molecules/DropdownSearch';

export const Header = {
  RootContainer: RootContainer,
  GroupItens: GroupItens,
  Avatar: HeaderAvatar,
  Title: HeaderTitle,
  Notification: HeaderNotifications,
  SearchBar,
  BudgetMapList: HeaderBudgetMapList,
  BudgetMapListWithoutDropdown: HeaderBudgetMapListWithoutDropdown,
  PatrimonyMapList: HeaderPatrimonyMapList,
  DropdownSearch: DropdownSearch,
};
