import { CloudWithAddIcon } from "components/atoms/CloudWithAddIcon";
import PencilIcon from "components/atoms/icons/PencilIcon";
import { VerifiedIcon } from "components/atoms/icons/VerifiedIcon";
import { RoundVerifiedIcon } from "components/atoms/icons/RoundVerifiedIcon";
import React from "react";
import TrashIcon from "components/atoms/icons/TrashIcon";

export const DreamCardIcon = ({
    className = '',
    icon = '',
    onClick = () => { },
}) => {
    const icons = {
        'add-dream': <CloudWithAddIcon className={className} onClick={onClick} />,
        'verified': <VerifiedIcon className={className} onClick={onClick} />,
        'pencil': <PencilIcon className={className} onClick={onClick} />,
        'round-verified': <RoundVerifiedIcon className={className} onClick={onClick} />,
        'trash': <TrashIcon className={className} onClick={onClick} />,
    };

    return icons[icon] || <></>;
}