import * as React from 'react';
import { StyledBudgetManagementIcon } from './styles';

function BudgetManagementIcon({ className }) {
  return (
    <StyledBudgetManagementIcon
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.69 18.514c-.713-.182-1.268-.529-1.58-1.149a1.277 1.277 0 01-.135-.394c-.041-.305.106-.522.429-.606.327-.086.56.046.704.318.39.741 1.061 1.09 1.875.746.286-.12.433-.339.459-.567.018-.178-.158-.43-.328-.522-.283-.153-.63-.203-.952-.294-.31-.089-.634-.149-.928-.267-.68-.272-1.072-.759-1.09-1.451-.02-.67.314-1.18.929-1.525.19-.106.404-.177.626-.273.009-.088.006-.193.03-.292.074-.296.276-.47.612-.458.333.011.557.19.564.495.006.238.125.3.333.372.554.188.954.543 1.203 1.038.167.333.083.634-.212.783-.315.16-.642.057-.853-.272-.374-.585-1.146-.906-1.732-.571-.185.106-.393.272-.393.558-.064.257.276.522.525.603.27.088.555.136.831.21.324.089.657.16.96.291.767.331 1.157.936 1.106 1.663-.054.754-.554 1.19-1.27 1.443-.269.094-.505.136-.534.495-.011.146-.21.396-.535.385-.282-.009-.497-.126-.585-.387-.036-.11-.037-.228-.058-.372h-.002zM25.77 23.39H14.877c-.39 0-.705-.292-.705-.651 0-.359.317-.65.705-.65h10.895c.39 0 .704.291.704.65 0 .359-.316.65-.704.65zM25.77 26.391H14.877c-.39 0-.705-.292-.705-.65 0-.36.317-.651.705-.651h10.895c.39 0 .704.292.704.65 0 .36-.316.651-.704.651zM25.77 29.392H14.877c-.39 0-.705-.292-.705-.651 0-.359.317-.65.705-.65h10.895c.39 0 .704.291.704.65 0 .359-.316.65-.704.65z"
        fill="#fff"
      />
      <path
        d="M28.114 8.472H12.533c-.934 0-1.694.701-1.694 1.564V33.52c0 .228.134.443.347.558a.756.756 0 00.698.007l2.273-1.172 1.793 1.179a.75.75 0 00.832-.012l1.589-1.113 1.41 1.09a.744.744 0 00.875.019l1.583-1.11 1.413 1.094c.24.184.574.202.836.045l1.986-1.2 2.288 1.179c.216.111.484.109.699-.007a.642.642 0 00.346-.558V10.035c0-.862-.76-1.563-1.694-1.563h.001zm.5 24.277l-1.818-.937a.757.757 0 00-.728.024l-1.93 1.167-1.435-1.11a.742.742 0 00-.875-.019l-1.585 1.11-1.408-1.092a.742.742 0 00-.875-.018l-1.613 1.13-1.753-1.15a.742.742 0 00-.749-.037l-1.815.935V10.035c0-.254.225-.462.5-.462h15.581c.276 0 .5.208.5.463v22.712l.003.001z"
        fill="#fff"
      />
    </StyledBudgetManagementIcon>
  );
}

export default BudgetManagementIcon;
