import React from "react";
import { Wrapper } from "./styles";

export const DreamCardWrapper = ({
    children,
    className = '',
}) => {
    return (
        <Wrapper className={className}>
            {children}
        </Wrapper>
    )
}