import styled from 'styled-components';

export const StyledIcon = styled.svg`
  width: ${props => props.theme.getResponsiveWidth(9)};
  height: ${props => props.theme.getResponsiveHeight(15)};
  margin-left: ${props => props.theme.getResponsiveWidth(12)};

  min-width: 10px;
  min-height: 10px;

`;
