import styled from "styled-components";

export const HouseIconContainer = styled.svg`
    &.goal-panel {
        width: ${props => props.theme.getResponsiveWidth(27)};
        height: ${props => props.theme.getResponsiveWidth(27)};
        min-width: 14.4px;
        min-height: 14.4px;
        
        path {
            fill: ${({ theme }) => theme.blueRoyal};
        }
    }
`;