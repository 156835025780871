import styled from "styled-components";

export const Container = styled.div`
    &.asset-management {
        width: ${props => props.theme.getResponsiveWidth(18)};
        height: ${props => props.theme.getResponsiveWidth(27)};

        @media (max-width: 1023px){
            width: 9.6px;
            height: 14.4px;
        }
    }
`;

export const Icon = styled.svg`
    &.asset-management {
        width: ${props => props.theme.getResponsiveWidth(18)};
        height: ${props => props.theme.getResponsiveWidth(27)};

        path {
            fill: ${props => props.theme.blueRoyal};
        }

        &.hovered {
            path {
                fill: ${props => props.theme.white};
            }
        }

        @media (max-width: 1023px){
            width: 9.6px;
            height: 14.4px;
        }
    }
`;