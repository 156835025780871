import { Card } from 'components/templates/Card';
import { Table } from 'components/templates/Tables';
import React from 'react';
import { LineGrafic } from 'components/atoms/LineGrafic';

export const Rentirement = ({ rentirement }) => {
  const columnOrder = ['data', 'value'];

  return (
    <Card.Container className="spending-table">
      <Table.TitleSet
        nameTable="Patrimônio Financeiro da Aposentadoria"
        className="patrimony none"
      />
      <Card.Container className="card-table-grafic">
        <Card.Container className="table-grafic">
          <Table.Container className="responsive">
            <Table.TableBasic className="grafic-table">
              <Table.Thead>
                <Table.Tr className="receipt-table-title receipts">
                  {rentirement.header.map((item, index) => (
                    <Table.Th
                      className={`budget-table-title ${index === 0 &&
                        'first-table-border checkbox'}`}
                      first={index === 0}
                      key={index}
                    >
                      {item}
                    </Table.Th>
                  ))}
                  <Table.Th className="budget-table-title empty last-table-border" />
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {rentirement.body.map((column, rowIndex) => (
                  <Table.Tr className="receipt-table-body" key={rowIndex}>
                    {columnOrder.map((item, index) => {
                      const currentItem = column[item];

                      return (
                        <Table.Td
                          className={`receipt-table-body ${item === 'data' &&
                            'first-table-border checkbox'}`}
                          first={item === 'data'}
                          key={index}
                        >
                          {currentItem}
                        </Table.Td>
                      );
                    })}
                    <Table.Td />
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table.TableBasic>
          </Table.Container>
        </Card.Container>
        <Card.Container className="grafic-table">
          <LineGrafic />
        </Card.Container>
      </Card.Container>
    </Card.Container>
  );
};
