import { Span } from "components/atoms/Span";
import React from "react";

export const DreamCardText = ({
    className = '',
    text = '',
}) => {
    return (
        <Span className={className}>
            {text}
        </Span>
    )
}