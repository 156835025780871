import styled from 'styled-components';

export const SpendingIconContainer = styled.svg`
  width: ${props => props.theme.getResponsiveHeight(38)};
  height: ${props => props.theme.getResponsiveHeight(32)};

  &.report {
    width: ${props => props.theme.getResponsiveWidth(23)};
    height: ${props => props.theme.getResponsiveHeight(18)};
    min-width: 23px;
    min-height: 18px;
  }

  &.selected path {
    fill: ${({ theme }) => theme.white};
  }

  &.extract.spendings {
    width: 23px;
    height: 18px;
    path {
      fill: ${({ theme }) => theme.blueSky};
    }
    &.selected path {
      fill: ${({ theme }) => theme.white};
    }
    @media (max-width: 1440px){
      width: 20px;
      height: 16px;
    }
  }
`;
