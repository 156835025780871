import * as React from "react";
import { CloudWithAddIconContainer } from "./styles";

export const CloudWithAddIcon = ({
    className = '',
}) => {
    return (
        <CloudWithAddIconContainer
            width={65}
            height={47}
            viewBox="0 0 65 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M47.812 8.5C44.242 3.935 38.716 1 32.525 1 23.448 1 15.653 7.227 13.57 16.142a.294.294 0 01-.242.216c-7.058 1.186-12.242 7.3-12.326 14.538-.046 3.961 1.468 7.72 4.268 10.58 2.852 2.916 6.643 4.52 10.672 4.52h36.225V46C58.645 46 63.953 40.734 64 34.263a11.908 11.908 0 00-1.098-5.105"
                stroke="#969797"
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeLinecap="round"
            />
            <circle cx={54.5} cy={19.5} r={9.75} stroke="#969797" strokeWidth={1.5} />
            <path
                d="M54.5 15v9M50 19.5h9"
                stroke="#969797"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </CloudWithAddIconContainer>
    )
}
