import React from 'react';
import { StyledIcon } from './styles';

function FlexibleEventualIcon({ className }) {
  return (
    <StyledIcon
      viewBox="0 0 35 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M22.667 1.71L33 5.21v8.75h-5.167v14c0 .464-.181.91-.504 1.238a1.709 1.709 0 01-1.218.512H8.89c-.457 0-.895-.184-1.218-.512a1.765 1.765 0 01-.504-1.238v-14H2V5.21l10.333-3.5c0 1.393.545 2.728 1.514 3.713A5.126 5.126 0 0017.5 6.96c1.37 0 2.684-.553 3.653-1.537a5.293 5.293 0 001.514-3.713z"
        stroke="#697BFF"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledIcon>
  );
}

export default FlexibleEventualIcon;
