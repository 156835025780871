import React from "react";
import { Avatar } from "components/atoms/Avatar";
import { GeneralContext } from "utils/contexts/GeneralContext";

export const HeaderAvatar = ({ text, className, loadingClassName }) => {
    const { loading } = React.useContext(GeneralContext);

    return (
        <Avatar className={`${className} ${loading ? loadingClassName : ''}`}>
            {text}
        </Avatar>
    )
}