import { CardContainer } from './styles';
import React from 'react';

export const CardContainerBasic = ({
  children,
  className,
  onClick,
  onMouseLeave,
  onMouseEnter,
}) => {
  return (
    <CardContainer
      className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </CardContainer>
  );
};
