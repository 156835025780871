import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js';
import {
  CanvasWrapper,
  ChartWrapper,
  Container,
  StyledCanvas,
  Title,
} from './styles';
import { getLineGraphData } from 'development/retirement';
import { formatOutputMoney } from 'utils/numbers';
import light from 'styles/themes/light';

export const LineGrafic = () => {
  const chartContainer = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const { labels, data } = getLineGraphData();

      const chartData = {
        labels,
        datasets: [
          {
            label: 'Patrimônio',
            data,
            fill: true,
            backgroundColor: light.lilacBackground,
            borderColor: light.blueLight,
            borderWidth: 1,
            tension: 0.4,
            pointRadius: 0,
            pointHitRadius: 0,
          },
        ],
      };

      const options = {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true,
                color: light.gradeColor,
                borderDash: [3, 3],
              },
              ticks: {
                fontSize: 12,
                fontColor: light.grayShadow,
                fontStyle: 'normal',
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: light.gradeColor,
                borderDash: [3, 3],
              },
              ticks: {
                display: true,
                fontSize: 12,
                fontColor: light.grayShadow,
                fontStyle: 'normal',
                stepSize: 10000,
                padding: 10,
                callback: function(value) {
                  return formatOutputMoney(value);
                },
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      };

      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      chartInstance.current = new Chart(chartContainer.current, {
        type: 'line',
        data: chartData,
        options,
      });
    }
  }, [chartContainer]);

  return (
    <ChartWrapper>
      <Title>Patrimônio</Title>
      <Container>
        <CanvasWrapper>
          <StyledCanvas ref={chartContainer} />
        </CanvasWrapper>
        <Title className="year">Anos</Title>
      </Container>
    </ChartWrapper>
  );
};
