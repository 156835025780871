import styled from 'styled-components';

export const Description = styled.div`
  display: flex;
  justify-content: space-between;

  &.loading {
    display: none;
  }
`;
