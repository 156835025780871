import * as React from 'react';
import { StyledDashboardIcon } from './styles';

function DashboardIcon({ className }) {
  return (
    <StyledDashboardIcon
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M32.431 16.943h-9.49c-.795 0-1.44-.608-1.44-1.357V9.829c0-.75.645-1.357 1.44-1.357h9.49c.795 0 1.44.608 1.44 1.357v5.755c0 .75-.645 1.358-1.44 1.358v.001zm-9.49-7.299c-.106 0-.196.085-.196.185v5.755c0 .1.09.185.196.185h9.49c.106 0 .196-.085.196-.185V9.83c0-.1-.09-.185-.196-.185h-9.49zM31.971 34.214h-8.57c-1.048 0-1.9-.804-1.9-1.792v-12.52c0-.989.852-1.792 1.9-1.792h8.57c1.048 0 1.9.803 1.9 1.791v12.521c0 .988-.852 1.792-1.9 1.792zm-8.57-14.931c-.362 0-.656.277-.656.618v12.521c0 .342.294.619.656.619h8.57c.362 0 .656-.277.656-.619v-12.52c0-.342-.294-.62-.656-.62h-8.57zM19.059 34.213h-9.49c-.795 0-1.44-.608-1.44-1.357v-5.755c0-.75.645-1.358 1.44-1.358h9.49c.794 0 1.44.609 1.44 1.358v5.755c0 .75-.646 1.357-1.44 1.357zm-9.49-7.299c-.106 0-.196.086-.196.185v5.756c0 .1.09.184.195.184h9.491c.106 0 .196-.085.196-.184v-5.756c0-.1-.09-.184-.196-.184h-9.49zM18.599 24.575h-8.57c-1.048 0-1.9-.803-1.9-1.791V10.262c0-.988.852-1.791 1.9-1.791h8.57c1.048 0 1.9.803 1.9 1.79v12.521c0 .988-.852 1.792-1.9 1.792zm-8.57-14.93c-.363 0-.656.276-.656.618v12.52c0 .342.293.62.656.62h8.57c.362 0 .656-.278.656-.62v-12.52c0-.342-.294-.619-.656-.619h-8.57z"
        fill="#fff"
      />
    </StyledDashboardIcon>
  );
}

export default DashboardIcon;
