import styled from "styled-components";

export const DollarSignIconContainer = styled.svg`
    &.goal-panel {
        width: ${props => props.theme.getResponsiveWidth(31)};
        height: ${props => props.theme.getResponsiveWidth(31)};
        min-width: 16.5px;
        min-height: 16.5px;
        
        path {
            stroke: ${({ theme }) => theme.blueRoyal};
        }

        path:first-child {
            width: ${props => props.theme.getResponsiveWidth(25)};
            height: ${props => props.theme.getResponsiveWidth(25)};
            min-width: 13px;
            height: 13px;
        }

        path:last-child {
            width: ${props => props.theme.getResponsiveWidth(8.33)};
            height: ${props => props.theme.getResponsiveWidth(11.89)};
            min-width: 4px;
            min-height: 6px;
        }
    }
`;