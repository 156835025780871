import styled, { keyframes, css } from 'styled-components';

const slideFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
    &.header {
        width: 97%;
        height: 29px;
        display: flex;
        justify-content: space-between;
        animation: ${slideFromTop} 0.6s ease-out;

        @media (max-width: 1440px) {
            height: 25px;
        }
    }

    &.body {
        display: flex;
        width: 100%;
        height: 20px;
        animation: ${slideFromTop} 0.6s ease-out;

        @media (max-width: 1440px) {
            height: 16px;
        }
    }

    &.items {
        margin-top: 26px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 36px;
        animation: ${slideFromTop} 0.6s ease-out;

        @media (max-width: 1440px) {
            height: 32px;
            margin-top: 22px;
        }
    }

    &.body-items {
        display: flex;
        align-items: center;
        width: 97%;
        height: 20px;

        @media (max-width: 1440px) {
            height: 16px;
        }
    }

    &.category-items {
        width: 97%;
        height: 14px;
        display: flex;
        justify-content: space-between;

        @media (max-width: 1440px) {
            height: 10px;
        }
    }
`;


export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0 8px;
    
    &.extract-title {
        height: 29px;

        @media (max-width: 1440px) {
            height: 25px;
            gap: 0 6px;
        }
    }

    &.extract-title-item {
        height: 14px;

        @media (max-width: 1440px) {
            height: 10px;
        }
    }
`;

export const IconContainer = styled.div`
    &.extract-title {
        width: 29px;
        height: 29px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 1440px) {
            width: 25px;
            height: 25px;
        }
    }

    &.extract-category {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        margin-top: 8px;
        margin-left: 7px;

        &:hover {
            cursor: pointer;
            background-color: ${(props) => props.theme.sideBarHover};

            svg path {
                fill:  ${(props) => props.theme.white};
            }
        }

        @media (max-width: 1440px) {
            width: 16px;
            height: 16px;
            margin-top: 6px;
            margin-left: 5px;
        }
    }

    &.extract-category-item {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        margin-left: 0.74%;

        &:hover {
            cursor: pointer;
            background-color: ${(props) => props.theme.sideBarHover};

            svg path {
                fill:  ${(props) => props.theme.white};
            }
        }

        @media (max-width: 1440px) {
            width: 16px;
            height: 16px;
        }
    }
`;

export const ChevronIcon = styled.div`
    transition: transform 0.3s ease-in-out;

    ${({ isRotated }) =>
        isRotated &&
        css`
            transform: rotate(-180deg);
        `}
`;
