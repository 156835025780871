import styled from 'styled-components';

export const DebtsIconContainer = styled.svg`
  width: ${props => props.theme.getResponsiveWidth(47)};
  height: ${props => props.theme.getResponsiveHeight(32)};

  &.report {
    width: ${props => props.theme.getResponsiveWidth(24)};
    height: ${props => props.theme.getResponsiveHeight(16)};
    min-width: 24px;
    min-height: 16px;
  }

  &.selected path {
    fill: ${({ theme }) => theme.white};
  }
`;
