import * as React from 'react';
import { StyledIcon } from './styles';

function ArrowRightIcon({ className, onClick }) {
  return (
    <StyledIcon
      className={className}
      onClick={onClick}
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 10.5L5 6 1 1.5"
        stroke="#0C053F"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </StyledIcon>
  );
}

export default ArrowRightIcon;
