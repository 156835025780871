import React, { useContext } from 'react';
import { Card } from 'components/templates/Card';
import { GeneralContext } from 'utils/contexts/GeneralContext';
import ProgressBarCard from 'components/atoms/ProgressBarCard';
import light from 'styles/themes/light';
import { getCompositionData } from 'development/retirement';

export const Composition = ({
  title = 'Composição de renda',
  icon = 'bar',
  text,
}) => {
  const { loading } = useContext(GeneralContext);

  const loadingClass = loading ? 'loading' : '';

  const {
    financialPatrimonyValue,
    rentValue,
    socialSecurityValue,
  } = getCompositionData();

  const data = [
    {
      label: 'Patrimônio Financeiro',
      value: financialPatrimonyValue,
      color: light.blueRoyal,
    },
    { label: 'Aluguel', value: rentValue, color: light.blueSky },
    {
      label: 'Prev. Social',
      value: socialSecurityValue,
      color: light.bluePale,
    },
  ];

  return (
    <Card.Container className={`progress-card ${loadingClass}`}>
      <Card.BodyContainer className={`budget ${loadingClass}`}>
        <Card.TitleInformation
          title={title}
          type={icon}
          className="spending-card passive blue teste"
          tooltipText={text}
        />
      </Card.BodyContainer>
      <Card.Content className={`budget `}>
        <ProgressBarCard data={data} />
      </Card.Content>
    </Card.Container>
  );
};
