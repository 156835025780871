import * as React from "react"
import { SVG } from "./styles"

export const LegalEntityCategoryIcon = ({
    className = '',
}) => {
    return (
        <SVG
            className={className}
            viewBox="0 0 21 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.5 9.5a.968.968 0 00.713-.288.964.964 0 00.287-.712.973.973 0 00-.288-.712.965.965 0 00-.712-.288h-3a.965.965 0 00-.712.288.973.973 0 00-.288.712c0 .283.095.52.288.713a.96.96 0 00.712.287h3zm0-3a.968.968 0 00.713-.288.964.964 0 00.287-.712.973.973 0 00-.288-.712.965.965 0 00-.712-.288h-3a.965.965 0 00-.712.288.973.973 0 00-.288.712c0 .283.095.52.288.713a.96.96 0 00.712.287h3zm-9 3c-.6 0-1.142.054-1.625.163-.483.109-.908.28-1.275.512-.35.217-.617.463-.8.738a1.57 1.57 0 00-.275.887c0 .2.075.367.225.5.15.133.333.2.55.2h6.4a.77.77 0 00.55-.213.708.708 0 00.225-.537c0-.283-.092-.558-.275-.825-.183-.267-.45-.517-.8-.75a3.98 3.98 0 00-1.275-.513A7.39 7.39 0 007.5 9.5zm0-1c.55 0 1.02-.196 1.412-.587A1.93 1.93 0 009.5 6.5c0-.55-.195-1.021-.587-1.412A1.937 1.937 0 007.5 4.5a1.913 1.913 0 00-1.412.588A1.935 1.935 0 005.5 6.5c0 .548.195 1.019.588 1.413.393.394.863.59 1.412.587zm-5 8c-.55 0-1.02-.196-1.412-.587A1.93 1.93 0 01.5 14.5v-12c0-.55.196-1.02.588-1.412A1.93 1.93 0 012.5.5h16c.55 0 1.021.196 1.413.588.392.392.588.863.587 1.412v12c0 .55-.196 1.021-.587 1.413a1.92 1.92 0 01-1.413.587h-16zm0-2h16v-12h-16v12z"
                fill="#0C053F"
            />
        </SVG>
    )
}
