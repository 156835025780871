import React, { useContext } from 'react';

import { LoadingContainer, Container } from './styles';
import TitleCard from 'components/molecules/TitleCard';
import { Span } from 'components/atoms/Span';
import { GeneralContext } from 'utils/contexts/GeneralContext';

const PatrimonyCard = ({
  title,
  number,
  tooltipText,
  url,
  showToolTip,
  className,
}) => {
  const { loading } = useContext(GeneralContext);

  if (loading) {
    return (
      <Container>
        <TitleCard
          tooltipText={tooltipText}
          url={url}
          title={title}
          showToolTip={showToolTip}
          loading={loading}
          className={`${className}`}
        />
        <LoadingContainer />
      </Container>
    );
  }

  return (
    <Container>
      <TitleCard
        tooltipText={tooltipText}
        url={url}
        title={title}
        showToolTip={showToolTip}
        loading={loading}
        className={`${className} title-card`}
      />
      <Span className="patrimony-value">{number}</Span>
    </Container>
  );
};

export default PatrimonyCard;
