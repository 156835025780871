import styled from 'styled-components';

export const StyledSuccessionPlanningIcon = styled.svg`
  &.sidebar {
    width: 100%;
    height: 100%;
    transition: ease-in-out 0.5s ease;
    flex: 1;
    cursor: pointer;
    border-radius: 8px;

    path {
      width: 100%;
      height: 100%;
    }

    &.in-page {
      background-color: ${({ theme }) => theme.sideBarHover};
    }

    &:hover {
      background-color: ${({ theme }) => theme.sideBarHover};
    }
  }
`;
