import styled from 'styled-components';

export const Container = styled.div`
  &.dream-panel {
    display: flex;
    background: transparent;
    border: 2px dashed ${({ theme }) => theme.grayPale};
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    width: 323px;
    height: 218px;
    padding-left: 0.68vw;
    padding-right: 0.63vw;
    padding-bottom: 1.81vh;
    overflow: hidden;
    width: 35%;
  }
`;
