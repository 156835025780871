import React, { useState } from 'react';
import { Modal } from 'components/templates/Modal';
import { getAnualValue } from 'utils/numbers';
import { getMonths } from 'utils/date';

export const EditSpendingItemModal = ({
  showModal = false,
  setShowModal = () => { },
  title = '',
  selectedItem,
  addItemKey,
  icon = 'edit',
  onCancel = () => { },
  onConfirm = () => { },
  selectedItemKey,
  theme,
  classificationOptions = [],

}) => {
  const [name, setName] = useState(selectedItem?.name);
  const [classification, setClassification] = useState(
    selectedItem?.classification
  );
  const [maturity, setMaturity] = useState(selectedItem?.maturity);
  const [value, setValue] = useState(selectedItem?.value);
  const [monthlyValue, setMonthlyValue] = useState(selectedItem?.monthlyValue);

  const GetAddValues = key => {
    const base = String(classification).toLocaleLowerCase();
    let currentItem = JSON.parse(localStorage.getItem(key));

    const propertiesByClassification = {
      'mensal comprometido': {
        maturity: maturity,
        monthlyValue: monthlyValue,
        value: getAnualValue(monthlyValue)
      },
      'mensal flexível': {
        maturity: '-',
        monthlyValue: monthlyValue,
        value: getAnualValue(monthlyValue)
      },
      'eventual comprometido': {
        maturity: maturity,
        monthlyValue: '-',
        value: value
      },
      'eventual flexível': {
        maturity: '-',
        monthlyValue: '-',
        value: value
      }
    }

    currentItem = {
      ...currentItem,
      name: name,
      classification: classification,
      recipeId: selectedItem?.recipeId || null,
      spendingId: selectedItem?.spendingId || null,
      ...propertiesByClassification[base]
    };

    return currentItem;
  }

  const additionalSettings = {
    'mensal comprometido': (
      <>
        <Modal.InputNumber
          className="simple-modal after"
          label="Dia do mês"
          getInputValue={setMaturity}
          defaultValue={maturity}
          dayOfMonth
        />
        <Modal.InputMoney
          className="simple-modal after only-it"
          label="Valor mensal"
          getInputValue={setMonthlyValue}
          defaultValue={monthlyValue}
        />
      </>
    ),
    'mensal flexível': (
      <Modal.InputMoney
        className="simple-modal after only-it"
        label="Valor mensal"
        getInputValue={setMonthlyValue}
        defaultValue={monthlyValue}
      />
    ),
    'eventual comprometido': (
      <>
        <Modal.Dropdown
          className="simple-modal after"
          selectOptions={getMonths()}
          getDropdownValue={setMaturity}
          label="Mês do ano"
          placeholderSelect={maturity}
        />
        <Modal.InputMoney
          className="simple-modal after"
          label="Valor anual"
          getInputValue={setValue}
          defaultValue={value}
        />
      </>
    ),
    'eventual flexível': (
      <Modal.InputMoney
        className="simple-modal after only-it"
        label="Valor anual"
        getInputValue={setValue}
        defaultValue={value}
      />
    )
  }

  const returnAdditionalSettings = () => {
    let base = String(classification).toLocaleLowerCase();
    return additionalSettings[base] || <></>;
  };

  const cancel = () => {
    if (icon === 'add') {
      localStorage.setItem(addItemKey, JSON.stringify(GetAddValues(addItemKey)));
    } else {
      localStorage.setItem(selectedItemKey, JSON.stringify(GetAddValues(selectedItemKey)));
    }

    onCancel();
    setShowModal(false);
  }

  const confirm = () => {
    onConfirm();
    setShowModal(false);
  }

  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="simple-modal edit"
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title={title}
          onClose={() => setShowModal(false)}
          type={icon}
          className="simple-modal"
          theme={theme}
        />
      </Modal.TitleContainer>
      <Modal.BodyContainer>
        <Modal.InputText
          className="simple-modal"
          label="Nome do item"
          getInputValue={setName}
          defaultValue={name}
        />
        <Modal.Dropdown
          className="simple-modal after"
          selectOptions={classificationOptions}
          getDropdownValue={setClassification}
          label="Classificação"
          placeholderSelect={classification}
        />
        {returnAdditionalSettings()}
      </Modal.BodyContainer>
      <Modal.SimpleFooter
        onCancel={() => cancel()}
        onConfirm={() => confirm()}
        className={`simple-modal ${theme}`}
        confirmButtonText="Excluir item"
        cancelButtonText="Concluído"
      />
    </Modal.Container>
  );
};
