import styled from 'styled-components';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

export const Th = styled.th`
  &.budget-table-title {
    color: ${({ theme }) => theme.white};
    height: ${props => props.theme.getResponsiveHeight(58)};
    padding: ${props => props.theme.getResponsiveHeight(16)},
      ${props => props.theme.getResponsiveHeight(24)};
    border: none;
    font-size: ${props => props.theme.getResponsiveHeight(16)};
    text-align: ${props => (props.first ? 'left' : 'center')};

    &.checkbox {
      width: min-content;
      height: min-content;
      text-align: left;
    }

    &.empyt {
      width: ${props => props.theme.getResponsiveWidth(45)};
    }
  }

  &.first-table-border {
    border-top-left-radius: ${props => props.theme.getResponsiveWidth(4)};
    border-bottom-left-radius: ${props => props.theme.getResponsiveWidth(4)};
  }

  &.last-table-border {
    border-top-right-radius: ${props => props.theme.getResponsiveWidth(4)};
    border-bottom-right-radius: ${props => props.theme.getResponsiveWidth(4)};
    width: 50px;

    &.final {
      width: 0;
    }
  }
  &.debts {
    width: min-content;
    height: min-content;
    text-align: center;
  }

  &.loading {
    height: ${props => props.theme.getResponsiveHeight(58)};
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.gradientSkeleton[0]} 25%,
      ${({ theme }) => theme.gradientSkeleton[1]} 50%,
      ${({ theme }) => theme.gradientSkeleton[0]} 75%
    );
    background-size: 200% 100%;
    animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;
    border-radius: 4px;
  }
`;
