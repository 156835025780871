import React from "react";
import { Container, Svg } from './styles';

export const PuzzleIcon = ({
    className = '',
    onClick = () => { },
}) => {
    return (
        <Container className={className} onClick={onClick}>
            <Svg
                width={27}
                height={28}
                viewBox="0 0 27 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <path
                    d="M15.75 0c-2.486 0-4.5 1.928-4.5 4.308H4.5v5.384C2.014 9.692 0 11.62 0 14s2.014 4.308 4.5 4.308v5.384h6.75c0 2.38 2.014 4.308 4.5 4.308s4.5-1.928 4.5-4.308H27v-7.538h-2.25c-1.339 0-2.25-.873-2.25-2.154s.911-2.154 2.25-2.154H27V4.308h-6.75c0-2.38-2.014-4.308-4.5-4.308zm0 2.154c1.339 0 2.25.872 2.25 2.154v2.154h6.75v3.23c-2.486 0-4.5 1.928-4.5 4.308s2.014 4.308 4.5 4.308v3.23H18v2.154c0 1.282-.911 2.154-2.25 2.154s-2.25-.872-2.25-2.154v-2.154H6.75v-5.384H4.5c-1.339 0-2.25-.873-2.25-2.154s.911-2.154 2.25-2.154h2.25V6.462h6.75V4.308c0-1.282.911-2.154 2.25-2.154z"
                    fill="#140361"
                />
            </Svg>
        </Container>
    )
}