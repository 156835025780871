import styled from 'styled-components';

export const StyledContainer = styled.div`
  &.detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: ${props => props.theme.getResponsiveWidth(400)};
    min-width: 300px;
  }
`;
export const LegendValue = styled.div`
  &.detail {
    font-family: ${({ theme }) => theme.fonts.AperturaMedium};
    font-size: ${props => props.theme.getResponsiveWidth(24)};

    @media (max-width: 1023px) {
      font-size: 16px;
    }

    &.first-column {
      color: ${({ theme }) => theme.blueRoyal};
    }

    &.second-column {
      color: ${({ theme }) => theme.blueDeep};
    }

    &.third-column {
      color: ${({ theme }) => theme.blueSky};
    }
    &.lilac {
      color: ${({ theme }) => theme.blueLilac};
    }
    &.blue-light {
      color: ${({ theme }) => theme.blueLight};
    }
    &.green-medium {
      color: ${({ theme }) => theme.greenMedium};
    }
    &.green-dark {
      color: ${({ theme }) => theme.greenDark};
    }
    &.red-light {
      color: ${({ theme }) => theme.redLight};
    }
    &.red-medium {
      color: ${({ theme }) => theme.redMedium};
    }
  }
`;
