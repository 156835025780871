import styled from 'styled-components';

export const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ theme }) => theme.getResponsiveWidth(719)};
`;

export const ProgressBarWrapper = styled.div`
  display: flex;
  width: 100%;
  height: ${({ theme }) => theme.getResponsiveHeight(32)};
  border-radius: ${({ theme }) => theme.getResponsiveWidth(4)};
  overflow: hidden;
`;
export const Progress = styled.div`
  display: flex;
  height: auto;
  border-radius: ${({ theme }) => theme.getResponsiveWidth(4)};
  overflow: hidden;
  width: ${({ theme }) => theme.getResponsiveWidth(627)};
`;

export const ProgressSegment = styled.div`
  height: 100%;
  width: ${({ width }) => width}%;
  background-color: ${props => props.color};
`;

export const ProgressLabels = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${({ theme }) => theme.getResponsiveWidth(719)};
  margin-top: ${({ theme }) => theme.getResponsiveHeight(8)};
`;

export const ProgressLabel = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const ProgressTotal = styled.div`
  margin-left: ${({ theme }) => theme.getResponsiveWidth(10)};
`;
