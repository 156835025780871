import styled from "styled-components";

export const ModalFooter = styled.div`
  width: 100%;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;

  @media (max-width: 1440px) {
    gap: 24px;
  }

  @media (max-width: 1023px) {
    gap: 16px;
  }
`;