import React from 'react';
import { StyledIcon } from './styles';

function PassiveIcon({ className }) {
  return (
    <StyledIcon
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.884 14.02c-.389-1.335-2.37-2.538-4.748-1.637-2.378.9-2.755 3.797.84 4.106 1.627.139 2.686-.162 3.656.688.972.85 1.152 3.214-1.33 3.85-2.479.638-4.936-.357-5.202-1.77M12.67 10.8v1.272m0 9.151V22.5"
        stroke="#B1113B"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.4 16.2c0-6.462-5.238-11.7-11.7-11.7C6.238 4.5 1 9.738 1 16.2c0 6.462 5.238 11.7 11.7 11.7.457 0 .907-.026 1.35-.077"
        stroke="#B1113B"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        d="M19.23 25.5v-6h5.54v6H28l-6 6-6-6h3.23z"
        stroke="#B1113B"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledIcon>
  );
}

export default PassiveIcon;
