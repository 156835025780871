import styled from 'styled-components';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

export const StyledAddIcon = styled.svg`
  &.receipt-table {
    width: 37px;
    height: 37px;
    border: 1px solid ${({ theme }) => theme.grayShadow};
    padding: 6px;
    border-radius: 8px;
    fill: ${({ theme }) => theme.grayShadow};
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.grayOpacity};
      border: none;

      path {
        fill: ${({ theme }) => theme.white};
      }
    }
  }

  &.simple-modal {
    width: 19px;
    height: 21px;
    cursor: pointer;
    fill: ${({ theme }) => theme.blueMidnight};
    border: 1px solid ${({ theme }) => theme.blueMidnight};
    border-radius: 4px;

    &.add-modal {
      width: 24px;
      height: 24px;
      padding: 2px;
      cursor: auto;
    }

    &.receipt {
      path {
        fill: ${({ theme }) => theme.blueHeaderWithSlowOpacity};
        stroke: ${({ theme }) => theme.blueHeaderWithSlowOpacity};
      }
    }

    &.spendings {
      fill: ${({ theme }) => theme.blueSky};
      stroke: ${({ theme }) => theme.blueSky};
    }
  }

  &.loading {
    width: 37px;
    height: 37px;
    padding: 6px;
    border-radius: 8px;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.gradientSkeleton[0]} 25%,
      ${({ theme }) => theme.gradientSkeleton[1]} 50%,
      ${({ theme }) => theme.gradientSkeleton[0]} 75%
    );
    background-size: 200% 100%;
    animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;

    path {
      fill: none;
    }
  }

  &.generic-dream {
    width: 24px;
    height: 24px;
    padding: 3px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.blueMidnight};

    path {
      fill: ${({ theme }) => theme.blueMidnight};
    }

    @media (max-width: 1023px) {
      width: 12.8px;
      height: 12.8px;
      padding: 1.6px;
      border-radius: 2.13px;
      border: 0.7px solid ${({ theme }) => theme.blueMidnight};
    }
  }
`;
