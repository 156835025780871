import styled from 'styled-components';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

export const StyledContainer = styled.div`
  &.home {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* height: 100px; */
  }

  &.doughnut-grafic-asset-management {
    width: min-content;
    height: min-content;
  }
`;

export const StyledSvg = styled.svg`
  display: flex;
  /* width: 100%; */
  /* height: 100%; */
  width: 100%;
  align-self: flex-end;
  &.doughnut-grafic-asset-management {
    width: 7vw;
    @media (max-width: 1023px) {
      width: 50.7px;
      height: 50.7px;
    }
  }
`;

export const StyledCircle = styled.circle`
  &.home {
    &.first {
      stroke: ${({ theme }) => theme.blueRoyal};
    }

    &.second {
      stroke: ${({ theme }) => theme.blueDeep};
    }

    &.third {
      stroke: ${({ theme }) => theme.blueSky};
    }

    &.loading {
      background: linear-gradient(
        90deg,
        ${({ theme }) => theme.gradientSkeleton[0]} 25%,
        ${({ theme }) => theme.gradientSkeleton[1]} 50%,
        ${({ theme }) => theme.gradientSkeleton[0]} 75%
      );
      background-size: 200% 100%;
      animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;
    }
  }

  &.doughnut-grafic-asset-management {
    &.first {
      stroke: ${({ theme }) => theme.blueRoyal};
    }

    &.second {
      stroke: ${({ theme }) => theme.blueDeep};
    }
  }
`;

export const StyledPercentage = styled.text`
  &.home,
  &.doughnut-grafic-asset-management {
    font-family: ${({ theme }) => theme.fonts.AperturaRegular};
    fill: ${({ theme }) => theme.black};
    font-size: 1.5rem;
    text-anchor: middle;
  }

  &.doughnut-grafic-asset-management {
    fill: ${({ theme }) => theme.blueRoyal};

    &.hovered {
      fill: ${({ theme }) => theme.white};
    }
  }
`;
