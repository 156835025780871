import React from 'react';
import { InvestmentsTable } from 'components/templates/Table/InvestmentsTable';

export const MapInvestmentsPage = ({ investments }) => {

  const tooltipMessage = "Meta de Investimento Mensal: é o valor que deve ser investido por mês para alcançar os objetivos almejados"

  return (
    <>
      <InvestmentsTable
        body={investments.financial_independence_goals_detail.data}
        nameTable="Independência Financeira"
        tooltipText={tooltipMessage}
        showTooltip={true}
        totalCategoryPercentage={investments.financial_independence_goals_detail.percentage}
        totalCategoryValue={investments.financial_independence_goals_detail.total}
      />
      <InvestmentsTable
        body={investments.default_goals.data}
        nameTable="Sonhos"
        tooltipText={tooltipMessage}
        showTooltip={true}
        totalCategoryPercentage={investments.default_goals.percentage}
        totalCategoryValue={investments.default_goals.total}
      />
    </>
  );
};
