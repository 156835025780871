import styled from 'styled-components';

export const ModalBody = styled.div`
  font-size: 0.9rem;
  text-align: center;
  margin: 38px 0;

  &.modal {
    display: flex;
  }

  &.generic-dream {
    margin: 0;
    display: flex;
    gap: ${props => props.theme.getResponsiveHeight(48)};

    @media (max-width: 1024px) {
      gap: 32.78px;
    }
  }

  &.gallery-modal {
    display: flex;
    gap: ${props => props.theme.getResponsiveWidth(8)};
    flex-wrap: wrap;
    width: ${props => props.theme.getResponsiveWidth(349)};
    height: ${props => props.theme.getResponsiveWidth(172)};
    overflow: auto;
    margin: ${({ theme }) => theme.getResponsiveWidth(18)} 0 0 0;

    @media (max-width: 1023px) {
      gap: 4.27px;
      width: 186.13px;
      height: 91.73px;
      margin: 9.6px 0 0 0;
    }
  }

  &.extract-modal {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (max-width: 1440px) {
      gap: 16px;
    }

    @media (max-width: 1024px) {
      gap: 12px;
    }
  }

  @media (max-width: 1440px){
    font-size: 0.8rem;
    line-height: 0.8rem;
    margin: 28px 0;
  }

  @media (max-width: 1024px){
    font-size: 0.7rem;
    line-height: 0.7rem;
    margin: 24px 0;
  }
`;
