import * as React from "react";
import { StyledContainer, StyledVerifiedIcon } from "./styles";

export const VerifiedIcon = ({
    className = '',
    onClick = () => { },
}) => {
    return (
        <StyledContainer className={className} onClick={onClick}>
            <StyledVerifiedIcon
                width={19}
                height={18}
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <path
                    d="M8.245 1.608a1.55 1.55 0 012.118 0l.712.666c.434.406 1 .64 1.593.66l.975.032a1.55 1.55 0 011.498 1.498l.032.975c.02.594.254 1.16.66 1.593l.667.713a1.55 1.55 0 010 2.118l-.667.712c-.406.434-.64 1-.66 1.593l-.032.975a1.55 1.55 0 01-1.498 1.498l-.975.032a2.45 2.45 0 00-1.593.66l-.712.667a1.55 1.55 0 01-2.118 0l-.713-.667a2.45 2.45 0 00-1.593-.66l-.975-.032a1.55 1.55 0 01-1.498-1.498l-.032-.975a2.45 2.45 0 00-.66-1.593l-.666-.712a1.55 1.55 0 010-2.118l.666-.713c.406-.433.64-1 .66-1.593l.032-.975a1.55 1.55 0 011.498-1.498l.975-.032a2.45 2.45 0 001.593-.66l.713-.666z"
                    stroke="#fff"
                    strokeWidth={0.9}
                    strokeLinejoin="round"
                />
                <path
                    d="M6.474 9.432l1.572 1.887 4.402-4.402"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </StyledVerifiedIcon>
        </StyledContainer>
    )
}
