import { DoughnutGrafic } from "components/atoms/DoughnutGrafic";
import React from "react";

export const NavigationCardDoughnutGrafic = ({
    className = '',
    percentage = 0,
}) => {
    return (
        <DoughnutGrafic
            className={className}
            percentage={percentage}
            loading={false}
        />
    )
}