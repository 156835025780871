import styled from 'styled-components';
import { H6 } from 'components/atoms/Headings/H6';

export const StyledContainer = styled.div`
  width: auto;
`;

export const StyledH6 = styled(H6)`
  @media (max-width: 1023px) {
    display: none;
  }
`;

export const StyledUpIcon = styled.div`
  display: inline-block;
  margin-left: 30%;

  @media (max-width: 1023px) {
    margin-left: 0;
  }
`;
