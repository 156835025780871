import React from 'react';
import { StyledIcon } from './styles';

function YearIcon({ className }) {
  return (
    <StyledIcon
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13 0a13 13 0 1013 13A13.013 13.013 0 0013 0zm0 24a11 11 0 1111-11 11.012 11.012 0 01-11 11zm8-11a1 1 0 01-1 1h-7a1 1 0 01-1-1V6a1 1 0 012 0v6h6a1 1 0 011 1z"
        fill="#140361"
      />
    </StyledIcon>
  );
}

export default YearIcon;
