import React from "react";
import {
    Container,
    GroupItens
} from './styles'
import { DetailedItem } from "components/molecules/DetailedItem";
import { DetailedExtractFooter } from "components/molecules/DetailedExtractFooter";
import { GenericLoading } from "components/atoms/GenericLoading";

export const DetailedExtract = ({
    items = {},
    currentPage = '',
    loading = false,
    setShowEditItemModal = () => { },
    setItemData = () => { },
}) => {

    const containerRef = React.useRef(null);
    const [currentItems, setCurrentItems] = React.useState(items.data || []);
    const [loadingItems, setLoadingItems] = React.useState(false);

    const addNewItems = () => {
        setLoadingItems(true);

        setTimeout(() => {
            const newItems = currentItems.concat(items.data);
            setCurrentItems(newItems);
            setLoadingItems(false);
        }, 5000);

    }

    const handleScroll = () => {
        if (!containerRef.current) return;

        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

        if (scrollTop + clientHeight >= scrollHeight - 5 && !loading) {
            addNewItems();
        }
    };

    if (loading) {
        return (
            <Container className="main">
                <GroupItens>
                    {Array.from({ length: 4 }, (_, index) => (
                        <GenericLoading key={index} className="detailed-extract-item" />
                    ))}
                </GroupItens>
                <GenericLoading className="detailed-extract-footer" />
            </Container>
        )
    }

    return (
        <Container className="main">
            <GroupItens ref={containerRef} onScroll={handleScroll}>
                {currentItems.map((item, index) => (
                    <DetailedItem
                        key={index}
                        categoryName={item.categoryName}
                        categoryItemName={item.categoryItemName}
                        releaseDate={item.releaseDate}
                        value={item.value}
                        paymentMethod={item.paymentMethod}
                        installments={item.installments}
                        className="detailed-extract-item"
                        classification={item.classification}
                        type={item.type}
                        inInstallments={item.inInstallments}
                        setShowEditItemModal={setShowEditItemModal}
                        setItemData={setItemData}
                        itemData={item}
                    />
                ))}
                {loadingItems && (
                    Array.from({ length: 3 }, (_, index) => (
                        <GenericLoading key={index} className="detailed-extract-item-loading" />
                    ))
                )}
            </GroupItens>
            <DetailedExtractFooter
                text="Total"
                value={items.total || 0}
                className={`detailed-extract-footer ${currentPage}`}
            />
        </Container>
    )
}