import * as React from 'react';
import { StyledCloseIcon } from './styles';

function CloseIcon({ className }) {
  return (
    <StyledCloseIcon
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16 15.342l11 11m0-11l-11 11"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </StyledCloseIcon>
  );
}

export default CloseIcon;
