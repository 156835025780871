import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: max-content;
    background-color: ${props => props.theme.white};
    margin-top: ${props => props.theme.getResponsiveWidth(34)};
    margin-bottom: ${props => props.theme.getResponsiveWidth(43)};
    padding-top: ${props => props.theme.getResponsiveWidth(45)};
    padding-left: ${props => props.theme.getResponsiveWidth(43)};
    padding-bottom: ${props => props.theme.getResponsiveWidth(45)};
    border-radius: ${props => props.theme.getResponsiveWidth(16)};

    @media (max-width: 1023px) {
        margin-top: 18.1px;
        margin-bottom: 22.9px;
        padding-top: 24px;
        padding-left: 22.9px;
        padding-bottom: 24px;
        border-radius: 8.5px;
    }
`;

export const GraphContainer = styled.div`
    margin-top: ${props => props.theme.getResponsiveWidth(32)};
    display: flex;
    gap: ${props => props.theme.getResponsiveWidth(32)};
    overflow: auto;

    @media (max-width: 1023px) {
        margin-top: 17.1px;
        gap: 17.1px;
    }
`;