import styled from "styled-components";

export const Container = styled.div`
    &.gallery-modal {
        width: ${props => props.theme.getResponsiveWidth(111)};
        height: ${props => props.theme.getResponsiveWidth(82)};
        padding-left: ${props => props.theme.getResponsiveWidth(28)};
        padding-top: ${props => props.theme.getResponsiveWidth(20)};
        padding-bottom: ${props => props.theme.getResponsiveWidth(21)};
        padding-right: ${props => props.theme.getResponsiveWidth(27)};
        border-radius: ${props => props.theme.getResponsiveWidth(8)};
        border: ${props => props.theme.getResponsiveWidth(1)} dotted ${props => props.theme.graySilver};
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        @media (max-width: 1023px) {
            width: 59.2px;
            height: 43.74px;
            padding-left: 14.94px;
            padding-top: 10.7px;
            padding-bottom: 11.2px;
            padding-right: 14.4px;
            border-radius: 4.27px;
            border: 0.53px dotted ${props => props.theme.graySilver};
        }
    }
`;

export const Group = styled.div`
    &.gallery-modal {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: ${props => props.theme.getResponsiveWidth(56)};
        height: ${props => props.theme.getResponsiveWidth(41)};

        @media (max-width: 1023px) {
            width: 29.87px;
            height: 21.87px;
        }
    }
`;