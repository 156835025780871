import React from "react";
import { UpArrowContainer, StyledUpArrow } from "./styles";

export const UpArrowIcon = ({
    className = 'default',
    onClick = () => { },
}) => {
    return (
        <UpArrowContainer className={className} onClick={onClick}>
            <StyledUpArrow
                width={6}
                height={4}
                viewBox="0 0 6 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <path d="M5.5 3L3 1 .5 3" stroke="#fff" strokeLinecap="round" />
            </StyledUpArrow>
        </UpArrowContainer>
    )
}