import React from "react";
import { H5 } from "components/atoms/Headings/H5";
import { Line, Dropdown } from './styles';
import { GeneralContext } from "utils/contexts/GeneralContext";
import ArrowDropdown from "components/atoms/icons/ArrowDropdown";

export const HeaderBudgetMapList = ({
    children,
    className,
    loadingClassName,
}) => {
    const { loading } = React.useContext(GeneralContext);
    const [dropdownOpen, setDropdownOpen] = React.useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <>
            <H5 className={` ${loading ? 'map-header-loading' : 'map-header map-border'}`}>
                Mapear
            </H5>
            <Line onClick={toggleDropdown}>
                <H5 className={` ${loading ? 'map-header-loading' : 'map-header'}`}>
                    Planejar
                </H5>
                <ArrowDropdown className={` ${loading ? 'loading' : ''}`} />
                {dropdownOpen && (
                    <Dropdown>
                        <p>Dropdown planejar</p>
                    </Dropdown>
                )}
            </Line>
            <H5 className={` ${loading ? 'map-header-loading' : 'map-header'}`}>
                Atuar
            </H5>
        </>
    )
}