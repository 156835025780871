import React from 'react';
import { StyledLegend, LegendColor, LegendText, Loading } from './styles';

export const SubtitleGraphic = ({ loading, subtitleText, className }) => {
  return (
    <StyledLegend className={className}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <LegendColor className={className} />
          <LegendText className={className}>{subtitleText}</LegendText>
        </>
      )}
    </StyledLegend>
  );
};
