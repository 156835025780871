import React from 'react';
import { StyledIcon } from './styles';

function MoneyBagIcon() {
  return (
    <StyledIcon
      viewBox="0 0 28 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.12 21.769a22.065 22.065 0 00-6.266-12.062c-.724-.705-1.085-1.056-1.885-1.38C17.17 8 16.483 8 15.11 8h-3.018c-1.374 0-2.061 0-2.86.326-.798.325-1.162.676-1.885 1.38A22.064 22.064 0 001.08 21.77C.398 25.882 4.192 29 8.43 29H18.77c4.24 0 8.036-3.118 7.35-7.231"
        stroke="#140361"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.878 16.287c-.303-1.119-1.844-2.127-3.694-1.372-1.849.754-2.141 3.18.654 3.44 1.266.116 2.09-.136 2.844.576a1.939 1.939 0 01-1.035 3.226c-1.928.533-3.839-.3-4.046-1.484m2.778-7.084v1.065m0 7.667v1.07M6.959 4.42c-.29-.42-.708-.992.157-1.12.89-.135 1.812.472 2.716.461.817-.012 1.232-.375 1.68-.893C11.982 2.324 12.711 1 13.6 1s1.617 1.324 2.087 1.869c.448.518.864.882 1.68.892.905.014 1.827-.595 2.716-.462.866.13.447.7.157 1.12L18.935 6.32c-.56.812-.838 1.218-1.423 1.45-.586.23-1.34.229-2.85.229h-2.123c-1.512 0-2.267 0-2.85-.23-.585-.23-.865-.638-1.425-1.45L6.96 4.42z"
        stroke="#140361"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledIcon>
  );
}

export default MoneyBagIcon;
