import React from "react";
import { Container } from "./styles";

export const DreamCardContainer = ({
    children,
    className = '',
    backgroundImage = '',
    onClick = () => { },
}) => {
    return (
        <Container className={className} backgroundImage={backgroundImage} onClick={onClick}>
            {children}
        </Container>
    )
}