import * as React from "react"
import { SVG } from "./styles"

export const LeisureCategoryIcon = ({
    className = '',
}) => {
    return (
        <SVG
            className={className}
            width={19}
            height={19}
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.25 7.833l2.732-2.571.015-.016a3 3 0 10-4.243-4.243c0 .006-.01.01-.016.016l-2.57 2.732L3.387.919a.75.75 0 00-.787.175L.35 3.344a.75.75 0 00.114 1.154l5.99 3.993-1.39 1.384H3.126a.75.75 0 00-.53.22l-2.25 2.25a.75.75 0 00.249 1.226l3.452 1.38 1.378 3.446.005.015a.75.75 0 001.236.232l2.238-2.239a.75.75 0 00.222-.53v-1.94l1.384-1.383 3.993 5.99a.75.75 0 001.154.114l2.25-2.25a.75.75 0 00.174-.787l-2.83-7.786zm-.007 9.115l-3.993-5.989a.75.75 0 00-.55-.334h-.074a.75.75 0 00-.53.22l-2.25 2.25a.75.75 0 00-.221.53v1.94L6.4 16.79l-1.078-2.696a.75.75 0 00-.416-.415L2.212 12.6l1.224-1.226h1.939a.75.75 0 00.53-.219l2.25-2.25a.75.75 0 00-.114-1.155L2.053 3.757l1.265-1.264 7.802 2.836a.75.75 0 00.803-.187l2.9-3.085a1.5 1.5 0 012.12 2.121l-3.08 2.9a.75.75 0 00-.188.802l2.837 7.802-1.269 1.266z"
                fill="#0C053F"
            />
        </SVG>
    )
}
