import ProgressBar from "components/atoms/ProgressBar";
import React from "react";

export const DreamCardProgressBar = ({
    className = '',
    progress = 0,
}) => {
    return (
        <ProgressBar
            className={className}
            progress={progress}
        />
    )
}