import * as React from "react";
import { StyledDirectArrowDown } from "./styles.jsx";

function DirectArrowDown(props) {
    return (
        <StyledDirectArrowDown
            viewBox="0 0 11 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M1 .5l4.5 4 4.5-4" stroke="#6C6C6C" strokeLinecap="round" />
        </StyledDirectArrowDown>
    )
}

export default DirectArrowDown
