import React from "react";
import {
    Container,
    IconContainer,
    TextContainer,
} from "./styles";
import { EatingOutCategoryIcon } from "components/atoms/icons/EatingOutCategoryIcon";
import { HousingCategoryIcon } from "components/atoms/icons/HousingCategoryIcon";
import { LifeAndHealthCategoryIcon } from "components/atoms/icons/LifeAndHealthCategoryIcon";
import { LeisureCategoryIcon } from "components/atoms/icons/LeisureCategoryIcon";
import { LegalEntityCategoryIcon } from "components/atoms/icons/LegalEntityCategoryIcon";
import { PersonalCategoryIcon } from "components/atoms/icons/PersonalCategoryIcon";
import { TransportCategoryIcon } from "components/atoms/icons/TransportCategoryIcon";
import { InvestmentBarChartCategoryIcon } from "components/atoms/icons/InvestmentBarChartCategoryIcon";
import { WalletCategoryIcon } from "components/atoms/icons/WalletCategoryIcon";
import { Span } from "components/atoms/Span";
import PencilIcon from "components/atoms/icons/PencilIcon";
import { AiOutlineReload } from "react-icons/ai";

export const DetailedItem = ({
    classification = '',
    inInstallments = false,
    className = '',
    categoryName = '',
    categoryItemName = '',
    releaseDate = 'dd/mm/aa',
    value = '',
    installments = '',
    paymentMethod = '',
    type = '',
    setShowEditItemModal = () => { },
    setItemData = () => { },
    itemData,
}) => {

    const categoryIcons = {
        eatingOut: <EatingOutCategoryIcon className="extract-title item" />,
        housing: <HousingCategoryIcon className="extract-title item" />,
        lifeAndHealth: <LifeAndHealthCategoryIcon className="extract-title item" />,
        leisure: <LeisureCategoryIcon className="extract-title item" />,
        legalEntity: <LegalEntityCategoryIcon className="extract-title item" />,
        personal: <PersonalCategoryIcon className="extract-title item" />,
        transport: <TransportCategoryIcon className="extract-title item" />,
        investment: <InvestmentBarChartCategoryIcon className="extract-title item" />,
        wallet: <WalletCategoryIcon className="extract-title item" />
    };

    const colorBase = type === 'spendings' ? 'spending' : type === 'receipts' ? 'wallet' : 'investment';
    const currentIcon = type === 'spendings' ? categoryIcons[classification] : categoryIcons[colorBase];
    const installmentsColor = inInstallments ? 'installment' : 'spending';

    const getPaymentMethodRow = (name, installments) => {
        const paymentIcons = {
            'Recorr.': <AiOutlineReload className={`${className} reload-icon`} />,
            'Parcelas': <Span className={`${className} body installments-header`}>{installments}</Span>,
        };

        return paymentIcons[name] || <Span className={`${className} body`}>{installments}</Span>;
    };

    const getPaymentMethodTitle = (paymentMethod) => {
        const paymentTitles = {
            'Parcelas': <Span className={`${className} title installments-header`}>{paymentMethod}</Span>,
        };

        return paymentTitles[paymentMethod] || <Span className={`${className} title`}>{paymentMethod}</Span>;
    };


    return (
        <Container className={className}>
            <IconContainer className={`${className} category ${colorBase} ${colorBase === 'spending' ? installmentsColor : ''}`}>
                {currentIcon}
            </IconContainer>
            <TextContainer className={`${className} first`}>
                <Span className={`${className} title`}>{categoryName}</Span>
                <Span className={`${className} body`}>{categoryItemName}</Span>
            </TextContainer>
            <TextContainer className={`${className} second`}>
                <Span className={`${className} title`}>Lançamento</Span>
                <Span className={`${className} body`}>{releaseDate}</Span>
            </TextContainer>
            <TextContainer className={`${className} third`}>
                <Span className={`${className} title`}>Valor</Span>
                <Span className={`${className} body`}>{value}</Span>
            </TextContainer>
            <TextContainer className={`${className} fourth`}>
                {getPaymentMethodTitle(paymentMethod, installments)}
                {getPaymentMethodRow(paymentMethod, installments)}
            </TextContainer>
            <PencilIcon
                className={className}
                onClick={() => {
                    const item = {
                        id: itemData?.id,
                        name: categoryItemName,
                        description: itemData?.description || '',
                        recurrent: itemData?.recurrent || false,
                        installmentValue: value,
                        startDate: itemData?.startDate ? new Date(itemData?.startDate) : null,
                        endDate: itemData?.endDate ? new Date(itemData?.endDate) : null,
                        classification: categoryName,
                    }

                    setItemData(item)
                    setShowEditItemModal(prev => !prev)
                }}
            />
        </Container>
    );
}