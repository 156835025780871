import React, { useMemo, useState } from 'react';
import PencilIcon from 'components/atoms/icons/PencilIcon';
import { EditSpendingItemModal } from 'components/molecules/BudgetModal/EditSpendingItemModal';
import { Card } from 'components/templates/Card';
import { Table } from 'components/templates/Tables';

export const TableReport = ({ data, key, type, title }) => {
  const [currentTableIndex] = useState(0);
  const [showEditItemModal, setShowEditItemModal] = useState(false);
  const [tableData, setTableData] = useState(data.body);

  const selectedItemKey = key;

  const columnOrder1 = ['name', 'valueEstimated'];
  const columnOrder2 = [
    'month1',
    'month2',
    'month3',
    'month4',
    'month5',
    'month6',
  ];
  const columnOrder3 = ['average', 'sum'];

  const getCurrentColumnOrder = () => {
    const columnOrders = {
      0: columnOrder1,
      1: columnOrder2,
      2: columnOrder3,
    };

    return columnOrders[currentTableIndex] || [];
  };

  const { nonTotalRows, totalRow } = useMemo(() => {
    const nonTotalRows = tableData.filter(row => row.name !== 'Total');
    const totalRow = tableData.find(row => row.name === 'Total');
    return { nonTotalRows, totalRow };
  }, [tableData]);

  const handleEditClick = item => {
    localStorage.setItem(selectedItemKey, JSON.stringify(item));
    setShowEditItemModal(true);
  };

  const editItem = () => {
    const editedItem = JSON.parse(localStorage.getItem(selectedItemKey));

    const reworkedItem = {
      ...editedItem,
    };

    const updatedTableData = tableData.map(item =>
      item.id === editedItem.id ? reworkedItem : item
    );

    setTableData(updatedTableData);
    localStorage.removeItem(selectedItemKey);
    setShowEditItemModal(false);
  };

  return (
    <Card.Container className="spending-table">
      <Table.TitleSet nameTable={title} className="patrimony none" />
      <Card.Container className="table-recom ">
        <Table.Container>
          <Table.TableBasic className="first-table">
            <Table.Thead>
              <Table.Tr className={`receipt-table-title ${type}`}>
                <Table.Th className="budget-table-title first-table-border" />
                {data.header.slice(0, 2).map((headerItem, index) => (
                  <Table.Th
                    key={index}
                    className={`budget-table-title ${index === 0 &&
                      'checkbox'}`}
                  >
                    {headerItem}
                  </Table.Th>
                ))}
                <Table.Th className="budget-table-title empty last-table-border" />
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {nonTotalRows.map((column, rowIndex) => (
                <Table.Tr className="receipt-table-body" key={rowIndex}>
                  <Table.Td>
                    <PencilIcon
                      className="category-title"
                      onClick={() => handleEditClick(column)}
                    />
                  </Table.Td>
                  {getCurrentColumnOrder().map((item, index) => {
                    const currentItem = column[item];
                    return (
                      <Table.Td
                        className={`receipt-table-body ${item === 'name' &&
                          'first-table-border checkbox'}`}
                        key={index}
                      >
                        {currentItem}
                      </Table.Td>
                    );
                  })}
                </Table.Tr>
              ))}
              <Table.Tr>
                <Table.Td className={` total-report blue-opacity`} />
                <Table.Td className="total-report blue-opacity first">
                  {totalRow.name}
                </Table.Td>
                <Table.Td className={`total-report center `}>
                  {totalRow.valueEstimated}
                </Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table.TableBasic>
        </Table.Container>
        <Table.Container>
          <Table.TableBasic className="second-table">
            <Table.Thead>
              <Table.Tr className={`receipt-table-title ${type}`}>
                {data.header.slice(2, 8).map((headerItem, index) => (
                  <Table.Th
                    key={index}
                    className={`budget-table-title ${index === 0 &&
                      'first-table-border'}`}
                  >
                    {headerItem}
                  </Table.Th>
                ))}
                <Table.Th className="budget-table-title empty last-table-border" />
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {nonTotalRows.map((column, rowIndex) => (
                <Table.Tr className="receipt-table-body" key={rowIndex}>
                  {columnOrder2.map((item, index) => (
                    <Table.Td
                      className={`receipt-table-body blue-opacity ${item ===
                        'name' && 'first-table-border'}`}
                      key={index}
                    >
                      {column[item]}
                    </Table.Td>
                  ))}
                </Table.Tr>
              ))}
              {totalRow && (
                <Table.Tr className="total-row">
                  {columnOrder2.map((item, index) => (
                    <Table.Td
                      key={index}
                      className="total-report blue-opacity center"
                    >
                      {totalRow[item]}
                    </Table.Td>
                  ))}
                </Table.Tr>
              )}
            </Table.Tbody>
          </Table.TableBasic>
        </Table.Container>
        <Table.Container>
          <Table.TableBasic className="third-table">
            <Table.Thead>
              <Table.Tr className={`receipt-table-title ${type}`}>
                {data.header.slice(8).map((headerItem, index) => (
                  <Table.Th
                    key={index}
                    className={`budget-table-title ${index === 0 &&
                      'first-table-border'}`}
                  >
                    {headerItem}
                  </Table.Th>
                ))}
                <Table.Th className="budget-table-title empty last-table-border" />
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {nonTotalRows.map((column, rowIndex) => (
                <Table.Tr className="receipt-table-body" key={rowIndex}>
                  {columnOrder3.map((item, index) => (
                    <Table.Td
                      className={`receipt-table-body ${item === 'name' &&
                        'first-table-border'}`}
                      key={index}
                    >
                      {column[item]}
                    </Table.Td>
                  ))}
                </Table.Tr>
              ))}
              {totalRow && (
                <Table.Tr className="total-row">
                  {columnOrder3.map((item, index) => (
                    <Table.Td key={index} className="total-report center">
                      {totalRow[item]}
                    </Table.Td>
                  ))}
                </Table.Tr>
              )}
            </Table.Tbody>
          </Table.TableBasic>
        </Table.Container>
      </Card.Container>
      {showEditItemModal && (
        <EditSpendingItemModal
          showModal={showEditItemModal}
          setShowModal={setShowEditItemModal}
          selectedItem={JSON.parse(localStorage.getItem(selectedItemKey))}
          title="Editar recebimento"
          onCancel={editItem}
          selectedItemKey={selectedItemKey}
          icon="edit"
          theme="edit"
          classificationOptions={data.itemClassification}
        />
      )}
    </Card.Container>
  );
};
