import styled from "styled-components";

export const Container = styled.div`
    &.financial-assets {
        width: ${props => props.theme.getResponsiveWidth(320)};
        height: ${props => props.theme.getResponsiveWidth(320)};
        margin-top: ${props => props.theme.getResponsiveWidth(72)};
        margin-left: ${props => props.theme.getResponsiveWidth(93)};

        @media (max-width: 1023px) {
            width: 171px;
            height: 171px;
            margin-top: 38.4px;
            margin-left: 49.6px;
        }
    }
`;