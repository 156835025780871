export const getSpendingClassificationOptions = () => {
    return [
        { value: 'Moradia', label: 'Moradia' },
        { value: 'Transporte', label: 'Transporte' },
        { value: 'Vida e Saúde', label: 'Vida e Saúde' },
        { value: 'Lazer', label: 'Lazer' },
        { value: 'Alimentação Fora de Casa', label: 'Alimentação Fora de Casa' },
        { value: 'Pessoa Jurídica', label: 'Pessoa Jurídica' },
        {
            value: 'Pessoa Física - Cliente Yuno',
            label: 'Pessoa Física - Cliente Yuno',
        },
    ]
}

export const getItemsClassificationOptions = () => {
    return [
        { value: 'Mensal Comprometido', label: 'Mensal Comprometido' },
        { value: 'Mensal Flexível', label: 'Mensal Flexível' },
        { value: 'Eventual Comprometido', label: 'Eventual Comprometido' },
        { value: 'Eventual Flexível', label: 'Eventual Flexível' },
    ]
}

export const getReceiptsClassificationOptions = () => {
    return [
        {
            label: 'Recebimento',
            value: 'Recebimento'
        }
    ]
}