import React from 'react';
import { StyledAvatarContainer } from './styles';
import { H6 } from '../Headings/H6';

export const Avatar = ({ className, children }) => {
  return (
    <StyledAvatarContainer className={className}>
      <H6 className={`${className} apertura-medium`}>{children}</H6>
    </StyledAvatarContainer>
  );
};
