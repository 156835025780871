import styled from 'styled-components';

export const SVG = styled.svg`
  &.icon-color-gray-4 {
    path {
      fill: ${({ theme }) => theme.graySilver};
    }
  }

  &.invisible {
    display: none;
  }

  &.header {
    width: 23px;
    height: 24px;
  }

  @media (max-width: 1440px) {
    &.header {
      width: 20px;
      height: 21px;
    }
  }

  @media (max-width: 768px) {
    &.header {
      width: 18px;
      height: 19px;
    }
  }
`;
