import * as React from "react";
import { GrowingUpIconContainer } from "./styles";

export const GrowingUpIcon = ({
    className,
}) => {
    return (
        <GrowingUpIconContainer
            width={32}
            height={22}
            viewBox="0 0 32 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M.44 21.077c.293.281.618.423.947.423.288 0 .582-.109.862-.326.158-.122.287-.261.411-.397l9.57-10.087a10 10 0 01.246-.25c.084.082.164.162.242.244.839.883 1.678 1.768 2.515 2.652l1.976 2.085c.978 1.028 1.634 1.034 2.6.016l9.377-9.886c0 .158 0 .316.002.474.004.725.008 1.452.018 2.177.012.95.565 1.597 1.372 1.611.376 0 .723-.135.978-.396.278-.287.434-.71.436-1.187.01-1.987.012-4.04 0-6.108-.006-1.037-.56-1.612-1.565-1.616a665.708 665.708 0 00-5.728 0c-.902.004-1.517.585-1.532 1.444-.006.4.12.751.366 1.018.282.306.695.47 1.193.476.543.006 1.087.004 1.632.004h.662l-8.606 9.071-.11-.113-1.846-1.946c-.911-.96-1.824-1.924-2.737-2.883-.91-.954-1.59-.948-2.51.02-3.516 3.7-7.03 7.407-10.544 11.113l-.063.065c-.091.095-.185.191-.272.31-.48.651-.436 1.47.107 1.992H.44z"
                fill="#140361"
            />
        </GrowingUpIconContainer>
    )
}
