import * as React from "react"
import { SVG } from "./styles"

export const TransportCategoryIcon = ({
    className,
}) => {
    return (
        <SVG
            className={className}
            viewBox="0 0 23 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.767 6.652h-.99L18.23.878a1.475 1.475 0 00-.54-.64 1.458 1.458 0 00-.8-.238H6.11c-.284 0-.561.083-.8.239a1.475 1.475 0 00-.54.639L2.223 6.652h-.99a.73.73 0 00-.518.217.742.742 0 00.518 1.261h.734v7.392c0 .392.154.768.43 1.045A1.46 1.46 0 003.432 17h2.2a1.46 1.46 0 001.037-.433c.275-.277.43-.653.43-1.045v-1.479h8.8v1.479c0 .392.155.768.43 1.045a1.46 1.46 0 001.037.433h2.2a1.46 1.46 0 001.037-.433c.275-.277.43-.653.43-1.045V8.13h.733a.73.73 0 00.518-.216.742.742 0 000-1.045.73.73 0 00-.518-.217zM6.11 1.478h10.78l2.282 5.174H3.828L6.11 1.478zm-.477 14.044h-2.2v-1.479h2.2v1.479zm11.734 0v-1.479h2.2v1.479h-2.2zm2.2-2.957H3.433V8.13h16.134v4.435zM4.9 10.348c0-.196.077-.384.215-.523a.73.73 0 01.518-.216H7.1a.73.73 0 01.519.216.742.742 0 01-.519 1.262H5.633a.73.73 0 01-.518-.216.742.742 0 01-.215-.523zm10.267 0c0-.196.077-.384.215-.523a.73.73 0 01.518-.216h1.467a.73.73 0 01.518.216.742.742 0 010 1.046.73.73 0 01-.518.216H15.9a.73.73 0 01-.518-.216.742.742 0 01-.215-.523z"
                fill="#0C053F"
            />
        </SVG>
    )
}
