import styled from "styled-components";

export const Container = styled.div`
    &.realized-generic-dream {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const StyledIcon = styled.svg`
    &.realized-generic-dream {
        width: ${props => props.theme.getResponsiveWidth(51)};
        height: ${props => props.theme.getResponsiveWidth(51)};
        margin-bottom: ${props => props.theme.getResponsiveWidth(16)};

        @media (max-width: 1023px){
            width: 27.2px;
            height: 27.2px;
            margin-bottom: 8.53px;
        }
    }
`;