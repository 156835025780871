import styled from 'styled-components';

export const StyledIcon = styled.svg`
  width: ${props => props.theme.getResponsiveWidth(31)};
  height: ${props => props.theme.getResponsiveHeight(31)};
  min-width: 14.4px;
  min-height: 14.4px;

  &.selected path {
    fill: ${({ theme }) => theme.white};
  }
`;
