import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js';
import { ChartContainer } from './styles';
import { getGraphData } from 'development/retirement';
import light from 'styles/themes/light';
import { formatOutputMoney } from 'utils/numbers';

const GraficBarLine = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    const graphData = getGraphData();

    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: graphData.labels,
        datasets: [
          {
            ...graphData.datasets[0],
            type: 'bar',
            backgroundColor: light.blueSky,
            borderColor: light.blueSky,
            borderWidth: 1,
            borderRadius: 5,
            barPercentage: 0.8,
            categoryPercentage: 0.6,
            stack: 'Projetado',
            order: 1,
          },
          {
            ...graphData.datasets[1],
            type: 'bar',
            backgroundColor: light.blueRoyal,
            borderColor: light.blueRoyal,
            borderWidth: 1,
            borderRadius: 5,
            barPercentage: 0.8,
            categoryPercentage: 0.6,
            stack: 'Projetado',
            order: 2,
          },
          {
            ...graphData.datasets[2],
            type: 'bar',
            backgroundColor: light.opacityBlueLight,
            borderColor: light.opacityBlueLight,
            borderWidth: 1,
            borderRadius: 5,
            barPercentage: 0.8,
            categoryPercentage: 0.6,
            stack: 'Alcançado',
            order: 3,
          },
          {
            ...graphData.datasets[3],
            type: 'bar',
            backgroundColor: light.opacityBlueSky,
            borderColor: light.opacityBlueSky,
            borderWidth: 1,
            borderRadius: 5,
            barPercentage: 0.8,
            categoryPercentage: 0.6,
            stack: 'Alcançado',
            order: 4,
          },
          {
            ...graphData.datasets[4],
            type: 'line',
            borderColor: light.greenBrave,
            backgroundColor: light.grayGradient,
            fill: false,
            tension: 0.4,
            borderWidth: 2,
            pointRadius: 0,
            order: 5,
          },
          {
            ...graphData.datasets[5],
            type: 'line',
            borderColor: light.grayLine,
            backgroundColor: light.backgroundLilac,
            fill: true,
            tension: 0.4,
            borderWidth: 2,
            borderDash: [10, 5],
            pointRadius: 0,
            order: 6,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true,
                color: light.gradeColor,
                borderDash: [3, 3],
              },
              ticks: {
                fontSize: 12,
                fontColor: light.grayShadow,
                fontStyle: 'normal',
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: light.gradeColor,
                borderDash: [3, 3],
              },
              ticks: {
                display: true,
                fontSize: 12,
                fontColor: light.grayShadow,
                fontStyle: 'normal',

                callback: function(value) {
                  return formatOutputMoney(value);
                },
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
        legend: false,
      },
    });
  }, []);

  return (
    <ChartContainer>
      <canvas ref={chartRef} />
    </ChartContainer>
  );
};

export default GraficBarLine;
