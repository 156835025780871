import * as React from "react";
import { DollarSignIconContainer } from "./styles";

export const DollarSignIcon = ({
    className,
}) => {
    return (
        <DollarSignIconContainer
            viewBox="0 0 27 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M1 14a12.5 12.5 0 1024.999 0A12.5 12.5 0 001 14z"
                stroke="#140361"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="teste"
            />
            <path
                d="M17.39 9.834a2.778 2.778 0 00-2.5-1.39H12.11a2.778 2.778 0 100 5.556h2.778a2.778 2.778 0 010 5.556h-2.778a2.779 2.779 0 01-2.5-1.39m3.89-11.11v13.889"
                stroke="#140361"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </DollarSignIconContainer>
    )
}
