import React from "react";
import BellIcon from "components/atoms/icons/BellIcon";
import { GeneralContext } from "utils/contexts/GeneralContext";

export const HeaderNotifications = ({ children, className, loadingClassName }) => {
    const { loading } = React.useContext(GeneralContext);

    return (
        <BellIcon
            className={`${className} ${loading ? loadingClassName : ''}`}
        />
    )
}