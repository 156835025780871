import * as React from 'react';
import { Container } from './styles';

function SaveIcon({ className, onClick }) {
  return (
    <Container
      className={className}
      onClick={onClick}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.813 11.275a1.15 1.15 0 01-1.626 0L4.36 8.449a.577.577 0 11.816-.816l2.253 2.253V.57a.571.571 0 011.142 0v9.315l2.252-2.252a.577.577 0 11.816.816l-2.826 2.825zM1.847 16c-.527 0-.966-.176-1.318-.528C.177 15.12.001 14.68 0 14.153v-2.198a.571.571 0 111.143 0v2.198c0 .176.073.338.22.485.146.147.307.22.483.22h12.308a.674.674 0 00.484-.22.667.667 0 00.22-.485v-2.198a.571.571 0 011.142 0v2.198c0 .527-.176.966-.528 1.318-.352.352-.792.528-1.319.529H1.847z"
        fill="#969797"
      />
    </Container>
  );
}

export default SaveIcon;
