import React from 'react';
import { StyledIcon } from './styles';

function CommittedEventualIcon({ className }) {
  return (
    <StyledIcon
      viewBox="0 0 40 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M38.667 12.84h-1.8l-4.63-10.529a2.689 2.689 0 00-.984-1.165A2.646 2.646 0 0029.8.71H10.2c-.516 0-1.02.152-1.453.436a2.688 2.688 0 00-.984 1.165l-4.63 10.53h-1.8c-.353 0-.692.142-.942.395a1.355 1.355 0 000 1.906c.25.253.589.395.942.395h1.334v13.478c0 .715.28 1.4.78 1.906.5.505 1.18.79 1.886.79h4c.708 0 1.386-.285 1.886-.79.5-.506.781-1.191.781-1.906v-2.696h16v2.696c0 .715.281 1.4.781 1.906.5.505 1.178.79 1.886.79h4c.707 0 1.385-.285 1.885-.79a2.71 2.71 0 00.781-1.906V15.536h1.334c.353 0 .692-.141.942-.394a1.355 1.355 0 000-1.906 1.326 1.326 0 00-.942-.395zM10.2 3.407h19.6l4.148 9.435H6.052L10.2 3.406zm-.867 25.609h-4v-2.696h4v2.696zm21.334 0v-2.696h4v2.696h-4zm4-5.392H5.333v-8.087h29.334v8.088zM8 19.58c0-.357.14-.7.39-.953s.59-.395.943-.395H12c.354 0 .693.142.943.395a1.355 1.355 0 010 1.906c-.25.253-.59.395-.943.395H9.333c-.353 0-.692-.142-.942-.395A1.355 1.355 0 018 19.58zm18.667 0c0-.357.14-.7.39-.953s.59-.395.943-.395h2.667c.353 0 .692.142.942.395a1.356 1.356 0 010 1.906c-.25.253-.589.395-.942.395H28c-.354 0-.693-.142-.943-.395a1.355 1.355 0 01-.39-.953z"
        fill="#697BFF"
      />
    </StyledIcon>
  );
}

export default CommittedEventualIcon;
