import React from 'react';
import { ArrowIconContainer } from './styles';

const ArrowIcon = ({ className, width, height }) => {
    return (
        <ArrowIconContainer viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className} width={width} height={height}>
            <path d="M12 23.9983C5.38316 23.9983 0 18.6168 0 12C0 5.38316 5.38316 0 12 0C18.6168 0 24 5.38316 24 12C24 18.6168 18.6168 24 12 24V23.9983ZM12 1.42206C6.16838 1.42206 1.42206 6.16671 1.42206 12C1.42206 17.8333 6.16671 22.5779 12 22.5779C17.8333 22.5779 22.5779 17.8333 22.5779 12C22.5779 6.16671 17.8316 1.42206 12 1.42206Z" />
            <path d="M19.4971 11.4482L13.2453 5.19644C12.9669 4.91803 12.5168 4.91803 12.2401 5.19644C11.9617 5.47485 11.9617 5.92498 12.2401 6.20172L17.3265 11.2881H4.99972C4.58627 11.2881 4.25452 11.6416 4.29119 12.0634C4.32453 12.4368 4.66129 12.7102 5.0364 12.7102H17.2548L12.1684 17.7966C11.89 18.075 11.89 18.5251 12.1684 18.8019C12.3068 18.9403 12.4885 19.0103 12.6719 19.0103C12.8552 19.0103 13.0353 18.9403 13.1753 18.8019L19.5004 12.4768C19.7855 12.1917 19.7855 11.7316 19.5004 11.4465L19.4971 11.4482Z" />
        </ArrowIconContainer>
    );
};

export default ArrowIcon;
