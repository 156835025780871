import React, { useState, useRef, useEffect, useMemo } from 'react';
import {
  DropdownOptionsList,
  DropdownOptionItem,
  DropdownContainer,
  DropdownOptionItemLabel,
  ContainerOptions,
} from './styles';
import { useHistory } from 'react-router-dom';
import { Input } from 'components/atoms/Input';
import routesInfo from 'routes/routesInfo';
import { extractOptions } from 'utils/routes';

export const DropdownSearch = ({
  placeholder = 'Comece a digitar...',
  className,
}) => {
  const history = useHistory();
  const [showOptions, setShowOptions] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const options = useMemo(() => extractOptions(routesInfo), []);

  const handleRedirect = (newPath) => history.push(`/${newPath}`);

  const handleToggleDropdown = () => {
    setShowOptions((prevState) => !prevState);
  };

  const handleSelectOption = (option) => {
    handleRedirect(option.value);
    setSearchTerm('');
    setShowOptions(false);
  };

  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const filteredOptions = useMemo(
    () =>
      options.filter((option) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [options, searchTerm]
  );

  return (
    <DropdownContainer ref={modalRef}>
      <Input
        placeholder={placeholder}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onFocus={handleToggleDropdown}
        className="header"
      />
      {showOptions && filteredOptions.length > 0 && (
        <ContainerOptions>
          <DropdownOptionsList className={className} isOpen={showOptions}>
            {filteredOptions.map((option, index) => (
              <DropdownOptionItem
                key={index}
                onClick={() => handleSelectOption(option)}
              >
                <DropdownOptionItemLabel>
                  {option.label}
                </DropdownOptionItemLabel>
              </DropdownOptionItem>
            ))}
          </DropdownOptionsList>
        </ContainerOptions>
      )}
    </DropdownContainer>
  );
};
