import { CardContainerBasic } from './CardContainer';
import { Cardtitle } from './CardTitle';
import { CardBodyBasic } from './CardBody';
import { CardContentBasic } from './CardContent';
import { CardProgress } from './CardProgress';
import { CardSpending } from './CardContentSpending';
import { CardContentH5 } from './CardContentH5';
import { CardContentValueMoney } from './CardContentValueMoney';
import { CardTitleInformation } from './CardTitleInformation';
import { CardContentValueDescription } from './CardContentValueDescription';

export const Card = {
  Container: CardContainerBasic,
  BodyContainer: CardBodyBasic,
  Title: Cardtitle,
  Content: CardContentBasic,
  Progress: CardProgress,
  ContentSpending: CardSpending,
  ContentH5: CardContentH5,
  ContentMoney: CardContentValueMoney,
  TitleInformation: CardTitleInformation,
  ContentDescription: CardContentValueDescription,
};
