import React from 'react';
import { StyledIcon } from './styles';

function SetLeftIcon({ className, onClick }) {
  return (
    <StyledIcon
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M16 6.75l-6.125 6.125L16 19"
        stroke="#969797"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledIcon>
  );
}

export default SetLeftIcon;
