import * as React from 'react';
import { StyledDividerIcon } from './styles';

function DividerIcon({ className }) {
  return (
    <StyledDividerIcon
      viewBox="0 0 40 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        opacity={0.5}
        y={0.342499}
        width={40}
        height={3}
        rx={1.5}
        fill="#fff"
      />
    </StyledDividerIcon>
  );
}

export default DividerIcon;
