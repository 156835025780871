import React from 'react';
import { StyledLegend, LegendText, LegendValue } from './styles';

export const SubtitleBar = ({
  subtitleText,
  subtitleValue,
  loading,
  className,
  width,
}) => {
  return (
    <StyledLegend className={className}>
      <LegendText className={loading ? className : `${className} color`}>
        {subtitleText}
      </LegendText>
      <LegendValue className={`${className} value`} loading={loading}>
        {subtitleValue}
      </LegendValue>
    </StyledLegend>
  );
};
