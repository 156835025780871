import * as React from 'react';
import { StyledSuccessionPlanningIcon } from './styled';

function SuccessionPlanningIcon({ className }) {
  return (
    <StyledSuccessionPlanningIcon
      viewBox="0 0 46 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M24.084 13.761c-.264-.119-.556-.184-.837-.263-.24-.07-.489-.111-.725-.192-.218-.075-.514-.315-.458-.549 0-.26.181-.41.343-.508.511-.305 1.184-.012 1.511.519.184.298.47.393.745.247.257-.136.33-.41.185-.712a1.791 1.791 0 00-1.049-.944c-.181-.064-.285-.122-.29-.338-.008-.278-.203-.44-.492-.451-.293-.011-.47.147-.534.417-.021.09-.02.187-.027.266-.194.087-.38.152-.547.249-.536.313-.827.778-.812 1.386.017.63.36 1.073.953 1.32.258.108.54.162.81.243.282.084.584.13.832.268.15.082.304.313.287.474a.633.633 0 01-.4.516c-.709.312-1.294-.005-1.636-.68-.125-.247-.33-.365-.615-.288-.282.075-.41.272-.374.55.016.124.062.247.117.359.272.563.757.88 1.378 1.044.018.133.02.24.05.338.076.238.265.345.51.353.283.009.457-.218.468-.35.026-.326.232-.364.467-.45.625-.231 1.06-.626 1.108-1.312.047-.66-.295-1.21-.965-1.512h-.003z"
        fill="#fff"
      />
      <path
        d="M30.45 23.23v-2.508h-6.888v-1.268c2.894-.273 5.162-2.625 5.162-5.478 0-3.035-2.569-5.504-5.724-5.504-3.156 0-5.725 2.47-5.725 5.505 0 2.854 2.269 5.206 5.163 5.479v1.267H15.55v2.509c-2.894.27-5.163 2.624-5.163 5.476 0 3.036 2.568 5.506 5.725 5.506 3.156 0 5.724-2.47 5.724-5.506 0-2.854-2.268-5.206-5.162-5.479v-1.428h12.652v1.428c-2.894.273-5.163 2.625-5.163 5.479 0 3.036 2.568 5.506 5.725 5.506 3.156 0 5.724-2.47 5.724-5.506 0-2.854-2.268-5.206-5.162-5.479v.002zm-12.051-9.253c0-2.44 2.063-4.425 4.6-4.425 2.538 0 4.602 1.985 4.602 4.425 0 2.44-2.064 4.425-4.601 4.425-2.538 0-4.601-1.985-4.601-4.425zM20.71 28.71c0 2.44-2.064 4.425-4.6 4.425-2.538 0-4.602-1.985-4.602-4.425 0-2.44 2.064-4.424 4.601-4.424s4.601 1.984 4.601 4.424zm9.177 4.425c-2.538 0-4.601-1.985-4.601-4.425 0-2.44 2.064-4.424 4.6-4.424 2.538 0 4.602 1.984 4.602 4.424 0 2.44-2.064 4.425-4.601 4.425z"
        fill="#fff"
      />
    </StyledSuccessionPlanningIcon>
  );
}

export default SuccessionPlanningIcon;
