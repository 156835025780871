import React from "react";
import { ModalFooterWithOneButtonContainer } from "./styles";
import { Button } from "components/atoms/Button";

export const ModalFooterWithOneButton = ({
    className = 'default',
    onClick,
    text = '',
}) => {
    return (
        <ModalFooterWithOneButtonContainer className={className}>
            <Button className={className} onClick={onClick}>
                {text}
            </Button>
        </ModalFooterWithOneButtonContainer>
    )
}