import styled from 'styled-components';

export const TopRowCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 20%;
  min-height: 23.96px;
`;

export const Container = styled.div`
  display: flex;
`;
