import styled from 'styled-components';

export const TextContainer = styled.div`
  width: ${({ theme }) => theme.getResponsiveWidth(1556)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleCardWrapper = styled.div`
  width: ${({ theme }) => theme.getResponsiveWidth(261)};
  display: flex;
  justify-content: space-between;

  &.second-column {
    align-self: center;
  }
`;

export const GroupItens = styled.div`
  &.total {
    width: ${({ theme }) => theme.getResponsiveWidth(261)};
    display: flex;
    justify-content: space-between;
  }
`;
