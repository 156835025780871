import React from 'react';
import { Container, Title, ScrollContainer, LoadingContainer } from './styles';
import { H4 } from 'components/atoms/Headings/H4';
import GoIcon from 'components/atoms/icons/GoIcon';
import { Table } from 'components/templates/Tables';
import { GeneralContext } from 'utils/contexts/GeneralContext';
import { GenericLoading } from 'components/atoms/GenericLoading';
import { getInvestmentPanelData } from 'development';
import { redirect } from 'utils/navigation';

export const InvestmentPanel = () => {
  const { loading } = React.useContext(GeneralContext);
  const investments = getInvestmentPanelData();
  const head = investments.columns;
  const rows = investments.data;

  const getValueByLabel = (item, label) => {
    return item.find(row => row.label === label).value;
  };

  const aditionalClass = (index, length) => {
    if (index === 0) {
      return 'first';
    }

    if (index === length - 1) {
      return 'last';
    }

    return 'generic-class';
  };

  if (loading) {
    return (
      <Container>
        <Title>
          <GenericLoading className="investment-panel-title" />
          <GenericLoading className="investment-panel-go-icon" />
        </Title>
        <ScrollContainer className="loading">
          <LoadingContainer>
            <GenericLoading className="investment-panel-table" />
          </LoadingContainer>
        </ScrollContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Title>
        <H4 className="investment-panel-title">Painel de investimentos</H4>
        <GoIcon
          className="investment-panel-title"
          onClick={() => redirect('/finances/patrimony/map')}
        />
      </Title>
      <ScrollContainer>
        <Table.Container className="responsive investment-panel">
          <Table.TableBasic className="investment-panel">
            <Table.Thead className="investment-panel">
              <Table.Tr className="investment-panel-title">
                {head.map((title, index) => (
                  <Table.Th
                    key={index}
                    className={`investment-panel ${aditionalClass(
                      index,
                      head.length
                    )}`}
                  >
                    {title}
                  </Table.Th>
                ))}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody className="investment-panel">
              {rows.map((row, index) => (
                <Table.Tr key={index} className="investment-panel-body">
                  {head.map((value, index) => (
                    <Table.Td
                      key={index}
                      className={`investment-panel ${aditionalClass(
                        index,
                        head.length
                      )}`}
                    >
                      {getValueByLabel(row, value)}
                    </Table.Td>
                  ))}
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table.TableBasic>
        </Table.Container>
      </ScrollContainer>
    </Container>
  );
};
