import React from 'react';
import { getArrayNumbers } from 'utils/numbers';
import { Wrapper, ContentBody, Content, Separator } from './styles';

export const QuestionPager = ({
  total = 11,
  className = '',
  onChange,
  currentQuestion = 0,
}) => {
  const numbers = getArrayNumbers(total, false);

  const isSelected = number => {
    return number === currentQuestion;
  };

  const handleClick = number => {
    onChange(number - 1);
  };

  return (
    <Wrapper className={className}>
      {numbers.map((number, index) => (
        <>
          {index !== 0 && <Separator className={className} key={index} />}
          <ContentBody
            className={className + (isSelected(number) ? ' selected' : '')}
            onClick={() => handleClick(number)}
            key={number}
          >
            <Content
              key={number}
              className={className + (isSelected(number) ? ' selected' : '')}
            >
              {number}
            </Content>
          </ContentBody>
        </>
      ))}
    </Wrapper>
  );
};
