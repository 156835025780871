import * as React from 'react';
import { StyledCloseModalIcon } from './styles';

function CloseModalIcon(props) {
  return (
    <StyledCloseModalIcon
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 1l11 11m0-11L1 12"
        stroke="#B1113B"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </StyledCloseModalIcon>
  );
}

export default CloseModalIcon;
