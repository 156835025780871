import React from 'react';
import { StyledIcon } from './styles';

function BarIcon({ className }) {
  return (
    <StyledIcon
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x={1}
        y={6}
        width={6}
        height={21}
        rx={1}
        stroke="#140361"
        strokeWidth={2}
      />
      <rect
        x={11}
        y={13}
        width={6}
        height={14}
        rx={1}
        stroke="#140361"
        strokeWidth={2}
      />
      <rect
        x={21}
        y={1}
        width={6}
        height={26}
        rx={1}
        stroke="#140361"
        strokeWidth={2}
      />
    </StyledIcon>
  );
}

export default BarIcon;
