import styled from "styled-components";

export const Footer = styled.div`
    &.detailed-extract-footer {
        width: 100%;
        height: 48px;
        border-radius: 8px;
        padding: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 12px;

        @media (max-width: 1440px) {
            height: 44px;
            padding: 12px;
        }
    }

    &.detailed-extract-footer.spendings {
        background-color: ${props => props.theme.blueSky};
    }

    &.detailed-extract-footer.receipts {
        background-color: ${props => props.theme.blueHeaderWithSlowOpacity};
    }

    &.detailed-extract-footer.investments {
        background-color: ${props => props.theme.greenDark};
    }
`