import styled from "styled-components";

export const GroupItensTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  @media (max-width: 1440px) {
    gap: 8px;
  }

  &.generic-dream {
    gap: 0;
  }
`;