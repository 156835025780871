import React, { useCallback } from 'react';
import { Page } from 'components/templates/Page';
import { GoalsPanelContainer } from './styles';
import { GoalPanelHeader } from 'components/molecules/GoalPanelHeader';
import { Goal } from 'components/molecules/Goal';
import { GenericLoading } from 'components/atoms/GenericLoading';
import useGoals from 'hooks/useGoals';

export default function GoalPanel() {
  const {
    goals,
    loading,
    card,
    fetchGoals,
    updateGoal,
    createGoal,
    deleteGoal,
  } = useGoals();

  const handleToggleDreamStatus = useCallback(
    async (goalId, doneAt) => {
      try {
        const updatedData = { done_at: doneAt ? null : new Date() };
        await updateGoal(goalId, updatedData);
        await fetchGoals();
      } catch (error) {
        console.error('Failed to toggle dream status:', error);
      }
    },
    [updateGoal, fetchGoals]
  );

  const handleCreateGoal = async goalData => {
    try {
      const response = await createGoal(goalData);
      await fetchGoals();
      return response;
    } catch (error) {
      console.error('Failed to create goal:', error);
      throw error;
    }
  };

  const handleUpdateGoal = async (goalId, goalData) => {
    try {
      const response = await updateGoal(goalId, goalData);
      await fetchGoals();
      return response;
    } catch (error) {
      console.error('Failed to update goal:', error);
      throw error;
    }
  };

  const handleDeleteGoal = async goalId => {
    try {
      await deleteGoal(goalId);
      await fetchGoals();
    } catch (error) {
      console.error('Error deleting goal:', error);
      throw error;
    }
  };

  const pageContent = loading ? (
    <Page.RootContainer>
      <GoalPanelHeader cardList={goals.card} />
      <GoalsPanelContainer>
        {Array(4)
          .fill(0)
          .map((_, index) => (
            <GenericLoading className="goals-panel-dream" key={index} />
          ))}
      </GoalsPanelContainer>
    </Page.RootContainer>
  ) : (
    <Page.RootContainer>
      <GoalPanelHeader
        financialPatrimony={card.financial_patrimony}
        materialPatrimony={card.material_patrimony}
        seleable={card.sealed_value}
        investmentValue={card.investments_value}
      />
      <GoalsPanelContainer>
        {goals.map((goal, index) => (
          <Goal
            key={index}
            type={goal.type}
            backgroundImage={goal.backgroundImage}
            dreamProps={goal}
            onToggleDreamStatus={handleToggleDreamStatus}
            reloadPage={fetchGoals}
            onCreateGoal={handleCreateGoal}
            onUpdateGoal={handleUpdateGoal}
            onDeleteGoal={handleDeleteGoal}
          />
        ))}
        <Goal type="empty-goal" onCreateGoal={handleCreateGoal} />
      </GoalsPanelContainer>
    </Page.RootContainer>
  );

  return pageContent;
}
