import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: ${props => props.theme.getResponsiveHeight(315)};
  min-height: 250px;
  height: 100%;
  width: ${props => props.theme.getResponsiveWidth(210)};
  min-width: 150px;
`;
