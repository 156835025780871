import React from "react";
import { Doughnut } from 'react-chartjs-2';
import { Container } from "./styles";

export const DoughnutChart = ({
    data,
    options,
    className = '',
}) => {
    return (
        <Container className={className}>
            <Doughnut
                data={data}
                options={options}
                width={100}
                height={100}
            />
        </Container>
    )
}