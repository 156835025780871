import React from "react";
import { BoxIcon } from "components/atoms/BoxIcon";
import { Container } from './styles'
import { GenericLoading } from "components/atoms/GenericLoading";

export const ExtractMenuRedirect = ({
    currentPage = '',
    setCurrentPage = () => { },
    loading = false
}) => {
    const receipts = 'receipts'
    const spendings = 'spendings'
    const investments = 'investments'

    const [hovered, setHovered] = React.useState('');

    const isSelected = (value) => currentPage === value;
    const isHovered = (value) => hovered === value;

    const handleClick = (value) => {
        setCurrentPage(value);
    }

    const handleMouseHover = (value) => {
        setHovered(value);
    }

    if (loading) {
        return (
            <Container>
                <GenericLoading className="extract-redirect-icons" />
                <GenericLoading className="extract-redirect-icons" />
                <GenericLoading className="extract-redirect-icons" />
            </Container>
        )
    }

    return (
        <Container>
            <BoxIcon
                className="extract after spendings"
                icon="spendings"
                selected={isSelected(spendings)}
                hovered={isHovered(spendings)}
                onClick={() => handleClick(spendings)}
                onMouseEnter={() => handleMouseHover(spendings)}
                onMouseLeave={() => handleMouseHover('')}
            />
            <BoxIcon
                className="extract first receipts"
                icon="receipt"
                selected={isSelected(receipts)}
                hovered={isHovered(receipts)}
                onClick={() => handleClick(receipts)}
                onMouseEnter={() => handleMouseHover(receipts)}
                onMouseLeave={() => handleMouseHover('')}
            />
            <BoxIcon
                className="extract after investments"
                icon="investments"
                selected={isSelected(investments)}
                hovered={isHovered(investments)}
                onClick={() => handleClick(investments)}
                onMouseEnter={() => handleMouseHover(investments)}
                onMouseLeave={() => handleMouseHover('')}
            />
        </Container>
    )
}