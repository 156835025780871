import React, { useContext } from 'react';
import TitleCard from 'components/molecules/TitleCard';
import SummaryCardRow from 'components/molecules/SummaryCardRow';
import { SpendingCard, Container, GroupEmptyCard } from './styles';
import { EmptyCardRow } from 'components/atoms/EmptyCardRow';
import { GeneralContext } from 'utils/contexts/GeneralContext';

const SummaryCard = ({ url, title, spendingObjects, className }) => {
  const { loading } = useContext(GeneralContext);

  if (!spendingObjects.length && !loading) {
    return (
      <Container className={`${className} empty`}>
        <TitleCard
          url={url}
          title={title}
          loading={loading}
          className={`${className} title-card`}
        />
        <GroupEmptyCard>
          <SpendingCard className="empty">
            <EmptyCardRow className="summary-card">
              Ainda não há gastos lançados
            </EmptyCardRow>
          </SpendingCard>
        </GroupEmptyCard>
      </Container>
    );
  }

  if (loading) {
    return (
      <Container>
        <TitleCard
          url={url}
          title={title}
          loading={loading}
          className={className}
        />
        <SpendingCard className="loading">
          {[...Array(8)].map(spending => (
            <SummaryCardRow loading={loading} />
          ))}
        </SpendingCard>
      </Container>
    );
  }

  return (
    <Container className="default">
      <TitleCard
        url={url}
        title={title}
        loading={loading}
        className={`${className} title-card`}
      />
      <SpendingCard>
        {spendingObjects?.map(spending => (
          <SummaryCardRow
            name={spending.description}
            category={spending.category_name}
            value={spending.value}
            type={spending.type}
            spendingDate={spending.date_start}
            loading={loading}
          />
        ))}
      </SpendingCard>
    </Container>
  );
};

export default SummaryCard;
