import React from 'react';
import { StyledIcon } from './styles';

function SocialSecurityIcon({ className }) {
  return (
    <StyledIcon
    viewBox="0 0 25 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.79 0H.916a.916.916 0 000 1.831h1.599v4.282a7.833 7.833 0 003.388 6.45.576.576 0 010 .949 7.832 7.832 0 00-3.388 6.446v4.28h-1.6a.916.916 0 000 1.83h8.54a7.886 7.886 0 01-.521-1.83H4.346v-4.283a6.003 6.003 0 013.495-5.448c.553-.256.919-.79.919-1.398v-.143c0-.608-.366-1.142-.919-1.398a6.015 6.015 0 01-3.495-5.455V1.831H16.36v4.282a6.013 6.013 0 01-3.492 5.455c-.552.256-.922.79-.922 1.398v.143c0 .607.37 1.142.922 1.398.46.21.888.48 1.276.797a7.948 7.948 0 012.13-.473 7.837 7.837 0 00-1.468-1.319.573.573 0 010-.95 7.838 7.838 0 003.385-6.449V1.831h1.6a.914.914 0 100-1.831zM18.16 22.69c-.29-.138-.613-.211-.922-.303-.265-.079-.54-.128-.8-.22-.237-.085-.567-.36-.503-.628 0-.3.199-.47.379-.583.564-.351 1.306-.015 1.666.595.202.342.516.449.821.284.284-.156.363-.47.205-.815a2.005 2.005 0 00-1.157-1.08c-.18-.068-.293-.129-.317-.324-.037-.311-.27-.583-.58-.58-.312.003-.486.18-.556.48-.024.103-.021.213-.027.305-.214.097-.419.173-.602.283-.592.36-.912.892-.894 1.588.018.723.397 1.23 1.05 1.51.284.122.595.187.894.278.308.095.644.147.916.305.165.095.333.357.314.544-.04.393-.403.76-1.16.68-.482-.052-.87-.387-1.065-.83-.134-.305-.36-.455-.68-.372-.226.058-.412.244-.43.476-.016.19.048.39.133.568.303.647.834 1.007 1.52 1.196.022.15.022.275.056.388.085.271.29.393.561.406.311.012.504-.25.516-.4.027-.373.256-.415.513-.516.69-.266 1.169-.717 1.22-1.502.052-.757-.323-1.385-1.062-1.73l-.009-.003z"
        fill="#140361"
      />
      <path
        d="M16.963 30A7.07 7.07 0 019.9 22.937a7.07 7.07 0 017.063-7.063 7.07 7.07 0 017.063 7.063A7.07 7.07 0 0116.963 30zm0-12.297a5.238 5.238 0 00-5.231 5.231 5.238 5.238 0 005.231 5.232 5.238 5.238 0 005.232-5.232 5.238 5.238 0 00-5.232-5.231z"
        fill="#140361"
      />
    </StyledIcon>
  );
}

export default SocialSecurityIcon;
