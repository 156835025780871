import React from "react";
import { H5 } from "components/atoms/Headings/H5";

export const HeaderBudgetMapListWithoutDropdown = ({
    children,
    className,
    loadingClassName,
    toMap,
    toPlanning,
    toAct 
}) => {

    const isSelected = (path) => {
        const pathname = window.location.pathname;
        if (pathname === path) {
            return 'map-border';
        }
    }

    const onClick = (path) => {
        window.location.pathname = path;
    }

    return (
        <>
            <H5 className={`map-header ${isSelected(toMap)}`} onClick={() => onClick(toMap)}>
                Mapear
            </H5>
            <H5 className={`map-header ${isSelected(toPlanning)}`} onClick={() => onClick(toPlanning)}>
                Planejar
            </H5>
            <H5 className={`map-header ${isSelected(toAct)}`} onClick={() => onClick(toAct)}>
                Atuar
            </H5>
        </>
    )
}