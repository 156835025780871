import React, { useContext } from 'react';
import { GeneralContext } from 'utils/contexts/GeneralContext';
import { LoadingTable } from 'components/templates/Table/LoadingTable';
import { TableReport } from './Table';
import {
  getHousing,
  getInvestmentSemiannual,
  getReceiptSemiannual,
  getTransport,
} from 'development/report';

export const ReportSpendingTable = ({ currentPage }) => {
  const { loading } = useContext(GeneralContext);

  const pages = {
    spendings: (
      <>
        <TableReport
          data={getHousing()}
          key="selectedSpendingItem"
          type="spendings"
          title="Moradia"
        />
        <TableReport
          data={getTransport()}
          key="selectedTransportItem"
          type="spendings"
          title="Transporte"
        />
      </>
    ),
    receipt: (
      <TableReport
        data={getReceiptSemiannual()}
        key="selectedReceiptItem"
        type="receipts"
        title="Recebimentos"
      />
    ),
    investment: (
      <TableReport
        data={getInvestmentSemiannual()}
        key="selectedInvestmentItem"
        type="investments"
        title="Investimentos"
      />
    ),
  };

  if (loading) {
    return <LoadingTable />;
  }
  return pages[currentPage] || <></>;
};
