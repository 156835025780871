import React from 'react';
import { LegendValue, StyledContainer } from './styles';
import { SubtitleGraphic } from 'components/atoms/SubtitleGraphic';

export const SubtitleDetail = ({ text, className, value, onClick }) => {
  return (
    <StyledContainer className="detail" onClick={onClick}>
      <SubtitleGraphic subtitleText={text} className={` detail ${className}`} />
      <LegendValue className={` detail ${className}`}>{value}</LegendValue>
    </StyledContainer>
  );
};
