import { Table } from 'components/atoms/Table';
import { Th } from 'components/atoms/Th';
import { Tr } from 'components/atoms/Tr';
import AddIcon from 'components/atoms/icons/AddIcon';
import TrashIcon from 'components/atoms/icons/TrashIcon';
import React from 'react';
import { TitleLoading } from 'components/molecules/TableTitleLoading';
import { CardContainer, Container, GroupIcons } from './styles';

export const LoadingTable = () => {
  return (
    <>
      <CardContainer>
        <TitleLoading />
        <Container>
          <Table className="receipt-table">
            <thead>
              <Tr className="receipt-table-title receipts">
                <Th className="loading" />
              </Tr>
            </thead>
            <tbody>
              <Tr className="loading" />
            </tbody>
          </Table>
        </Container>
        <GroupIcons>
          <AddIcon className="loading" />
          <TrashIcon className="loading" />
        </GroupIcons>
      </CardContainer>
    </>
  );
};
