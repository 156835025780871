import React, { useContext } from 'react';
import { GeneralContext } from 'utils/contexts/GeneralContext';
import { LoadingTable } from 'components/templates/Table/LoadingTable';
import { MaterialAssets } from './MaterialAssets';
import { FinancialAssets } from './FinancialAssets';
import { EquityPassive } from './EquityPassive';
import { NotEquityPassive } from './NotEquityPassive';

export const PatrimonyMapTable = ({
  currentPage,
  materialAssets,
  financialAssets,
  equityPassive,
  textMaterial,
  textFinancial,
  textEquity,
  textNotEquity,
}) => {
  const { loading } = useContext(GeneralContext);

  const pages = {
    active: (
      <>
        <MaterialAssets
          materialAssets={materialAssets}
          tooltipText={textMaterial}
        />
        <FinancialAssets
          financialAssets={financialAssets}
          tooltipText={textFinancial}
        />
      </>
    ),
    passive: (
      <>
        <EquityPassive equityPassive={equityPassive} tooltipText={textEquity} />
        <NotEquityPassive
          equityPassive={equityPassive}
          tooltipText={textNotEquity}
        />
      </>
    ),
  };

  if (loading) {
    return <LoadingTable />;
  }
  return pages[currentPage] || <></>;
};
