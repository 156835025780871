import * as React from 'react';
import { StyledBiggerLogo } from './styles';

function BiggerLogo({ className }) {
  return (
    <StyledBiggerLogo
      id="Yuno_Know_1"
      data-name="Yuno Know 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1983 850"
      className={className}
    >
      <defs>
        <style>
          {'.cls-1,.cls-2{fill:#7386ff;stroke-width:0}.cls-2{fill:#fff}'}
        </style>
      </defs>
      <path
        className="cls-2"
        d="M387.44 0L269.05 208.8 147.31 0 3.73 0 200.2 321.49 200.2 552.66 328.66 552.66 328.66 320.66 525.13 0 387.44 0z"
      />
      <path
        className="cls-2"
        d="M754.05 373.69c0 22.1-6.63 41.84-19.89 59.24-13.26 17.4-32.04 26.1-56.34 26.1-21 0-36.87-6.35-47.64-19.06-10.77-12.7-16.16-30.1-16.16-52.2V164.06H494.71v241.11c0 51.93 13.11 91.14 39.36 117.66 26.23 26.51 61.44 39.77 105.64 39.77 22.64 0 44.33-4.84 65.04-14.5 20.71-9.66 37.41-21.96 50.13-36.87l3.31 41.43h114.34v-388.6H754.04v209.63zM1194.84 154.94c-22.1 0-43.64 4.7-64.63 14.09-21 9.4-37.84 21.83-50.54 37.28l-3.31-42.26H962.02v388.6h118.49v-208.8c0-22.64 6.76-42.53 20.3-59.66 13.53-17.12 33.27-25.69 59.24-25.69 19.33 0 34.39 6.36 45.16 19.06 10.77 12.71 16.16 29.83 16.16 51.37v223.71h119.31V312.35c0-51.92-13.26-91.14-39.77-117.66-26.51-26.51-61.87-39.77-106.06-39.77zM1804.61 253.54c-17.4-31.49-41.71-56.47-72.91-74.99-31.21-18.5-67.53-27.76-108.96-27.76s-77.75 9.26-108.96 27.76c-31.21 18.51-55.66 43.5-73.33 74.99-17.68 31.49-26.51 66.29-26.51 104.4s8.83 72.91 26.51 104.4c14.83 26.42 40.19 55.04 64.56 72.51 63.94-44.16 169.74-48.46 239.42 0 23.83-17.35 45.79-46.47 60.18-72.51 17.4-31.49 26.1-66.29 26.1-104.4s-8.7-72.91-26.1-104.4zm-99.84 154.95c-7.18 16.03-17.54 29-31.07 38.94-13.54 9.94-30.53 14.91-50.96 14.91s-36.87-4.97-50.96-14.91c-14.09-9.94-24.73-22.92-31.9-38.94-7.19-16.02-10.77-32.86-10.77-50.54s3.59-34.38 10.77-50.13c7.17-15.74 17.81-28.59 31.9-38.53 14.09-9.94 31.07-14.91 50.96-14.91s37.42 4.97 50.96 14.91c13.53 9.94 23.89 22.79 31.07 38.53 7.17 15.74 10.77 32.46 10.77 50.13s-3.6 34.53-10.77 50.54z"
      />
      <g>
        <path
          className="cls-1"
          d="M1622.43 574.63c67.31 0 116.65 59.28 116.65 137.68s-47.42 137.68-116.65 137.68-116.27-59.28-116.27-137.68 47.42-137.68 116.27-137.68zm0 250.51c51.63 0 87.97-48.57 87.97-112.82 0-59.66-34.42-112.44-87.97-112.44s-87.58 52.78-87.58 112.44c0 64.25 34.42 112.82 87.58 112.82zM1830.87 651.89l-11.09-22.18-2.29.38v216.09h-28.68V578.46h28.3l122.39 198.88 8.8 17.98 2.29-.38V578.47h28.68v267.72h-27.92L1830.88 651.9z"
        />
      </g>
    </StyledBiggerLogo>
  );
}

export default BiggerLogo;
