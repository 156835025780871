import React, { useState, useEffect } from 'react';
import { Container, TitleContainer, Card } from './styles';
import InvestmentsIcon from 'components/atoms/icons/InvestmentsIcon';
import { H4 } from 'components/atoms/Headings/H4';
import MapBody from 'components/molecules/MapBody';

const InvestmentCard = ({
  currentPage,
  onChangePage,
  value = 0,
  loading = true,
}) => {
  const [selected, setSelected] = useState(currentPage === 'investments');
  const [hovered, setHovered] = useState(currentPage === 'investments');

  const investment = selected || hovered ? 'selected' : 'investments';
  const selectedClass = selected || hovered ? 'selected' : '';
  const loadingClass = loading ? 'loading' : '';

  const handleClick = () => {
    setSelected(!selected);
    onChangePage('investments');
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  useEffect(() => {
    setSelected(currentPage === 'investments');
    setHovered(currentPage === 'investments');
  }, [currentPage]);

  return (
    <Container
      selected={selected}
      className={`${loadingClass} ${selectedClass}`}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Card className={loadingClass}>
        <TitleContainer>
          <InvestmentsIcon className={selectedClass} />
          <H4 className={investment}>Investimentos</H4>
        </TitleContainer>
        <MapBody className={investment} value={value} />
      </Card>
    </Container>
  );
};

export default InvestmentCard;
