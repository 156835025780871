import * as React from "react";
import { HouseIconContainer } from "./styles";

export const HouseIcon = ({
    className,
}) => {
    return (
        <HouseIconContainer
            width={27}
            height={28}
            viewBox="0 0 27 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M26.273 11.268L15.023 1.077A2.303 2.303 0 0013.493.5c-.56 0-1.099.2-1.513.562l-.016.015L.727 11.267c-.23.203-.412.449-.537.723-.125.273-.19.569-.19.868V25.34c0 .573.237 1.122.659 1.527.422.405.994.633 1.591.633H9c.597 0 1.169-.228 1.591-.633.422-.405.659-.954.659-1.527v-6.48h4.5v6.48c0 .573.237 1.122.659 1.527.422.405.994.633 1.591.633h6.75c.597 0 1.169-.228 1.591-.633.422-.405.659-.954.659-1.527V12.858c0-.299-.064-.595-.19-.869a2.164 2.164 0 00-.537-.721zM24.75 25.34H18v-6.48c0-.574-.237-1.123-.659-1.528a2.299 2.299 0 00-1.591-.633h-4.5c-.597 0-1.169.228-1.591.633A2.117 2.117 0 009 18.859v6.48H2.25V12.859l.015-.014L13.5 2.659l11.236 10.184.015.013-.001 12.485z"
                fill="#140361"
            />
        </HouseIconContainer>
    )
}
