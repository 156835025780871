import * as React from "react"
import { SVG } from "./styles"

export const EatingOutCategoryIcon = ({
    className = '',
}) => {
    return (
        <SVG
            className={className}
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.387.028a.773.773 0 01.944.523l4.636 15.454a.773.773 0 01-.962.962L.551 12.331a.773.773 0 01-.523-.944A16.227 16.227 0 0111.387.027zm-.314 1.708a14.682 14.682 0 00-9.337 9.337l1.406.422a13.94 13.94 0 018.353-8.353l-.422-1.406zm.867 2.89a12.395 12.395 0 00-7.315 7.314l10.45 3.135-3.135-10.45zm-1.894 3.101c0-.426.346-.772.772-.772h.008a.773.773 0 010 1.545h-.008a.773.773 0 01-.772-.773zm-3.091 3.091c0-.426.346-.772.772-.772h.008a.773.773 0 010 1.545h-.008a.773.773 0 01-.772-.773zm3.863.773c0-.427.346-.773.773-.773h.008a.773.773 0 010 1.546h-.008a.773.773 0 01-.773-.773z"
                fill="#0C053F"
            />
        </SVG>
    )
}
