import * as React from 'react';
import { StyledAssetManagementIcon } from './styles';

function AssetManagementIcon({ className }) {
  return (
    <StyledAssetManagementIcon
      viewBox="0 0 46 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M27.33 18.676v14.459c0 .595.507 1.079 1.13 1.079H33c.623 0 1.13-.484 1.13-1.08V18.677c0-.595-.507-1.079-1.13-1.079h-4.54c-.623 0-1.13.484-1.13 1.08zm5.687 14.475h-4.574V18.66h4.574v14.49zM20.73 34.214h4.54c.623 0 1.129-.484 1.129-1.08V21.779c0-.596-.506-1.08-1.13-1.08h-4.54c-.623 0-1.129.484-1.129 1.08v11.357c0 .595.506 1.079 1.13 1.079zm-.017-12.452h4.573V33.15h-4.573V21.76zM13 34.214h4.54c.624 0 1.13-.484 1.13-1.08v-8.332c0-.595-.506-1.079-1.13-1.079H13c-.623 0-1.129.484-1.129 1.079v8.333c0 .595.506 1.079 1.13 1.079zm-.016-9.428h4.573v8.364h-4.573v-8.364zM12.46 22.175a.57.57 0 00.394-.155l8.36-7.988 3.317 3.17a.651.651 0 00.892 0l7.235-6.913v3.106c0 .277.213.524.502.551.332.03.61-.218.61-.529V9.006a.543.543 0 00-.01-.105c-.003-.015-.01-.03-.015-.044-.005-.019-.01-.037-.018-.056-.007-.017-.018-.032-.026-.05-.008-.014-.015-.028-.025-.042a.642.642 0 00-.063-.074l-.005-.007a.571.571 0 00-.085-.066c-.014-.01-.028-.015-.042-.023-.018-.009-.035-.02-.054-.026-.017-.007-.035-.01-.053-.016l-.051-.015a.737.737 0 00-.094-.01h-4.608c-.29 0-.55.203-.577.48-.032.317.228.583.553.583h3.274l-6.895 6.587-3.316-3.169a.653.653 0 00-.893 0l-8.7 8.314a.516.516 0 000 .753c.109.103.25.155.394.155z"
        fill="#fff"
      />
    </StyledAssetManagementIcon>
  );
}

export default AssetManagementIcon;
