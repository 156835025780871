import GoIcon from 'components/atoms/icons/GoIcon';
import React from 'react';

export const NavigationCardRedirect = ({ to, children, className = '' }) => {
  const handleClick = () => {
    window.location.href = to;
  };

  return <GoIcon className={className} onClick={handleClick} />;
};
