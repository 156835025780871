import React, { useState } from "react";
import { Modal } from "components/templates/Modal";
import { GenericLoading } from "components/atoms/GenericLoading";

export const SimpleEditExtractItemModal = ({
    showModal = false,
    setShowModal = () => { },
    onConfirm = () => { },
    onCancel = () => { },
    data,
    loading = false,
    setLoading = () => { },
}) => {

    const drodownOptions = [
        { value: 'Moradia', label: 'Moradia' },
        { value: 'Alimentação', label: 'Alimentação' },
        { value: 'Lazer', label: 'Lazer' },
        { value: 'Vida e Saúde', label: 'Vida e Saúde' },
        { value: 'Transporte', label: 'Transporte' },
        { value: 'Pessoal', label: 'Pessoal' },
        { value: 'Outros', label: 'Outros' },
    ];
    const today = new Date();
    const formatedDate = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;
    const [name, setName] = useState(data?.name || '');
    const [classification, setClassification] = useState(data?.classification || 'Selecione uma classificação');
    const [recurrent, setRecurrent] = useState(data?.recurrent || false);
    const [startDate, setStartDate] = useState(data?.startDate || formatedDate);
    const [endDate, setEndDate] = useState(data?.endDate || formatedDate);
    const [description, setDescription] = useState(data?.description || '');
    const [installmentValue, setInstallmentValue] = useState(data?.installmentValue || 0);

    if (loading) {
        return (
            <Modal.Container
                showModal={showModal}
                setShowModal={setShowModal}
                className="extract-modal"
            >
                <Modal.TitleContainer>
                    <GenericLoading className="simple-modal-delete-header" />
                </Modal.TitleContainer>
                <Modal.BodyContainer className="extract-modal">
                    <GenericLoading className="simple-modal-edit-item-category" />
                    <GenericLoading className="simple-modal-edit-item-category" />
                    <GenericLoading className="simple-modal-edit-item-category" />
                    <GenericLoading className="simple-modal-edit-item-category" />
                    <Modal.GroupInputs show={true}>
                        <GenericLoading className="simple-modal-edit-item-category-group-itens first" />
                        <GenericLoading className="simple-modal-edit-item-category-group-itens second" />
                    </Modal.GroupInputs>
                </Modal.BodyContainer>
                <Modal.SimpleFooter
                    loading={loading}
                    loadingClassname="simple-modal-delete-footer"
                />
            </Modal.Container>
        );
    }

    return (
        <Modal.Container
            showModal={showModal}
            setShowModal={setShowModal}
            className="extract-modal"
        >
            <Modal.TitleContainer>
                <Modal.SimpleTitleContent
                    title="Editar item"
                    onClose={() => setShowModal(false)}
                    type='edit'
                    className="extract-modal"
                    theme='edit'
                />
            </Modal.TitleContainer>
            <Modal.BodyContainer className="extract-modal">
                <Modal.Dropdown
                    className="extract-modal"
                    placeholderSelect={classification}
                    selectOptions={drodownOptions}
                    getDropdownValue={setClassification}
                    label="Categoria"
                />
                <Modal.InputText
                    className="extract-modal"
                    placeholder={""}
                    label="Nome do item"
                    getInputValue={setName}
                    defaultValue={name}
                />
                <Modal.DatePicker
                    className="generic-dream-modal-datepicker full-width after"
                    label="Data de início"
                    getInputValue={setStartDate}
                    defaultValue={startDate}
                />
                <Modal.InputText
                    className="extract-modal"
                    placeholder={""}
                    label="Descrição"
                    getInputValue={setDescription}
                    defaultValue={description}
                />
                <Modal.GroupInputs show={true}>
                    <Modal.Switch
                        label="Recorrente"
                        className="extract-modal"
                        getSwitchValue={setRecurrent}
                        defaultValue={recurrent}
                    />
                    <Modal.InputMoney
                        className="extract-modal"
                        label="Valor da parcela"
                        getInputValue={setInstallmentValue}
                        defaultValue={installmentValue}
                    />
                </Modal.GroupInputs>
                {recurrent && (
                    <Modal.GroupInputs show={true}>
                        <Modal.EmptySpace className="extract-modal" />
                        <Modal.DatePicker
                            className="generic-dream-modal-datepicker after top"
                            label="Data de fim"
                            getInputValue={setEndDate}
                            defaultValue={endDate}
                        />
                    </Modal.GroupInputs>
                )}
            </Modal.BodyContainer>
            <Modal.SimpleFooter
                onConfirm={() => {
                    onConfirm();
                    setShowModal(false);
                }}
                onCancel={() => {
                    setShowModal(false);
                }}
                className="extract-modal"
                confirmButtonText="Excluir"
                cancelButtonText="Salvar"
            />
        </Modal.Container>
    );
}