import React, { useState } from 'react';
import { CardContainer, Home } from './styles';
import DirecionalButton from 'components/organisms/Direcional';
import {
  getEquityPassive,
  getFinancialAssets,
  getMaterialAssets,
} from 'development';
import { Active } from 'components/organisms/PatrimonyMapCard/Active';
import { Passive } from 'components/organisms/PatrimonyMapCard/Passive';
import { LiquidPatrimony } from 'components/organisms/PatrimonyMapCard/LiquidPatrimony';
import { CoverageIndex } from 'components/organisms/PatrimonyMapCard/CoverageIndex';
import { PatrimonyMapTable } from 'components/organisms/PatrimonyMapTable';

export default function Map() {
  const [currentPage, setCurrentPage] = useState('active');

  const activeVaule = 'R$ 0';
  const passiveVaule = 'R$ 0';
  const liquidyValue = 'R$ 0';
  const indexValue = '0 meses';

  const textEquity =
    'São dívidas que tem algum bem ou propriedade como garantia. Ex.: Financiamentos de imóvel ou veículo e consórcios';
  const textFinancial =
    'É a parte do seu patrimônio que está na forma de investimentos financeiros.';
  const textMaterial =
    'São os bens e propriedades que podem ser vendidos e possuem valor mensurável. Ex.: Imóvel e veículo';
  const textNotEquity =
    'São dívidas que não possuem nenhum bem ou propriedade como garantia. Ex.: empréstimos e consignados';
  const textCoverage =
    'O índice de cobertura é a razão entre as reservas financeiras de liquidez sobre as despesas mensais. Ele mede a quantidade de tempo em que será possível manter o custo de vida familiar com os ativos de alta liquidez';
  return (
    <>
      <Home>
        <CardContainer>
          <Active
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalValue={activeVaule}
          />
          <Passive
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalValue={passiveVaule}
          />
          <LiquidPatrimony totalValue={liquidyValue} />
          <CoverageIndex totalValue={indexValue} text={textCoverage} />
        </CardContainer>
      </Home>
      <PatrimonyMapTable
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        materialAssets={getMaterialAssets()}
        financialAssets={getFinancialAssets()}
        equityPassive={getEquityPassive()}
        textEquity={textEquity}
        textFinancial={textFinancial}
        textMaterial={textMaterial}
        textNotEquity={textNotEquity}
      />
      <DirecionalButton />
    </>
  );
}
