import React from 'react';
import { Page } from 'components/templates/Page';
import { GeneralContext } from 'utils/contexts/GeneralContext';
import { GenericLoading } from 'components/atoms/GenericLoading';
import { getSuitabilityProfile } from 'development/suitability';
import { FilledSuitabilityProfile } from './FilledSuitabilityProfile';
import { EmptySuitabilityProfile } from './EmptySuitabilityProfile';

export const Suitability = ({ updateSuitabilityProfile }) => {
  const { loading } = React.useContext(GeneralContext);
  const [userInformation, setUserInformation] = React.useState(
    getSuitabilityProfile()
  );
  const [currentClientProfile, setCurrentClientProfile] = React.useState(
    userInformation.profileName !== 'Indefinido' ? 'filled' : 'empty'
  );

  const changeProfile = ({ answersGiven }) => {
    // TODO: Fazer a lógica real de atualizar o perfil
    setUserInformation(prev => ({ ...prev, profileName: 'Iniciante' }));
    updateSuitabilityProfile('Iniciante');
    setCurrentClientProfile('filled');
  };

  const updateProfile = () => {
    setCurrentClientProfile('empty');
  };

  const pages = {
    empty: <EmptySuitabilityProfile changeProfile={changeProfile} />,
    filled: (
      <FilledSuitabilityProfile
        updateProfile={updateProfile}
        profileName={userInformation.profileName}
        profileDescription={userInformation.profileDescription}
        profileImage={userInformation.profileImage}
      />
    ),
  };

  React.useEffect(() => {
    updateSuitabilityProfile(userInformation.profileName);
  }, [updateSuitabilityProfile, userInformation]);

  return (
    <Page.RootContainer>
      {loading ? (
        <GenericLoading className="empty-suitability-questionnaire" />
      ) : (
        pages[currentClientProfile]
      )}
    </Page.RootContainer>
  );
};
