import { EditSocialSecurity } from 'components/molecules/RetirementMapModal/EditSocialSecurity';
import { Card } from 'components/templates/Card';
import { Table } from 'components/templates/Tables';
import React, { useState } from 'react';

export const SocialSecurity = ({ socialSecurity }) => {
  const columnOrder = ['data', 'name01', 'name02'];

  const [showEdit, setShowEdit] = useState(false);

  const handleEditClick = () => {
    setShowEdit(true);
  };

  return (
    <Card.Container className="spending-table">
      <Table.Container className="responsive">
        <Table.TableBasic className="receipt-table">
          <Table.Thead>
            <Table.Tr className="receipt-table-title receipts">
              {socialSecurity.header.map((item, index) => (
                <Table.Th
                  className={`budget-table-title ${index === 0 &&
                    'first-table-border checkbox'}`}
                  first={index === 0}
                  key={index}
                >
                  {index === 0 ? (
                    item
                  ) : (
                    <div
                      onClick={handleEditClick}
                      style={{ cursor: 'pointer' }}
                    >
                      <Table.ContentIcon item={item} type="pencil-clip-clip" />
                    </div>
                  )}
                </Table.Th>
              ))}
              <Table.Th className="budget-table-title empty last-table-border" />
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {socialSecurity.body.map((column, rowIndex) => (
              <Table.Tr className="receipt-table-body" key={rowIndex}>
                {columnOrder.map((item, index) => {
                  const currentItem = column[item];
                  return (
                    <Table.Td
                      className={`receipt-table-body ${item === 'data' &&
                        'first-table-border checkbox'}`}
                      first={item === 'data'}
                      key={index}
                    >
                      {currentItem}
                    </Table.Td>
                  );
                })}
                <Table.Td />
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table.TableBasic>
      </Table.Container>

      {showEdit && (
        <EditSocialSecurity
          showModal={showEdit}
          setShowModal={setShowEdit}
          theme="edit"
        />
      )}
    </Card.Container>
  );
};
