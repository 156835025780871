import React from "react";
import { Page } from "components/templates/Page";
import { AssetManagementNavigationCard } from "components/molecules/AssetManagementNavigationCard";
import { getWealthManagementNavigationMenu } from "development/wealthManagement";
import { Suitability } from "components/organisms/Suitability";
import { FinancialAssets } from "components/organisms/FinancialAssets";
import { ImmediateLiquidity } from "components/organisms/ImmediateLiquidity";

export default function PlanningAssets() {
    const [wealthManagementNavigationMenu, setWealthManagementNavigationMenu] = React.useState(getWealthManagementNavigationMenu());
    const [currentPage, setCurrentPage] = React.useState('financial-assets');

    const updateSuitabilityProfile = (newProfile) => {
        setWealthManagementNavigationMenu(prev => {
            return {
                ...prev,
                currentInvestorProfile: newProfile,
            }
        })
    }

    const page = {
        'financial-assets': <FinancialAssets />,
        'suitability': <Suitability updateSuitabilityProfile={updateSuitabilityProfile} />,
        'liquidity': <ImmediateLiquidity />,
    }

    return (
        <Page.RootContainer>
            <AssetManagementNavigationCard
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                valueOfAvailableFinancialEquity={wealthManagementNavigationMenu.valueOfAvailableFinancialEquity}
                currentInvestorProfile={wealthManagementNavigationMenu.currentInvestorProfile}
                liquidityNeedPercentage={wealthManagementNavigationMenu.liquidityNeedPercentage}
                currentLiquidityPercentage={wealthManagementNavigationMenu.currentLiquidityPercentage}
                nonCompliancePercentage={wealthManagementNavigationMenu.nonCompliancePercentage}
            />
            {page[currentPage] || []}
        </Page.RootContainer>
    )
}