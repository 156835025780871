import Checkbox from 'components/atoms/Checkbox';
import { Table } from 'components/atoms/Table';
import { Td } from 'components/atoms/Td';
import { Th } from 'components/atoms/Th';
import { Tr } from 'components/atoms/Tr';
import { BudgetTableTitle } from 'components/molecules/BudgetTableTitle';
import React from 'react';
import { CardContainer, Container } from './styles';

export const SimpleTableWithCheckboxAtTheEnd = ({
  body,
  nameTable,
  totalCategoryValue,
  totalCategoryPercentage,
  showEditIconOnTitle,
  typeCard,
}) => {
  const header = [
    'Passivo',
    'Valor da Parcela',
    'Número de Parcelas',
    'Valor total',
    'Quitado',
  ]

  const columnOrder = [
    'name',
    'installment',
    'remaining_installments',
    'in_cash',
  ]

  return (
    <CardContainer className="no-footer">
      <BudgetTableTitle
        nameTable={nameTable}
        total={totalCategoryValue}
        percentage={totalCategoryPercentage}
        showEditIcon={showEditIconOnTitle}
        showGoldIcon
        className={typeCard}
      />
      <Container>
        <Table className="receipt-table">
          <thead>
            <Tr className="receipt-table-title debts">
              {header.map((item, index) => (
                <Th
                  className={`budget-table-title  ${index === 0 &&
                    'first-table-border '} ${index === 4 && 'debts'} `}
                  first={index === 0}
                  key={index}
                >
                  {item}
                </Th>
              ))}
              <Th className="budget-table-title empty last-table-border final" />
            </Tr>
          </thead>
          <tbody>
            {body.map((row, rowIndex) => (
              <Tr className="receipt-table-body" key={rowIndex}>
                {columnOrder.map((item, index) => {
                  return (
                    <Td className="receipt-table-body " key={index}>
                      {row[item]}
                    </Td>
                  );
                })}
                <Td className="receipt-table-body ">
                  <Checkbox checked={row.settled} className="circular" />
                </Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </CardContainer>
  );
};
