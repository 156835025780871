import React, { useCallback, useEffect, useState } from 'react';
import { SimpleTableWithCheckboxAtTheEnd } from 'components/templates/Table/SimpleTableWithCheckboxAtTheEnd';
import { InstallmentPurchaseTable } from 'components/templates/Table/InstallmentPurchaseTable';
import { EditDebtItemModal } from 'components/molecules/BudgetModal/EditDebtItemModal';
import { SimpleDeleteModal } from 'components/molecules/BudgetModal/SimpleDeleteModal';
import useBudgetManagement from 'hooks/useBudgetManagement';

export const DebtMappingPage = ({ data, reloadPageData }) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [spendingCategories, setSpendingCategories] = useState([]);

  const {
    deleteInstallments,
    deleteManyInstallments,
    getCategoriesWithItems,
    createInstallment,
    updateInstallment,
  } = useBudgetManagement();

  const handleDelete = async () => {
    const delectedItems = await deleteManyInstallments(selectedItems);
    if (delectedItems) {
      await reloadPageData();
      setShowDeleteModal(false);
      setSelectedItems([]);
    }
  }

  const deleteUniqueItem = async () => {
    const deletedItem = await deleteInstallments(selectedItem.id);
    if (deletedItem) {
      await reloadPageData();
      setShowModal(false);
      setSelectedItem(null);
    }
  }

  const addNewItem = async (data) => {
    const createdItem = await createInstallment(data);

    if (createdItem) {
      await reloadPageData();
      setShowAddModal(false);
      setSelectedItem(null);
    }
  }

  const editItem = async (data) => {
    const updatedItem = await updateInstallment(data);

    if (updatedItem) {
      await reloadPageData();
      setShowModal(false);
      setSelectedItem(null);
    }
  }

  const getCategories = useCallback(async () => {
    const categories = await getCategoriesWithItems({
      type: 'spendings',
      items: true
    });
    setSpendingCategories(categories);
  }, [getCategoriesWithItems])

  useEffect(() => {
    getCategories()
  }, [getCategories])

  return (
    <>
      {showModal && (
        <EditDebtItemModal
          showModal={showModal}
          setShowModal={setShowModal}
          selectedItem={selectedItem}
          title="Editar item"
          icon="edit"
          onCancel={editItem}
          cancelButtonText='Concluído'
          confirmButtonText='Excluir item'
          onConfirm={deleteUniqueItem}
          theme='debts'
          allCategories={spendingCategories}
        />
      )}
      {showAddModal && (
        <EditDebtItemModal
          showModal={showAddModal}
          setShowModal={setShowAddModal}
          title="Adicionar item"
          icon="add"
          onCancel={addNewItem}
          cancelButtonText='Concluído'
          confirmButtonText='Excluir item'
          theme='debts'
          allCategories={spendingCategories}
        />
      )}
      {showDeleteModal && (
        <SimpleDeleteModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          title={selectedItems.length > 1 ? 'Excluir itens' : 'Excluir item'}
          text={selectedItems.length > 1 ? 'Excluir itens?' : 'Excluir item?'}
          highlightedText='Esta ação é permanente e não poderá ser desfeita'
          onConfirm={handleDelete}
          cancelButtonText='Cancelar'
          confirmButtonText={selectedItems.length > 1 ? 'Excluir itens' : 'Excluir item'}
          theme='debts'
        />
      )}
      <InstallmentPurchaseTable
        body={data.installments.data}
        setShowModal={setShowModal}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        nameTable="Compras Parceladas"
        totalCategoryPercentage={data.installments.percentage}
        totalCategoryValue={data.installments.total}
        showEditIconOnTitle={false}
        typeCard="debts"
        className="color-debts"
        showAddModal={showAddModal}
        setShowAddModal={setShowAddModal}
        setSelectedItem={setSelectedItem}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
      />
      <SimpleTableWithCheckboxAtTheEnd
        body={data.patrimonial_passive.data}
        nameTable="Passivos Patrimoniais"
        totalCategoryPercentage={
          data.patrimonial_passive.percentage
        }
        totalCategoryValue={
          data.patrimonial_passive.total
        }
        showEditIconOnTitle={false}
        typeCard="debts"
      />
      <SimpleTableWithCheckboxAtTheEnd
        body={data.financial_passive.data}
        nameTable="Passivos Financeiros"
        totalCategoryPercentage={
          data.financial_passive.percentage
        }
        totalCategoryValue={
          data.financial_passive.total
        }
        showEditIconOnTitle={false}
        typeCard="debts"
      />
    </>
  );
};
