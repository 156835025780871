import * as React from "react"
import { SVG } from "./styles"

export const HousingCategoryIcon = ({
    className = '',
}) => {
    return (
        <SVG
            className={className}
            viewBox="0 0 23 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.233 17.06H20.7v-6.48l.224.21c.144.136.34.212.543.211a.793.793 0 00.543-.211.7.7 0 00.224-.51.7.7 0 00-.225-.51L12.584.921A1.585 1.585 0 0011.5.5c-.406 0-.796.152-1.084.421L.991 9.771a.699.699 0 00-.224.508c0 .191.08.374.224.51.144.134.34.21.543.21a.793.793 0 00.542-.211l.224-.208v6.48H.767a.793.793 0 00-.542.21.699.699 0 00-.225.51c0 .191.08.374.225.51.143.134.338.21.542.21h21.466a.792.792 0 00.542-.21.698.698 0 00.225-.51c0-.19-.08-.374-.225-.51a.792.792 0 00-.542-.21zm-18.4-7.92l7.667-7.2 7.667 7.2v7.92h-4.6v-5.04a.698.698 0 00-.225-.509.792.792 0 00-.542-.21H9.2a.792.792 0 00-.542.21.698.698 0 00-.225.51v5.039h-4.6V9.14zm9.2 7.92H9.967v-4.32h3.066v4.32z"
                fill="#0C053F"
            />
        </SVG>
    )
}
