import styled from "styled-components";

export const InputContainer = styled.div`
  display: ${props => (props.show ? 'flex' : 'none')};
  justify-content: space-between;
  width: 100%;

  &.not-separate {
    justify-content: inherit;
  }
`;