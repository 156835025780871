import styled from "styled-components";

export const Container = styled.div`
    &.asset-management-doughnut-grafic-description {
        height: min-content;
    }
`;

export const Svg = styled.svg`
    &.asset-management-doughnut-grafic-description {
        width: ${props => props.theme.getResponsiveWidth(10)};
        height: ${props => props.theme.getResponsiveWidth(5)};
        
        path {
            fill: ${({ theme }) => theme.downIcon};
        }

        @media (max-width: 1023px) {
            width: 5.3px;
            height: 2.7px;
        }

        &.liquid-status-negative {
            path {
                fill: ${({ theme }) => theme.redMedium};
            }
        }
    }
`;