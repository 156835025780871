import styled from 'styled-components';

export const CardContainer = styled.div`
  margin-top: ${({ theme }) => theme.getResponsiveHeight(36)};
  display: flex;
  gap: ${({ theme }) => theme.getResponsiveWidth(32)};

  @media (max-width: 1023px) {
    overflow: auto;
    gap: 17.066px;
    margin-top: 18.5px;
    padding-right: ${({ theme }) => theme.getResponsiveWidth(16)};
    padding-top: 6px;
    padding-bottom: 12px;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.scrollHoover};
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.scroll};
  }
`;

export const Home = styled.div`
  margin: 0 auto;
  width: ${props => props.theme.getResponsiveWidth(1600)};
`;
