import { DreamCardContainer } from "./DreamCardContainer";
import { DreamCardIcon } from "./DreamCardIcon";
import { DreamCardProgressBar } from "./DreamCardProgressBar";
import { DreamCardText } from "./DreamCardText";
import { DreamCardWrapper } from "./DreamCardWrapper";

export const DreamCard = {
    RootContainer: DreamCardContainer,
    Icon: DreamCardIcon,
    Text: DreamCardText,
    GroupItens: DreamCardWrapper,
    ProgressBar: DreamCardProgressBar,
}