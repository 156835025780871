import { AllocationCard } from 'components/molecules/AllocationCard';
import { Page } from 'components/templates/Page';
import React from 'react';
import { GraphSection } from './styles';
import { getAllocationData } from 'development';
import { InvestmentPanel } from 'components/molecules/InvestmentPanel';

export const FinancialAssets = () => {

    const allocationData = getAllocationData();

    return (
        <Page.RootContainer>
            <InvestmentPanel />
            <GraphSection>
                <AllocationCard
                    titleCard='Alocação atual'
                    allocationData={allocationData.currentAllocation}
                    labels={allocationData.labels}
                />
                <AllocationCard
                    titleCard='Alocação recomendada'
                    allocationData={allocationData.recommendedAllocation}
                    labels={allocationData.labels}
                />
            </GraphSection>
        </Page.RootContainer>
    )
}
