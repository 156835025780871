export const redirect = (link) => {
    window.location.href = link;
};

export const getPathName = () => {
    return window.location.pathname;
}

export const validatePathname = (pathname) => {
    const currentPathname = getPathName();

    if (Array.isArray(pathname)) {
        return pathname.includes(currentPathname);
    }

    return currentPathname === pathname;
}

export function addQueryParams(params) {
    const url = new URL(window.location.href);
    url.search = '';

    Object.keys(params).forEach(key => {
        url.searchParams.append(key, params[key]);
    });

    window.location.href = url.toString();
}

export function getQueryParams() {
    const params = new URLSearchParams(window.location.search);
    const queryParams = {};
    params.forEach((value, key) => {
        queryParams[key] = value;
    });
    return queryParams;
}

export function getQueryParamsByName(name) {
    const params = new URLSearchParams(window.location.search);
    return params.get(name);
}