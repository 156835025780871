import styled from 'styled-components';

export const Card = styled.div`
  &.dream-panel {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(
        180deg,
        rgba(12, 5, 63, 0) -2.75%,
        rgba(12, 5, 63, 0.5525) 50.46%,
        rgba(12, 5, 63, 0.85) 99.89%
      ),
      url(${props => props.backgroundImage});
    background-size: cover;
    background-position: left;
    justify-content: end;
    border-radius: 13px;
    overflow: hidden;
    width: 35%;

    padding: 10px;
  }

`;

export const Title = styled.div`
  &.dream-panel {
    display: flex;
    align-items: center;
    gap: 10%;
    overflow: hidden;
    margin-bottom: 13px;
  }
`;

export const ProgressBarContainer = styled.div`
  &.dream-panel {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: ${({ theme }) => theme.white};
    color: red;
    margin-bottom: 5px;
  }
`;

export const GroupItens = styled.div`
  display: flex;
  justify-content: space-between;
`;
