import styled from "styled-components";

export const Container = styled.div`
    background-color: ${props => props.theme.white};
    margin-top: 31px;
    width: 32.08vw;
    height: 77.46vh;
    border-radius: 16px;
    padding: 24px 25px 23px 25px;
    margin-bottom: 52px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    @media (max-width: 1440px){
        height: 75.46vh;
        margin-top: 18.1px;
        padding: 18.1px 19.1px 17.1px 19.1px;
        margin-bottom: 22.9px;
    }
    
    @media (max-width: 1024px){
        width: 100%;
    }
`;

export const GroupItens = styled.div`
    height: 93%;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 19px;

    @media (max-width: 1440px) {
        gap: 15px;
    }

    ::-webkit-scrollbar {
        width: 8px;

        @media (max-width: 1440px) {
            width: 6px;
        }

        @media (max-width: 1024px) {
            width: 4px;
        }
    }

    ::-webkit-scrollbar-thumb {
        background-color: ${props => props.theme.blackScroll};
        border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
        background: transparent;
    }

    scrollbar-width: thin;
    scrollbar-color: ${props => props.theme.blackScroll} transparent; 
`;
