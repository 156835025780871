import styled from "styled-components";

export const CloudWithAddIconContainer = styled.svg`
    &.empty-goal-panel {
        width: ${props => props.theme.getResponsiveWidth(64)};
        height: ${props => props.theme.getResponsiveWidth(45)};

        path {
            stroke: ${({ theme }) => theme.graySilver};
        }

        @media (max-width: 1023px) {
            width: 34.13px;
            height: 24px;
        }
    }
`;