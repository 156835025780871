import styled from 'styled-components';

export const Td = styled.td`
  &.receipt-table-body {
    height: ${props => props.theme.getResponsiveHeight(44)};
    padding: ${props => props.theme.getResponsiveHeight(16)},
      ${props => props.theme.getResponsiveHeight(24)};
    text-align: ${props => (props.first ? 'left' : 'center')};
    font-size: ${props => props.theme.getResponsiveHeight(14)};
    line-height: ${props => props.theme.getResponsiveHeight(16)};
    color: ${({ theme }) => theme.grayShadow};
    vertical-align: middle;
    font-family: ${props => props.theme.fonts.AperturaRegular};
    font-weight: 400;

    &.checkbox {
      text-align: left;
      width: min-content;
    }

    &.options {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  &.debts {
    width: min-content;
    height: min-content;
    text-align: right;
  }
`;
