import React from 'react';
import { Legend } from 'components/molecules/Legend';
import { StyledContainer } from './styles';

export const LegendTable = ({
  getCategoryInformation,
  categoryId,
  categoryClassification,
  nameTable,
  setShowModal,
  valuePEC,
  valueCommitted,
  total,
}) => {
  const edit = () => {
    setShowModal(prev => !prev);
  };
  return (
    <StyledContainer>
      <Legend
        title="Eventual Comprometido"
        subtitle="Total"
        value={total}
        className="legend-table"
      />
      <Legend
        title="Quanto você tem"
        subtitle="Total"
        value={valueCommitted}
        type="edit"
        className="legend-table category-title spending-color"
        onClick={edit}
      />
      <Legend
        title="Provisão para Eventuais 
        Comprometidos (PEC)"
        subtitle="Total"
        value={valuePEC}
        className="legend-table"
      />
    </StyledContainer>
  );
};
