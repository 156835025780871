import styled from 'styled-components';

export const StyledIcon = styled.svg`
  width: ${props => props.theme.getResponsiveWidth(36)};
  height: ${props => props.theme.getResponsiveHeight(36)};
  min-width: 14.4px;
  min-height: 14.4px;

  &.selected-patrimony path {
    stroke: ${({ theme }) => theme.white};
  }
`;
