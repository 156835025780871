import React from "react";
import { Group, Container } from "./styles";
import { SelectImageIcon } from "components/atoms/icons/SelectImageIcon";
import { Span } from "components/atoms/Span";

export const SelectImageCard = ({
    className = '',
    onClick = () => { },
    text = '',
}) => {
    return (
        <Container className="gallery-modal" onClick={onClick}>
            <Group className="gallery-modal">
                <SelectImageIcon
                    className={className}
                />
                <Span className="gallery-modal">
                    {text}
                </Span>
            </Group>
        </Container>
    )
}