import styled from "styled-components";

export const Container = styled.div`
    padding-top: ${props => props.theme.getResponsiveWidth(32)};
    background-color: ${props => props.theme.white};
    width: 100%;
    margin-top: ${props => props.theme.getResponsiveWidth(34)};
    border-radius: ${props => props.theme.getResponsiveWidth(16)};
    padding-left: ${props => props.theme.getResponsiveWidth(27)};
    padding-right: ${props => props.theme.getResponsiveWidth(23)};
    padding-bottom: ${props => props.theme.getResponsiveWidth(32)};
    
    @media (max-width: 1023px){
        padding-bottom: 17.1px;
        padding-left: 14.4px;
        padding-right: 12.3px;
        padding-top: 17.1px;
        margin-top: 18.1px;
        border-radius: 8.5px;
    }
`;

export const Title = styled.div`
    display: flex;
    align-items: baseline;
`;

export const LoadingContainer = styled.div`
    width: 100%;
    overflow: auto;
`;

export const ScrollContainer = styled.div`
    width: 100%;
    margin-top: ${({ theme }) => theme.getResponsiveWidth(28)};

    @media (max-width: 1023px) {
      margin-top: 14.9px;
    }
`;