import React from 'react';
import { CardContainer } from './styles';
import { SocialSecurity } from './SocialSecurity';
import { Rent } from './Rent';
import { FinancialPatrimony } from './FinancialPatrimony';
import { RetirementPercent } from './Retirement';
import { getNavegationCardRetirement } from 'development/retirement';
import { GeneralContext } from 'utils/contexts/GeneralContext';
import { GenericLoading } from 'components/atoms/GenericLoading';

export default function RetirementMapCard({ currentPage, setCurrentPage }) {
  const { loading } = React.useContext(GeneralContext);

  const page = {
    loading: (
      <CardContainer>
        {Array.from({ length: 4 }, (_, index) => (
          <GenericLoading className="goal-panel-navigation-card " key={index} />
        ))}
      </CardContainer>
    ),

    content: (
      <CardContainer>
        <SocialSecurity
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalValue={getNavegationCardRetirement().activeValue}
        />
        <Rent
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalValue={getNavegationCardRetirement().rentValue}
        />
        <FinancialPatrimony
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalValue={getNavegationCardRetirement().financialPatrimony}
        />
        <RetirementPercent
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalValue={getNavegationCardRetirement().percentValue}
        />
      </CardContainer>
    ),
  };
  return page[loading ? 'loading' : 'content'];
}
