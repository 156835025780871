import React from "react";
import { StyledCalendarIcon, StyledCalendarIconContainer } from "./styles"

export const CalendarIcon = ({
    className = '',
    onClick = () => { },
}) => {
    return (
        <StyledCalendarIconContainer className={className} onClick={onClick}>
            <StyledCalendarIcon
                width={15}
                height={15}
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <path
                    d="M12.585 2.194v-.98A.705.705 0 0011.889.5h-.83a.705.705 0 00-.696.713v.943H4.468v-.943A.705.705 0 003.772.5h-.83a.705.705 0 00-.696.713V2.23C1.238 2.492.5 3.428.5 4.527v7.603c0 1.307 1.038 2.37 2.313 2.37h9.374c1.275 0 2.313-1.063 2.313-2.37V4.527c0-1.168-.83-2.139-1.915-2.333zm-1.322-.77h.42v2.974h-.42V1.423zm-8.119 0h.42v2.974h-.42V1.423zm-.901 1.781v1.403c0 .393.312.713.696.713h.83c.383 0 .695-.32.695-.713V3.08h5.896v1.527c0 .393.312.713.696.713h.83c.383 0 .695-.32.695-.713V3.14c.586.176 1.014.73 1.014 1.387v1.698H1.401V4.528c0-.586.345-1.096.842-1.321v-.002zm9.944 10.372H2.813c-.779 0-1.412-.649-1.412-1.447V7.148H13.6v4.982c0 .798-.633 1.447-1.412 1.447z"
                    fill="#969797"
                />
            </StyledCalendarIcon>
        </StyledCalendarIconContainer>
    )
}