import React, { useState, useEffect, useMemo } from 'react';
import { EstimatedDetail } from 'components/molecules/EstimatedDetail';
import { RealizedDetail } from 'components/molecules/RealizedDetail';
import { Card } from 'components/templates/Card';
import { Table } from 'components/templates/Tables';
import { getPotDetails } from 'development/potDetail';
import { monthNumberToName } from 'utils/date';
import { GenericLoading } from 'components/atoms/GenericLoading';
import { GeneralContext } from 'utils/contexts/GeneralContext';

export const PotDetail = () => {
  const [potDetails, setPotDetails] = useState({});
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const { loading } = React.useContext(GeneralContext);

  useEffect(() => {
    const details = getPotDetails();
    setPotDetails(details);

    const now = new Date();
    setSelectedYear(now.getFullYear().toString());
    setSelectedMonth(String(now.getMonth() + 1).padStart(2, '0'));
  }, []);

  const yearOptions = useMemo(
    () => Object.keys(potDetails).map(year => ({ label: year, value: year })),
    [potDetails]
  );

  const monthOptions = useMemo(() => {
    if (!selectedYear || !potDetails[selectedYear]) return [];
    return potDetails[selectedYear]
      .map(item => item.month)
      .sort((a, b) => a.localeCompare(b))
      .map(month => ({
        label: monthNumberToName(month),
        value: month,
      }));
  }, [selectedYear, potDetails]);

  useEffect(() => {
    if (
      monthOptions.length &&
      !monthOptions.some(option => option.value === selectedMonth)
    ) {
      setSelectedMonth(monthOptions[0].value);
    }
  }, [monthOptions, selectedMonth]);

  const filteredData = useMemo(
    () =>
      (potDetails[selectedYear] || []).find(
        item => item.month === selectedMonth
      ),
    [selectedYear, selectedMonth, potDetails]
  );

  const page = {
    loading: (
      <Card.Container>
        <GenericLoading className="detail-pot" />
      </Card.Container>
    ),
    content: (
      <Card.Container className="pot-detail">
        <Table.TitleDropdown
          className="none"
          nameTable="Detalhamento do Pote"
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
          setSelectedYear={setSelectedYear}
          setSelectedMonth={setSelectedMonth}
          yearOptions={yearOptions}
          monthOptions={monthOptions}
        />
        <Card.Container className="card-detail">
          {filteredData && (
            <>
              <EstimatedDetail budget={filteredData.potEstimated} />
              <RealizedDetail budget={filteredData.potRealized} />
            </>
          )}
        </Card.Container>
      </Card.Container>
    ),
  };

  return page[loading ? 'loading' : 'content'];
};
