import styled from 'styled-components';

export const ChartWrapper = styled.div`
  padding-left: ${({ theme }) => theme.getResponsiveWidth(10)};
  width: 100%;
  height: 100%;
  margin-top: ${({ theme }) => theme.getResponsiveHeight(10)};
`;
export const Container = styled.div`
  display: flex;
  align-items: flex-end;
`;
export const Title = styled.h2`
  color: ${props => props.theme.blueRoyal};
  font-family: ${props => props.theme.fonts.AperturaRegular};
  font-size: ${({ theme }) => theme.getResponsiveHeight(14)};
  margin-bottom: ${({ theme }) => theme.getResponsiveHeight(10)};

  &.year {
    margin-left: ${({ theme }) => theme.getResponsiveWidth(5)};
    margin-bottom: ${({ theme }) => theme.getResponsiveWidth(25)};
  }
`;

export const CanvasWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const StyledCanvas = styled.canvas`
  width: auto;
  height: auto;
`;
