import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1440px){
        height: 38px;
    }
`;

export const GroupItens = styled.div`
    display: flex;
    align-items: center;
    
    &.title {
        width: 29.31%;
        gap: 2.63%;
    }
`

export const GroupDropdown = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 63.87%;

    @media (max-width: 1023px) {
        justify-content: right;
        gap: 5px;
    }
`;