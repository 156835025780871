import * as React from 'react';
import { DebtsIconContainer } from './styles';

function DebtsIcon({className }) {
  return (
    <DebtsIconContainer
      className={className}
      viewBox="0 0 47 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.474 0A4.481 4.481 0 000 4.479V27.52A4.481 4.481 0 004.474 32h38.052A4.481 4.481 0 0047 27.521V4.48A4.481 4.481 0 0042.526 0H4.474zm0 1.706h38.052a2.776 2.776 0 012.77 2.773V6.52H1.704V4.48a2.776 2.776 0 012.77-2.773zm40.822 6.52v4.714H1.704V8.226h43.592zm-2.77 22.066H4.474a2.776 2.776 0 01-2.77-2.773V14.644h43.59v12.875a2.776 2.776 0 01-2.77 2.773h.002z"
        fill="#B1113B"
      />
      <path
        d="M40.776 22.59h-9.048c-1.159 0-2.1.941-2.1 2.1v.116c0 1.16.941 2.102 2.1 2.102h9.048c1.16 0 2.1-.942 2.1-2.102v-.115c0-1.16-.94-2.102-2.1-2.102zm.398 2.216a.4.4 0 01-.398.398h-9.048a.397.397 0 01-.396-.398v-.115c0-.218.178-.399.396-.399h9.048a.4.4 0 01.398.399v.115z"
        fill="#B1113B"
      />
    </DebtsIconContainer>
  );
}

export default DebtsIcon;
