import React, { useContext } from 'react';
import { Card } from 'components/templates/Card';
import { GeneralContext } from 'utils/contexts/GeneralContext';

export const RetirementPercent = ({
  title = 'Aposentadoria alcançada',
  icon = 'percentage',
  totalValue,
  text,
}) => {
  const { loading } = useContext(GeneralContext);

  const loadingClass = loading ? 'loading' : '';

  return (
    <Card.Container className={`empyt ${loadingClass}`}>
      <Card.BodyContainer className={`budget ${loadingClass}`}>
        <Card.Title
          title={title}
          type={icon}
          className="spending-card passive blue teste"
          tooltipText={text}
        />
      </Card.BodyContainer>
      <Card.Content className="budget passive">
        <Card.ContentMoney
          className="passive blue"
          label="Percentual atual"
          value={totalValue}
        />
      </Card.Content>
    </Card.Container>
  );
};
