import React from "react";
import { DollarSignIcon } from "components/atoms/icons/DollarSignIcon";
import { GrowingUpIcon } from "components/atoms/icons/GrowingUpIcon";
import { HouseIcon } from "components/atoms/icons/HouseIcon";
import { SealedICon } from "components/atoms/icons/SealedICon";
import { PuzzleIcon } from "components/atoms/icons/PuzzleIcon";
import { PercentageIcon } from "components/atoms/icons/PercentageIcon";
import { DroupIcon } from "components/atoms/icons/DroupIcon";

export const NavigationCardIcon = ({
    icon = '',
    className = '',
}) => {

    const icons = {
        'house': <HouseIcon className={className} />,
        'growing-up': <GrowingUpIcon className={className} />,
        'dollar-sign': <DollarSignIcon className={className} />,
        'sealed': <SealedICon className={className} />,
        'puzzle': <PuzzleIcon className={className} />,
        'percentage': <PercentageIcon className={className} />,
        'droup': <DroupIcon className={className} />,
    }

    return icons[icon] || <></>;
}