import React from 'react';
import {
  GroupItens,
  TextContainer,
  TitleWrapper,
  TitleCardWrapper,
} from './styles';
import { Span } from 'components/atoms/Span';

export const TitleTotal = ({ nameTable, className, value, percentage }) => {
  return (
    <TextContainer className={className}>
      <GroupItens>
        <TitleWrapper>
          <Span className="table-title">{nameTable}</Span>
        </TitleWrapper>
      </GroupItens>
      <GroupItens className="total">
        <TitleCardWrapper>
          <Span className="category-title">Total</Span>
          <Span className="category-title">{value}</Span>
        </TitleCardWrapper>
        <TitleCardWrapper>
          <Span className={`category-title-two ${className}`}>Percentual</Span>
          <Span className={`category-title-two ${className}`}>
            {percentage}
          </Span>
        </TitleCardWrapper>
      </GroupItens>
    </TextContainer>
  );
};
