import React, { useState } from 'react';
import { StyledContainer } from './styles';
import { Overlay } from 'components/atoms/Overlay';
import PencilIcon from 'components/atoms/icons/PencilIcon';

const OptionOverlay = ({ onClick, className }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <StyledContainer onClick={onClick}>
      <PencilIcon
        className="category-title"
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      />
      <Overlay className={` budget ${isVisible && 'visible'} ${className}`}>
        Editar Item
      </Overlay>
    </StyledContainer>
  );
};

export default OptionOverlay;
