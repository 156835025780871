import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

export const DivContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.getResponsiveWidth(36)};
`;

export const Wrapper = styled.div`
  display: flex;
  margin-top: ${props => props.theme.getResponsiveHeight(24)};
  gap: ${props => props.theme.getResponsiveWidth(5)};
`;

export const ContainerOverlay = styled.div`
  position: relative;
`;
