import styled from 'styled-components';

export const StyledIcon = styled.svg`
  width: 34px;
  height: 28px;
  min-width: 14.4px;
  min-height: 14.4px;

  &.selected-patrimony path {
    stroke: ${({ theme }) => theme.white};
  }

  @media (max-width: 1440px) {
    width: 32px;
    height: 26px;
  }

  @media (max-width: 1280px) {
    width: 30px;
    height: 24px;
  }

  @media (max-width: 1024px) {
    width: 28px;
    height: 22px;
  }

  @media (max-width: 768px) {
    width: 26px;
    height: 20px;
  }
`;
