import styled from "styled-components";

export const SealedIConContainer = styled.svg`
    &.goal-panel {
        width: ${props => props.theme.getResponsiveWidth(20.67)};
        height: ${props => props.theme.getResponsiveWidth(25.83)};
        min-width: 11px;
        min-height: 13.8px;
        
        path {
            stroke: ${({ theme }) => theme.blueRoyal};
        }

        path:first-child {
            width: ${props => props.theme.getResponsiveWidth(7.75)};
            height: ${props => props.theme.getResponsiveWidth(14.21)};
            min-width: 4.1px;
            min-height: 7.6px;
        }

        path:last-child {
            width: ${props => props.theme.getResponsiveWidth(20.67)};
            height: ${props => props.theme.getResponsiveWidth(11.63)};
            min-width: 11px;
            min-height: 6.2px;
        }
    }
`;