import React from 'react';
import { DoughnutGrafic } from 'components/atoms/DoughnutGrafic';
import { FlexContainer } from './styles';

const ActiveGroup = ({ width, height, mainAssets, loading, className }) => {
  return (
    <FlexContainer className={className}>
      <DoughnutGrafic
        percentage={mainAssets[0].progress.scale}
        loading={loading}
        className="home first"
      />
      <DoughnutGrafic
        percentage={mainAssets[1].progress.scale}
        loading={loading}
        className="home second"
      />
      <DoughnutGrafic
        height={height}
        width={width}
        percentage={mainAssets[2].progress.scale}
        loading={loading}
        className="home third"
      />
    </FlexContainer>
  );
};

export default ActiveGroup;
