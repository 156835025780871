import React from "react";
import { Modal } from "components/templates/Modal";
import { GenericLoading } from "components/atoms/GenericLoading";

export const SimpleDeleteModal = ({
    showModal = false,
    setShowModal = () => { },
    title = "Excluir item",
    text = "",
    highlightedText = "",
    confirmButtonText = "Excluir categoria",
    cancelButtonText = "Cancelar",
    onConfirm = () => { },
    onCancel = () => { },
    theme = 'delete',
    loading = false,
    setLoading = () => { },
}) => {

    if (loading) {
        return (
            <Modal.Container
                showModal={showModal}
                setShowModal={setShowModal}
                className="simple-modal delete"
            >
                <Modal.TitleContainer>
                    <GenericLoading className="simple-modal-delete-header" />
                </Modal.TitleContainer>
                <Modal.BodyContainer>
                    <GenericLoading className="simple-modal-delete-body" />
                </Modal.BodyContainer>
                <Modal.SimpleFooter
                    loading={loading}
                    loadingClassname="simple-modal-delete-footer"
                />
            </Modal.Container>
        );
    }

    return (
        <Modal.Container
            showModal={showModal}
            setShowModal={setShowModal}
            className="simple-modal delete"
        >
            <Modal.TitleContainer>
                <Modal.SimpleTitleContent
                    title={title}
                    onClose={() => setShowModal(false)}
                    type='delete'
                    className="simple-modal"
                    theme={theme}
                />
            </Modal.TitleContainer>
            <Modal.BodyContainer>
                <Modal.HighlightedBodyContent
                    text={text}
                    highlightedText={highlightedText}
                    className="simple-modal body"
                />
            </Modal.BodyContainer>
            <Modal.SimpleFooter
                onConfirm={onConfirm}
                onCancel={() => setShowModal(false)}
                className={`simple-modal ${theme}`}
                confirmButtonText={confirmButtonText}
                cancelButtonText={cancelButtonText}
            />
        </Modal.Container>
    );
}