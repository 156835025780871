import React from 'react';
import { Modal } from 'components/templates/Modal';

export const DeleteEquityPassive = ({
  showModal = false,
  setShowModal = () => {},
  onConfirm = () => {},
  onCancel = () => {},
  theme,
}) => {
  const confirm = () => {
    setShowModal(false);
    onConfirm();
  };

  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="simple-modal edit"
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title="Excluir passivo"
          onClose={() => setShowModal(false)}
          type="delete"
          className="simple-modal"
          theme={theme}
        />
      </Modal.TitleContainer>
      <Modal.BodyContainer>
        <Modal.HighlightedBodyContent
          text="Excluir passivo? "
          highlightedText="Esta ação é permanente e não poderá ser desfeita"
          className="simple-modal body"
        />
      </Modal.BodyContainer>
      <Modal.SimpleFooter
        onConfirm={confirm}
        className={`simple-modal ${theme}`}
        confirmButtonText="Excluir Passivo"
        cancelButtonText="Cancelar"
      />
    </Modal.Container>
  );
};
