import styled from 'styled-components';
export const Container = styled.div`
  position: ${({ expanded }) => (expanded ? 'fixed' : 'relative')};
  display: flex;
  flex-shrink: 0;
  padding-top: 2%;
  padding-bottom: 2%;
  top: 0;
  overflow-x: hidden;
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.gradientDarkBlueMenu[0]} 0%,
    ${({ theme }) => theme.gradientDarkBlueMenu[1]} 51.51%,
    ${({ theme }) => theme.gradientDarkBlueMenu[2]} 100%
  );
  border-radius: 0 40px 40px 0;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 1000;
  height: 100vh;
  max-height: 100vh;
  width: ${({ expanded }) => (expanded ? '330px' : '60px')};
  box-sizing: border-box;

  .content {
    flex-grow: 1;
    overflow-y: auto;
    padding-top: 0;
    padding-bottom: 20px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 20px;
    background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.2));
    pointer-events: none;
    opacity: ${({ isScrolled }) => (isScrolled ? 1 : 0)};
    transition: opacity 0.3s;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.scrollbarColor || '#888'};
    border-radius: 10px;
  }
  ::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;
  @keyframes expandSidebar {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  div.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    padding: 0 10px;
    min-width: ${({ expanded }) => (expanded ? '330px' : '60px')};
    transition: width 0.3s ease-in-out;
  }
  ${props =>
    props.expanded &&
    `
    animation: expandSidebar 0.5s ease-in-out forwards;
  `}
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10%;

  &.align-center {
    justify-content: center;
    width: 100%;
  }
`;

export const SidebarIconContainer = styled.div`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 9px;
  max-width: 51px;
  max-height: 51px;
`;

export const SidebarTextContainer = styled.div`
  width: 100%;
  background-color: red;
  height: 42px;
  transition: ease-in-out 0.5s;
  flex: 1;
  cursor: pointer;
  border-radius: 8px;
  margin-top: 20px;
  color: ${({ theme }) => theme.white};
  font-weight: 700;
  white-space: nowrap;
  padding: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
`;
