import React, { useEffect, useState } from 'react';
import { Container, TitleContainer, Card } from './styles';
import ReceiptIcon from 'components/atoms/icons/ReceiptIcon';
import { H4 } from 'components/atoms/Headings/H4';
import MapBody from 'components/molecules/MapBody';

const ReceiptCard = ({
  currentPage,
  value = 0,
  loading = true,
  onChangePage = () => { }
}) => {
  const [selected, setSelected] = useState(currentPage === 'receipts');
  const [hovered, setHovered] = useState(currentPage === 'receipts');

  const receipt = selected || hovered ? 'selected' : 'receipts';
  const selectedClass = selected || hovered ? 'selected' : '';
  const loadingClass = loading ? 'loading' : '';

  const handleClick = () => {
    setSelected(!selected);
    onChangePage('receipts');
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  useEffect(() => {
    setSelected(currentPage === 'receipts');
    setHovered(currentPage === 'receipts');
  }, [currentPage]);

  return (
    <Container
      selected={selected}
      className={`${loadingClass} ${selectedClass}`}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Card className={loadingClass}>
        <TitleContainer>
          <ReceiptIcon className={selectedClass} />
          <H4 className={receipt}>Recebimentos</H4>
        </TitleContainer>
        <MapBody className={receipt} value={value} />
      </Card>
    </Container>
  );
};

export default ReceiptCard;
