import { GenericLoading } from 'components/atoms/GenericLoading';
import { NavigationCard } from 'components/templates/NavigationCard';
import React from 'react';
import { GeneralContext } from 'utils/contexts/GeneralContext';
import { CardContainer } from './styles';

export const AssetManagementNavigationCard = ({
  currentPage = 'financial-assets',
  setCurrentPage = () => {},
  valueOfAvailableFinancialEquity = '',
  currentInvestorProfile = '',
  liquidityNeedPercentage = '',
  currentLiquidityPercentage = '',
  nonCompliancePercentage = '',
}) => {
  const { loading } = React.useContext(GeneralContext);
  const [isHovered, setIsHovered] = React.useState(currentPage);

  const handleMouseEnter = page => {
    setIsHovered(page);
  };

  const handleMouseLeave = page => {
    setIsHovered(currentPage);
  };

  if (loading) {
    return (
      <CardContainer>
        {Array(4)
          .fill(0)
          .map((_, index) => (
            <GenericLoading
              className="goal-panel-navigation-card"
              key={index}
            />
          ))}
      </CardContainer>
    );
  }

  const upAndDownSelectorConfig = {
    positive:
      'asset-management-doughnut-grafic-description liquid-status-positive',
    negative:
      'asset-management-doughnut-grafic-description liquid-status-negative',
    normal: 'asset-management-doughnut-grafic-description',
  };

  const nameOfFinancialEquitySection = 'financial-assets';
  const nameOfSuitabilitySection = 'suitability';
  const liquiditySectionName = 'liquidity';

  const selectedPage = page => {
    return currentPage === page || isHovered === page;
  };

  const changePage = newPage => {
    setCurrentPage(newPage);
  };

  return (
    <CardContainer>
      <NavigationCard.Container
        className={`asset-management first ${selectedPage(
          nameOfFinancialEquitySection
        ) && 'hovered'}`}
        onClick={() => changePage(nameOfFinancialEquitySection)}
        onMouseEnter={() => handleMouseEnter(nameOfFinancialEquitySection)}
        onMouseLeave={() => handleMouseLeave(nameOfFinancialEquitySection)}
      >
        <NavigationCard.GroupItens className="goal-panel">
          <NavigationCard.Icon
            icon="growing-up"
            className={`goal-panel ${selectedPage(
              nameOfFinancialEquitySection
            ) && 'hovered'}`}
          />
        </NavigationCard.GroupItens>
        <NavigationCard.Title
          title="Patrimônio Financeiro"
          className={`goal-panel ${selectedPage(nameOfFinancialEquitySection) &&
            'hovered'}`}
        />
        <NavigationCard.AmountOfMoney
          className={`goal-panel ${selectedPage(nameOfFinancialEquitySection) &&
            'hovered'}`}
          label="Valor disponível"
          value={valueOfAvailableFinancialEquity}
        />
      </NavigationCard.Container>
      <NavigationCard.Container
        className={`asset-management second ${selectedPage(
          nameOfSuitabilitySection
        ) && 'hovered'}`}
        onClick={() => changePage(nameOfSuitabilitySection)}
        onMouseEnter={() => handleMouseEnter(nameOfSuitabilitySection)}
        onMouseLeave={() => handleMouseLeave(nameOfSuitabilitySection)}
      >
        <NavigationCard.GroupItens className="goal-panel">
          <NavigationCard.Icon
            icon="puzzle"
            className={`asset-management ${selectedPage(
              nameOfSuitabilitySection
            ) && 'hovered'}`}
          />
        </NavigationCard.GroupItens>
        <NavigationCard.Title
          title="Suitability"
          className={`goal-panel ${selectedPage(nameOfSuitabilitySection) &&
            'hovered'}`}
        />
        <NavigationCard.AmountOfMoney
          className={`goal-panel ${selectedPage(nameOfSuitabilitySection) &&
            'hovered'}`}
          label="Perfil investidor"
          value={currentInvestorProfile}
        />
      </NavigationCard.Container>
      <NavigationCard.Container
        className={`asset-management second ${selectedPage(
          liquiditySectionName
        ) && 'hovered'}`}
        onClick={() => changePage(liquiditySectionName)}
        onMouseEnter={() => handleMouseEnter(liquiditySectionName)}
        onMouseLeave={() => handleMouseLeave(liquiditySectionName)}
      >
        <NavigationCard.GroupItens className="goal-panel">
          <NavigationCard.Icon
            icon="droup"
            className={`asset-management ${
              selectedPage(liquiditySectionName) ? 'hovered' : ''
            }`}
          />
        </NavigationCard.GroupItens>
        <NavigationCard.Title
          title="Liquidez imediata"
          className={`goal-panel ${
            selectedPage(liquiditySectionName) ? 'hovered' : ''
          }`}
        />
        <NavigationCard.GroupItens className="asset-management">
          <NavigationCard.GroupItens className="asset-management-container">
            <NavigationCard.DoughnutGrafic
              percentage={liquidityNeedPercentage}
              className={`doughnut-grafic-asset-management first ${
                selectedPage(liquiditySectionName) ? 'hovered' : ''
              }`}
            />
            <NavigationCard.Text
              text="Necessidade"
              className={`asset-management-doughnut-grafic-description first ${
                selectedPage(liquiditySectionName) ? 'hovered' : ''
              }`}
            />
          </NavigationCard.GroupItens>
          <NavigationCard.GroupItens className="asset-management-container">
            <NavigationCard.DoughnutGrafic
              percentage={currentLiquidityPercentage}
              className={`doughnut-grafic-asset-management second ${
                selectedPage(liquiditySectionName) ? 'hovered' : ''
              }`}
            />
            <NavigationCard.GroupItens className="asset-management-container-liquity">
              <NavigationCard.UpAndDownSelector
                className={upAndDownSelectorConfig.normal}
              />
              <NavigationCard.Text
                text="Atual"
                className={`asset-management-doughnut-grafic-description second ${
                  selectedPage(liquiditySectionName) ? 'hovered' : ''
                }`}
              />
            </NavigationCard.GroupItens>
          </NavigationCard.GroupItens>
        </NavigationCard.GroupItens>
      </NavigationCard.Container>
      <NavigationCard.Container className="goal-panel third sealed">
        <NavigationCard.GroupItens className="goal-panel">
          <NavigationCard.Icon icon="percentage" className="asset-management" />
        </NavigationCard.GroupItens>

        <NavigationCard.Title title="Desenquadramento" className="goal-panel" />
        <NavigationCard.AmountOfMoney
          className="goal-panel"
          label="Percentual"
          value={nonCompliancePercentage}
        />
      </NavigationCard.Container>
    </CardContainer>
  );
};
