import * as React from 'react';
import { StyledExitIcon } from './styles';

function ExitIcon({ className }) {
  return (
    <StyledExitIcon
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M33.989 19.867c-.002-.013-.006-.023-.009-.034l-.006-.021-.012-.042-.018-.038-.01-.021a.205.205 0 00-.015-.031.68.68 0 00-.06-.078l-3.269-3.508a.457.457 0 00-.68 0 .545.545 0 000 .73l2.448 2.627h-7.141c-.265 0-.48.23-.48.515 0 .138.05.268.14.365a.46.46 0 00.341.152h7.142l-2.448 2.627a.53.53 0 00-.141.366c0 .139.05.267.141.365a.47.47 0 00.34.151c.125 0 .247-.05.341-.15l3.269-3.509a.462.462 0 00.06-.08l.014-.027.01-.02c.007-.014.014-.026.02-.041a.178.178 0 00.011-.04l-.051-.015.057-.006c.003-.011.007-.023.009-.035a.493.493 0 00.008-.09v-.02a.488.488 0 00-.008-.092h-.003z"
        fill="#fff"
      />
      <path
        d="M27.551 16.191c.365 0 .66-.282.66-.631v-3.586a.645.645 0 00-.66-.632H12.66s-.01 0-.015.002a.66.66 0 00-.106.01c-.011.001-.022.004-.033.007a.696.696 0 00-.228.098l-.01.007a.822.822 0 00-.067.055l-.02.02a.648.648 0 00-.058.068l-.013.017a.671.671 0 00-.094.212l-.004.022a.587.587 0 00-.012.113V29.1c0 .236.138.452.356.561l9.307 4.61a.679.679 0 00.646-.023.626.626 0 00.317-.538v-3.979h4.923c.366 0 .66-.282.66-.63v-3.78a.645.645 0 00-.66-.63.645.645 0 00-.66.63v3.148h-4.263V14.763a.633.633 0 00-.463-.602l-5.191-1.556h9.918v2.955c0 .35.294.631.66.631h.001zm-6.242 16.485l-7.988-3.958V12.834l7.988 2.393v17.45-.001z"
        fill="#fff"
      />
    </StyledExitIcon>
  );
}

export default ExitIcon;
