import React from 'react';
import { LegendCard } from './styles';
import { SubtitleDetail } from '../SubtitleDetail';

export const LegendPotDetail = ({
  budget,
  getValue,
  cutItems,
  handleClick,
  items,
}) => {
  return (
    <LegendCard>
      {items.map(({ key, text, className }) => (
        <SubtitleDetail
          key={key}
          text={text}
          value={getValue(key, budget[key])}
          className={`${className} ${cutItems[key] ? 'line' : ''}`}
          onClick={() => handleClick(key)}
        />
      ))}
    </LegendCard>
  );
};
