import styled from "styled-components";

export const ProgressBarContainer = styled.div`
  &.main {
    width: 100%;
  }

  &.extract-category {
    height: 20px;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    margin-top: 8px;
    width: 100%;

    @media (max-width: 1440px) {
      height: 16px;
      margin-top: 6px;
    }
  }

  &.extract-category-item {
    width: 100%;
    height: 9px;
    border-radius: 20px;
    overflow: hidden;
    display: flex;

    @media (max-width: 1440px) {
      height: 7px;
      border-radius: 14px;
    }
  }
`;

export const ProgressBarModel = styled.div`
  &.extract-category {
    height: 100%;
    background-color: ${props => props.color};
    width: ${(props) => props.width}%;
    transition: width 0.3s ease-in-out;
    border-radius: 4px;
  }

  &.extract-category-item {
    height: 100%;
    background-color: ${props => props.color};
    width: ${(props) => props.width}%;
    transition: width 0.3s ease-in-out;
    border-radius: 20px;

    @media (max-width: 1440px) {
      border-radius: 14px;
    }
  }
`;

export const Tooltip = styled.div`
  position: absolute;
  background-color: ${props => props.theme.tooltipBackground};
  color: ${props => props.theme.white};
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.6rem;
  white-space: nowrap;
  pointer-events: none;
  z-index: 1000;
  transform: translate(-50%, -100%);
  transition: opacity 0.3s;
  
  ::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: ${props => props.theme.tooltipBackground} transparent transparent transparent;
  }

  @media (max-width: 1440px) {
    font-size: 0.5rem;
    padding: 3px 5px;
  }
`;
