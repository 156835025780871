import { css } from 'styled-components';

export const VerticalScrollbarStyles = css`
  /* Estilo para a barra de rolagem */
  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Estilo para o rastreador (a barra de rolagem propriamente dita) */
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.graySilver};
    border-radius: 50px;
  }

  /* Estilo para o fundo da barra de rolagem */
  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.grayPale};
    border-radius: 50px;
  }

  /* Estilo para os botões de rolagem (setas) */
  &::-webkit-scrollbar-button {
    display: none;
  }
`;
