import React, { useState } from "react";
import light from "styles/themes/light";
import { ProgressBarContainer, ProgressBarModel, Tooltip } from "./styles";

const CategoryProgressBar = ({
    redPercentage = 0,
    bluePercentage = 0,
    pinkPercentage = 0,
    darkBluePercentage = 0,
    darkGreenPercentage = 0,
    className
}) => {
    const [tooltip, setTooltip] = useState({ visible: false, content: "", position: {} });

    const red = light.redMedium;
    const blue = light.blueSky;
    const pink = light.pinkInstallmentPurchases;
    const gray = light.grayPale;
    const darkBlue = light.blueNavy;
    const darkGreen = light.greenDark;

    const totalPercentage = redPercentage + bluePercentage + pinkPercentage + darkBluePercentage + darkGreenPercentage;
    const remainingPercentage = totalPercentage < 100 ? 100 - totalPercentage : 0;

    const showTooltip = (event, content) => {
        const position = {
            top: event.clientY - 10,
            left: event.clientX
        };
        setTooltip({ visible: true, content, position });
    };

    const hideTooltip = () => {
        setTooltip({ visible: false, content: "", position: {} });
    };

    const messages = {
        red: 'Gastos parcelados, à vista e recorrentes',
        pink: 'Parcelados',
        blue: 'À vista e recorrentes',
    }

    return (
        <ProgressBarContainer className="main">
            <ProgressBarContainer className={className}>
                <ProgressBarModel
                    width={pinkPercentage}
                    color={pink}
                    className={className}
                    onMouseEnter={(e) => showTooltip(e, messages.pink)}
                    onMouseLeave={hideTooltip}
                />
                <ProgressBarModel
                    width={bluePercentage}
                    color={blue}
                    className={className}
                    onMouseEnter={(e) => showTooltip(e, messages.blue)}
                    onMouseLeave={hideTooltip}
                />
                <ProgressBarModel
                    width={redPercentage}
                    color={red}
                    className={className}
                    onMouseEnter={(e) => showTooltip(e, messages.red)}
                    onMouseLeave={hideTooltip}
                />
                <ProgressBarModel
                    width={darkBluePercentage}
                    color={darkBlue}
                    className={className}
                />
                <ProgressBarModel
                    width={darkGreenPercentage}
                    color={darkGreen}
                    className={className}
                />
                {remainingPercentage > 0 && (
                    <ProgressBarModel
                        width={remainingPercentage}
                        color={gray}
                        className={className}
                    />
                )}
            </ProgressBarContainer>
            {tooltip.visible && (
                <Tooltip style={{ top: `${tooltip.position.top}px`, left: `${tooltip.position.left}px` }}>
                    {tooltip.content}
                </Tooltip>
            )}
        </ProgressBarContainer>
    );
};

export default CategoryProgressBar;
