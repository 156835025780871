import React from "react";
import { BoxContainer } from "./styles";
import ReceiptIcon from 'components/atoms/icons/ReceiptIcon';
import InvestmentsIcon from 'components/atoms/icons/InvestmentsIcon';
import SpendingsIcon from 'components/atoms/icons/SpendingsIcon';

export const BoxIcon = ({
    className = '',
    icon = '',
    selected = null,
    hovered = null,
    onClick = () => { },
    onMouseEnter = () => { },
    onMouseLeave = () => { },
}) => {

    const classname = `${className} ${selected ? 'selected' : hovered ? 'hovered' : ''}`;

    const icons = {
        receipt: <ReceiptIcon className={classname} />,
        investments: <InvestmentsIcon className={classname} />,
        spendings: <SpendingsIcon className={classname} />,
    };

    return (
        <BoxContainer
            className={classname}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {icons[icon]}
        </BoxContainer>
    )
}