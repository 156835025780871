import React from "react";
import { Container, StyledIcon } from "./styles";

export const RoundVerifiedIcon = ({
    className = '',
}) => {
    return (
        <Container className={className}>
            <StyledIcon
                width={51}
                height={51}
                viewBox="0 0 51 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <path
                    d="M25.5 0C11.417 0 0 11.417 0 25.5 0 39.584 11.417 51 25.5 51 39.584 51 51 39.584 51 25.5 51 11.417 39.584 0 25.5 0zm0 47.863c-12.303 0-22.313-10.06-22.313-22.363 0-12.303 10.01-22.313 22.313-22.313 12.303 0 22.313 10.01 22.313 22.313 0 12.303-10.01 22.363-22.313 22.363zm10.177-31.694L20.715 31.225l-6.737-6.738a1.594 1.594 0 00-2.254 2.254l7.888 7.889a1.596 1.596 0 002.443-.231l15.877-15.975a1.595 1.595 0 00-2.255-2.254z"
                    fill="#fff"
                />
            </StyledIcon>
        </Container>
    )
}