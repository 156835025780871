import React, { useEffect, useState } from 'react';
import { CardContainer, Home } from './styles';
import DirecionalButton from 'components/organisms/Direcional';
import { CommittedMonthly } from 'components/organisms/SpendingCards/CommittedMonthly';
import { CommittedEventual } from 'components/organisms/SpendingCards/CommittedEventuals';
import { FlexibleEventual } from 'components/organisms/SpendingCards/FlexibleEventual';
import { FlexibleMonthly } from 'components/organisms/SpendingCards/FlexibleMonthly';
import { SpendingTable } from 'components/organisms/SpendingTable';
import useBudgetManagement from 'hooks/useBudgetManagement';
import { endOfMonth, startOfMonth } from 'date-fns';
import toast from 'react-hot-toast';
import { debounce } from 'lodash';

const INITIAL_COMMITTED_MONTHLY_DATA = [
  {
    item_id: 0,
    item_name: "-",
    category_id: 0,
    category_name: "-",
    when: 0,
    estimated: 0,
    accomplished: 0,
    recurrent: false,
    classification: "Mensal Comprometido",
    status: "-",
  }
]

const INITIAL_FLEXIBLE_MONTHLY_DATA = [
  {
    item_id: 0,
    item_name: "-",
    category_id: 0,
    category_name: "-",
    when: 0,
    estimated: 0,
    accomplished: 0,
    classification: "Mensal Flexível",
    balance_to_spend: 0,
    percentage: 0
  }
]

const INITIAL_FLEXIBLE_EVENTUAL_DATA = [
  {
    item_id: 0,
    item_name: "-",
    category_id: 0,
    category_name: "-",
    when: 0,
    estimated: 0,
    accomplished: 0,
    classification: "Mensal Flexível",
    balance_to_spend: 0,
    percentage: 0
  }
]

const INITIAL_NAVIGATION_CARD_DATA = {
  flexible_monthly: {
    accomplished: {
      raw: 0,
      formatted: "R$ 0"
    },
    estimated: {
      raw: 0,
      formatted: "R$ 0"
    },
    percentage: {
      raw: 0,
      formatted: "0%"
    },
    can_spend: {
      raw: 0,
      formatted: "R$ 0"
    }
  },
  flexible_eventual: {
    accomplished: {
      raw: 0,
      formatted: "R$ 0"
    },
    estimated: {
      raw: 0,
      formatted: "R$ 0"
    },
    percentage: {
      raw: 0,
      formatted: "0%"
    },
    can_spend: {
      raw: 0,
      formatted: "R$ 0"
    }
  },
  monthly_committed: {
    accomplished: {
      raw: 0,
      formatted: "R$ 0"
    },
    estimated: {
      raw: 0,
      formatted: "R$ 0"
    },
    percentage: {
      raw: 0,
      formatted: "0%"
    },
    can_spend: {
      raw: 0,
      formatted: "R$ 0"
    }
  },
  eventual_committed: {
    accomplished: {
      raw: 0,
      formatted: "R$ 0"
    },
    estimated: {
      raw: 0,
      formatted: "R$ 0"
    },
    percentage: {
      raw: 0,
      formatted: "0%"
    },
    can_spend: {
      raw: 0,
      formatted: "R$ 0"
    }
  }
}

const INITIAL_EVENTUAL_COMMITTED_DATA = {
  total: {
    raw: 0,
    formatted: "R$ 0",
  },
  accomplished: {
    raw: 0,
    formatted: "R$ 0",
  },
  accumulated_value: {
    id: null,
    value: {
      raw: 0,
      formatted: "R$ 0",
    }
  },
  pec_prevision: {
    raw: 0,
    formatted: "R$ 0",
  },
  items: [
    {
      item_id: 0,
      item_name: "Feira",
      category_id: 0,
      category_name: "-",
      when: "-",
      estimated: "R$ 0",
      accomplished: "R$0",
      classification: "Eventual Comprometido",
      status: "A PAGAR"
    }
  ]
}


export default function SpendingManagement() {
  const [currentPage, setCurrentPage] = useState('committedMonthly');
  const [loading, setLoading] = useState(true);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [navigationCardData, setNavigationCardData] = useState(INITIAL_NAVIGATION_CARD_DATA);
  const [committedMonthlyData, setCommittedMonthlyData] = useState(INITIAL_COMMITTED_MONTHLY_DATA);
  const [flexibleMonthlyData, setFlexibleMonthlyData] = useState(INITIAL_FLEXIBLE_MONTHLY_DATA);
  const [flexibleEventualData, setFlexibleEventualData] = useState(INITIAL_FLEXIBLE_EVENTUAL_DATA);
  const [eventualCommittedData, setEventualCommittedData] = useState(INITIAL_EVENTUAL_COMMITTED_DATA);

  const { getExpenseOverview, updateRecorrence, updatePec } = useBudgetManagement();

  const pageDataName = {
    committedMonthly: "monthly_committed",
    flexibleMonthly: "flexible_monthly",
    flexibleEventual: "flexible_eventual",
    committedEventual: "eventual_committed"
  }

  const verifyDataPage = (page) => {
    switch (page) {
      case 'committedMonthly':
        return committedMonthlyData === INITIAL_COMMITTED_MONTHLY_DATA;
      case 'flexibleMonthly':
        return flexibleMonthlyData === INITIAL_FLEXIBLE_MONTHLY_DATA;
      case 'flexibleEventual':
        return flexibleEventualData === INITIAL_FLEXIBLE_EVENTUAL_DATA;
      case 'committedEventual':
        return eventualCommittedData === INITIAL_EVENTUAL_COMMITTED_DATA;
      default:
        return null;
    }
  }

  const fillVariablesOnError = (page) => {
    switch (page) {
      case 'committedMonthly':
        setCommittedMonthlyData(prev => prev !== INITIAL_COMMITTED_MONTHLY_DATA ? INITIAL_COMMITTED_MONTHLY_DATA : prev)
        break
      case 'flexibleMonthly':
        setFlexibleMonthlyData(prev => prev !== INITIAL_FLEXIBLE_MONTHLY_DATA ? INITIAL_FLEXIBLE_MONTHLY_DATA : prev)
        break
      case 'flexibleEventual':
        setFlexibleEventualData(prev => prev !== INITIAL_FLEXIBLE_EVENTUAL_DATA ? INITIAL_FLEXIBLE_EVENTUAL_DATA : prev)
        break
      case 'committedEventual':
        setEventualCommittedData(prev => prev !== INITIAL_EVENTUAL_COMMITTED_DATA ? INITIAL_EVENTUAL_COMMITTED_DATA : prev)
        break
      default:
        break
    }
  }

  const fillVariables = (data) => {
    if (data.navigation_card) {
      setNavigationCardData(data.navigation_card)
    }

    if (data.monthly_committed) {
      setCommittedMonthlyData(data.monthly_committed)
    }

    if (data.flexible_monthly) {
      setFlexibleMonthlyData(data.flexible_monthly)
    }

    if (data.flexible_eventual) {
      setFlexibleEventualData(data.flexible_eventual)
    }

    if (data.eventual_committed) {
      setEventualCommittedData(data.eventual_committed)
    }
  }

  const onStart = async (types = []) => {
    try {
      const start = startOfMonth(currentDate);
      const end = endOfMonth(currentDate);
      const response = await getExpenseOverview({
        start,
        end,
        types,
      });
      fillVariables(response)
    } catch (error) {
      fillVariablesOnError(currentPage);
    } finally {
      setLoading(false);
    }
  }

  const onChangePage = async (page) => {
    try {
      setLoading(true);
      setCurrentPage(page);

      if (!verifyDataPage(page)) {
        return;
      }

      const start = startOfMonth(currentDate);
      const end = endOfMonth(currentDate);

      const response = await getExpenseOverview({
        start,
        end,
        types: [pageDataName[page]],
      });

      fillVariables(response)
    } catch (error) {
      fillVariablesOnError(page);
    } finally {
      setLoading(false);
      setCurrentDate(new Date());
    }
  }

  const updateRecorrenceItem = debounce(async (data) => {
    try {
      const updatedRecurrence = await updateRecorrence(data);
      if (!updatedRecurrence) return;

      const start = startOfMonth(currentDate);
      const end = endOfMonth(currentDate);

      const response = await getExpenseOverview({
        start,
        end,
        types: ["monthly_committed"],
      });

      fillVariables(response);

      toast.success("Recorrência atualizada com sucesso!");
    } catch (error) {
      toast.error("Erro ao atualizar a recorrência!");
    }
  }, 300);

  const changePecValue = async (data) => {
    try {
      const updatedPec = await updatePec(data);
      if (!updatedPec) return;

      const start = startOfMonth(currentDate);
      const end = endOfMonth(currentDate);

      const response = await getExpenseOverview({
        start,
        end,
        types: ["eventual_committed"],
      });

      fillVariables(response);

      return true
    } catch (error) {
      fillVariablesOnError(currentPage);
      return false;
    }
  }

  useEffect(() => {
    const types = [pageDataName[currentPage]]
    onStart(types);
    // eslint-disable-next-line
  }, [currentDate]);

  useEffect(() => {
    const types = [pageDataName[currentPage], "navigation_card"]
    onStart(types);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Home>
        <CardContainer>
          <CommittedMonthly
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            estimated={navigationCardData.monthly_committed.estimated.formatted}
            realized={navigationCardData.monthly_committed.can_spend.formatted}
            progressValue={navigationCardData.monthly_committed.percentage.raw}
            onChangePage={onChangePage}
          />
          <FlexibleMonthly
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            estimated={navigationCardData.flexible_monthly.estimated.formatted}
            realized={navigationCardData.flexible_monthly.can_spend.formatted}
            progressValue={navigationCardData.flexible_monthly.percentage.raw}
            onChangePage={onChangePage}
          />
          <CommittedEventual
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            estimated={navigationCardData.eventual_committed.estimated.formatted}
            realized={navigationCardData.eventual_committed.can_spend.formatted}
            progressValue={navigationCardData.eventual_committed.percentage.raw}
            onChangePage={onChangePage}
          />
          <FlexibleEventual
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            estimated={navigationCardData.flexible_eventual.estimated.formatted}
            realized={navigationCardData.flexible_eventual.can_spend.formatted}
            progressValue={navigationCardData.flexible_eventual.percentage.raw}
            onChangePage={onChangePage}
          />
        </CardContainer>
      </Home>
      <SpendingTable
        loading={loading}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        committedMonthly={committedMonthlyData}
        flexibleMonthly={flexibleMonthlyData}
        flexibleEventual={flexibleEventualData}
        committedEventual={eventualCommittedData}
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        updateRecorrenceItem={updateRecorrenceItem}
        updatePec={changePecValue}
      />
      <DirecionalButton />
    </>
  );
}
