import styled from 'styled-components';

export const LegendContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: ${({ theme }) => theme.getResponsiveWidth(1200)};
  margin-top: ${({ theme }) => theme.getResponsiveHeight(38)};
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ theme }) => theme.getResponsiveHeight(44)};
  &.start {
    align-items: flex-start;
  }
`;

export const ColorBox = styled.div`
  display: flex;
  width: ${({ theme }) => theme.getResponsiveWidth(20)};
  height: ${({ theme }) => theme.getResponsiveHeight(20)};
  ${({ theme }) => theme.getResponsiveWidth(8)};
  border-radius: ${({ theme }) => theme.getResponsiveWidth(4)};

  &.blue {
    background-color: ${({ theme }) => theme.blueRoyal};
  }
  &.blue-opacity {
    background-color: ${({ theme }) => theme.opacityBlueSky};
  }
  &.blue-sky {
    background-color: ${({ theme }) => theme.blueSky};
  }
  &.lilac-opacity {
    background-color: ${({ theme }) => theme.opacityBlueLight};
  }
`;

export const Line = styled.div`
  width: ${({ theme }) => theme.getResponsiveWidth(30)};
  height: ${({ theme }) => theme.getResponsiveHeight(5)};
  margin-right: ${({ theme }) => theme.getResponsiveWidth(8)};
  border: ${props => (props.dashed ? '2px dashed' : 'none')};
  border-color: white;

  &.gray {
    background-color: ${({ theme }) => theme.graySilver};
  }
  &.green {
    background-color: ${({ theme }) => theme.greenMedium};
  }
`;
