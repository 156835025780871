import React from 'react';
import { CardContent } from './styles';
import { H4 } from 'components/atoms/Headings/H4';
import { H5 } from 'components/atoms/Headings/H5';

export const CardSpending = ({ className, title, subTitle }) => {
  return (
    <CardContent className={className}>
      <H5 className={className}>{title}</H5>
      <H4 className={className}>{subTitle}</H4>
    </CardContent>
  );
};
