import styled from 'styled-components';

export const IconWrapper = styled.div`
  position: relative;
  display: flex;
  height: min-content;
  height: auto;

  &.home {
    align-items: flex-start;
  }
  &.spending {
    margin-top: 0;
  }

  &.investments {
    cursor: pointer;
  }
`;

export const ContainerIcon = styled.div`
  &.loading-home {
    display: none;
  }
`;
