import styled from 'styled-components';
import { HorizontalScrollbarStyles } from 'styles/components/HorizontalScrollbarStyles';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  height: 100%;

  &.default {
    ${HorizontalScrollbarStyles};
  }
`;

export const FlexContainer = styled.div`
  &.home {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 55%;
  }
`;
export const DivContainer = styled.div`
  &.home {
    display: flex;
    height: 90%;
    flex-direction: row;
    justify-content: flex-start;
    gap: 8%;
  }
`;
