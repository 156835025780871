import React from 'react';
import { Description } from './styles';
import { H6 } from 'components/atoms/Headings/H6';

const MapDescription = ({ className, value }) => {
  return (
    <Description className={className}>
      <H6 className={className}>Limite da Fatura</H6>
      <H6 className={className}>{value}</H6>
    </Description>
  );
};

export default MapDescription;
