import * as React from 'react';
import { StyledIcon } from './styles';

function CheckedIcon() {
  return (
    <StyledIcon
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.087 5.435L4.13 8.913l5.653-6.956"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledIcon>
  );
}

export default CheckedIcon;
