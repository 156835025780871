export const getAllocationStrategy = () => {
    return [
        { label: 'Estrategia 1', value: 'Estrategia 1' },
        { label: 'Estrategia 2', value: 'Estrategia 2' },
        { label: 'Estrategia 3', value: 'Estrategia 3' },
        { label: 'Estrategia 4', value: 'Estrategia 4' },
        { label: 'Estrategia 5', value: 'Estrategia 5' },
        { label: 'Estrategia 6', value: 'Estrategia 6' },
    ]
}

export const getLiquidity = () => {
    return [
        { label: 'Liquidez 1', value: 'Liquidez 1' },
        { label: 'Liquidez 2', value: 'Liquidez 2' },
        { label: 'Liquidez 3', value: 'Liquidez 3' },
        { label: 'Liquidez 4', value: 'Liquidez 4' },
        { label: 'Liquidez 5', value: 'Liquidez 5' },
        { label: 'Liquidez 6', value: 'Liquidez 6' },
    ]
}

export const getAllocationStrategyBPL = () => {
    return [
        { label: 'Estrategia 1', value: 'Estrategia 1' },
        { label: 'Estrategia 2', value: 'Estrategia 2' },
        { label: 'Estrategia 3', value: 'Estrategia 3' },
        { label: 'Estrategia 4', value: 'Estrategia 4' },
        { label: 'Estrategia 5', value: 'Estrategia 5' },
        { label: 'Estrategia 6', value: 'Estrategia 6' },
    ]
}

export const getAllocationStrategyPLF = () => {
    return [
        { label: 'Estrategia 1', value: 'Estrategia 1' },
        { label: 'Estrategia 2', value: 'Estrategia 2' },
        { label: 'Estrategia 3', value: 'Estrategia 3' },
        { label: 'Estrategia 4', value: 'Estrategia 4' },
        { label: 'Estrategia 5', value: 'Estrategia 5' },
        { label: 'Estrategia 6', value: 'Estrategia 6' },
    ]
}