import { H5 } from "components/atoms/Headings/H5";
import React from "react";

export const NavigationCardTitle = ({
    title = '',
    className = '',
}) => {
    return (
        <H5 className={className}>
            {title}
        </H5>
    )
}