import styled from 'styled-components';

export const StyledIcon = styled.svg`
  margin-right: ${props => props.theme.getResponsiveWidth(20)};
  width: ${props => props.theme.getResponsiveWidth(30)};
  height: ${props => props.theme.getResponsiveHeight(30)};
  min-width: 20px;

  @media (max-width: 1023px) {
    margin-right: 0;
  }
`;
