import React from 'react';
import { StyledContainer, StyledBar } from './styles';

export const BarGraficTable = ({ valuePot, pec }) => {
  const max = valuePot;

  const data = {
    labels: [''],
    datasets: [
      {
        label: 'Eventual Comprometido',
        data: [max],
        backgroundColor: 'transparent',
        borderColor: '#312B79',
        borderSkipped: 'none',
        borderWidth: 1.5,
        yAxisID: 'right',
      },
      {
        label: 'pec',
        data: [pec],
        backgroundColor: '#697BFF',
        borderWidth: 1.5,
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cornerRadius: 2,
    legend: {
      display: false,
    },
    tooltips: {
      showAllTooltips: false,
      enabled: false,
      mode: 'index',
      bodySpacing: 12,
      bodyFontSize: 15,
    },
    plugins: {
      datalabels: {
        color: 'rgba(0,0,0,0)',
      },
    },
    scales: {
      xAxes: [
        {
          display: false,
          stacked: true,
          barPercentage: 1,
        },
      ],

      yAxes: [
        {
          display: false,
          stacked: true,
          ticks: {
            beginAtZero: true,
            max,
          },
        },
        {
          id: 'right',
          display: false,
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            max,
          },
        },
      ],
    },
  };

  return (
    <StyledContainer>
      <StyledBar data={data} options={options} />
    </StyledContainer>
  );
};
