import React, { useState } from 'react';
import { Modal } from 'components/templates/Modal';

export const EditFinancialAssets = ({
  showModal = false,
  setShowModal = () => {},
  selectedItem = null,
  selectedCategoryKey,
  onConfirm = () => {},
  onCancel = () => {},
  theme = 'debts',
  classificationCategory = [],
  classificationLiquidy = [],
}) => {
  const [name, setName] = useState(selectedItem?.name);
  const [classification, setClassification] = useState(selectedItem?.category);
  const [installmentValue, setInstallmentValue] = useState(
    selectedItem?.installmentValue
  );

  const confirm = () => {
    setShowModal(false);
    onConfirm();
  };

  const cancel = () => {
    let currentCategory = JSON.parse(localStorage.getItem(selectedCategoryKey));
    currentCategory.name = name;
    currentCategory.classification = classification;
    localStorage.setItem(selectedCategoryKey, JSON.stringify(currentCategory));
    setShowModal(false);
    onCancel();
  };

  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="simple-modal edit"
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title="Editar ativo"
          onClose={() => setShowModal(false)}
          type="edit"
          className="simple-modal"
          theme={theme}
        />
      </Modal.TitleContainer>
      <Modal.BodyContainer>
        <Modal.InputText
          className="simple-modal"
          label="Nome completo"
          getInputValue={setName}
          defaultValue={name}
        />
        <Modal.Dropdown
          className="simple-modal after"
          selectOptions={classificationCategory}
          getDropdownValue={setClassification}
          label="Categoria"
          placeholderSelect={classification}
        />
        <Modal.Dropdown
          className="simple-modal after"
          selectOptions={classificationLiquidy}
          getDropdownValue={setClassification}
          label="Liquidez"
          placeholderSelect={classification}
        />
        <Modal.InputMoney
          className="simple-modal after "
          label="Valor "
          getInputValue={setInstallmentValue}
          defaultValue={installmentValue}
        />
      </Modal.BodyContainer>
      <Modal.SimpleFooter
        onConfirm={confirm}
        onCancel={cancel}
        className={`simple-modal ${theme}`}
        confirmButtonText="Concluído"
        cancelButtonText="Cancelar"
      />
    </Modal.Container>
  );
};
