import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-between;
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
  &.first-column {
  }

  &.second-column,
  &.third-column {
    height: 300px;

    margin-top: 30px;
    @media (max-width: 1300px) {
      flex-direction: column;
      height: auto;
    }
  }

  &.second-template-height {
    height: 312px;

    @media (max-width: 1920px) {
      height: 312px;
    }

    @media (max-width: 1650px) {
      height: 290px;
    }

    @media (max-width: 1500px) {
      height: 280px;
    }

    @media (max-width: 1280px) {
      height: 270px;
    }
  }
`;
