import { useSelector } from 'react-redux';

const useFormatName = () => {
  const { name } = useSelector(state => state.user.profile);
  let initials = 'CY';
  if (!name || name.trim() === '') {
    return 'Client Yuno';
  }
  const names = name.split(' ');

  const firstNameInitial = names[0].charAt(0).toUpperCase();

  const formattedFirstName =
    names[0].charAt(0).toUpperCase() + names[0].slice(1).toLowerCase();
  const formattedSecondName =
    names.length > 1
      ? names[1].charAt(0).toUpperCase() + names[1].slice(1).toLowerCase()
      : '';

  if (names.length === 1) {
    return `${firstNameInitial}${names[0].slice(1).toLowerCase()}`;
  }
  initials = `${firstNameInitial}${formattedSecondName.charAt(0)}`;
  return { name: `${formattedFirstName} ${formattedSecondName}`, initials };
};

export default useFormatName;
