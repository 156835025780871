import styled from "styled-components";

export const GrowingUpIconContainer = styled.svg`
    &.goal-panel {
        width: ${props => props.theme.getResponsiveWidth(32)};
        height: ${props => props.theme.getResponsiveWidth(21)};
        min-width: 17.1px;
        min-height: 11.2px;
        
        path {
            fill: ${({ theme }) => theme.blueRoyal};
        }

        &.hovered {
            path {
                fill: ${({ theme }) => theme.white};
            }
        }
    }
`;