import styled from 'styled-components';

export const LegendCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${props => props.theme.getResponsiveWidth(537)};
  margin-top: ${props => props.theme.getResponsiveHeight(48)};
  height: auto;
  min-width: 350px;
`;
