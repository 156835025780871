import React from "react";
import { HorizontalBar } from "react-chartjs-2";
import { Container } from "./styles";

export const HorizontalBarChart = ({
    className = '',
    data,
    options,
    width = 1522,
    height = 677,
}) => {

    return (
        <Container className={className}>
            <HorizontalBar
                data={data}
                options={options}
                width={width}
                height={height}
            />
        </Container>
    );
};
