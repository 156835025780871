import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DivContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${props => props.theme.getResponsiveWidth(43)};
  gap: ${props => props.theme.getResponsiveWidth(32)};
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const ContainerOverlay = styled.div`
  position: relative;
`;
