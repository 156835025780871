import React from 'react';
import { Label } from 'components/atoms/Label';
import { Input } from 'components/atoms/Input';
import { Span } from 'components/atoms/Span';
import { getOnlyNumbers } from 'utils/numbers';

export const ModalInputPercentage = ({
  className,
  getInputValue,
  placeholder,
  label,
  defaultValue = '',
}) => {
  const [inputValue, setInputValue] = React.useState(defaultValue || '0,00%');

  React.useEffect(() => {
    getInputValue(inputValue);
  }, [getInputValue, inputValue]);

  const handleChange = event => {
    const percentageSymbol = '% a.m';
    const brutalValue = event.target.value;
    const currentValue = brutalValue.replace(percentageSymbol, '');
    let numbersOnly = String(getOnlyNumbers(currentValue));

    if (!brutalValue.includes(percentageSymbol)) {
      numbersOnly = numbersOnly.slice(0, -1);
    }

    if (numbersOnly.length > 2) {
      const integerPart = numbersOnly.slice(0, numbersOnly.length - 2);
      const decimalPart = numbersOnly.slice(numbersOnly.length - 2);
      const formattedValue = `${integerPart},${decimalPart}${percentageSymbol}`;
      setInputValue(formattedValue);
    } else if (numbersOnly.length > 0) {
      const formattedValue = `0,${numbersOnly.padStart(
        2,
        '0'
      )}${percentageSymbol}`;
      setInputValue(formattedValue);
    } else {
      setInputValue(`0,00${percentageSymbol}`);
    }
  };

  return (
    <Label className={className}>
      <Span className={className}>{label}</Span>
      <Input
        className={className}
        value={inputValue}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </Label>
  );
};
