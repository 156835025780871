import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { StyledContainer, DivContainer, Line } from './styles';
import { BarGrafic } from '../../atoms/BarGrafic';
import { Span } from 'components/atoms/Span';
import { SubtitleBar } from 'components/atoms/SubtitleBar';
import { formatOutputMoneyNoDecimals } from 'utils/numbers';
import { LegendPotDetail } from '../LegendPotDetail';
import light from 'styles/themes/light';

export const RealizedDetail = ({ budget }) => {
  const [cutItems, setCutItems] = useState({});

  const calculateBalance = useCallback(() => {
    const totalExpenses = Object.entries(budget)
      .filter(([key]) => key !== 'receipt')
      .reduce((acc, [key, value]) => acc + (cutItems[key] ? 0 : value), 0);

    const receipt = cutItems['receipt'] ? 0 : budget.receipt;
    return receipt - totalExpenses;
  }, [budget, cutItems]);

  const balance = useMemo(() => calculateBalance(), [calculateBalance]);

  useEffect(() => {
    setCutItems({});
  }, [budget]);

  const handleClick = useCallback(item => {
    setCutItems(prevState => ({
      ...prevState,
      [item]: !prevState[item],
    }));
  }, []);

  const getValue = useCallback(
    (item, originalValue) =>
      cutItems[item] ? 'R$ 0' : formatOutputMoneyNoDecimals(originalValue),
    [cutItems]
  );

  const data = useMemo(
    () => ({
      labels: [''],
      datasets: [
        {
          label: 'Recebimentos',
          data: [cutItems.receipt ? 0 : budget.receipt],
          backgroundColor: 'transparent',
          borderColor: light.blueRoyal,
          borderSkipped: 'none',
          borderWidth: 2,
          yAxisID: 'right',
        },
        {
          label: 'Dívidas',
          data: [cutItems.debts ? 0 : budget.debts],
          backgroundColor: light.redMedium,
          borderWidth: 0,
        },
        {
          label: 'Compras Parceladas',
          data: [cutItems.installment ? 0 : budget.installment],
          backgroundColor: light.redLight,
          borderWidth: 0,
        },
        {
          label: 'Investimentos',
          data: [cutItems.investments ? 0 : budget.investments],
          backgroundColor: light.greenDark,
          borderWidth: 0,
        },
        {
          label: 'Eventuais Comprometidos - PEC',
          data: [cutItems.committedEventual ? 0 : budget.committedEventual],
          backgroundColor: light.greenMedium,
          borderWidth: 0,
        },
        {
          label: 'Mensais Comprometidos',
          data: [cutItems.committedMonthly ? 0 : budget.committedMonthly],
          backgroundColor: light.blueSky,
          borderWidth: 0,
        },
        {
          label: 'Mensais Flexíveis',
          data: [cutItems.flexibleMonthly ? 0 : budget.flexibleMonthly],
          backgroundColor: light.blueLilac,
          borderWidth: 0,
        },
        {
          label: 'Eventuais Flexíveis - LEF',
          data: [cutItems.flexibleEventual ? 0 : budget.flexibleEventual],
          backgroundColor: light.blueLight,
          borderWidth: 0,
        },
      ],
    }),
    [budget, cutItems]
  );

  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      cornerRadius: 2,
      legend: { display: false },
      tooltips: {
        showAllTooltips: false,
        enabled: false,
        mode: 'index',
        bodySpacing: 12,
        bodyFontSize: 15,
      },
      plugins: { datalabels: { color: 'rgba(0,0,0,0)' } },
      scales: {
        xAxes: [{ display: false, stacked: true, barThickness: 100 }],
        yAxes: [
          {
            display: false,
            stacked: true,
            ticks: { beginAtZero: true, max: budget.receipt },
          },
          {
            id: 'right',
            display: false,
            gridLines: { display: false },
            ticks: { beginAtZero: true, max: budget.receipt },
          },
        ],
      },
    }),
    [budget]
  );

  const itemsRelized = [
    { key: 'receipt', text: 'Recebimentos', className: 'first-column' },
    {
      key: 'flexibleEventual',
      text: 'Eventuais Flexíveis - LEF',
      className: 'blue-light',
    },
    {
      key: 'flexibleMonthly',
      text: 'Mensais Flexíveis',
      className: 'lilac',
    },
    {
      key: 'committedMonthly',
      text: 'Mensais Comprometidos',
      className: 'third-column',
    },
    {
      key: 'committedEventual',
      text: 'Eventuais Comprometidos - PEC',
      className: 'green-medium',
    },
    { key: 'investments', text: 'Investimentos', className: 'green-dark' },
    {
      key: 'installment',
      text: 'Compras Parceladas',
      className: 'red-light',
    },
    { key: 'debts', text: 'Dívidas', className: 'red-medium' },
  ];

  return (
    <StyledContainer className="default">
      <Span className="pot-detail">Pote Realizado</Span>
      <DivContainer className="home">
        <LegendPotDetail
          budget={budget}
          cutItems={cutItems}
          getValue={getValue}
          handleClick={handleClick}
          items={itemsRelized}
        />
        <BarGrafic data={data} options={options} />
      </DivContainer>
      <Line />
      <SubtitleBar
        className="detail"
        subtitleText="Saldo"
        subtitleValue={formatOutputMoneyNoDecimals(balance)}
      />
    </StyledContainer>
  );
};
