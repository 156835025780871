import React from 'react';
import light from 'styles/themes/light';
import { Span } from 'components/atoms/Span';
import { DoughnutChart } from 'components/atoms/DoughnutChart';
import { GenericLoading } from 'components/atoms/GenericLoading';
import { GeneralContext } from 'utils/contexts/GeneralContext';
import {
  GraphContainer,
  LabelContainer,
  GraphWrapper,
  DescriptionContainer,
} from './styles';

export const AllocationCard = ({
  titleCard = '',
  allocationData = {},
  emptyMessage = 'Sem alocação',
  labels = [],
}) => {
  const { loading } = React.useContext(GeneralContext);

  const getItemByLabel = label => {
    return allocationData.filter(item => item.label === label)[0] || null;
  };

  const getProportionInOrder = () => {
    const valuesInOrder = [];

    labels.forEach(label => {
      const item = getItemByLabel(label);
      if (item) {
        valuesInOrder.push(item.proportion);
      }
    });

    return valuesInOrder;
  };

  const getModifiedLabel = (tooltipItem, data) => {
    const label = data.labels[tooltipItem.index];

    if (label === emptyMessage) {
      return emptyMessage;
    }

    const { percentage } = getItemByLabel(label);
    return `${label}: ${percentage}`;
  };

  const colors = {
    'R. Fixa Pós': light.blueMidnight,
    'R. Fixa Pré': light.blueRoyal,
    'R. Fixa IPCA': light.blueNavy,
    Multimercado: light.blueDeep,
    FIIS: light.blueSky,
    'Ações BR': light.blueLilac,
    'R.V. Exterior': light.bluePale,
    Alternativo: light.blueLight,
  };

  const originalData = {
    labels,
    datasets: [
      {
        data: getProportionInOrder(),
        backgroundColor: labels.map(name => colors[name]),
        borderColor: labels.map(name => colors[name]),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    tooltips: {
      enabled: true,
      callbacks: {
        label: getModifiedLabel,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    legend: {
      display: false,
    },
    hover: {
      mode: true,
    },
  };

  const areAllValuesZero = data => {
    return data.datasets[0].data.every(value => value === 0);
  };

  const getAdjustedData = data => {
    if (areAllValuesZero(data)) {
      return {
        ...data,
        datasets: [
          {
            ...data.datasets[0],
            data: [1],
            backgroundColor: [light.grayPale],
            borderColor: [light.grayPale],
          },
        ],
        labels: [emptyMessage],
      };
    }
    return data;
  };

  const adjustedData = getAdjustedData(originalData);

  if (loading) return <GenericLoading className="allocation-card" />;

  return (
    <GraphContainer className="financial-assets">
      <Span className="financial-assets">{titleCard}</Span>
      <GraphWrapper className="financial-assets">
        <DoughnutChart
          data={adjustedData}
          options={options}
          className="financial-assets"
        />
        <LabelContainer className="financial-assets">
          {labels.map((label, index) => (
            <DescriptionContainer
              className={`financial-assets ${index === 0 ? 'first' : ''}`}
              backgroundColor={colors[label]}
              key={index}
            >
              <Span className="allocation-card percentage">
                {getItemByLabel(label)?.percentage || '0.0%'}
              </Span>
              <Span className="allocation-card name">{label}</Span>
            </DescriptionContainer>
          ))}
        </LabelContainer>
      </GraphWrapper>
    </GraphContainer>
  );
};
