import styled from 'styled-components';

export const StyledCloudIcon = styled.svg`
  &.sidebar {
    width: 100%;
    height: 35px;
    transition: ease-in-out 0.5s ease;
    flex: 1;
    cursor: pointer;
    border-radius: 8px;
    padding: 3px;

    &.in-page {
      background-color: ${({ theme }) => theme.sideBarHover};
    }

    &:hover {
      background-color: ${({ theme }) => theme.sideBarHover};
    }
  }

  &.empty-dream {
    width: 95px;
    height: 68px;

    @media (max-width: 1440px) {
      width: 90px;
      height: 65px;
    }

    @media (max-width: 1280px) {
      width: 85px;
      height: 62px;
    }

    @media (max-width: 1024px) {
      width: 80px;
      height: 60px;
    }

    @media (max-width: 768px) {
      width: 75px;
      height: 55px;
    }
  }
`;
