import styled from 'styled-components';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

export const CardContainer = styled.div`
  &.budget {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: ${({ theme }) => theme.getResponsiveWidth(376)};
    height: ${({ theme }) => theme.getResponsiveWidth(256)};
    background-color: ${({ theme }) => theme.white};
    border-radius: ${({ theme }) => theme.getResponsiveWidth(16)};
    box-shadow: ${({ theme }) => theme.getResponsiveWidth(4)}
      ${({ theme }) => theme.getResponsiveWidth(4)}
      ${({ theme }) => theme.getResponsiveWidth(15)} 0px
      ${({ theme }) => theme.blackWithSlowOpacity};
    padding: ${props => props.theme.getResponsiveWidth(30)};
    min-width: 200.53px;
    min-height: 136.53px;

    &:hover {
      transition: transform 0.3s ease;
      transform: scale(1.05);
      transform-origin: left;
    }

    &.selected-spending {
      background-color: ${({ theme }) => theme.blueSky};
    }
    &.selected-patrimony {
      background-color: ${({ theme }) => theme.blueRoyal};
    }
    &.selected-passive {
      background-color: ${({ theme }) => theme.redMedium};
    }

    @media (max-width: 1023px) {
      width: 200.53px;
      height: 136.53px;
      padding-top: 1px;
      padding-left: 17.067px;
      border-radius: 8.54px;
      box-shadow: 2.133px 2.133px 8px 0px
        ${({ theme }) => theme.blackWithSlowOpacity};
    }
  }
  &.empyt {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: ${({ theme }) => theme.getResponsiveWidth(376)};
    height: ${({ theme }) => theme.getResponsiveWidth(256)};
    border-radius: ${({ theme }) => theme.getResponsiveWidth(16)};
    padding: ${props => props.theme.getResponsiveWidth(30)};
    min-width: 200.53px;
    min-height: 136.53px;
    background-color: transparent;
    box-shadow: none;
    border: ${({ theme }) => theme.getResponsiveWidth(1)} solid
      ${({ theme }) => theme.blueRoyal};

    @media (max-width: 1023px) {
      width: 200.53px;
      height: 136.53px;
      padding-top: 1px;
      padding-left: 17.067px;
      border-radius: 8.54px;
      box-shadow: 2.133px 2.133px 8px 0px
        ${({ theme }) => theme.blackWithSlowOpacity};
      border: 0.54px solid ${({ theme }) => theme.blueRoyal};
    }
  }

  &.spending-table {
    display: block;
    margin-top: ${({ theme }) => theme.getResponsiveHeight(24)};
    background-color: ${({ theme }) => theme.white};
    width: ${({ theme }) => theme.getResponsiveWidth(1600)};
    padding-top: ${({ theme }) => theme.getResponsiveHeight(24)};
    padding-left: ${({ theme }) => theme.getResponsiveWidth(22)};
    padding-right: ${({ theme }) => theme.getResponsiveWidth(22)};
    padding-bottom: ${({ theme }) => theme.getResponsiveWidth(22)};

    margin: ${({ theme }) => theme.getResponsiveHeight(32)} auto
      ${({ theme }) => theme.getResponsiveHeight(24)};
    border-radius: ${({ theme }) => theme.getResponsiveWidth(16)};

    &.no-footer {
      padding-bottom: ${({ theme }) => theme.getResponsiveHeight(24)};
    }
    &.grafic {
      height: auto;
    }
  }
  &.card-table {
    display: flex;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.getResponsiveHeight(24)};
    background-color: ${({ theme }) => theme.white};
    width: ${({ theme }) => theme.getResponsiveWidth(1600)};
    padding-top: ${({ theme }) => theme.getResponsiveHeight(24)};
    padding-left: ${({ theme }) => theme.getResponsiveWidth(22)};
    padding-right: ${({ theme }) => theme.getResponsiveWidth(22)};
    padding-bottom: ${({ theme }) => theme.getResponsiveWidth(10)};
    margin: ${({ theme }) => theme.getResponsiveHeight(32)} auto
      ${({ theme }) => theme.getResponsiveHeight(24)};
    border-radius: ${({ theme }) => theme.getResponsiveWidth(16)};

    @media (max-width: 1023px) {
      flex-direction: column-reverse;
      overflow: auto;
      margin-top: 18.5px;
      padding-right: ${({ theme }) => theme.getResponsiveWidth(16)};
      padding-top: 6px;
      padding-bottom: 12px;
    }

    ::-webkit-scrollbar {
      width: 10px;
      height: 2px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }
  }
  &.card-table-grafic {
    display: flex;
    justify-content: space-between;
    gap: ${({ theme }) => theme.getResponsiveWidth(8)};

    @media (max-width: 1023px) {
      flex-direction: column;
    }
  }
  &.table-recom {
    display: flex;
    justify-content: space-between;
    gap: ${({ theme }) => theme.getResponsiveWidth(8)};
    width: 100%;
    min-width: 300px;
    @media (max-width: 1325px) {
      overflow-x: scroll;

      &::-webkit-scrollbar {
        height: ${({ theme }) => theme.getResponsiveHeight(3)};
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.graySilver};
        border-radius: 50px;
      }

      &::-webkit-scrollbar-track {
        background-color: ${({ theme }) => theme.grayPale};
        border-radius: 50px;
      }

      &::-webkit-scrollbar-button {
        display: none;
      }
    }
  }
  &.no-footer {
    padding-bottom: ${({ theme }) => theme.getResponsiveHeight(24)};
  }
  &.pot-table {
    display: flex;
    align-items: flex-end;
    width: 45%;
    height: auto;
  }
  &.grafic-table {
    display: flex;
    width: 60%;
  }
  &.table-grafic {
    display: flex;
    width: 60%;
    height: 100%;

    @media (max-width: 1023px) {
      width: 100%;
    }
  }

  &.loading {
    overflow-x: hidden;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.gradientSkeleton[0]} 25%,
      ${({ theme }) => theme.gradientSkeleton[1]} 50%,
      ${({ theme }) => theme.gradientSkeleton[0]} 75%
    );
    background-size: 200% 100%;
    animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;
  }
  &.progress-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: ${({ theme }) => theme.getResponsiveWidth(784)};
    height: ${({ theme }) => theme.getResponsiveWidth(256)};
    background-color: ${({ theme }) => theme.white};
    border-radius: ${({ theme }) => theme.getResponsiveWidth(16)};
    box-shadow: ${({ theme }) => theme.getResponsiveWidth(4)}
      ${({ theme }) => theme.getResponsiveWidth(4)}
      ${({ theme }) => theme.getResponsiveWidth(15)} 0px
      ${({ theme }) => theme.blackWithSlowOpacity};
    padding: ${props => props.theme.getResponsiveWidth(30)};
    min-width: 320px;
    min-height: 136.53px;
  }
  &.pot-detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: ${({ theme }) => theme.getResponsiveWidth(1600)};
    height: auto;
    margin-bottom: ${props => props.theme.getResponsiveHeight(20)};
    background-color: ${({ theme }) => theme.white};
    border-radius: ${({ theme }) => theme.getResponsiveWidth(16)};
    box-shadow: ${({ theme }) => theme.getResponsiveWidth(4)}
      ${({ theme }) => theme.getResponsiveWidth(4)}
      ${({ theme }) => theme.getResponsiveWidth(15)} 0px
      ${({ theme }) => theme.blackWithSlowOpacity};
    padding: ${props => props.theme.getResponsiveWidth(30)};
  }
  &.card-detail {
    display: flex;
    justify-content: space-between;
    margin-top: ${props => props.theme.getResponsiveHeight(91)};

    @media (max-width: 1024px) {
      margin-top: ${props => props.theme.getResponsiveHeight(20)};
      flex-direction: column;
      gap: ${props => props.theme.getResponsiveHeight(20)};
    }
  }

  &.carousel-report {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({ theme }) => theme.getResponsiveWidth(240)};
    height: ${({ theme }) => theme.getResponsiveWidth(88)};
    min-height: 40px;
    border-radius: ${({ theme }) => theme.getResponsiveWidth(8)};

    &.spendings {
      background-color: ${({ theme }) => theme.blueSky};
    }
    &.receipt {
      background-color: ${({ theme }) => theme.blueRoyal};
    }
    &.investment {
      background-color: ${({ theme }) => theme.greenDark};
    }
  }
  &.report-table {
    display: relative;
    margin-top: ${({ theme }) => theme.getResponsiveHeight(24)};
    background-color: ${({ theme }) => theme.white};
    width: ${({ theme }) => theme.getResponsiveWidth(1600)};
    padding-top: ${({ theme }) => theme.getResponsiveHeight(24)};
    padding-left: ${({ theme }) => theme.getResponsiveWidth(22)};
    padding-right: ${({ theme }) => theme.getResponsiveWidth(22)};
    padding-bottom: ${({ theme }) => theme.getResponsiveWidth(22)};
    margin: ${({ theme }) => theme.getResponsiveHeight(32)} auto
      ${({ theme }) => theme.getResponsiveHeight(24)};
    border-radius: ${({ theme }) => theme.getResponsiveWidth(16)};
    min-width: 400px;
  }
`;
export const CardBody = styled.div`
  &.budget {
    width: ${props => props.theme.getResponsiveWidth(265)};
    height: ${props => props.theme.getResponsiveHeight(192)};
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.loading {
      display: none;
    }
  }
`;

export const CardTitle = styled.div`
  &.spending-card {
    width: ${props => props.theme.getResponsiveWidth(380)};
    height: auto;

    @media (max-width: 1024px) {
      width: 150px;
      margin-top: 10px;
      margin-bottom: 0;
    }
  }
`;
export const CardContent = styled.div`
  &.budget {
    display: flex;
    justify-content: space-between;
    width: auto;
  }
  &.carousel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: ${props => props.theme.getResponsiveWidth(119)};
    height: ${props => props.theme.getResponsiveHeight(49)};
    min-width: 80px;
  }
  &.right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
  &.margin-min {
    margin-top: ${props => props.theme.getResponsiveWidth(5)};
  }
  &.margin-max {
    margin-top: ${props => props.theme.getResponsiveWidth(10)};
    @media (max-width: 1024px) {
      margin-top: 0;
    }
  }
  &.loading {
    display: none;
  }
`;
export const AmountOfMoneyContainer = styled.div``;
export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
`;
