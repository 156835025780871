import * as React from 'react';
import { StyledIcon } from './styles';

function ArrowLeftIcon({ className, onClick }) {
  return (
    <StyledIcon
      className={className}
      onClick={onClick}
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 1.5L2 6l4 4.5"
        stroke="#8C8B8C"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </StyledIcon>
  );
}

export default ArrowLeftIcon;
