import styled from 'styled-components';

export const StyledIcon = styled.svg`
  width: 16px;
  height: 16px;

  &.selected {
    path {
      fill:  ${({ theme }) => theme.blueDarkOpacity};
    }
    rect {
      fill:  ${({ theme }) => theme.white};
    }
  }
`;
