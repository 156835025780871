import React from "react";
import { Container, TitleContainer, ScrollContainer, Wrapper, IntroductionContainer } from "./styles";
import { Span } from "components/atoms/Span";
import { H4 } from "components/atoms/Headings/H4";
import { Button } from "components/atoms/Button";
import { QuestionPager } from "components/atoms/QuestionPager";
import { SuitabilityQuiz } from "../../../molecules/SuitabilityQuiz";
import { getSuitabilityQuestions } from "development/suitability";

export const EmptySuitabilityProfile = ({
    changeProfile = () => { },
}) => {
    const status = ['starting', 'doing'];
    const [currentPage, setCurrentPage] = React.useState(status[0]);
    const [currentQuestion, setCurrentQuestion] = React.useState(0);
    const [answersGiven, setAnswersGiven] = React.useState([]);
    const suitabilityQuestions = getSuitabilityQuestions();

    const changeQuestionByPager = (number) => {
        setCurrentQuestion(number);
    }

    const handleNextQuestion = () => {
        if (currentQuestion <= suitabilityQuestions.length - 2) {
            setCurrentQuestion(prev => prev + 1);
            return;
        }
    }

    const handlePreviousQuestion = () => {
        if (currentQuestion > 0) {
            setCurrentQuestion(prev => prev - 1);
            return;
        }
    }

    const returnToInvestorProfile = () => {
        setAnswersGiven([]);
        setCurrentPage(status[0]);
    }

    const endTest = () => {
        if (answersGiven.length !== suitabilityQuestions.length) {
            return alert('Preencha todas as questões para prosseguir')
        }

        changeProfile({
            answersGiven,
        })
    }

    const pages = {
        starting: (
            <>
                <ScrollContainer>
                    <Wrapper>
                        <IntroductionContainer>
                            <H4 className='empty-suitability-questionnaire'>
                                Bem-vindo(a) ao Questionário de Suitability!
                            </H4>
                            <Span className='empty-suitability-questionnaire-description'>
                                Visando cumprir as exigências regulatórias, a Yuno ON possui um procedimento de Suitability para definir o perfil de investimento de seus clientes. Para continuar realizando suas operações, precisamos que preencha o questionário a seguir.
                            </Span>

                            <Span className='empty-suitability-questionnaire-warning'>
                                É necessário responder todas as perguntas para que seu perfil seja gerado e os dados computados.
                            </Span>
                        </IntroductionContainer>
                    </Wrapper>
                </ScrollContainer>
                <Wrapper className="button">
                    <Button className='empty-suitability-questionnaire' onClick={() => setCurrentPage(status[1])}>
                        Iniciar questionário
                    </Button>
                </Wrapper>
            </>
        ),
        doing: (
            <>
                <ScrollContainer>
                    <Wrapper>
                        <QuestionPager
                            className='empty-suitability-questionnaire'
                            total={suitabilityQuestions.length}
                            currentQuestion={currentQuestion + 1}
                            onChange={changeQuestionByPager}
                        />
                    </Wrapper>
                    <Wrapper>
                        <SuitabilityQuiz
                            currentQuestion={suitabilityQuestions[currentQuestion]}
                            answersGiven={answersGiven}
                            setAnswersGiven={setAnswersGiven}
                        />
                    </Wrapper>
                </ScrollContainer>
                <Wrapper className="button">
                    {currentQuestion === 0 ? (
                        <Button className='empty-suitability-questionnaire' onClick={returnToInvestorProfile}>
                            Retornar ao Perfil Investidor
                        </Button>
                    ) : (
                        <Button className='empty-suitability-questionnaire' onClick={handlePreviousQuestion}>
                            Voltar
                        </Button>
                    )}
                    {currentQuestion === suitabilityQuestions.length - 1 ? (
                        <Button className='empty-suitability-questionnaire suitability-form' onClick={endTest}>
                            Finalizar
                        </Button>
                    ) : (
                        <Button className='empty-suitability-questionnaire suitability-form' onClick={handleNextQuestion}>
                            Continuar
                        </Button>
                    )}
                </Wrapper>
            </>
        )
    }

    return (
        <Container className='empty-suitability-questionnaire'>
            <TitleContainer>
                <Span className='empty-suitability-questionnaire'>
                    Questionário de Suitability
                </Span>
            </TitleContainer>
            {pages[currentPage]}
        </Container>
    )
}