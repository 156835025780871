import React from 'react';
import { StyledIcon } from './styles';

function CommittedMonthlyIcon({ className }) {
  return (
    <StyledIcon
      className={className}
      viewBox="0 0 29 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.389 31.71H1.61c-.427 0-.837-.163-1.14-.454A1.521 1.521 0 010 30.16V2.26c0-.41.17-.805.472-1.096.302-.29.712-.454 1.14-.454h25.777c.427 0 .837.164 1.14.454.301.291.471.685.471 1.096v27.9c0 .412-.17.806-.472 1.096-.302.291-.712.454-1.14.454zm-1.611-3.1V3.81H3.222v24.8h22.556zM8.056 11.56h12.888v3.1H8.056v-3.1zm0 6.2h12.888v3.1H8.056v-3.1z"
        fill="#697BFF"
      />
    </StyledIcon>
  );
}

export default CommittedMonthlyIcon;
