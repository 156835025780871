import styled from 'styled-components';

export const ScrollToTopButtonContainer = styled.div`
  position: fixed;
  bottom: ${props => props.theme.getResponsiveWidth(20)};
  right: ${props => props.theme.getResponsiveWidth(25)};

  &.loading {
    display: none;
  }
`;

export const ScrollToTopButton = styled.div`
  background-color: transparent;
  cursor: pointer;
`;
