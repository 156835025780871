import * as React from 'react';
import { StyledIcon } from './styles';

function UpIcon(props) {
  return (
    <StyledIcon
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={14}
        cy={14}
        r={14}
        transform="rotate(180 14 14)"
        fill="#140361"
        fillOpacity={0.45}
      />
      <path
        d="M20.125 15.75L14 9.625 7.875 15.75"
        stroke="#fff"
        strokeOpacity={0.75}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledIcon>
  );
}

export default UpIcon;
