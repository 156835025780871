import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import DashboardLayout from 'pages/_layouts/DashboardNew';
import { loadInitialStateRequest } from 'store/modules/suitability/actions';
import { signOut } from 'store/modules/auth/actions';

const RouteWrapper = ({
  component: Component,
  isPrivate = false,
  dashboard = false,
  path,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { profile: user } = useSelector(state => state.user);
  const { signed } = useSelector(state => state.auth);
  const { parcial } = useSelector(state => state.suitability);
  const authUser = useSelector(state => state.auth.user); // Mova o useSelector para fora do callback

  const validateSession = useCallback(() => {
    const requiredProps = ['type', 'email'];

    if (!signed || path !== '/' || !authUser || !user) {
      // Verifica se authUser e user existem
      return;
    }

    const invalidUser = requiredProps.some(
      prop => authUser[prop] !== user[prop]
    );

    if (invalidUser) {
      dispatch(signOut());
    }
  }, [dispatch, path, user, authUser, signed]);

  useEffect(() => {
    validateSession();
  }, [validateSession]);

  useEffect(() => {
    if (parcial === null && signed && user?.type === 'client') {
      dispatch(loadInitialStateRequest(user.id));
    }
  }, [parcial, signed, user, dispatch]);

  if (path === '/external_acess') {
    return <Route {...rest} render={props => <Component {...props} />} />;
  }

  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  if (signed && !isPrivate) {
    return <Redirect to="/home" />;
  }

  return (
    <Route
      {...rest}
      render={props =>
        dashboard ? (
          <DashboardLayout>
            <Component {...props} />
          </DashboardLayout>
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default RouteWrapper;
