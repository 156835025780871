import * as React from 'react';
import { StyledIcon } from './styles';

function CategoryIcon({ className }) {
  return (
    <StyledIcon
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={16} height={16} rx={2} fill="#6C6C6C" />
      <path
        d="M12.353 7.353H8.647V3.647a.647.647 0 00-1.294 0v3.706H3.647a.647.647 0 000 1.294h3.706v3.706a.648.648 0 001.294 0V8.647h3.706a.647.647 0 000-1.294z"
        fill="#fff"
      />
    </StyledIcon>
  );
}

export default CategoryIcon;
