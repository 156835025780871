import styled from 'styled-components';

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const ContainerOptions = styled.div`
  width: 100%;
`;

export const DropdownTrigger = styled.div`
  height: ${({ theme }) => theme.getResponsiveHeight(40)};
  margin-top: ${({ theme }) => theme.getResponsiveHeight(12)};
  background-color: ${({ theme }) => theme.white};
  padding: ${props => props.theme.getResponsiveHeight(10)};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.grayShadow};
  border-radius: ${({ theme }) => theme.getResponsiveHeight(4)};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.grayPale};
  }
`;

export const DropdownTriggerLabel = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.graySelectPlaceholder};
  font-weight: 500;
`;

export const DropdownTriggerIcon = styled.span`
  transition: transform 0.3s ease-in-out;
  transform: ${props => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export const DropdownOptionsList = styled.ul`
  position: absolute;
  width: 100%;
  background-color: ${({ theme }) => theme.white};
  border-radius: ${({ theme }) => theme.getResponsiveHeight(4)};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  display: ${props => (props.isOpen ? 'block' : 'none')};
`;

export const DropdownOptionItem = styled.li`
  padding: ${props => props.theme.getResponsiveHeight(12)} ${props => props.theme.getResponsiveWidth(16)};
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${props => props.theme.selectHover};

    span {
      color: ${props => props.theme.white};
    }
  }
`;

export const DropdownOptionItemLabel = styled.span`
  font-family: ${props => props.theme.fonts.AperturaRegular};
  font-size: 12px;
  color: ${props => props.theme.graySilver};
  font-weight: 400;
`;
