import styled from 'styled-components';

export const Container = styled.div`

  flex: 1 1 calc(33.33% - 30px);
  padding: 15px;
  min-width: 300px;
  height: inherit;
  max-height: 400px;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  justify-content: space-between;
  box-shadow: 4px 4px 15px 0px ${({ theme }) => theme.boxShadowColor};

  @media (max-width: 1024px) {
    flex: 1 1 calc(50% - 30px);
  }

  @media (max-width: 768px) {
    flex: 1 1 100%;
  }
`;

export const ContainerGrafic = styled.div`
  &.home {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;

    height: 100%;
  }
`;
