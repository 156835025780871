import React from 'react';
import { H4 } from '../Headings/H4';
import { H5 } from '../Headings/H5';
import {
  Progress,
  ProgressBarContainer,
  ProgressBarWrapper,
  ProgressLabel,
  ProgressLabels,
  ProgressSegment,
  ProgressTotal,
} from './styles';

const ProgressBarCard = ({ data, total }) => {
  return (
    <ProgressBarContainer>
      <ProgressBarWrapper>
        <Progress>
          {data.map((item, index) => (
            <ProgressSegment
              key={index}
              width={item.value}
              color={item.color}
            />
          ))}
        </Progress>
        <ProgressTotal>
          <H4 className="progress-card">{total}</H4>
        </ProgressTotal>
      </ProgressBarWrapper>
      <ProgressLabels>
        {data.map((item, index) => (
          <ProgressLabel key={index}>
            <H5 className="progress-card">{item.label}</H5>
            <H4 className="value-card" style={{ color: item.color }}>
              {item.value} %
            </H4>
          </ProgressLabel>
        ))}
      </ProgressLabels>
    </ProgressBarContainer>
  );
};

export default ProgressBarCard;
