import React, { useState } from 'react';
import { DivContainer, StyledContainer } from './styles';
import { Overlay } from 'components/atoms/Overlay';
import SaveIcon from 'components/atoms/icons/SaveIcon';

export const SaveReport = ({ onClick, className }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <StyledContainer onClick={onClick}>
      <DivContainer
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        <SaveIcon />
        <Overlay
          className={`report ${isVisible ? 'visible' : ''} ${className}`}
        >
          Baixar informações para o Excel
        </Overlay>
      </DivContainer>
    </StyledContainer>
  );
};
