import React from "react";
import { DownArrowContainer, StyledDownArrow } from "./styles";

export const DownArrowIcon = ({
    className = 'default',
    onClick = () => { },
}) => {
    return (
        <DownArrowContainer className={className} onClick={onClick}>
            <StyledDownArrow
                width={6}
                height={4}
                viewBox="0 0 6 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <path d="M.5 1L3 3l2.5-2" stroke="#fff" strokeLinecap="round" />
            </StyledDownArrow>
        </DownArrowContainer>
    )
}