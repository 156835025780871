import * as React from 'react';
import { PencilIconContainer, StyledContainer } from './styles';

function PencilIcon({ className, onClick, ...rest }) {
  return (
    <StyledContainer className={className} onClick={onClick}>
      <PencilIconContainer
        viewBox="0 0 12 14"
        fill="none"
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          d="M11.488.546A1.685 1.685 0 0010.252 0c-.468 0-.908.194-1.237.546L.854 9.261l-.658 2.612a.62.62 0 00.144.578.537.537 0 00.543.154l2.446-.703 8.16-8.713c.329-.352.511-.822.511-1.32 0-.5-.182-.97-.512-1.322zm-1.199 2.87l-7.322 7.819-2.035.584.547-2.173 7.323-7.818 1.487 1.588zm1.013-1.548c0 .3-.108.582-.307.795l-.213.227-1.487-1.588.212-.227a1.007 1.007 0 011.488 0c.198.211.307.493.307.794zM9.085 13.254H.349c-.192 0-.349.167-.349.373S.156 14 .35 14h8.735c.192 0 .35-.167.35-.373s-.157-.373-.35-.373z"
          fill="#6C6C6C"
        />
      </PencilIconContainer>
    </StyledContainer>
  );
}

export default PencilIcon;
