import React from 'react';

import { Span } from 'components/atoms/Span';
import TooltipIconWithOverlay from 'components/atoms/IconInformation';
import {
  GroupItens,
  TextContainer,
  TitleWrapper,
  TitleCardWrapper,
} from './styles';

export const TitleInformationTotal = ({
  nameTable,
  tooltipText,
  className,
}) => {
  return (
    <TextContainer className={className}>
      <GroupItens>
        <TitleWrapper>
          <Span className="table-title">{nameTable}</Span>
          <TooltipIconWithOverlay
            className="home spending"
            tooltipText={tooltipText}
          />
        </TitleWrapper>
      </GroupItens>
      <GroupItens className="total">
        <TitleCardWrapper>
          <Span className="category-title">Total</Span>
          <Span className="category-title">R$ 0</Span>
        </TitleCardWrapper>
        <TitleCardWrapper>
          <Span className={`category-title-two ${className}`}>Percentual</Span>
          <Span className={`category-title-two ${className}`}>0%</Span>
        </TitleCardWrapper>
      </GroupItens>
    </TextContainer>
  );
};
