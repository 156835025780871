import React, { useContext, useState } from 'react';
import { Button, Container } from './styles';
import CategoryIcon from 'components/atoms/icons/CategoryIcon';
import { GeneralContext } from 'utils/contexts/GeneralContext';

export const ButtonCategory = ({ setShowModal }) => {
  const { loading } = useContext(GeneralContext);

  const [selected] = useState(false);
  const [hovered, setHovered] = useState(false);

  const selectedClass = selected || hovered ? 'selected' : '';
  const selectedButton = selected ? 'selected' : '';

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <Container className={` ${loading ? 'loading' : ''}`}>
      <Button
        className={` ${selectedButton}`}
        selected={selected}
        onClick={() => setShowModal(prev => !prev)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <CategoryIcon className={` ${selectedClass}`} />
        Adicionar Categoria
      </Button>
    </Container>
  );
};
