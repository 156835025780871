import styled from 'styled-components';

export const StyledIcon = styled.svg`
  width: ${props => props.theme.getResponsiveHeight(7)};
  height: ${props => props.theme.getResponsiveHeight(12)};

  &.blue-midnight {
    path {
      stroke: ${({ theme }) => theme.blueMidnight};
    }
  }
`;
