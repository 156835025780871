import styled from "styled-components";

export const ImageIconContainer = styled.div`
    &.generic-dream {
        width: ${props => props.theme.getResponsiveWidth(22)};
        height: ${props => props.theme.getResponsiveWidth(22)};

        @media (max-width: 1024px){
            width: 11.73px;
            height: 11.73px;
        }
    }
`;

export const StyledIcon = styled.svg`
    &.generic-dream {
        width: ${props => props.theme.getResponsiveWidth(22)};
        height: ${props => props.theme.getResponsiveWidth(22)};

        path {
            fill: ${({ theme }) => theme.grayPale};
        }

        @media (max-width: 1024px){
            width: 11.73px;
            height: 11.73px;
        }
    }
`;