import React from "react";
import { Container } from "./styles";
import { Table } from "components/templates/Tables";
import PencilIcon from "components/atoms/icons/PencilIcon";
import { GenericLoading } from "components/atoms/GenericLoading";

export const SimpleExtractCategoryItemsTable = ({
    item,
    loading = true,
    setShowEditItemModal = () => { },
    setItemData = () => { },
}) => {

    if (loading) {
        return (
            <Container className={`table ${loading ? 'loading' : ''}`}>
                {Array(4).fill(0).map((_, index) => (
                    <GenericLoading key={index} className="extract-item-body" />
                ))}
            </Container>
        )
    }

    return (
        <Container className="table">
            <Table.Container className="extract-item" key={item}>
                <Table.Thead>
                    <Table.Tr className="extract-item-title">
                        <Table.Th className="extract-item"></Table.Th>
                        <Table.Th className="extract-item name">Item</Table.Th>
                        <Table.Th className="extract-item center"></Table.Th>
                        <Table.Th className="extract-item center">Valor</Table.Th>
                        <Table.Th className="extract-item center">Pagamento</Table.Th>
                        <Table.Th className="extract-item center">Parcela</Table.Th>
                        <Table.Th className="extract-item center">Data</Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {item.data.map((itemData, index) => (
                        <Table.Tr className="extract-item-body">
                            <Table.Td className="extract-item" onClick={() => {
                                const item = {
                                    id: itemData.id || null,
                                    name: itemData.name || '',
                                    description: itemData.description || '',
                                    recurrent: itemData.recurrent || false,
                                    installmentValue: itemData.installmentValue || 0,
                                    startDate: itemData.startDate ? new Date(itemData.startDate) : null,
                                    endDate: itemData.endDate ? new Date(itemData.endDate) : null,
                                    classification: itemData.classification || '',
                                }

                                setItemData(item)
                                setShowEditItemModal(prev => !prev)
                            }}>
                                <PencilIcon className="extract--category-item" />
                            </Table.Td>
                            <Table.Td className="extract-item" colSpan={2}>{itemData.name}</Table.Td>
                            <Table.Td className="extract-item center">{itemData.value}</Table.Td>
                            <Table.Td className="extract-item center">{itemData.paymentMethod}</Table.Td>
                            <Table.Td className="extract-item center">{itemData.installments}</Table.Td>
                            <Table.Td className="extract-item center">{itemData.date}</Table.Td>
                        </Table.Tr>
                    ))}
                </Table.Tbody>

            </Table.Container>
        </Container>
    )
};