import { keyframes } from 'styled-components';

export const GradientSkeletonAnimation = keyframes`
  0% {
    background-position: 100% 50%;
 }
  100% {
    background-position: 0% 50%;
 }
`;
