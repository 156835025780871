import styled from "styled-components";

export const Container = styled.div`
    &.asset-management {
        width: ${props => props.theme.getResponsiveWidth(25)};
        height: ${props => props.theme.getResponsiveWidth(25)};

        @media (max-width: 1023px){
            width: 13.3px;
            height: 13.3px;
        }
    }
`;

export const Icon = styled.svg`
    &.passive {
        width: ${props => props.theme.getResponsiveWidth(25)};
        height: ${props => props.theme.getResponsiveWidth(25)};
        min-width: 14.4px;
        min-height: 14.4px;
    }

    &.selected-patrimony {
        width: ${props => props.theme.getResponsiveWidth(25)};
        height: ${props => props.theme.getResponsiveWidth(25)};
        min-width: 14.4px;
        min-height: 14.4px;

        path{
            stroke: ${({ theme }) => theme.white};
        }
    }

    &.asset-management {
        width: ${props => props.theme.getResponsiveWidth(25)};
        height: ${props => props.theme.getResponsiveWidth(25)};

        path {
            fill: ${({ theme }) => theme.blueRoyal};
        }

        @media (max-width: 1023px){
            width: 13.3px;
            height: 13.3px;
        }
    }
`;