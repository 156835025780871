import * as React from 'react';
import { StyledProfileIcon } from './styles';

function ProfileIcon({ className }) {
  return (
    <StyledProfileIcon
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M21.058 15.14a4.91 4.91 0 00-4.904 4.904 4.908 4.908 0 004.904 4.905 4.908 4.908 0 004.905-4.905 4.908 4.908 0 00-4.905-4.904zm0 8.065a3.166 3.166 0 01-3.163-3.163 3.166 3.166 0 013.163-3.163 3.166 3.166 0 013.163 3.163 3.166 3.166 0 01-3.163 3.163z"
        fill="#fff"
      />
      <path
        d="M21.058 7.852c-7.471 0-13.548 6.077-13.548 13.549 0 7.471 6.077 13.548 13.548 13.548 7.472 0 13.548-6.076 13.548-13.548S28.53 7.852 21.058 7.852zm-7.05 23.013a9.868 9.868 0 017.053-2.976 9.858 9.858 0 017.05 2.976 11.739 11.739 0 01-7.05 2.343c-2.642 0-5.082-.871-7.051-2.343h-.002zm15.416-1.14a11.596 11.596 0 00-8.366-3.575c-3.163 0-6.187 1.299-8.369 3.574a11.772 11.772 0 01-3.44-8.32c.002-6.513 5.3-11.81 11.81-11.81 6.509 0 11.806 5.297 11.806 11.807 0 3.244-1.316 6.187-3.44 8.323z"
        fill="#fff"
      />
    </StyledProfileIcon>
  );
}

export default ProfileIcon;
