import React, { useContext } from "react";
import { TolltipIconContainer, LoadingIcon } from "./styles";
import { GeneralContext } from "utils/contexts/GeneralContext";

function TolltipIcon({ className, onMouseEnter, onMouseLeave }) {

    const { loading, width, height } = useContext(GeneralContext);

    return (
        loading ? (
            <LoadingIcon className={className} width={width} height={height} />
        ) : (
            <TolltipIconContainer
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
                width={width}
                height={height}
            >
                <path d="M14 7A7 7 0 110 7a7 7 0 0114 0z" />
                <path
                    d="M6.253 10.64V5.095h1.503v5.545H6.253zM6.16 3.52c0-.354.272-.72.835-.72.595 0 .845.366.845.72 0 .333-.25.71-.845.71-.563 0-.835-.377-.835-.71z"
                />
            </TolltipIconContainer>
        )
    );
}

export default TolltipIcon;
