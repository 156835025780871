import React, { useContext } from 'react';
import { Container, ContainerGrafic } from './styles';
import TitleCard from 'components/molecules/TitleCard';
import ActiveGroup from 'components/molecules/ActiveGroup';
import SubtitleGroup from 'components/molecules/SubtitleGroup';
import { GeneralContext } from 'utils/contexts/GeneralContext';

const MainAssetsCard = ({
  title,
  url,
  colors,
  mainAssets,
  showToolTip,
  tooltipText,
  className,
}) => {
  const { loading } = useContext(GeneralContext);

  return (
    <Container>
      <TitleCard
        tooltipText={tooltipText}
        url={url}
        title={title}
        showToolTip={showToolTip}
        loading={loading}
        className={`${className} title-card`}
      />
      <ContainerGrafic className={className}>
        <SubtitleGroup
          mainAssets={mainAssets}
          loading={loading}
          className={className}
        />
        <ActiveGroup
          colors={colors}
          mainAssets={mainAssets}
          loading={loading}
          className={className}
        />
      </ContainerGrafic>
    </Container>
  );
};

export default MainAssetsCard;
