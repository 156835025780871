import styled from 'styled-components';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

export const GenericLoading = styled.div`
  display: block;
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.gradientSkeleton[0]} 25%,
    ${({ theme }) => theme.gradientSkeleton[1]} 50%,
    ${({ theme }) => theme.gradientSkeleton[0]} 75%
  );
  background-size: 200% 100%;
  animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;

  &.goal-panel-navigation-card {
    width: ${({ theme }) => theme.getResponsiveWidth(376)};
    height: ${({ theme }) => theme.getResponsiveWidth(256)};
    min-width: 200.53px;
    min-height: 136.53px;
    border-radius: ${({ theme }) => theme.getResponsiveWidth(16)};

    @media (max-width: 1023px) {
      border-radius: 8.54px;
    }
  }

  &.goals-panel-title {
    height: 39px;
    width: 75%;
    border-radius: 50px;

    @media (min-width: 2161px) {
      margin-top: 36px;
      height: 36px;
    }

    @media (min-width: 1921px) and (max-width: 2160px) {
      margin-top: 32px;
      height: 32px;
    }

    @media (max-width: 1920px) {
      margin-top: 24px;
      height: 24px;
    }

    @media (max-width: 1440px){
      margin-top: 18.1px;
      height: 18.1px;
    }
    
    @media (max-width: 1024px) {
      margin-top: 16px;
      height: 16px;
    }
  }

  &.goals-panel-searchbar {
    width: 20%;
    height: 39px;
    border-radius: 8px;

    @media (min-width: 2161px) {
      margin-top: 36px;
      height: 36px;
    }

    @media (min-width: 1921px) and (max-width: 2160px) {
      margin-top: 32px;
      height: 32px;
    }

    @media (max-width: 1920px) {
      margin-top: 24px;
      height: 24px;
    }

    @media (max-width: 1440px){
      margin-top: 18.1px;
      height: 22px;
    }
    
    @media (max-width: 1024px) {
      margin-top: 16px;
      height: 18px;
    }
  }

  &.goals-panel-dream {
    width: ${({ theme }) => theme.getResponsiveWidth(376)};
    height: ${({ theme }) => theme.getResponsiveWidth(396)};
    margin-top: ${({ theme }) => theme.getResponsiveWidth(32)};
    border-radius: ${({ theme }) => theme.getResponsiveWidth(16)};
    transition: all 0.7s ease-in-out;

    @media (max-width: 1023px) {
      width: 200.53px;
      height: 211.2px;
      margin-top: 17.07px;
      border-radius: 8.53px;
    }

    &:hover {
      transition: transform 0.3s ease;
      transform: scale(1.05) translateX(10px) translateY(10px);
      transform-origin: left end;
    }
  }

  &.empty-suitability-questionnaire {
    width: ${({ theme }) => theme.getResponsiveWidth(1600)};
    height: ${({ theme }) => theme.getResponsiveWidth(532)};
    border-radius: ${({ theme }) => theme.getResponsiveWidth(16)};
    margin-top: ${({ theme }) => theme.getResponsiveWidth(34)};
    margin-bottom: ${({ theme }) => theme.getResponsiveWidth(64)};
  }

  &.investment-panel-title {
    width: ${({ theme }) => theme.getResponsiveWidth(279)};
    height: ${({ theme }) => theme.getResponsiveWidth(25)};
    margin-left: ${({ theme }) => theme.getResponsiveWidth(22)};
    border-radius: ${({ theme }) => theme.getResponsiveWidth(16)};
    min-width: 148.8px;
    min-height: 13.3px;

    @media (max-width: 1023px) {
      margin-left: 11.7px;
      border-radius: 8.5px;
    }
  }

  &.investment-panel-go-icon {
    width: ${({ theme }) => theme.getResponsiveWidth(25)};
    height: ${({ theme }) => theme.getResponsiveWidth(25)};
    margin-left: ${({ theme }) => theme.getResponsiveWidth(20)};
    border-radius: ${({ theme }) => theme.getResponsiveWidth(8)};

    @media (max-width: 1023px) {
      width: 13.3px;
      height: 13.3px;
      margin-left: 10.7px;
      border-radius: 4.3px;
    }
  }

  &.investment-panel-table {
    width: 100%;
    height: ${({ theme }) => theme.getResponsiveWidth(444)};
    border-radius: ${({ theme }) => theme.getResponsiveWidth(8)};
    min-width: 826.7px;
    min-height: 237px;

    @media (max-width: 1023px) {
      border-radius: 4.3px;
    }
  }

  &.allocation-card {
    width: ${({ theme }) => theme.getResponsiveWidth(784)};
    height: ${({ theme }) => theme.getResponsiveWidth(512)};
    border-radius: ${({ theme }) => theme.getResponsiveWidth(16)};
    margin-bottom: ${({ theme }) => theme.getResponsiveWidth(35)};
    min-width: 418.1px;
    min-height: 273.1px;

    @media (max-width: 1023px) {
      border-radius: 8.5px;
      margin-bottom: 18.7px;
    }
  }

  &.immediate-liquidity {
    width: 100%;
    height: ${({ theme }) => theme.getResponsiveWidth(823)};
    border-radius: ${({ theme }) => theme.getResponsiveWidth(16)};
    margin-top: ${({ theme }) => theme.getResponsiveWidth(34)};
    margin-bottom: ${({ theme }) => theme.getResponsiveWidth(64)};
    margin-bottom: ${({ theme }) => theme.getResponsiveWidth(43)};
    min-width: 853px;

    @media (max-width: 1023px) {
      width: 853px;
      height: 438.9px;
      border-radius: 8.5px;
      margin-top: 18.1px;
      margin-bottom: 22.9px;
    }
  }
  &.detail-pot {
    width: ${({ theme }) => theme.getResponsiveWidth(1600)};
    height: ${({ theme }) => theme.getResponsiveHeight(256)};
    margin-top: ${({ theme }) => theme.getResponsiveHeight(100)};
  }

  &.extract-menu-header {
    width: 100%;
    height: 42px;
    border-radius: 8px;

    @media (max-width: 1440px) {
      height: 36px;
    }
  }

  &.extract-redirect-icons {
    height: 40px;
    width: 40px;
    border-radius: 8px;

    @media (max-width: 1440px) {
      width: 36px;
      height: 36px;
    }
  }

  &.extract-menu-dropdown {
    width: 48.03%;
    height: 42px;
    border-radius: 8px;

    @media (max-width: 1440px) {
      height: 38px;
    }
  }

  &.extract-category-title {
    width: 160px;
    height: 29px;
    border-radius: 8px;

    @media (max-width: 1440px) {
      height: 25px;
    }

    @media (max-width: 1024px) {
      height: 22px;
    }
  }

  &.extract-category-title-text {
    width: 204px;
    height: 18px;
    border-radius: 8px;
    align-self: flex-end;

    @media (max-width: 1440px) {
      height: 14px;
    }
  }

  &.extract-category-title-progressbar {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    margin-top: 8px;

    @media (max-width: 1440px) {
      margin-top: 6px;
    }
  }

  &.extract-category-title-icon {
    width: 20px;
    height: 20px;
    border-radius: 4px;

    @media (max-width: 1440px) {
      width: 16px;
      height: 16px;
    }

    @media (max-width: 1024px) {
      width: 14px;
      height: 14px;
    }
  }

  &.extract-item-progressbar {
    width: 100%;
    height: 14px;
    border-radius: 20px;

    @media (max-width: 1440px) {
      height: 12px;
    }

    @media (max-width: 1024px) {
      height: 10px;
    }
  }

  &.detailed-extract-footer {
    width: 100%;
    height: 48px;
    border-radius: 8px;

    @media (max-width: 1440px) {
      height: 44px;
    }
  }

  &.detailed-extract-item {
    width: 100%;
    height: 32px;
    border-radius: 8px;

    @media (max-width: 1440px) {
      height: 28px;
    }
  }

  &.detailed-extract-item-loading {
    width: 100%;
    height: 32px;
    border-radius: 8px;
    padding: 20px 0;

    @media (max-width: 1440px) {
      height: 28px;
      padding: 16px 0;
    }

    @media (max-width: 1024px) {
      height: 24px;
      padding: 12px 0;
    }
  }

  &.extract-item-body {
    width: 100%;
    height: 14px;
    border-radius: 20px;

    @media (max-width: 1024px) {
      height: 12px;
    }
  }

  &.simple-modal-delete-header {
    width: 100%;
    height: 23px;
    border-radius: 20px;

    @media (max-width: 1440px) {
      height: 20px;
    }

    @media (max-width: 1024px) {
      height: 18px;
      border-radius: 16px;
    }
  }

  &.simple-modal-delete-body {
    width: 90%;
    height: 36px;
    border-radius: 8px;
    margin: 0 auto;

    @media (max-width: 1440px) {
      height: 32px;
    }

    @media (max-width: 1024px) {
      height: 28px;
    }
  }

  &.simple-modal-delete-footer {
    width: 20%;
    height: 34px;
    border-radius: 4px;

    @media (max-width: 1440px) {
      height: 30px;
    }

    @media (max-width: 1024px) {
      height: 26px;
    }
  }

  &.simple-modal-delete-footer.first {
    width: 20%;
  }

  &.simple-modal-delete-footer.second {
    width: 30%;
  }

  &.simple-modal-edit-item-category {
    width: 100%;
    height: 60px;
    border-radius: 8px;

    @media (max-width: 1440px) {
      height: 52px;
    }

    @media (max-width: 1024px) {
      height: 46px;
    }
  }

  &.simple-modal-edit-item-category-group-itens {
    height: 64px;
    border-radius: 8px;

    @media (max-width: 1440px) {
      height: 56px;
    }

    @media (max-width: 1024px) {
      height: 48px;
    }
  }

  &.simple-modal-edit-item-category-group-itens.first {
    width: 30%;
  }

  &.simple-modal-edit-item-category-group-itens.second {
    width: 62%;
  }
`;
