import * as React from "react"
import { SVG } from "./styles"

export const LifeAndHealthCategoryIcon = ({
    className,
}) => {
    return (
        <SVG
            className={className}
            viewBox="0 0 19 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.38 16.065l-.093-.085-6.502-5.953A5.474 5.474 0 010 5.987v-.12C0 3.291 1.855 1.081 4.423.598a5.486 5.486 0 014.15.882c.333.234.645.505.927.816.156-.176.323-.337.501-.487A5.504 5.504 0 0114.577.594C17.144 1.077 19 3.291 19 5.867v.12a5.474 5.474 0 01-1.785 4.04l-6.502 5.953-.092.085A1.665 1.665 0 019.5 16.5c-.416 0-.816-.154-1.12-.435zm.493-11.826c-.015-.011-.026-.026-.037-.04l-.66-.732-.005-.004a3.683 3.683 0 00-3.414-1.142c-1.729.326-2.976 1.812-2.976 3.546v.12c0 1.044.442 2.042 1.217 2.752L9.5 14.693l6.502-5.954a3.76 3.76 0 00.899-1.25c.21-.474.317-.985.318-1.501v-.121c0-1.73-1.247-3.22-2.973-3.546a3.69 3.69 0 00-3.414 1.142l-.004.004-.003.003-.66.732c-.012.015-.027.026-.038.04a.894.894 0 01-1.254 0V4.24z"
                fill="#140361"
            />
        </SVG>
    )
}
