import React from 'react';
import { Span } from 'components/atoms/Span';
import SetLeftIcon from 'components/atoms/icons/SetLeftIcon';
import SetRightIcon from 'components/atoms/icons/SetRightIcon';
import { GroupItens, TextContainer, TitleWrapper } from './styles';

export const TableTitleSet = ({
  nameTable,
  className,
  onClickLeft,
  onClickRight,
  currentTableIndex,
  maxTables,
}) => {
  const isFirstTable = currentTableIndex === 0;
  const isLastTable = currentTableIndex === maxTables - 1;

  return (
    <TextContainer className={className}>
      <GroupItens>
        <TitleWrapper className="set">
          <Span className="table-title">{nameTable}</Span>
          <div>
            <SetLeftIcon
              onClick={isFirstTable ? null : onClickLeft}
              className={isFirstTable ? 'gray' : className}
            />
            <SetRightIcon
              onClick={isLastTable ? null : onClickRight}
              className={isLastTable ? 'gray' : className}
            />
          </div>
        </TitleWrapper>
      </GroupItens>
    </TextContainer>
  );
};
