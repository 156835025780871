import styled from "styled-components";

export const InputImageContainer = styled.div`
  &.generic-dream {
    width: ${props => props.theme.getResponsiveWidth(357)};
    height: ${props => props.theme.getResponsiveWidth(104)};
    border: ${props => props.theme.getResponsiveWidth(1)} solid ${props => props.theme.grayShadow};
    margin-top: ${props => props.theme.getResponsiveWidth(32)};
    border-radius: ${props => props.theme.getResponsiveWidth(8)};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: ${props =>
        props.backgroundImage
            ? `linear-gradient(
            180deg,
            rgba(12, 5, 63, 0) -2.75%,
            rgba(12, 5, 63, 0.5525) 50.46%,
            rgba(12, 5, 63, 0.85) 99.89%
          ), url(${props.backgroundImage})`
            : 'none'
    };
    background-size: cover;
    background-position: center;

    @media (max-width: 1023px) {
      width: 190.4px;
      height: 55.47px;
      border: 0.53px solid ${props => props.theme.grayShadow};
      margin-top: 17.07px;
      border-radius: 4.27px;
    }
  }
`;