export const getNewRealease = () => {
    return {
      receiptClassification: [
        { value: 'Mensal Comprometido', label: 'Mensal Comprometido' },
        { value: 'Mensal Flexível', label: 'Mensal Flexível' },
        { value: 'Eventual Comprometido', label: 'Eventual Comprometido' },
        { value: 'Eventual Flexível', label: 'Eventual Flexível' },
      ],
      receiptName: [
        { value: 'Mensal Comprometido', label: 'Mensal Comprometido' },
        { value: 'Mensal Flexível', label: 'Mensal Flexível' },
        { value: 'Eventual Comprometido', label: 'Eventual Comprometido' },
        { value: 'Eventual Flexível', label: 'Eventual Flexível' },
      ],
      spendingClassification: [
        { value: 'Mensal Comprometido', label: 'Mensal Comprometido' },
        { value: 'Mensal Flexível', label: 'Mensal Flexível' },
        { value: 'Eventual Comprometido', label: 'Eventual Comprometido' },
        { value: 'Eventual Flexível', label: 'Eventual Flexível' },
      ],
      spendingName: [
        { value: 'Mensal Comprometido', label: 'Mensal Comprometido' },
        { value: 'Mensal Flexível', label: 'Mensal Flexível' },
        { value: 'Eventual Comprometido', label: 'Eventual Comprometido' },
        { value: 'Eventual Flexível', label: 'Eventual Flexível' },
      ],
      debtsClassification: [
        { value: 'Mensal Comprometido', label: 'Mensal Comprometido' },
        { value: 'Mensal Flexível', label: 'Mensal Flexível' },
        { value: 'Eventual Comprometido', label: 'Eventual Comprometido' },
        { value: 'Eventual Flexível', label: 'Eventual Flexível' },
      ],
    };
  };
  